<template>
  <div>
    <v-main>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title>Alert Logs</v-toolbar-title>
        <v-select
          class="ml-6 mt-6"
          color="black"
          style="font-size: 12px; max-width: 150px"
          v-model="model"
          clearable
          label="Model"
          :items="modelItems"
          :no-data-text="loadingText"
          @click:clearable="get_status_value(model, 'model')"
          @input="get_status_value(model, 'model')"
        >
        <template #item="{ item }">
          <span class="filter-font"> {{ item }}</span>
        </template>
        </v-select>

        <v-select
          class="ml-2 mt-6"
          color="black"
          style="font-size: 12px; max-width: 150px"
          v-model="groups"
          clearable
          label="Groups"
          :no-data-text="loadingText"
          :items="groupsItems"
          @click:clearable="get_status_value(groups, 'group')"
          >
          <template #item="{ item }">
            <span class="filter-font"> {{ item }}</span>
          </template>

        </v-select>

        <v-select
          class="ml-2 mt-6"
          color="black"
          style="font-size: 12px; max-width: 150px"
          v-model="eventType"
          clearable
          label="Event Type"
          :items="eventTypeItems"
          :no-data-text="loadingText"
          @click:clearable="get_status_value(eventType, 'eventType')"
        >
          <template #item="{ item }">
            <span class="filter-font"> {{ item }}</span>
          </template>

        </v-select>

        <v-menu
          v-if="filterParams == 'Custom'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="fromDate"
              class="ml-1 mt-6"
              label="From*"
              prepend-icon="mdi-calendar"
              outlined
              style="max-width: 250px"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="fromDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(fromDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          v-if="filterParams == 'Custom'"
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="toDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              class="mt-6"
              v-model="toDate"
              label="To*"
              prepend-icon="mdi-calendar"
              style="max-width: 250px"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="toDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(toDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <!-- component for excel -->
        <vue-json-to-csv
          :labels="{
            created_on: { title: 'Created On' },
            router_model: { title: 'Device Model' },
            alert_type: { title: 'Event Type' },
            router_group: { title: 'Group' },
            router_id: { title: 'Device SN' },
            alert_description: { title: 'Description' },
          }"
          csv-title="Alert Logs"
          :json-data="tableData"
        >
          <v-btn class="button ml-6" dark>Export</v-btn>
        </vue-json-to-csv>
        <v-spacer></v-spacer>

        <div class="container-wrapper">
          <v-text-field
            clearable
            append-icon="mdi-magnify blue--text"
            @click:clearable="clear_value"
            v-model="search"
            class="search-bar mr-1"
            label="Search"
            single-line
            style="max-width: 250px"
            @blur="search_deactive" 
          ></v-text-field>
        </div>


   


      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
          :items-per-page="itemsPerPage"
          :loading="loading2"
          class="elevation-1"
          dense
        >
          <template v-slot:header.created_on="{ header }">
            <span class="ml-2">
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            <span class="caption ml-2">{{ convert_date(item.created_on) }}</span>
          </template>
          <template v-slot:header.created_on="{ header }">
              <span class="ml-2">
                {{ header.text }}
              </span>
            </template>
          <template v-slot:[`item.router_name`]="{ item }">
            <span class="caption">{{ item.router_name }} </span>
          </template>
          <template v-slot:[`item.alert_type`]="{ item }">
            <span class="caption">{{ item.alert_type }}</span>
          </template>
          <template v-slot:[`item.router_group`]="{ item }">
            <span class="caption">{{ item.router_group }}</span>
          </template>
          <template v-slot:[`item.router_id`]="{ item }">
            <span class="caption">{{ item.router_id }}</span>
          </template>
          <template v-slot:[`item.router_model`]="{ item }">
            <span class="caption">{{ item.router_model }}</span>
          </template>
          <template v-slot:[`item.alert_description`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="caption ellipsis">{{
                  item.alert_description
                }}</span>
              </template>
              <span>{{ item.alert_description }}</span>
            </v-tooltip>
          </template>
          <template v-slot:footer>
            <div class="footer-height">
              <LoadingIcon v-if="loadingIcon == true"
              style="position: absolute"
              class="ml-2 mt-2"></LoadingIcon>
              
              <span
                v-if="loadingIcon == true"
                style="position: absolute"
                class="ml-10 mt-5">
                Loading alert logs...
              </span>

              <span v-else>
                <v-icon
                    @click="refresh_list()"
                    class= "ml-1 mt-3 btn-fix"
                    dark
                    outline="none"
                    color="cyan"
                    style= "position:absolute; font-size: 34px"
                    id="refresh"
                  >
                    mdi-refresh
                </v-icon>
                <span 
                  style="position: absolute"
                  class="ml-11 pl-0 mt-5">
                  Last updated: {{ lastUpdated }} 
                </span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-main>
  </div>
</template>
<script>
import { get_alert_logs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import VueJsonToCsv from "vue-json-to-csv";
import Styling from "/src/assets/Styling.css";
import SnackBar from "@/components/SnackBar.vue";
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  components: {
    VueJsonToCsv,
    Styling,
    SnackBar,
    LoadingIcon,

  },
  data: () => ({
    loading2: false,
    eventType: "",
    noOfDaysItems: [
      { text: "Today", value: 1 },
      { text: "Last 3 Days", value: 3 },
      { text: "Last 7 Days", value: 7 },
      { text: "Last 15 Days", value: 15 },
      { text: "Custom", value: "Custom" },
    ],
    menu: false,
    toDate: "",
    fromDate: "",
    menu1: false,
    SnackBarComponent: {},
    selectData: [],
    selectDataForExport: [],
    actionButtonStatus: 0,
    search: "",
    dropDown: "",
    filterParams: "",
    filterItems: ["Model", "Groups", "Event Type"],
    dropDown: "",
    search: "",
    tableData: [],
    eventTypeItems: [],
    modelItems: [],
    model: "",
    groupsItems: [],
    groups: "",
    filterCheck: false,
    selectedValue: "",
    filterdItem: "",
    finalData: [],
    itemsPerPage: 10,
    loading: false,
    headers: [
      {
        text: "Created On",
        align: "start",
        sortable: true,
        class: "headerColor white--text ",
        value: "created_on",
      },
      {
        text: "Model",
        sortable: true,
        class: "headerColor white--text ",
        value: "router_model",
      },
      {
        text: "Device SN",
        sortable: true,
        class: "headerColor white--text ",
        value: "router_id",
      },
      {
        text: "Group",
        sortable: true,
        class: "headerColor white--text ",
        value: "router_group",
      },
      {
        text: "Event Type",
        sortable: true,
        class: "headerColor white--text ",
        value: "alert_type",
      },

      {
        text: "Description",
        sortable: true,
        class: "headerColor white--text ",
        value: "alert_description",
      },

      {
        text: "",
        value: "valueStatus",
        sortable: false,
        class: "headerColor white--text ",
      },
    ],
    searchActive: false,
    modelInput: null,
    groupsInput: null,
    eventInput: null,
    loadingIcon: true,
    startTime: null,
    lastUpdated: "Recently Updated"
  }),
  created() {
    this.startTime = Date.now();
    setInterval(() => {
      this.timeSince(this.startTime);
    }, 60001);
    this.get_alert_logs();
  },

  computed: {
    loadingText(){
      if (this.loading2 == true) {
        return "Loading..."
      } else {
        return "No Data Available"
      }
    },
  },
  methods: {
    //method invokes on filter input
    get_filter_data(data) {
      this.filterCheck = false;
      this.selectedValue = data;
    },

    //method invokes on filter inputs
    get_status_value(data, category) {
      if (category == "model") {
        this.modelInput = data;
      }
      if (category == "group") {
        this.groupInput = data;
      }      
      if (category == "eventType") {
        this.eventInput = data;
      }
      this.filterCheck = true;
      this.get_routers_list();
      },

    //query call to fetch the data on filter option
    async get_routers_list() {
        this.overlay = true;
        this.isLoading = true;
        try {
          this.overlay = false;
          this.filterLoading = false;
          this.filterCheck = false;
          this.isLoading = false;
          this.loadingTable = false;
          var response = this.tableData;
          var filteredData = [];
          response.forEach((element) => {
            if (this.modelInput != null && element.router_model != this.modelInput) {
              return;
            }
            if (this.regInput != null && element.registered != this.regInput) {
              return;
            }
            if (this.orgInput != null && element.organization_name != this.orgInput) {
              return;
            }
            if (this.onlineInput != null && element.online_status != this.onlineInput) {
              return;
            }
            filteredData.push(element);
          })
          response.forEach((element) => {
              if (
                element.partner_info != undefined ||
                element.partner_info != null
              ) {
                element.partner_name = element.partner_info.user_first_name;
                element.partner_email = element.partner_info.user_email_id;
                element.partner_id = element.partner_info.user_id;
              }
        });
          this.tableData = filteredData;
          this.$forceUpdate();
        } catch (err) {
          console.log(err);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: err.errors[0].message,
            timeout: 5000,
            Top: true,
          };
          if (this.SnackBarComponent.SnackbarText !== err.errors[0].message){
            this.get_routers();
          }
          this.routerData = [];
          this.dataInfo = this.routerData;
          this.overlay = false;
          this.isLoading = false;
        }
      },
    //query call to fetch alert logs
    async get_alert_logs() {
      this.loading2 = true;
      this.loadingIcon = true;
      try {
        let result = await API.graphql(
          graphqlOperation(get_alert_logs, {
            input: {
              customer_id: this.$store.getters.GetCurrentObj.user.customer_id,
            },
          })
        );
        this.loadingIcon = false;
        this.loading2 = false;
        var response = JSON.parse(result.data.get_alert_logs);
        var data = response.items;

        var self = this;
        (this.modelItems = []), (this.groupsItems = []);
        this.eventTypeItems = [];
        data.forEach((element) => {
          self.groupsItems.push(element.router_group);
          self.modelItems.push(element.router_model);
          self.eventTypeItems.push(element.alert_type);
        });

        this.tableData = data;
        this.finalData = data;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
        this.loadingIcon = false;
        this.loading2 = false;
      }
    },
    timeSince(start) {
        var seconds = Math.floor((Date.now() - start) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) {
          this.lastUpdated = Math.floor(interval) + " years";
        } else {
          interval = seconds / 2592000;
          if (interval > 1) {
            this.lastUpdated = Math.floor(interval) + " months";
          } else {
            interval = seconds / 86400;
            if (interval > 1) {
              this.lastUpdated = Math.floor(interval) + " days";
            } else {
              interval = seconds / 3600;
              if (interval >= 1) {
                const hours = Math.floor(interval);
                const remainingMinutes = Math.ceil((seconds - hours * 3600) / 60);
                if (remainingMinutes > 0) {
                  this.lastUpdated = hours + (hours === 1 ? " hour " : " hours ") + remainingMinutes + (remainingMinutes === 1 ? " minute ago" : " minutes ago");
                } else {
                  this.lastUpdated = hours + (hours === 1 ? " hour ago" : " hours ago");
                }
              } else {
                interval = seconds / 60;
                if (interval >= 1) {
                  this.lastUpdated = Math.floor(interval) + (Math.floor(interval) === 1 ? " minute ago" : " minutes ago");
                } else {
                  this.lastUpdated = "Recently Updated";
                }
              }
            }
          }
        }
      },
    async refresh_list() {
      this.loadingIcon = true;
      this.startTime = Date.now();

      await this.get_alert_logs();
    },
    // Fuction to display the date and time format
    convert_date(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(
          d.getDate()
        )}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },
    search_deactive() {
      if (this.search == null || this.search.length == 0) {
        this.search = "";
        this.searchActive = false;
      }
    },

    clear_value() {
      this.search = "";
    },
  },
};
</script>

<style>
.button.v-btn {
  text-transform: none;
}
.v-input.search-bar {
    width: 400px;
    margin-top: 25px;
  }
.v-icon.magnify-btn {
  color: #0195D2;
}

.ellipsis {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.filter-font {
  font-size: 14px;
}

</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
    border-color: #0195D2 !important;
    color: #0195D2 !important;
  }

  .v-application .search-bar >>> .mdi-close{
    color: grey !important
  }
</style>