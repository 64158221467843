import modelRG2100 from '@/models/rg2100';
import modelRG2102 from '@/models/rg2102';
import modelFX20 from '@/models/fx20';
import modelCG890 from '@/models/cg890';

export default [ 
    modelRG2100,
    modelRG2102,
    modelFX20,
    modelCG890,
]
