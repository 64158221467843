<template>
  <v-expansion-panels flat multiple :value="openPanels">
    <v-expansion-panel>
      <v-expansion-panel-header class="font-weight-light grey--text text--darken-3 text-h6">
        <div>{{ CardGroupTitle }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider class="mb-5"></v-divider>
        <slot name="body"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  props: {
    CardGroupTitle: String,
    isPreview: Boolean
  },
  watch: {
    isPreview: {
      handler(newVal){
        if(newVal) {
          this.openPanels = [0];
        } else {
          this.openPanels = [];
        }
      },
      immediate: true
    }
  },
  data(){
    return {
      openPanels: []
    }
  },
  methods: {
    initializeOpenPanels() {
      // Open all panels by default
      this.openPanels = this.items.map((item, index) => index);
    },
  },
}
</script>

<style scoped>
  ::v-deep .v-expansion-panel--active{
    border-radius: 20px !important;
  }
</style>