<template>
  <div>
    <v-card flat>
      <v-card-text>
        <span class="text-h6 primary--text"><b class="heading-font">Main Wi-Fi</b></span>
        <div class="subtitle-2">
          <b class="subheading-font"> This page allows you to configure basic feature of Main Wi-Fi interface. You can also change Wi-Fi bands, set the Wi-Fi network Name(SSID) / Password, and set the Wi-Fi maximum connection of Main/Guest interfaces.</b>
        </div>
        <div class="configurationCard">
          <v-row>
            <v-col cols="12" sm="10" md="8" lg="6">
              <v-select dense outlined label="Wi-Fi Band" item-text="text" item-value="value" :items="randBandItems" v-model="radioBand"></v-select>

              <v-text-field v-if="ssid == null || ssid == undefined || ssid.length <= 0" class="error-warning" label="Wi-Fi SSID Name*" hint="Please enter a Wi-Fi SSID Name" persistent-hint v-model="ssid" outlined dense maxlength="32"></v-text-field>
              <v-text-field v-else label="Wi-Fi SSID Name*" v-model="ssid" outlined dense maxlength="32"></v-text-field>

              <v-text-field v-if="authentication == 0" class="fixed-input" label="Password*" :type="isPwd ? 'text' : 'password'" @click:append="isPwd = !isPwd" :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'" id="password" outlined v-model="password" dense readonly maxlength="63"></v-text-field>
              <v-text-field v-else-if="password.length == 0" class="error-warning" label="Password*" hint="Please enter a password" persistent-hint :type="isPwd ? 'text' : 'password'" @click:append="isPwd = !isPwd" :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'" id="password" outlined v-model="password" dense maxlength="63"></v-text-field>
              <v-text-field v-else-if="password.length < 8" class="error-warning" label="Password*" hint="Password must be longer than 8 characters" persistent-hint :type="isPwd ? 'text' : 'password'" @click:append="isPwd = !isPwd" :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'" id="password" outlined v-model="password" dense maxlength="63"></v-text-field>
              <v-text-field v-else label="Password*" :type="isPwd ? 'text' : 'password'" @click:append="isPwd = !isPwd" :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'" id="password" outlined v-model="password" dense maxlength="63"></v-text-field>

              <v-select dense outlined label="Authentication" item-text="text" item-value="value" :items="authItems" v-model="authentication"></v-select>
              <v-row no-gutters>
                <v-col>
                  <v-select v-if="guestEnabledStatus == 0" label="Main Wifi" v-model="mainWifiData" outlined dense maxlength="2" :menu-props="{ bottom: true, offsetY: true }" :items="mainWifiItem" @input="get_main_count(mainWifiData)" hide-details></v-select>
                  <v-select v-else label="Main Wifi" v-model="mainWifiData" outlined dense maxlength="2" :menu-props="{ bottom: true, offsetY: true }" :items="mainWifiItem" @input="get_main_count(mainWifiData)" hide-details></v-select>
                </v-col>
                <v-col>
                  <v-select v-if="guestEnabledStatus == 0" label="Guest Wifi Disabled" class="ml-2" v-model="guestWifiData" outlined dense disabled hide-details maxlength="2" :menu-props="{ bottom: true, offsetY: true }" @input="get_wifi_count(guestWifiData)"></v-select>
                  <v-select v-else label="Guest Wifi" class="ml-2" v-model="guestWifiData" outlined dense hide-details maxlength="2" :menu-props="{ bottom: true, offsetY: true }" :items="guestWifiItem" @input="get_wifi_count(guestWifiData)"></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters class="pt-2">
                <v-col cols="5" v-if="availableConnections === 0" no-gutters class="pb-2 mb-2 pl-2 red--text">
                  Available Connections: <b>{{ availableConnections }}</b>
                </v-col>

                <v-col cols="5" v-else no-gutters class="pb-2 mb-2 pl-2">
                  Available Connections: <b>{{ availableConnections }}</b>
                </v-col>
                <em>(20 connections max)</em>
              </v-row>

              <v-select
                label="Inactive Time"
                v-model="inActiveTime"
                item-text="text"
                item-value="value"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[
                  { text: '10 minutes', value: 0 },
                  { text: '20 minutes', value: 1 },
                  { text: '30 minutes', value: 2 },
                  { text: 'Always-On', value: 3 },
                ]"
                outlined
                required
                dense
              ></v-select>
              <v-switch class="mt-n3" inset dense v-model="privacySeprator" hide-details>
                <template v-slot:label>
                  <div>Privacy Separator</div>
                </template>
              </v-switch>
              <v-switch inset dense v-model="ssidStealth" hide-details>
                <template v-slot:label>
                  <div>SSID Stealth</div>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
export default {
  props: {
    guestEnabledStatus: Number,
  },
  data() {
    return {
      authItems: [
        { value: 0, text: "None" },
        { value: 1, text: "WPA2 Personal" },
        { value: 2, text: "WPA3 Personal" },
        { value: 3, text: "WPA/WPA2 Mixed" },
        { value: 4, text: "WPA2/WPA3 Mixed" },
      ],
      connectionItems: [],
      totalConnectionsVal: null,
      ssid: "",
      privacySeprator: null,
      allowAccessWebUI: null,
      encryptionInfo: null,
      encryptItems: [
        { value: 0, text: "AES-CCMP" },
        { value: 1, text: "AES-TKIP" },
      ],
      password: "",
      isPwd: false,
      authentication: null,
      radioBand: null,
      randBandItems: [
        { value: 0, text: "Wi-Fi 2.4GHz" },
        { value: 1, text: "Wi-Fi 5GHz" },
      ],
      guestNetworkArray: [],
      tableData: [],
      ssidStealth: false,
      inActiveTime: null,
      maximumConnections: null,
      mainWifiConnection: null,
      guestwifiConnection: null,
      mainWifiData: null,
      guestWifiData: null,

      mainWifiItem: [],
      mainWifiNoGuest: [],
      guestWifiItem: [],
      totalCount: 0,
      mainWifiCount: 5,
      guestWifiCount: 5,
      availableConnections: 0,
      isMounted: false,
    };
  },

  computed: {
    configurationChanges() {
      return `${this.radioBand} | ${this.ssid} | ${this.password} | ${this.authentication} | ${this.mainWifiData} 
      | ${this.guestWifiData} | ${this.inActiveTime} | ${this.privacySeprator} | ${this.ssidStealth}`;
    },
    errorCheck() {
      return `${this.ssid} | ${this.password}`;
    },
  },
  watch: {
    configurationChanges() {
      if (this.isMounted == true) {
        this.validate_data();
      }
    },
    errorCheck: {
      handler(){
        if (this.isMounted == true) {
          if (this.ssid == null || this.ssid == undefined || this.ssid.length <= 0 || this.password.length < 8 || (/^\s|\s$/).test(this.ssid) || (/^\s|\s$/).test(this.password)) {
            this.$emit("configError", true, "wifi");
          } else {
            this.$emit("configError", false, "wifi");
          }
        }
      },
      immediate: true
    },
    guestEnabledStatus() {
      if (this.guestEnabledStatus == 0) {
        this.guestWifiData = 0;
        this.get_mainwifi_count();
        this.totalConnectionsVal = this.mainWifiData;
      } else if (this.mainWifiData < 20 && this.guestWifiData == 0) {
        this.guestWifiData = 1;
        this.totalConnectionsVal = this.mainWifiData + this.guestWifiData;
        this.get_mainwifi_count();
      } else {
        this.get_mainwifi_count();
      }
    },
    mainWifiData() {
      this.$emit("update", "main", this.mainWifiData);
    },
    guestWifiData() {
      this.$store.commit("SetGuestNetworkConnection", this.guestWifiData);
      this.$emit("update", "guest", this.guestWifiData);
    },
  },
  created() {
    this.bind_default_json();
    if (this.guestEnabledStatus == 0) {
      this.mainWifiCount = 10;
      this.guestWifiCount = 0;
    } else {
      this.mainWifiCount = 5;
      this.guestWifiCount = 5;
    }
  },
  mounted() {
    var primarNet = localStorage.getItem("primaryNetwork");
    var guestNet = localStorage.getItem("guestNetwork");
    if (JSON.parse(primarNet) == true || JSON.parse(guestNet) == true) {
      this.modified_data();
    }
    if (this.guestEnabledStatus == 0) {
      this.guestWifiData = 0;
    } else if (this.guestWifiData == 0 && this.mainWifiData < 20) {
      this.guestWifiData = 1;
    }
    setTimeout(() => {
      this.isMounted = true;
    }, 1);
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method to bind the default configuration
    bind_default_json() {
      var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN;
      // console.log(JSON.parse(JSON.stringify(this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN)));
      this.radioBand = defaultJson.Value.MainWiFi.RadioBand;
      this.authentication = defaultJson.Value.MainWiFi.AuthenticationMethod;
      this.ssid = defaultJson.Value.MainWiFi.WiFiName;
      this.password = defaultJson.Value.MainWiFi.WiFiPassword;
      this.ssidStealth = defaultJson.Value.MainWiFi.SSIDStealth == undefined ? false : defaultJson.Value.MainWiFi.SSIDStealth == 1 ? true : false;

      this.get_mainwifi_count();
      this.mainWifiCount = defaultJson.Value.MainWiFi.MaximumConnections;
      this.mainWifiData = defaultJson.Value.MainWiFi.MaximumConnections;
      this.guestWifiData = defaultJson.Value.Guest3WiFi.MaximumConnections;
      this.inActiveTime = defaultJson.Value.InActiveTime == undefined ? 1 : defaultJson.Value.InActiveTime;
      this.totalConnectionsVal = defaultJson.Value.TotalConnections;
      this.totalCount = defaultJson.Value.TotalConnections;
      this.availableConnections = 20 - this.totalConnectionsVal;
      this.privacySeprator = defaultJson.Value.MainWiFi.PrivacySeparator == undefined ? true : defaultJson.Value.MainWiFi.PrivacySeparator == 1 ? true : false;
      this.encryptionInfo = defaultJson.Value.MainWiFi.EncryptionMethod == undefined ? 0 : defaultJson.Value.MainWiFi.EncryptionMethod;
      this.format_guest_nwk(defaultJson.Value);
    },
    get_mainwifi_count() {
      this.mainWifiItem = [];
      if (this.guestEnabledStatus == 1) {
        for (var i = 1; i <= 20 - this.guestWifiData; i++) {
          this.mainWifiItem.push(i);
        }
        for (var i = 1; i <= 20 - this.mainWifiData; i++) {
          this.guestWifiItem.push(i);
        }
      } else {
        for (var i = 1; i <= 20; i++) {
          this.mainWifiItem.push(i);
        }
      }
      this.availableConnections = 20 - this.mainWifiData - this.guestWifiData;
    },

    //method to fetch guestwifi count based on main wifi value
    get_main_count(val) {
      var guest = 20 - val;
      this.totalConnectionsVal = this.mainWifiData + this.guestWifiData;
      this.availableConnections = 20 - this.mainWifiData - this.guestWifiData;
      this.mainWifiCount = val;
      this.guestWifiItem = [];
      if (this.mainWifiCount === 20) {
        this.guestWifiItem.push(0);
        this.guestWifiData = 0;
      } else {
        for (var i = 1; i <= guest; i++) {
          this.guestWifiItem.push(i);
        }
      }
    },

    //method to fetch guestwifi count on input
    get_wifi_count(val) {
      this.guestWifiCount = val;
      this.totalConnectionsVal = this.mainWifiData + this.guestWifiData;
      this.availableConnections = 20 - this.mainWifiData - this.guestWifiData;
      this.get_mainwifi_count();
    },

    //method to  convert the guestwifi values from json to array
    format_guest_nwk(val) {
      this.guestNetworkArray = [];

      var guestNw3 = {
        Enable: val.Guest3WiFiEnable,
      };
      if (val.Guest3WiFiEnable != undefined) {
        this.guestNetworkArray.push(guestNw3);
      }
      this.tableData = this.guestNetworkArray;
    },

    //method invokes on data modification
    modified_data() {
      this.radioBand = this.$store.getters.GetPrimaryNetwork.MainWiFi.RadioBand;
      this.authentication = this.$store.getters.GetPrimaryNetwork.MainWiFi.AuthenticationMethod;
      this.ssid = this.$store.getters.GetPrimaryNetwork.MainWiFi.WiFiName;
      this.password = this.$store.getters.GetPrimaryNetwork.MainWiFi.WiFiPassword;
      this.totalConnectionsVal = this.$store.getters.GetPrimaryNetwork.TotalConnections;

      this.availableConnections = 20 - this.totalConnectionsVal;

      this.ssidStealth = this.$store.getters.GetPrimaryNetwork.MainWiFi.SSIDStealth == undefined ? false : this.$store.getters.GetPrimaryNetwork.MainWiFi.SSIDStealth == 1 ? true : false;
      this.inActiveTime = this.$store.getters.GetPrimaryNetwork.InActiveTime == undefined ? 1 : this.$store.getters.GetPrimaryNetwork.InActiveTime;
      this.privacySeprator = this.$store.getters.GetPrimaryNetwork.MainWiFi.PrivacySeparator == 1 ? true : false;

      this.encryptionInfo = this.$store.getters.GetPrimaryNetwork.MainWiFi.EncryptionMethod;
      this.mainWifiData = this.$store.getters.GetPrimaryNetwork.MainWiFi.MaximumConnections;
      this.guestWifiData = this.$store.getters.GetPrimaryNetwork.Guest3WiFi.MaximumConnections;
      this.mainWifiItem = [];
      this.totalCount = this.$store.getters.GetPrimaryNetwork.TotalConnections;
    },

    //method to restrict to numbers only
    is_Number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    //method to restric the special characters
    specal_charaters(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var text = clipboardData.getData("Text");
        var reg = /^[a-zA-Z0-9- ]+$/;
        if (!reg.test(text)) {
          event.preventDefault();
        } else {
          return false;
        }
      }
      var regex = new RegExp("^[a-zA-Z0-9- ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },

    // method to validate the data on apply action
    validate_data() {
      var valid = true;
      if (this.authentication == null || this.totalConnectionsVal == null || this.inActiveTime == null) {
        valid = false;
        this.triggerSnackBar("error", "Provide Mandatory Fields");
      } else if (this.guestEnabledStatus != 0 && this.guestWifiData == 0) {
        valid = false;
        this.triggerSnackBar("error", "Guest Wi-Fi must have at least 1 connection");
      }
      if (valid) {
        this.save_data();
      }
    },

    //method invokes on valid data
    save_data() {
      var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value;
      var primarNet = localStorage.getItem("guestNetwork");
      var temp = JSON.parse(primarNet);
      var data = {};

      if (this.tableData.length == 1) {
        data = {
          Guest3WiFi: temp == true ? this.$store.getters.GetGuestNetwork.Guest3WiFi : this.get_guest_wifi(defaultJson.Guest3WiFi),
          Guest3WiFiEnable: temp == true ? this.$store.getters.GetGuestNetwork.Guest3WiFiEnable : defaultJson.Guest3WiFiEnable,
          MainWiFi: this.get_data(),
          InActiveTime: this.inActiveTime,
          AllowGuestWiFiToAccessWebUI: 0,
          TotalConnections: parseInt(this.totalConnectionsVal),
        };
      }
      this.$store.commit("SetInternet", true);
      localStorage.setItem("guestNetwork", true);
      this.$store.commit("SetGuestNetwork", data);
      localStorage.setItem("primaryNetwork", true);
      this.$store.commit("SetPrimaryNetwork", data);
      var defaultInactiveTime = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value.InActiveTime;
      if (this.inActiveTime !== defaultInactiveTime) {
        localStorage.setItem("inactiveTime", true);
      }
      this.$emit("apply");
    },

    //method to fetch the main wifi object
    get_data() {
      var data = {
        RadioBand: this.radioBand == null ? 0 : this.radioBand,
        WiFiName: this.ssid,
        WiFiPassword: this.password,
        AuthenticationMethod: this.authentication,
        MaximumConnections: parseInt(this.mainWifiData),
        SSIDStealth: this.ssidStealth == true ? 1 : 0,
        PrivacySeparator: this.privacySeprator == true ? 1 : 0,
        EncryptionMethod: this.encryptionInfo,
      };

      return data;
    },

    //method to fetch the guest wifi object
    get_guest_wifi(val) {
      var data = {
        AuthenticationMethod: val.AuthenticationMethod,
        RadioBand: val.RadioBand,
        WiFiName: val.WiFiName,
        WiFiPassword: val.WiFiPassword,
        SSIDStealth: val.SSIDStealth == undefined ? 0 : val.SSIDStealth,

        MaximumConnections: parseInt(this.guestWifiData),
        PrivacySeparator: val.PrivacySeparator == undefined ? 0 : val.PrivacySeparator,
        EncryptionMethod: val.EncryptionMethod == undefined ? 0 : val.EncryptionMethod,
      };

      return data;
    },
  },
};
</script>

<style scoped>
.available_connections {
  margin-bottom: 10px;
}

.error-warning >>> .theme--light.v-messages,
.error-warning >>> .v-label.theme--light {
  color: red !important;
}

.error-warning >>> .v-input__slot fieldset {
  border-color: red !important;
}

.fixed-input >>> fieldset {
  background-color: rgb(219, 219, 219) !important;
}

.fixed-input >>> .v-text-field__slot input {
  color: rgba(110, 110, 110, 0.87) !important;
}
</style>
