<template>
  <div>
    <v-dialog
      overflow-hidden
      persistent
      v-model="revertChangesDialog"
      max-width="400px"
    >
      <v-card class="cardBg">
        <v-card-title class="medium cardTitle">
          <span> Revert Invalid Params</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close_dialog()">
            <v-icon color="iconCross">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="cardBg pt-6 justify-center">
          Would you like to revert your changes for <b>"{{ revertChangesPath }}"</b>?
          <br />
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn class="blue--text" outlined @click="close_dialog()"
            >Cancel</v-btn>
          <v-btn @click="revert_changes()" dark class="button mr-2"
            >Revert</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    revertChangesDialog: Boolean,
    revertChangesPath: String,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },

   revert_changes() {
      this.$emit("clicked", 1);
    },
  },
};
</script>