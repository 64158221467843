import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v(" Basic Security")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Enabling Firewall Switch will allow configuring Port Forwarding features.")])]),_c('div',{staticClass:"configurationCard"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('div',{staticClass:"configurationCard"},[_c(VSwitch,{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Firewall Switch")])]},proxy:true}]),model:{value:(_vm.ip4firewall),callback:function ($$v) {_vm.ip4firewall=$$v},expression:"ip4firewall"}})],1)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ip4firewall),expression:"ip4firewall"}],staticClass:"mt-4 ml-n4 mr-n4"},[_c('PortForwarding',{ref:"portforwarding",on:{"saveData":function($event){return _vm.save_change()}}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }