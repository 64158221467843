const helpTable = {
  RG2100: {
    mainWifi: {
      title: "Main Wi-Fi",
      items: [
        { title: "Wi-Fi Band", text: ["This is the frequency band of your router's Wi-Fi. Your devices supports 2.4GHz and 5.0GHz."] },
        { title: "Wi-Fi SSID", text: ["This is the name of your Wi-Fi. You will see this name on the screen of the devices you are trying to connect to your router."] },
        { title: "Wi-Fi Password", text: ["This is the password of your Wi-Fi."] },
        { title: "Authentication Method", text: ["Choose the Wi-Fi Authentication method that best fits your Wi-Fi security. We recommend the WPA3 security.", "OPEN: Authentication and Encryption will not be performed. There are risks that private info will be intercepted or network will be used by unauthorized individuals.", "WPA2: WPA2-PSK is the securer version of WPA with implementation of the 802.11i standard.", "WPA3: WPA3 is the latest, updated implementation of WPA2.", "WPA/WPA2 Mixed: Apply both the WPA-PSK and WPA3-PSK scheme. WPA2/WPA3 Mixed: Apply both the WPA2 and WPA3 scheme."] },
        { title: "Main Wi-Fi Connections Allowed", text: ["This controls the maximum number of devices that are allowed to connect to your router's main Wi-Fi, up to 20 devices."] },
        { title: "Guest Wi-Fi Connections Allowed", text: ["This controls the maximum number of devices that are allowed to connect to your router's guest Wi-Fi, up to 20 devices."] },
        { title: "Inactive Time", text: ["If there is no Wi-Fi connection during the Inactive Time set, the device goes to sleep mode to save battery power."] },
        { title: "Privacy Separator", text: ["Turning on this option creates an environment on your router where devices that are connected to it via Wi-Fi cannot communicate with each other, keeping your devices more secure."] },
        { title: "SSID Stealth", text: ["Turning this option on will hide your Wi-Fi name(SSID) from other devices searching for Wi-Fi. Other than the devices that is already connected to your Wi-Fi, no other device will be able to make connection to your router."] },
      ],
    },
    guestWifi: {
      title: "Guest Wi-Fi",
      items: [
        { title: "Enable Guest Wi-Fi", text: ["Turns on/off your guest Wi-Fi.", "If there is no Wi-Fi connection during the Inactive Time set, the device goes to sleep mode to save battery power."] },
        { title: "Wi-Fi Band", text: ["This is the frequency band of your router's Wi-Fi. Your devices supports 2.4GHz and 5.0GHz."] },
        { title: "Wi-Fi SSID", text: ["This is the name of your Wi-Fi. You will see this name on the screen of the devices you are trying to connect to your router."] },
        { title: "Wi-Fi Password", text: ["This is the password of your Wi-Fi."] },
        { title: "Authentication Method", text: ["Choose the Wi-Fi Authentication method that best fits your Wi-Fi security. We recommend the WPA3 security.", "OPEN: Authentication and Encryption will not be performed. There are risks that private info will be intercepted or network will be used by unauthorized individuals.", "WPA2: WPA2-PSK is the securer version of WPA with implementation of the 802.11i standard.", "WPA3: WPA3 is the latest, updated implementation of WPA2.", "WPA/WPA2 Mixed: Apply both the WPA-PSK and WPA3-PSK scheme. WPA2/WPA3 Mixed: Apply both the WPA2 and WPA3 scheme."] },
        { title: "Privacy Separator", text: ["Turning on this option creates an environment on your router where devices that are connected to it via Wi-Fi cannot communicate with each other, keeping your devices more secure."] },
        { title: "SSID Stealth", text: ["Turning this option on will hide your Wi-Fi name(SSID) from other devices searching for Wi-Fi. Other than the devices that is already connected to your Wi-Fi, no other device will be able to make connection to your router."] },
      ],
    },
    connections: {
      title: "Connections",
      items: [
        { title: "Connection Mode", text: [`Select network type for your mobile hotspot to connect to. This list determines the mode of you Wide Area Network connection. If you choose “5G Only” for example, your device will maintain wireless connection only through 5G data network.`] },
        { title: "Connection Type", text: ["Select connection type between your connected devices and mobile hotspot."] },
        { title: "Data Roaming", text: ["Turn Data Roaming ON or OFF."] },
        { title: "Automatic DNS Enable", text: ["Your mobile hotspot uses Domain Name Servers(DNS) assigned by your network by default. The DNS Manual Mode option allows you touse your own DNS service or an external DNS service."] },
        // { title: "Active Profile Index", text: ["Active Profile refers to the currently active APN in the list. Typically, the index number of the Attached profile is the same as the active profile."] },
        // { title: "Attached Profile Index", text: ["if your mobile operator provides a separate APN,  Attached Profile can refer to a different one. Typically, the index number of the Attached profile is the same as the active profile."] },
        // { title: "APN List", text: ["The Internet access point name (APN) is the address used to connect to the Internet when using a cellular data connection.  Some cellular networks use technology that requires an APN configured on the modem to successfully establish an internet connection. The router is able to automatically discern and supply commonly used APNs, so in most cases, no additional configuration is necessary. Adding allows assigning a new Internet APN based on the mobile operator.", "CAUTION: Be careful when adding or editing it. If you enter nonfunctioning APN settings, your remote device might lose the internet connection."] },
      ],
    },
    display: {
      title: "Display",
      items: [
        { title: "Show Usage", text: ["This will determine whether the data usage information on your device's display will be showing or not."] },
        { title: "Device Display Timeout", text: ["This setting determines how long it takes for the device display to go into sleep mode after sitting idle for 'x' amount of time."] },
        { title: "Power LED Indicator", text: ["This setting controls if the white light (LED) illuminates below the device display, indicating the device is powered on."] },
        { title: "Display Wi-Fi SSID & Password", text: ["With this option, you can control whether to display Wi-Fi SSID & Password on your device or not."] },
        { title: "Display Web Admin Page", text: ["Display the Web Admin page information on your device."] },
      ],
    },
    control: {
      title: "Control",
      items: [
        { title: "Factory Reset Button", text: ["This will disable the physical Factory Reset button underneath the battery cover and the factory reset menu on the device display. If Disable is selected, pressing the button will not perform the factory reset."] },
        { title: "Power Bank", text: ["Your device can charge your cellphone or similar device. Connect your device to charge and select Connected device when prompted on the device display."] },
        { title: "Battery Level", text: ["You can choose a Stop charging battery level (25%, 50%, etc). Select the battery level where your device battery stops charging the other device connected via the USB cable provided."] },
        { title: "Access Web Interface", text: ["Allow accessing the device web UI. If Disabled is selected, a user will not access the device’s local web UI."] },
        { title: "Password", text: ["This is your web interface password. You change it directly through this field."] },
      ],
    },
    dataUsage: {
      title: "Data Usage",
      items: [
        { title: "Usage Cycle Reset", text: ["Usage Cycle: Select if the data usage meter resets monthly or yearly.", "Monthly or Yearly: Select the day (or month) when the data usage meter resets.", "Unlimited Usage: Check this item, you will not get any alert message on your device display.", "Uncheck this item and enter a data usage amount based on the Gigabyte amount. You will see the alert message on your device display when data usage reaches 90% of the amount set here."] },
        { title: "Usage Alert", text: ["You will be alerted every time you use up to the data threshold."] },
      ],
    },
    dhcpRange: {
      title: "DHCP Range",
      items: [
        { title: "Default Gateway", text: ["This is a default gateway IP of your device. The IP address for your Mobile Hotspot Web Admin page.", "DHCP (Dynamic Host Configuration Protocol) server – The DHCP server is ON by default. When turned ON, your mobile hotspot automatically assigns local IPs to the devices connected to your mobile hotspot. When turned OFF, you will need to set it up manually from the device you want to connect to your mobile hotspot."] },
        { title: "DHCP IP Pool Starting / Ending Address", text: ["Defines the local IP range that DHCP server assigns to connected devices."] },
        { title: "DHCP Lease Time", text: ["The period between when your connected device obtained its IP address from your mobile hotspot and the time when it expires. When the DHCP lease time expires, your connected device automatically releases IP address and asks your mobile hotspot to assign a new one."] },
      ],
    },
    security: {
      title: "Basic Security",
      items: [
        { title: "Firewall Switch", text: ["To set up Port Forwarding, turn Firewall Switch ON. If Firewall Switch is OFF, Port Forwarding settings are not active."] },
        { title: "Port Forwarding",
        text: [
            "1. Check the Enable item to turn ON Port Forwarding feature.",
            "2. Press Add to create a line to set up a rule.",
            "3. Select the Enabled button and enter the profile name of the rule you want to set up.",
            "4. Enter WAN port number of allowed inbound forward packet.",
            "5. Enter LAN port number of allowed inbound forward packet.",
            "6. Enter LAN IP address your connected device that is assigned by your mobile hotspot.",
            "7. Select Internet Protocol (TCP, UDP, TCP & UDP)"
          ]
        },
      ],
    },
    macFiltering: {
      title: "MAC Filtering",
      items: [
        { 
          title: "Allow only users on this list",
          text: [
            "The MAC filtering allows only selected devices to access your mobile hotspot Wi-Fi network. By default, MAC filtering is turned OFF. To enable MAC Filtering:",
            "1. Enable the “Allow only users on this list” switch.",
            `2. Press Add to add a rule to enter rule name and MAC address, then click the Save button. When entering MAC addresses, the “:” separators (for example, c2:b5:d7:27:fb:9b) will add automatically.`,
            "3. To add more, press Add to add another line.",
            "NOTE: If MAC filtering is on and at least one device is listed, only the listed devices can access your device Wi-Fi network. The MAC filtering has no effect on devices connected via USB connection."
          ] 
        }
      ],
    },
    todAccess: {
      title: "Time Of Day Access",
      items: [{ title: "Schedule List", text: ["Schedules"] }],
    },
  },
  FX20: {
    wirelessLAN: {
      title: "Wireless LAN",
      items: [
        { title: "Wi-Fi SSID", text: ["This is the name of your Wi-Fi. You will see this name on the screen of the devices you are trying to connect to your router."] },
        { title: "Wi-Fi Password", text: ["This is the password of your Wi-Fi."] },
        { title: "Wi-Fi Authentication", text: ["Wi-Fi Authentication"] },
      ],
    },
    DMZ: {
      title: "DMZ",
      items: [{ title: "Enable", text: ["The Demilitarized Zone (DMZ) is a feature that allows only one (1) local user to be exposed to the Internet for special purposes like Internet gaming or video conferencing."] }],
    },
    DynamicDNS: {
      title: "Dynamic DNS",
      items: [{ title: "Enable", text: ["Dynamic DNS(DDNS) allows you to direct your domain or a subdomain to a resource that is behind a gateway that has a dynamically assigned IP address. You can be embedded a popular DDNS service for your purpose."] }],
    },
    dhcpRange: {
      title: "DHCP Range",
      items: [{ title: "Default Gateway", text: ["DHCP (Dynamic Host Configuration Protocol) manages to automatically assign IP addresses and other network configuration parameters to network devices."] }],
    },
    VPNPassthrough: {
      title: "VPN Passthrough",
      items: [{ title: "PPTP Passthrough", text: ["This item allows a VPN connection to pass through your router to the network clients."] }],
    },
    PortForwarding: {
      title: "Port Forwarding",
      items: [{ title: "Enable", text: ["Port Forwarding enables computers to access WWW, FTP or other services provided by the LAN."] }],
    },
    MACFiltering: {
      title: "MAC Filtering",
      items: [{ title: "Allow only users on this list", text: ["Turn on MAC Filtering"] }],
    },
    ContentFiltering: {
      title: "Content Filtering",
      items: [{ title: "Enable", text: ["Content Keyword Filter provides to block all sites or pages you deem inappropriate such as Dangerous, Distracting, or Unsavory. You're able to block specific sites using a specific keyword which is associated content."] }],
    },
  },
};

const state = {
  helpObject: {},
};

const getters = {
  // This is used for both dashboard info and router count
  getterGetHelp: (state) => {
    return state.helpObject;
  },
};

const mutations = {
  mutationSetHelp: (state, { model, id }) => {
    state.helpObject = helpTable[model][id] ? { ...helpTable[model][id] } : {};
  },
};

export default {
  state,
  getters,
  mutations,
};
