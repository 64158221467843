import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("DMZ")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" The Demilitarized Zone (DMZ) is a feature that allows only one (1) local user to be exposed to the Internet for special purposes like Internet gaming or video conferencing. ")])]),_c('div',{staticClass:"configurationCard"},[_c(VCheckbox,{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Enable")])]},proxy:true}]),model:{value:(_vm.dmzEnabled),callback:function ($$v) {_vm.dmzEnabled=$$v},expression:"dmzEnabled"}})],1),(this.dmzEnabled == true)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VTextField,{attrs:{"label":"Host IP Address","outlined":"","dense":""},model:{value:(_vm.hostIpAddress),callback:function ($$v) {_vm.hostIpAddress=$$v},expression:"hostIpAddress"}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }