<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-card flat>
      <v-card-text>
        <span class="text-h6 primary--text"
          ><b class="heading-font"> Basic Security</b></span
        >
        <div class="subtitle-2">
          <b class="subheading-font">
            Enabling Firewall Switch will allow configuring Port Forwarding
            features.</b
          >
        </div>
        <div class="configurationCard">
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <div class="configurationCard">
                <v-switch v-model="ip4firewall" hide-details>
                  <template v-slot:label>
                    <div>Firewall Switch</div>
                  </template>
                </v-switch>
              </div>
            </v-col>
          </v-row>
          <div v-show="ip4firewall" class="mt-4 ml-n4 mr-n4">
            <PortForwarding 
            ref="portforwarding"
            @saveData="save_change()"
            ></PortForwarding>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import PortForwarding from "@/components/Security/PortForwarding.vue"
export default {
  components: {
    SnackBar,
    PortForwarding
  },
  data() {
    return {
      dosProtect: true,
      antiforward: true,
      SnackBarComponent: {},
      ip6firewall: true,
      ip4firewall: true,
      isMounted: false,
    };
  },

  computed: {
    configurationChanges() {
      return `${this.ip4firewall}` 
    },
  },

  watch: {
    configurationChanges(newVal, oldVal){
      if (this.isMounted == true) {
        this.save_change();
      }
    }
  },

  created() {
    this.bind_default_json();
  },
  mounted() {
    var data = localStorage.getItem("firewallGeneral");
    if (JSON.parse(data) == true) {
      this.modified_data();
    }
    setTimeout(() => {
      this.isMounted = true;
    }, 1);
  },
  methods: {
    bind_default_json() {
      var defaultJson =
        this.$store.getters.GetDefaultConfig.configuration_module
          .FirewallGeneral.Value;
      this.dosProtect = defaultJson.DoSProtection == 1 ? true : false;
      this.ip4firewall =
        defaultJson.IPv4Firewall == undefined
          ? false
          : defaultJson.IPv4Firewall == 1
          ? true
          : false;
      this.ip6firewall =
        defaultJson.IPv6Firewall == undefined
          ? false
          : defaultJson.IPv6Firewall == 1
          ? true
          : false;
    },

    modify_port_forwarding() {
      this.$refs.portforwarding.modified_data();
    },
    modified_data() {
      var defaultJson = this.$store.getters.GetFirewallGeneral;
      this.dosProtect = defaultJson.DoSProtection == 1 ? true : false;
      this.ip4firewall = defaultJson.IPv4Firewall == 1 ? true : false;
      this.ip6firewall = defaultJson.IPv6Firewall == 1 ? true : false;
    
    },
    save_change(val) {
      if (val == 1) {
        this.$emit("apply");
        return
      }
      var data = {
        DoSProtection: this.dosProtect == true ? 1 : 0,
        IPv4Firewall: this.ip4firewall == true ? 1 : 0,
        IPv6Firewall: this.ip6firewall == true ? 1 : 0,
      };
      this.$store.commit("SetFirewall", true);
      this.$store.commit("SetFirewallGeneral", data);
      localStorage.setItem("firewallGeneral", true);
      this.$emit("apply");

    },
  },
};
</script>