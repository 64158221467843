import { render, staticRenderFns } from "./FotaPackage.vue?vue&type=template&id=9d98ca98&scoped=true&"
import script from "./FotaPackage.vue?vue&type=script&lang=js&"
export * from "./FotaPackage.vue?vue&type=script&lang=js&"
import style0 from "./FotaPackage.vue?vue&type=style&index=0&id=9d98ca98&prod&lang=css&"
import style1 from "./FotaPackage.vue?vue&type=style&index=1&id=9d98ca98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d98ca98",
  null
  
)

export default component.exports