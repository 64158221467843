import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.editDailog),callback:function ($$v) {_vm.editDailog=$$v},expression:"editDailog"}},[_c(VCard,{staticClass:"dialogCard cardBg overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar cardBg",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v("Edit > Keyword Filter")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close_dialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VRow,{staticClass:"mt-3 mx-2",attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"label":"Keyword","dense":"","outlined":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c(VRow,{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c(VCheckbox,{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Enable")])]},proxy:true}]),model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text",on:{"click":function($event){return _vm.validate_data()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }