<template>
  <v-app v-if="auth" id="topbar" elevation="0">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- v-if start -->
    <v-app-bar app :extension-height="getImpersonatedUser ? '60' : '70'" :height="getImpersonatedUser ? '' : '0'" clipped-left dense elevation="0" tile color="bannercolor" :class="getImpersonatedUser ? 'impersonate-user' : ''">
      <v-container v-if="getImpersonatedUser" class="impersonate-header ma-0 pa-0">
        <div class="d-flex align-center">
          <span class="ml-4 mt-1 white--text"><b class="mr-1">Admin View:</b> {{ adminEmail }}</span>
          <span class="ml-auto white--text mt-1 mr-4" style="cursor: pointer" @click="back_to_admin()">
            <v-icon class="white--text mr-1">mdi-close</v-icon>
            <u class="bold">Back to Admin</u>
          </span>
        </div>
      </v-container>

      <template #extension>
        <v-img @click="mixinLogoRedirectToHome" max-width="240px" src="@/assets/JextreamLogoWht.png"></v-img>
        <div class="white--text ml-5 mt-5">{{ deployVersion }}</div>
        <v-spacer></v-spacer>
        
        <v-card flat :class="getImpersonatedUser ? 'impersonate-user' : ''">
          <TopMenu></TopMenu>
        </v-card>
        <v-card flat color="bannercolor" class="ml-2 mt-2" @click.stop="drawer = !drawer">
          <v-card-text class="mt-n4">
            <b class="button--text">{{ selectedEmail }}</b>
            <br />
            <span class="button--text">{{ getCurrentUserRoleString }}</span>
            <br />
          </v-card-text>
        </v-card>
      </template>
    </v-app-bar>

    <router-view @contains-only-fx20="isFX20Available"></router-view>

    <!-- Navigation drawer for info details -->
    <SideBar :drawer.sync="drawer" :containsOnlyFX20="containsOnlyFX20" :adminEmail="adminEmail" @openPricingTable="openPricingTable()" @view_profile="view_profile_details()" @back_to_admin="back_to_admin()" @openCustomerPortal="openCustomerPortal()" @openManageSubscription="openManageSubscription()" @unsubscribe="unsubscribe_action()"></SideBar>

    <div v-if="profileCheck">
      <UpdateProfile :updateDialog="updateDialog" :accountAccess="getCurrentUserRole" :dataResponse="getUserInfo" :impersonatedUser="getImpersonatedUser" @currentUser="get_current_user" @clicked="close_dialog"></UpdateProfile>
    </div>
    <div v-if="dataCheck == 1">
      <Subscription :subscriptionDialog="subscriptionDialog" @close="close_subscription_dialog" />
    </div>
    <!-- <PricingPage :pricingPageDialog="pricingPageDialog" @close="closePricingPageDialog" /> -->
    <CustomerPortal :customerPortalDialog="customerPortalDialog" @close="closeCustomerPortalDialog" />
    <ManageSubscription :manageSubscriptionDialog="manageSubscriptionDialog" @close="closeManageSubscriptionDialog" />
    
  </v-app>
</template>

<script>
import alerticon from "@/assets/alerticon.png";
import dashicon from "@/assets/dashicon.png";

import TopMenu from "../components/MenuAccess/TopNav/TopMenu.vue";
import Subscription from "@/components/Subscription.vue";
import UpdateProfile from "@/components/UpdateProfileInfo/UpdateProfile.vue";
import SideBar from "@/components/MenuAccess/SideNav/SideMenu.vue";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";

import PricingPage from "../components/Stripe/PricingPage.vue";
import CustomerPortal from "../components/Stripe/CustomerPortal.vue"
import { mapGetters, mapActions } from "vuex";
import ManageSubscription from "../components/Stripe/ManageSubscription.vue";

export default {
  mixins: [mixin_routing_utils],
  components: {
    alerticon,
    dashicon,
    TopMenu,
    UpdateProfile,
    Subscription,
    SideBar,
    PricingPage,
    CustomerPortal,
    ManageSubscription
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    deployVersion: process.env.VUE_APP_VERSION,
    updateDialog: false,
    overlay: true,
    containsOnlyFX20: false,
    affiliation: "",
    drawer: false,
    profileCheck: false,
    events: ["mousemove", "mousedown", "scroll", "click", "load", "keypress", "mouseup", "dblclick", "mouseover", "mousewheel", "mouseout", "contextmenu"],
    logoutTimer: null,
    dataCheck: 0,
    subscriptionDialog: false,
    accountsPathSAdmin: "",
    managePathSAdmin: "",
    accountsPath: "",
    managePath: "",
    selectedEmail: "",
    email: "",
    adminEmail: "",
    pricingPageDialog: false,
    customerPortalDialog: false,
    manageSubscriptionDialog: false,
  }),

  async created() {
    if (this.auth == false) {
      if (this.$route.path !== "/") this.$router.push("/");
    }

    if (process.env.VUE_APP_DEPLOY == "development") {
      this.deployVersion += " " + "in Staging";
    }

    this.adminEmail = this.getImpersonateAccount;

    this.unwatchGetUserEmail = this.$store.watch(
      (state) => {
        return this.$store.getters.GetUserEmail;
      },
      async (val) => {
        this.adminEmail = this.getImpersonatedUser ? this.getImpersonateAccount : "";
        this.updateEmailValues();
        await this.get_current_user();
      },
      {
        immediate: true,
      }
    );
  },
  // destroying a watcher declared in "created()" is absolutely necessary to avoid orphaned instance and watcher duplication
  beforeDestroy() {
    if (this.unwatchGetUserEmail) {
      this.unwatchGetUserEmail();
    }
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetCurrentUserRoleString", "GetImpersonate", "GetImpersonateAccount", "GetUserEmail", "GetImpersonateAccType"]),
    // To check auth status
    auth() {
      if (this.$store.getters.GetAuth == false) {
        if (this.$route.path !== "/") this.$router.push("/");
      }
      return this.$store.getters.GetAuth;
    },
    // needs to be changed to ;
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    getCurrentUserRoleString() {
      return this.getterGetCurrentUserRoleString;
    },
    getImpersonatedUser() {
      return this.GetImpersonate;
    },
    getImpersonateAccount() {
      return this.GetImpersonateAccount;
    },
    getImpersonateAccType() {
      return this.GetImpersonateAccType;
    },
    getUserEmail() {
      return this.GetUserEmail;
    },
    getUserInfo() {
      return this.getterGetUserInfo;
    },
  },

  methods: {
    ...mapActions(["actionGetUserInfo", "actionLogOut"]),

    //method to fetch the current user details
    async get_current_user() {
      try {
        this.overlay = true;
        await this.actionGetUserInfo(this.GetUserEmail);
        this.$store.commit("SetCurrentObj", this.getUserInfo);
        this.mixinRedirectFromHome(this.getCurrentUserRole, this.$route.path);
        this.mixinImpersonateRouting(this.getImpersonateAccount, this.getImpersonateAccType);
        this.affiliation = getUserInfo.customer.affiliation;
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    // openSubscription() {
    //   this.dataCheck = 1;
    //   this.subscriptionDialog = true;
    // },
    openPricingTable() {
      this.pricingPageDialog = true;
    },
    openCustomerPortal() {
      this.customerPortalDialog = true;
    },
    openManageSubscription() {
      this.manageSubscriptionDialog = true;
    },
    setTimers() {
      this.logoutTimer = setTimeout(() => {
        this.$router.push("/");
      }, 3600 * 1000);
    },

    resetTimer() {
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    //method invokes on update profile action
    view_profile_details() {
      this.profileCheck = true;
      this.updateDialog = true;
    },

    // method invokes on close actions
    close_dialog(val) {
      this.updateDialog = false;
      this.profileCheck = false;
      setTimeout(() => {
        if (val == 1) {
          this.drawer = true;
        }
      }, 1);
    },
    
    closePricingPageDialog() {
      this.pricingPageDialog = false;
    },
    closeCustomerPortalDialog() {
      this.customerPortalDialog = false;
    },
    closeManageSubscriptionDialog() {
      this.manageSubscriptionDialog = false;
    },
    isFX20Available(containsOnlyFX20) {
      this.containsOnlyFX20 = containsOnlyFX20;
    },

    back_to_admin() {
      this.drawer = false;
      this.overlay = true;
      this.$store.commit("SetUserEmail", this.getImpersonateAccount);
      this.$store.commit("SetImpersonate", false);
      this.$store.commit("SetImpersonateAccount", "");
    },


    unsubscribe_action() {
      this.dataCheck = 1;
      this.subscriptionDialog = true;
    },
    close_subscription_dialog(val) {
      if (val == 0) {
        this.subscriptionDialog = false;
      } else if (val == 1) {
        this.$router.push("/");
      }
    },


    updateEmailValues() {
      this.selectedEmail = this.getUserEmail;
      this.email = this.getUserEmail;
      this.$store.commit("SetCongifStatusCheck", false);
    },
  },
};
</script>

<style>
.acc-caption {
  font-size: 0.75em;
  font-weight: 500;
  color: #616161;
  letter-spacing: 0.06rem;
}

.acc-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.no-uppercase {
  text-transform: none;
}

.custom-btn .v-btn__content u {
  left: 0;
  position: absolute;
  width: 100%;
}

.logout-icon {
  margin-left: -3.2em;
  margin-right: 2.4em;
}

.impersonate-header {
  background-color: #0195d2;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.impersonate-user .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
  height: 32px !important;
}

.custom-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: white;
  background-color: rgb(255, 255, 255) !important;
}
</style>

<style scoped>
.left-align-text {
  text-align: left;
}

.button-text {
  color: #0195d2;
  border: 2px solid #0195d2;
  line-height: 125%;
  cursor: pointer;
  border-radius: 4px;
}
.button-text:hover {
  color: #015c8e;
  border-color: #015c8e;
}
</style>
