<template>
    <div>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <v-card flat>
        <v-card-text>
          <span class="text-h6 primary--text"
            ><b class="heading-font">Preference</b></span
          >
  
          <div class="subtitle-2 subheading-font">
            <b class="subheading-font">
                Ethernet mode is to select top and bottom Ethernet port functionality.
                LAN/LAN will set the both ports to LAN mode. WAN/LAN will set the top
                port to WAN mode, the bottom port to LAN mode.
              </b>
          </div>
          <div class="configurationCard">
            <v-row no-gutters>
              <v-select
                dense
                outlined
                label="Ethernet Mode"
                item-text="text"
                class="ml-3"
                style="max-width: 250px"
                item-value="value"
                :items="ethernetModes"
                v-model="ethernetMode"
              ></v-select>
            </v-row>
        </div>

        <div>
            <v-row>
                <div class="mt-8 ml-4 mb-4">
                    <v-img
                        src="@/assets/ethernet-mode.png"
                    >
                    </v-img>
                </div>
            </v-row>
        </div>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  import SnackBar from "@/components/SnackBar.vue";
  export default {
    components: {
      SnackBar,
    },
    data() {
      return {
        showUsage: null,
        SnackBarComponent: {},
        ethernetMode: null, 
        ethernetModes: [
          { value: 0, text: "LAN/LAN" },
          { value: 1, text: "WAN/LAN" },
        ],
      };
    },
  
    computed: {
      configurationChanges() {
        return `${this.ethernetMode}`; 
      },
    },
  
    watch: {
      configurationChanges(newVal, oldVal){
        if (this.isMounted == true) {
          this.final_save();
        }
      }
    },
  
    created() {
      this.bind_default_structure();
    },
    mounted() {
      var data = localStorage.getItem("Misc");
      if (JSON.parse(data) == true) {
        this.fetch_data();
      }
      setTimeout(() => {
        this.isMounted = true;
      }, 1);
    },
    methods: {
      //method to fetch the default values of configuration
      bind_default_structure() {
        var data =
          this.$store.getters.GetDefaultConfig.configuration_module.Ethernet.Value;
        this.ethernetMode = data.EthernetMode;
        this.wanPriority = data.WANPriority;
        this.pingFailover = data.PingFailover;
      },
  
      //method invokes when data is modified
      fetch_data() {
        var data = this.$store.getters.GetEthernet;
        this.ethernetMode = data.EthernetMode;
        this.wanPriority = data.WANPriority;
        this.pingFailover = data.PingFailover;
      },
  
      //method invokes on apply action to save the new changes
      final_save() {
        var defaultJson =
          this.$store.getters.GetDefaultConfig.configuration_module.Ethernet.Value;
        var data = {
          EthernetMode: this.ethernetMode,
          WANPriority: defaultJson.WANPriority,
          PingFailover: defaultJson.PingFailover,
        };
        localStorage.setItem("preference", true);
        this.$store.commit("SetInternet", true);
        this.$store.commit("SetPreference", data);
        
        this.$emit("apply");
      },
    },
  };
  </script>