import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"dense":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Operation Mode")])]),(this.modes == 0)?_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Access point (AP) mode as a gateway enables to connect the internet via PPPoE, DHCP, PPTP, L2TP, and Static IP. In this mode enables the NAT, Firewall, and DHCP items by default.")])]):_vm._e(),(this.modes == 1)?_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Bridge Mode will extend your Wi-Fi coverage, it will enable you to connect to the internet via an external AP router in your wireless signal coverage. In this mode, the Firewall, IP Sharing, and NAT functions will be disabled by default.")])]):_vm._e(),_c('div',{staticClass:"configurationCard"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('div',{staticClass:"configurationCard"},[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.modes),callback:function ($$v) {_vm.modes=$$v},expression:"modes"}},[_c(VRadio,{attrs:{"label":"AP Mode","value":0}}),_c(VRadio,{attrs:{"label":"Bridge Mode","value":1}})],1)],1)])],1)],1)])],1),_c(VLayout,{attrs:{"justify-center":""}},[_c(VCardActions,[_c(VBtn,{staticClass:"button",attrs:{"dark":""},on:{"click":function($event){return _vm.save_modes()}}},[_vm._v("Apply")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }