<template>
  <CardGroupSlot v-if="groupDiffs" :CardGroupTitle="'Port Forwarding'" :isPreview="isPreview">
    <template #body>
      <transition-group name="fade">
        <div class="history-list my-2" v-for="diff in indexes" :key="diff.path">
          <SingleCard type="single" :diff="diff" :propertyNameTable="mixinFieldNames" />
        </div>
      </transition-group>
      <!-- PortMappingList History -->
      <transition name="fade">
        <ListCard title="Port Mapping List" :listData="list" />
      </transition>
    </template>
  </CardGroupSlot>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import CardGroupSlot from "../CardComponents/CardGroupSlot.vue";
import SingleCard from "../CardComponents/SingleCard.vue";
import ListCard from "../CardComponents/ListCard.vue";

export default {
  mixins: [mixin_config_history_utils],
  components: {
    SingleCard,
    ListCard,
    CardGroupSlot
  },
  data() {
    return {
      indexes: [],
      // aka apnList
      list: [],
    };
  },
  props: {
    groupDiffs: Array,
    isPreview: Boolean
  },
  watch: {
    groupDiffs: {
      handler() {
        this.indexes = this.groupDiffs.filter((item) => item.path.split(".")[3] !== "PortMappingList");

        const tempList = this.groupDiffs.filter((item) => item.path.split(".")[3] === "PortMappingList");
        this.list = this.mixinParseListData(tempList);
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
