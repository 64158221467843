<template>
  <!-- Navigation drawer for info details -->
  <v-navigation-drawer v-model="localDrawer" right app width="270px" temporary hide-overlay>
    <template v-slot:prepend>
      <v-row justify="center" class="mt-4 mb-4"> User Information </v-row>
      <div class="ma-2 pa-2">
        <v-row no-gutters class="mb-10 mt-2">
          <v-col cols="auto">
            <v-icon>mdi-account</v-icon>
          </v-col>
          <v-col>
            <div class="acc-caption mt-n2 ml-2">Full Name</div>
            <div class="ml-2 mt-n1 acc-info">
              {{ getUserInfo ? getUserInfo.user.user_first_name : "" }}
              {{ getUserInfo ? getUserInfo.user.user_last_name : "" }}
            </div>
          </v-col>
        </v-row>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-row v-on="on" no-gutters class="mt-10 mb-10">
              <v-col cols="auto">
                <v-icon>mdi-email-outline</v-icon>
              </v-col>
              <v-col>
                <div class="acc-caption mt-n2 ml-2">Email</div>
                <div class="ml-2 mt-n1 acc-info">{{ getUserEmail }}</div>
              </v-col>
            </v-row>
          </template>
          <span>{{ getUserEmail }}</span>
        </v-tooltip>

        <v-row no-gutters class="mt-10" :class="{ 'mb-6': getterGetCurrentUserRole == 'INDIVIDUAL' ? 'mb-10' : getterGetCurrentUserRole != 'INDIVIDUAL' }">
          <v-col cols="auto">
            <v-icon>mdi-reorder-horizontal</v-icon>
          </v-col>
          <v-col>
            <div class="acc-caption mt-n2 ml-2">Role</div>
            <div class="ml-2 mt-n1 acc-info">
              {{ getterGetCurrentUserRoleString }}
            </div>
          </v-col>
        </v-row>
        <v-row v-if="getterGetCurrentUserRole != 'INDIVIDUAL'" no-gutters class="mt-10 mb-6">
          <v-col cols="auto">
            <v-icon>mdi-domain</v-icon>
          </v-col>
          <v-col>
            <div class="acc-caption mt-n2 ml-2">Organization</div>
            <div class="ml-2 mt-n1 acc-info">
              {{ getUserInfo ? getUserInfo.user.affiliation : "" }}
            </div>
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-4 mb-4">
          <v-btn class="button tabledata--text no-uppercase" depressed color="blue" width="240px" @click="view_profile_details()">Edit Profile</v-btn>
        </v-row>

        <v-divider></v-divider>
        <v-row class="mt-2" no-gutters v-if="getterGetCurrentUserRole === 'INDIVIDUAL' && isCustomer">
          <v-btn text color="primary" icon class="custom-btn" @click.prevent="openManageSubscription()">
            <u class="text-capitalize underline-text">Manage Subscriptions</u>
          </v-btn>
        </v-row>

        <v-row v-if="getterGetCurrentUserRole == 'INDIVIDUAL' && isCustomer && containsOnlyFX20 == false" class="mt-2" no-gutters>
          <v-btn icon text color="primary" class="custom-btn" @click="unsubscribe_action()">
            <u class="text-capitalize underline-text"> Unsubscribe </u>
          </v-btn>
        </v-row>

        <v-row no-gutters class="mt-2">
          <v-btn icon text color="primary" class="custom-btn" href="https://www.jextream.net/support/contact-us/" target="_blank">
            <u class="text-capitalize underline-text"> Contact JEXtream </u>
          </v-btn>
        </v-row>

        <v-row class="pt-2" no-gutters>
          <v-btn icon text class="custom-btn" color="primary" href="https://www.jextream.net/privacy-policy/" target="_blank">
            <u class="text-capitalize">Privacy Policy </u>
          </v-btn>
        </v-row>
        <v-row class="pt-2 mb-4" :class="{ 'mb-2': getterGetCurrentUserRole != 'INDIVIDUAL' || containsOnlyFX20 == true }" no-gutters>
          <v-btn icon text class="custom-btn" color="primary" href="https://www.jextream.net/terms-of-use/" target="_blank">
            <u class="text-capitalize">Terms Of Service </u>
          </v-btn>
        </v-row>

        <v-divider></v-divider>

        <v-row justify="center" class="mt-4" :class="{ 'mt-7': getterGetCurrentUserRole == 'INDIVIDUAL' && containsOnlyFX20 == false }">
          <v-tooltip bottom v-if="getImpersonatedUser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="width: 240px" class="no-uppercase custom-disabled" depressed outlined v-bind="attrs" v-on="on">
                <v-icon class="logout-icon">mdi-logout</v-icon>
                <div justify="center">Log Out</div>
              </v-btn>
            </template>
            <span>Signed in as admin: <br />{{ adminEmail }}</span>
          </v-tooltip>
          <div v-if="getImpersonatedUser" class="mt-14 px-2 py-1 button-text" @click="back_to_admin()">
            Go back to: <br />
            <u class="underline-text">{{ adminEmail }}</u>
          </div>

          <v-btn v-else color="primary" outlined style="width: 240px" class="no-uppercase" depressed @click="sign_out()">
            <v-icon class="logout-icon">mdi-logout</v-icon>
            <div justify="center">Log Out</div>
          </v-btn>
        </v-row>
      </div>
      <div id="portal"></div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getSingleUserDeviceList } from "@/services/deviceService";
import { getCheckSubscriptionExist } from "@/services/stripeService"

export default {
  props: ["drawer", "containsOnlyFX20", "adminEmail"],

  data() {
    return {
      localDrawer: this.drawer,
      isCustomer: false
    };
  },
  watch: {
    drawer(newVal) {
      this.localDrawer = newVal;
    },
    localDrawer(newVal) {
      if (newVal !== this.drawer) {
        this.$emit("update:drawer", newVal);
      }
    },
    // Check whether the user's subscription is valid whenever the user changes
    getterGetUserInfo: {
      async handler() {
        if (this.getterGetCurrentUserRole === "INDIVIDUAL") {
          this.checkStripeSub();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetCurrentUserRoleString", "GetUserEmail", "GetImpersonate"]),

    getImpersonatedUser() {
      return this.GetImpersonate;
    },
    getUserEmail() {
      return this.GetUserEmail;
    },
    getUserInfo() {
      return this.getterGetUserInfo;
    },
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
  },
  methods: {
    ...mapActions(["actionLogOut"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async logout_account() {
      this.actionLogOut(this.getUserEmail);
    },
    sign_out() {
      this.$router.push("/");
      this.$store.commit("SetAuth", false);
      this.$store.commit("SetAlerts", []);
      this.$store.commit("SetImpersonateAccount", "");
      this.$store.commit("SetImpersonateAccType", "");
      this.$store.commit("SetImpersonate", false);
      localStorage.setItem("dynamicChange", false); //vanilla js -> string
      localStorage.setItem("licenseReport", false);
      localStorage.setItem("offline", false);
      localStorage.setItem("online", false);
      localStorage.setItem("firstLogin", false);
      localStorage.setItem("modelInfo", "");
      this.logout_account();
    },
    back_to_admin() {
      this.$emit("back_to_admin");
    },
    async openPricingTable() {
      this.$emit("openPricingTable");
    },
    // a method that checks for users' stripe subscriptions
    async checkStripeSub() {
      const email = this.getUserInfo.user.user_email_id;
      const country = this.getUserInfo.user.user_language_code === "en" ? "USA" : this.getUserInfo.user.user_language_code === "ko" ? "Korea" : this.getUserInfo.user.user_country_code;

      try {
        const userDeviceList = await getSingleUserDeviceList(this.getCurrentUserID);
        const model = userDeviceList[0].router_model;
        const subCheck = await getCheckSubscriptionExist(email, model, country, "individual");
        if(subCheck.customer_id) {
          this.isCustomer = true;
        }
      } catch (err) {
        this.isCustomer = false;
      }
    },
    async openCustomerPortal() {
      // this.$emit("openCustomerPortal");
      const email = this.getterGetUserInfo.user.user_email_id;
      const response = await fetch("/api/create-customer-portal-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const session = await response.json();
      window.location.href = session.session.url;
      // session.mount("#portal")
    },
    openManageSubscription() {
      this.$emit("openManageSubscription");
    },
    view_profile_details() {
      this.$emit("view_profile");
    },
    unsubscribe_action() {
      this.$emit("unsubscribe");
    },
  },
};
</script>
