import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticStyle:{"padding":"0"}},[_c(VIcon,{staticClass:"white--text close-popup",attrs:{"size":"28"},on:{"click":_vm.close_ad_dialog}},[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"pa-0"},[_c(VImg,{attrs:{"max-width":"100%","src":_vm.currentImageSrc}})],1),_c(VCardActions,{staticClass:"card-actions"},[_c(VBtn,{staticClass:"mr-1 mb-2 white--text ml-2",staticStyle:{"text-transform":"none"},attrs:{"depressed":"","outlined":"","color":"white"},on:{"click":_vm.close_ad_dialog}},[_vm._v("Close")]),_c(VCheckbox,{staticClass:"mt-2 ml-2 custom-checkbox",staticStyle:{"color":"white","border-color":"white"},attrs:{"color":"white"},model:{value:(_vm.showAgainCheck),callback:function ($$v) {_vm.showAgainCheck=$$v},expression:"showAgainCheck"}}),_c('p',{staticClass:"mt-2 white--text"},[_vm._v("Do not show again")]),_c(VSpacer),_c(VBtn,{staticClass:"mb-2 white--text view-products mr-2",staticStyle:{"text-transform":"none"},attrs:{"color":"#4D95B6"},on:{"click":_vm.redirect_to_website}},[_vm._v("More Info")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }