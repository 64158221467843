import router from '@/router/index'
import store from '@/store/index'
import { EventBus } from '@/views/event-bus'

const roles = {
    "SU_ADMIN": "/Home/DashboardSAdmin",
    "C_ADMIN": "/Home/Dashboard",
    "SP_ADMIN": "/Home/CustomerDeviceList",
    "P_ADMIN": "/Home/CustomerDeviceList",
    "G_M_ADMIN": "/Home/IndividualDeviceList",
    "INDIVIDUAL": "/Home/IndividualDeviceList",
    "C_ADMIN_1": "/Home/Dashboard"
}

export const mixin_routing_utils = {
  methods: {
    mixinRedirectFromHome(userRole, currentPath) {
      if(currentPath == "/Home") {
        this.mixinChangeRoute(roles[userRole]);
      }
    },
    mixinLogoRedirectToHome(userRole) {
      if(userRole === "SU_ADMIN" || userRole ==="SP_ADMIN") this.mixinChangeRoute("DashboardSAdmin");
      if (userRole === "C_ADMIN" || userRole === "C_ADMIN_1") this.mixinChangeRoute("Dashboard");
    },
    mixinImpersonateRouting(impersonatedAccount, impersonatedAccountType) {
      if (impersonatedAccount === "") {
        const routingMap = {
          "Business": { path: "/Home/BusinessUsers", event: "Business" },
          "Individual": { path: "/Home/IndividualUsers", event: "Individual" },
          "Organization": { path: "/Home/Organization", event: "Organization" },
          "CustomerAdmin": { path: "/Home/CustomerAdmin" },
          "PartnersUsers": { path: "/Home/Users" }
        };
    
        const routeInfo = routingMap[impersonatedAccountType];
    
        if (routeInfo && router.currentRoute.path !== routeInfo.path) {
          this.mixinChangeRoute(routeInfo.path);
        }
    
        store.commit("SetImpersonateAccType", "");
    
        if (routeInfo && routeInfo.event) {
          // This has to be changed
          EventBus.$emit("refresh", routeInfo.event);
        }
      }
    },
    mixinChangeRoute(route) {
      router.push(route).catch((err) => {
        if (err.name !== 'NavigationDuplicated') console.error(err);
      });
    },
    mixinDataChangeRoute(name, data) {
      router.push({ name: name, params: data }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') console.error(err);
      });
    }
  },
};