<template>
  <Teleport to="#app">
    <v-slide-y-reverse-transition>
      <div v-show="errorListPanel" ref="errorListPanel" class="error-list-panel">
        <v-card flat>
          <v-card-text>
            <div class="error-content-wrapper">
              <div class="font-weight-bold">Error List</div>
              <v-fade-transition>
                <div v-if="getterGetErrorList.length === 0" key="no-data" class="no-record-box">
                  <v-icon x-large>mdi-note-off-outline</v-icon>
                  <span>No Errors</span>
                </div>
              </v-fade-transition>
              <div class="error-content">
                <transition-group name="fade">
                  <div name="fade" v-for="(error, index) in getterGetErrorList" :key="error.path">
                    <!-- error card -->
                    <div class="error-card my-2">
                      <div class="d-flex justify-center align-center red darken-1">
                        <v-icon color="white" style="display: block" size="30">mdi-close-circle-outline</v-icon>
                      </div>
                      <div class="white pa-2">
                        <div class="path-string">{{ error.pathString }}</div>
                        <div class="error-message">{{ error.errorMessage }}</div>
                      </div>
                      <div class="d-flex justify-center align-center white">
                        <v-icon style="display: block" size="40" @click="move_to_error_page(error.path)">mdi-chevron-right</v-icon>
                      </div>
                    </div>
                    <!-- error card -->
                  </div>
                </transition-group>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-y-reverse-transition>
  </Teleport>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Teleport from 'vue2-teleport';

export default {
  components: {
    Teleport,
  },
  props: {
    errorListPanel: Boolean,
  },
  data() {
    return {
      groupDiffs: {}
    };
  },
  mounted() {
    window.addEventListener("resize", this.screenResizeHandler);
    document.addEventListener('click', this.errorListPanelClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.screenResizeHandler);
    document.removeEventListener('click', this.errorListPanelClickOutside);
  },
  // Watch for diff array or in this case, the getter of the diff array state change
  // If the user makes a configuration change via UI and the configuration diff array changes, this form will execute "divideDiffsToGroups()"
  // In order to update the configuration History Log UI
  watch: {
    errorListPanel: {
      handler(newVal) {
        if(newVal) {
          this.$nextTick(() => {
            this.screenResizeHandler();
          })
        } 
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getterGetErrorList"]),
  },
  methods: {
    ...mapMutations([ "mutationSetCurrentError" ]),
    move_to_error_page(path){
      this.mutationSetCurrentError(path);
      this.$emit("close");
    },
    splitPath(path) {
      return path.split(".");
    },
    screenResizeHandler() {
      const dialog = this.$refs.errorListPanel;
      if(dialog) {
        if(window.innerWidth > 1000){
          dialog.style.left = `${44}px`;
          dialog.style.bottom = `${(window.innerHeight/5) + 14}px`;
        } else {
          dialog.style.left = `${window.innerWidth/2 - 216}px`;
          dialog.style.bottom = `84px`;
        }
      }
    },
    errorListPanelClickOutside(event){
      const errorListPanel = this.$refs.errorListPanel;
      if (this.errorListPanel && errorListPanel && !errorListPanel.contains(event.target)) {
        this.$emit("close");
      }
    },
    // This is very experimental and only affects AdminRouter structure's Password property
    // This may change depending on what Daniel decides to do with it
    convertMessage(message){
      if(message === false){
        return "Set your password."
      } else {
        return message;
      }
    }
  },
};
</script>

<style scoped>
.error-list-panel {
  display: block;
  position: fixed;
  z-index: 9999;
}

.error-list-panel::after {
  content: '';
  position: absolute;
  bottom: -40px;
  left: 150px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-clip-path: polygon(0 0, 100% 0, 0 100%);
  clip-path: polygon(0 0, 100% 0, 0 100%);
  backdrop-filter: blur(3px);
}

@media (max-width: 1000px) {
  .error-list-panel::after {
    left: 335px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
}

.error-list-panel > * {
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.error-list-title {
  cursor: pointer;
  transition: background 300ms ease-in-out;
}
.error-list-title:hover {
  background-color: rgb(220, 222, 224);
  transition: background 300ms ease-in-out;
}
.error-content-wrapper {
  height: 200px;
  width: 400px;
}
.error-content {
  box-sizing: border-box;
  position: absolute;
  padding: 1rem;
  height: 200px;
  width: 400px;
  overflow-y: auto;
}
.no-record-box {
  box-sizing: border-box;
  position: absolute;
  display: grid;
  place-content: center;
  padding: 1rem;
  height: 200px;
  width: 400px;
}
.error-card {
  display: grid;
  grid-template-columns: 4rem 1fr 2rem;
  box-shadow: 2px 2px 3px -3px rgba(0, 0, 0, 1);
}
.path-string {
  font-size: 0.8rem;
  color: rgb(156, 156, 156);
}
.error-message {
  font-size: 1.1rem;
}
</style>
