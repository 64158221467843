<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" md="6" sm="12" xs="12" lg="6" xl="6">

        <!-- IPv4 Info -->
        <div class="subtitle-1"><strong> IPv4</strong></div>
        <v-row class="mt-1" no-gutters>
          <v-col cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">IP Address</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getWANIPv4Status.IPAddress }}</div></v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Primary DNS</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getWANIPv4Status.DNSServer1 }}</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">SubnetMask</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getWANIPv4Status.SubnetMask }}</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Secondary DNS</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getWANIPv4Status.DNSServer2 }}</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Gateway</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getWANIPv4Status.Gateway }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- IPv6 Info -->
      <v-col cols="12" md="6" sm="12" xs="12" lg="6" xl="6">
        <div class="subtitle-1"><strong> IPv6</strong></div>
        <v-row no-gutters>
          <v-col cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card mt-1" outlined elevation="2" tile>
              <div class="ml-1">IP Address</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" class="mt-1" outlined elevation="2" tile>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                    {{ getWANIPv6Status.IPAddress }}
                  </div>
                </template>
                <span>{{ getWANIPv6Status.IPAddress }}</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Primary DNS</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                    {{ getWANIPv6Status.DNSServer1 }}
                  </div>
                </template>
                <span>{{ getWANIPv6Status.DNSServer1 }}</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">SubnetMask</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1 no-text-wrap">{{ getWANIPv6Status.SubnetMask }}</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Secondary DNS</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowDns(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                    {{ getWANIPv6Status.DNSServer2 }}
                  </div>
                </template>
                <span>{{ getWANIPv6Status.DNSServer2 }}</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Gateway</div>
            </v-card>
          </v-col>
          <v-col v-if="shouldShowSubnetMask(getDeviceModel)" cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1 no-text-wrap">
                {{ getWANIPv6Status.Gateway }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- LAN Details -->
    <v-row>
      <v-col cols="12" md="6" sm="12" xs="12" lg="6" xl="6">
        <div class="subtitle-1"><strong> LAN Details</strong></div>
        <v-row class="mt-1" no-gutters>
          <v-col cols="12" md="5" sm="12" xl="5" lg="5">
            <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
              <div class="ml-1">Default Gateway</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" xl="7" lg="7">
            <v-card height="30px" outlined elevation="2" tile>
              <div class="ml-1">{{ getDeviceLANDefaultGateway() }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </v-card>
</template>

<script>
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mapGetters } from "vuex";

export default {
  mixins: [
    router_model_utils
  ],
  computed: {
    ...mapGetters([ 'getterGetDeviceWANStatus', 'getterGetDeviceConfigurations' ]),
    getDeviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    getWANIPv4Status() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.WANIPv4Status[0] : {};
    },
    getWANIPv6Status() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.WANIPv6Status[0] : {};
    }
  },
  methods: {
    getDeviceLANDefaultGateway() {
      let gatewayIP = this.getterGetDeviceConfigurations.configuration_module.WiredLAN ? this.getterGetDeviceConfigurations.configuration_module.WiredLAN.Value.DHCPServer.DefaultGateway : '';
      if(this.getDeviceModel === "FX20") {
        gatewayIP = this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus ? this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus.Value.LANStatus[0].IPv4Address : this.getterGetDeviceConfigurations.configuration_module.WiredLAN.Value.DHCPServer.DefaultGateway;
      }

      return gatewayIP;
    },
  }
};
</script>
