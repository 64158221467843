import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSlideXReverseTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHelpPanel),expression:"showHelpPanel"}],staticClass:"help-panel"},[_c('ul',{staticClass:"help-background"},[_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li')]),_c('div',{staticClass:"help-panel-contents pa-2"},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.getterGetHelp.title))]),_c(VCardText,[_c(VFabTransition,[_c(VExpansionPanels,{attrs:{"multiple":""}},_vm._l((_vm.getterGetHelp.items),function(help){return _c(VExpansionPanel,{key:help.title},[_c(VExpansionPanelHeader,{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(help.title))]),_vm._l((help.text),function(sentences,index){return _c(VExpansionPanelContent,{key:index},[_c('div',[_vm._v(_vm._s(sentences))])])})],2)}),1)],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }