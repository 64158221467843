export const mixin_device_model_menu_utils = {
  data(){
    return {
      // This is side menu structure
      mixinSideMenu: {
        // RG2100
        RG2100: [
          {
            title: "General Settings",
            icon: "mdi-router-wireless",
            subMenu: [
              { id: "mainWifi", title: "Main Wi-Fi", icon: "mdi-wifi-star", valid: true },
              { id: "guestWifi", title: "Guest Wi-Fi", icon: "mdi-wifi", valid: true },
              { id: "connections", title: "Connections", icon: "mdi-connection", valid: true },
              { id: "display", title: "Display", icon: "mdi-monitor", valid: true },
              { id: "control", title: "Control", icon: "mdi-remote", valid: true },
              { id: "dataUsage", title: "Data Usage", icon: "mdi-signal-cellular-outline", valid: true },
            ],
          },
          {
            title: "LAN Settings",
            icon: "mdi-lan",
            subMenu: [{ id: "dhcpRange", title: "DHCP Range", icon: "mdi-lan-connect", valid: true }],
          },
          {
            title: "Security",
            icon: "mdi-security",
            subMenu: [
              { id: "security", title: "Basic Security", icon: "mdi-wall-fire", valid: true },
              { id: "macFiltering", title: "MAC Filtering", icon: "mdi-table-filter", valid: true },
              { id: "todAccess", title: "TOD Access", icon: "mdi-calendar-clock", valid: true },
            ],
          }
        ],
        // FX20
        FX20: [
          {
            title: "General Settings",
            icon: "mdi-router-wireless",
            subMenu: [
              { id: "wirelessLAN", title: "Wireless LAN", icon: "mdi-wifi-star", valid: true },
              { id: "VPNPassthrough", title: "VPN Passthrough", icon: "mdi-passport-check", valid: true },
              { id: "ParentalControl", title: "Parental Control", icon: "mdi-account-supervisor", valid: true },
              // { id: "DMZ", title: "DMZ", icon: "mdi-wall-fire", valid: true },
              // { id: "DDNS", title: "Dynamic DNS", icon: "mdi-dns", valid: true },
            ],
          },
          // {
          //   title: "LAN Settings",
          //   icon: "mdi-lan",
          //   subMenu: [
          //     { id: "dhcpRange", title: "DHCP Range", icon: "mdi-lan-connect", valid: true },
          //     { id: "PortForwarding", title: "Port Forwarding", icon: "mdi-router", valid: true },
          //   ],
          // },
          // {
          //   title: "Security",
          //   icon: "mdi-security",
          //   subMenu: [
          //     { id: "ContentFiltering", title: "Content Filtering", icon: "mdi-filter", valid: true },
          //     { id: "URLFiltering", title: "URL Filtering", icon: "mdi-filter-minus", valid: true },
          //     { id: "ServiceFiltering", title: "Service Filtering", icon: "mdi-filter-multiple", valid: true },
          //     { id: "MACFiltering", title: "MAC Filtering", icon: "mdi-table-filter", valid: true },
          //   ],
          // },
          // {
          //   title: "System",
          //   icon: "mdi-database-cog",
          //   subMenu: [
          //     { id: "NTPTime", title: "NTP Time", icon: "mdi-wrench-clock", valid: true },
          //     { id: "System", title: "MISC Settings", icon: "mdi-dots-horizontal", valid: true },
          //     { id: "RouterAdmin", title: "Remote Admin", icon: "mdi-account-key", valid: true },
          //     { id: "ReportSets", title: "Report Settings", icon: "mdi-window-shutter-cog", valid: true },
          //     { id: "AlertsSets", title: "Alert Settings", icon: "mdi-bell-alert", valid: true },
          //   ],
          // }
        ]
      },
      mixinMainContent: {
        // RG2100
        RG2100: [
          { name: 'RG2100_MainWifi', id: 'mainWifi' },
          { name: 'RG2100_GuestWifi', id: 'guestWifi' },
          { name: 'RG2100_Connections', id: 'connections' },
          { name: 'RG2100_Display', id: 'display' },
          { name: 'RG2100_Control', id: 'control' },
          { name: 'RG2100_DataUsage', id: 'dataUsage' },
          { name: 'RG2100_DHCPRange', id: 'dhcpRange' },
          { name: 'RG2100_BasicSecurity', id: 'security' },
          { name: 'RG2100_MACFiltering', id: 'macFiltering' },
          { name: 'RG2100_TODAccess', id: 'todAccess' }
        ],
        // FX20
        FX20: [
          { name: 'FX20_WirelessLAN', id: 'wirelessLAN' },
          { name: 'FX20_VPNPassthrough', id: 'VPNPassthrough' },
          { name: 'FX20_ParentalControl', id: 'ParentalControl' },
          // { name: 'FX20_DMZ', id: 'DMZ' },
          // { name: 'FX20_DDNS', id: 'DDNS' },
          // { name: 'FX20_DHCPRange', id: 'dhcpRange' },
          // { name: 'FX20_PortForwarding', id: 'PortForwarding' },
          // { name: 'FX20_ContentFiltering', id: 'ContentFiltering' },
          // { name: 'FX20_URLFiltering', id: 'URLFiltering' },
          // { name: 'FX20_ServiceFiltering', id: 'ServiceFiltering' },
          // { name: 'FX20_MACFiltering', id: 'MACFiltering' },
          // { name: 'FX20_NTPTime', id: 'NTPTime' },
          // { name: 'FX20_System', id: 'System' },
          // { name: 'FX20_RouterAdmin', id: 'RouterAdmin' },
          // { name: 'FX20_ReportSets', id: 'ReportSets' },
          // { name: 'FX20_AlertsSets', id: 'AlertsSets' },
        ]
      }
    }
  }
}