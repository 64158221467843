import { API, graphqlOperation } from "aws-amplify";
import { superadmin_dashboard_details, customer_admin_dashboard_details } from "@/graphql/queries/dashboardQueries";

// Dashboard related Queries per customer access level
export const getSuperAdminDashboardDetails = async () => {
    try{
        const result = await API.graphql(
            graphqlOperation(superadmin_dashboard_details)
          );
          const response = JSON.parse(result.data.superadmin_dashboard_details);
          return response;
    } catch(error) {
        console.error("Error fetching admin details", error);
        throw error;
    }
}

export const getCustomerAdminDashboardDetails = async (customerId) => {
    try{
        const result = await API.graphql(
            graphqlOperation(customer_admin_dashboard_details, {
              input: {
                customer_id: customerId
              },
            })
          );
          const response = JSON.parse(result.data.customer_admin_dashboard_details);
          return response;
    } catch(error) {
        console.error("Error fetching customer details", error);
        throw error;
    }
}