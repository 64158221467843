<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-card flat>
      <v-card-text>
        <span class="text-h6 primary--text"><b class="heading-font">Data Usage</b></span>
        <div class="subtitle-2 mb-6">
          <b class="subheading-font"> Data Usage Meter will reset on the date selected here and collect an amount of data on this schedule.</b>
        </div>

        <v-row>
          <v-col cols="6">
            <div class="subtitle-2 mb-n3">Data Cycle Resets</div>
            <v-radio-group v-model="dataUsageCheck" row class="mb-n5" @change="setDefaultUsageValues">
              <v-radio label="Monthly" :value="0"></v-radio>
              <v-radio label="Yearly" :value="1"></v-radio>
            </v-radio-group>

            <div class="configurationCard">
              <v-select v-if="dataUsageCheck == 0" dense outlined label="Day of Month" :items="daysCycle" v-model="monthlyDays"></v-select>
              <v-row>
                <v-col class="select-yearly-month" col="3">
                  <v-select v-if="dataUsageCheck == 1" dense outlined v-model="yearlyMonth" label="Month" :items="monthlyCycle" maxlength="3" class="width"></v-select>
                </v-col>

                <v-col col="3" class="select-yearly-day">
                  <v-select v-if="dataUsageCheck == 1" dense outlined v-model="yearlyDay" label="Day" :items="yearlyDayCycle" maxlength="3" class="width"></v-select>
                </v-col>
              </v-row>
              <div class="subtitle-2 mb-2">Usage Alert</div>
              <v-row no-gutters>
                <v-checkbox v-model="unlimitedData" class="mt-n1">
                  <template v-slot:label>
                    <div>Unlimited Usage</div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row v-if="unlimitedData" no-gutters>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div class="suffix-wrapper">
                        <v-text-field label="Usage" disabled outlined class="ml-1 right-aligned-text" v-model="monthlyLimit" dense maxlength="3" suffix="GB" v-on:keypress="is_Number($event), specal_charaters($event)" v-on:paste="specal_charaters($event)" @input="convert_monthly(monthlyLimit)" v-on="on"></v-text-field>
                      </div>
                    </template>
                    <span>Unlimited usage enabled</span>
                  </v-tooltip>
                </v-col>
                <v-col class="mt-n2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-switch label-class="custom-switch-label" disabled color="blue" v-model="usageUnit" class="ml-4" inset dense :true-value="1" :false-value="0">
                          <template v-slot:label>
                            <p class="custom-switch-label">Send Usage Alert</p>
                          </template>
                        </v-switch>
                      </div>
                    </template>
                    <span>Alert disabled during unlimited usage</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-else no-gutters>
                <v-col>
                  <v-text-field label="Usage" outlined class="ml-1 right-aligned-text" :class="{ 'usage-error': monthlyLimit == '' }" v-model="monthlyLimit" dense maxlength="3" suffix="GB" v-on:keypress="is_Number($event), specal_charaters($event)" v-on:paste="specal_charaters($event)" @input="convert_monthly(monthlyLimit)"></v-text-field>
                </v-col>
                <v-col class="mt-n2">
                  <v-switch label-class="custom-switch-label" color="blue" v-model="usageUnit" class="ml-4" inset dense :true-value="1" :false-value="0">
                    <template v-slot:label>
                      <p class="custom-switch-label">Send Usage Alert</p>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="mt-n9">
                <v-col cols="6">
                  <div v-if="!unlimitedData && monthlyLimit == 0" class="usage-error ml-2">Enter a number greater than 0</div>
                  <div v-else :class="{ 'usage-hint': !unlimitedData, 'disabled-usage-hint': unlimitedData }">(eg: 1, 10, 20)</div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import { mixin_time_utils } from '@/mixins/timeUtils.js';

export default {
  mixins: [ mixin_time_utils ],
  components: {
    SnackBar,
  },
  data() {
    return {
      dataUsageCheck: null,
      yearlyDay: null,
      yearlyMonth: null,
      monthlyCycle: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      yearlyDayCycle: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
      menu: false,
      SnackBarComponent: {},
      dailyEnabled: true,
      monthlyEnabled: true,
      capUnit: "",
      monthlyCapAlert: false,
      monthlyCap: false,
      monthlyLimitAlert: false,
      monthlyLimit: 0,
      monthlyDays: null,
      monthlyCapUnit: null,
      dailyCapAlert: false,
      dailyShutdownAlert: false,
      dailylimitAlert: false,
      dailyLimit: 0,
      daysCycle: [],
      usageUnit: null,
      capUnitItems: [
        { value: 0, text: "Unlimited" },
        { value: 1, text: "Gigabytes" },
      ],
      dailyGB: null,
      monthlyGB: null,
      dailyCheck: 0,
      monthlycheck: 0,
      isMounted: false,
      unlimitedData: false,
    };
  },
  computed: {
    configurationChanges() {
      return `${this.monthlyDays} | ${this.yearlyMonth} | ${this.yearlyDay}
      | ${this.usageUnit} | ${this.monthlyLimit} | ${this.unlimitedData}`;
    },
    errorCheck() {
      return `${this.monthlyLimit} | ${this.unlimitedData}`;
    },
  },

  watch: {
    configurationChanges(newVal, oldVal) {
      if (this.isMounted == true) {
        this.validate_data();
      }
    },
    // dataUsageCheck() {
    //   if (this.dataUsageCheck == 0) {
    //     this.get_count();
    //     if (this.monthlyDays == null || this.monthlyDays == undefined || this.monthlyDays == 0) {
    //       this.monthlyDays = 1;
    //     }
    //   } else if (this.dataUsageCheck == 1) {
    //     if (this.yearlyMonth == null || this.yearlyMonth == undefined) {
    //       this.yearlyMonth = "01";
    //     }
    //     if (this.yearlyDay == null || this.yearlyDay == undefined) {
    //       this.yearlyDay = "01";
    //     }
    //   }
    //   if (this.isMounted == true) {
    //     this.validate_data();
    //   }
    // },

    usageUnit() {
      if (this.usageUnit == 0) {
        this.monthlyLimitAlert = false;
      } else {
        this.monthlyLimitAlert = true;
      }
      this.save_details();
    },

    errorCheck() {
      if (this.unlimitedData == false && (this.monthlyLimit == "" || this.monthlyLimit == 0)) {
        this.$emit("configError", true, "data");
      } else {
        this.$emit("configError", false, "data");
      }
    },
  },

  created() {
    this.default_data();
    this.get_count();
  },

  mounted() {
    var data = localStorage.getItem("dataUsage");
    if (JSON.parse(data) == true) {
      this.modified_data();
    }
    setTimeout(() => {
      this.isMounted = true;
    }, 1);
  },
  methods: {
    //method to fetch the default configuration
    default_data() {
      var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.Usage.Value;
      this.dailyEnabled = defaultJson.DailyMonitorUsageEnable == 1 ? true : false;
      this.dailyCapAlert = defaultJson.DailyShutdownonCap == 1 ? true : false;
      this.dailyShutdownAlert = defaultJson.DailyShutdownonCapAlert == 1 ? true : false;
      this.dailylimitAlert = defaultJson.DailyLimitAlert == 1 ? true : false;
      this.dailyLimit = defaultJson.DailyLimit / 1024;
      this.dataUsageCheck = defaultJson.UsageCycle == undefined ? 0 : defaultJson.CycleStartDayofYear == 0 ? 0 : defaultJson.CycleStartDayofMonth == 0 ? 1 : defaultJson.UsageCycle;
      this.monthlyEnabled = defaultJson.MonthlyMonitorUsageEnable == 0 ? true : true;
      this.monthlyCapAlert = defaultJson.MonthlyShutdownonCapAlert == 1 ? true : false;
      this.monthlyCap = defaultJson.MonthlyShutdownonCap == 1 ? true : false;
      this.monthlyLimitAlert = defaultJson.MonthlyLimitAlert == 1 ? true : false;
      this.monthlyLimit = defaultJson.MonthlyLimit == 0 ? 1 : defaultJson.MonthlyLimit / 1024;
      this.usageUnit = this.monthlyLimitAlert == false ? 0 : 1;
      if (this.dataUsageCheck == 1) {
        if(defaultJson.hasOwnProperty("CycleStartDayofYear")) {
          const cycleStartDayofYear = typeof defaultJson.CycleStartDayofYear === "number" ? this.mixinConvertIntDateToString(defaultJson.CycleStartDayofYear) : defaultJson.CycleStartDayofYear;
          this.yearlyMonth = cycleStartDayofYear.substring(0, 2);
          this.yearlyDay = cycleStartDayofYear.substring(2, 4);
        }
      } else {
        this.monthlyDays = defaultJson.hasOwnProperty("CycleStartDayofMonth") ? defaultJson.CycleStartDayofMonth : null;
      }
      this.unlimitedData = defaultJson.MonthlyLimit == 0 ? true : false;
    },

    set_default(val) {
      this.monthlyEnabled = true;
      this.monthlyCapAlert = false;
      this.monthlyLimitAlert = false;
      this.MonthlyLimit = 0;
      this.UsageCycle = 7;
    },
    setDefaultUsageValues(){
      if (this.dataUsageCheck == 0) {
        this.monthlyDays = 1;
      } else if (this.dataUsageCheck == 1) {
        this.yearlyMonth = "01";
        this.yearlyDay = "01";
      }
    },
    //method invokes when default data is modified
    modified_data() {
      var defaultJson = this.$store.getters.GetDataUsage;
      this.dailyEnabled = defaultJson.DailyMonitorUsageEnable == 1 ? true : false;

      this.dailyCapAlert = defaultJson.DailyShutdownonCap == 1 ? true : false;

      this.dailyShutdownAlert = defaultJson.DailyShutdownonCapAlert == 1 ? true : false;

      this.dailylimitAlert = defaultJson.DailyLimitAlert == 1 ? true : false;

      this.dailyLimit = defaultJson.DailyLimit / 1024;

      this.monthlyEnabled = defaultJson.MonthlyMonitorUsageEnable == 1 ? true : false;

      this.monthlyCapAlert = defaultJson.MonthlyShutdownonCapAlert == 1 ? true : false;
      this.monthlyCap = defaultJson.MonthlyShutdownonCap == 1 ? true : false;
      this.monthlyLimitAlert = defaultJson.MonthlyLimitAlert == 1 ? true : false;

      this.monthlyLimit = defaultJson.MonthlyLimit / 1024;
      this.usageUnit = this.monthlyLimitAlert == false ? 0 : 1;
      this.dataUsageCheck = defaultJson.UsageCycle;
      if (this.dataUsageCheck == 1) {
        if(defaultJson.hasOwnProperty("CycleStartDayofYear")) {
          const cycleStartDayofYear = typeof defaultJson.CycleStartDayofYear === "number" ? this.mixinConvertIntDateToString(defaultJson.CycleStartDayofYear) : defaultJson.CycleStartDayofYear;
          this.yearlyMonth = cycleStartDayofYear.substring(0, 2);
          this.yearlyDay = cycleStartDayofYear.substring(2, 4);
        }
      } else {
        this.monthlyDays = defaultJson.hasOwnProperty("CycleStartDayofMonth") ? defaultJson.CycleStartDayofMonth : null;
      }
      this.unlimitedData = defaultJson.MonthlyLimit == 0 ? true : false;
    },

    //method to restrict to numbers only
    is_Number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    specal_charaters(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var text = clipboardData.getData("Text");
        var reg = /^[a-zA-Z0-9- ]+$/;
        if (!reg.test(text)) {
          event.preventDefault();
        } else {
          return false;
        }
      }
      var regex = new RegExp("^[a-zA-Z0-9- ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    convert_megabytes(val) {
      this.dailyCheck = 1;
      this.dailyGB = val * 1024;
    },
    convert_monthly(val) {
      this.monthlycheck = 1;
      this.monthlyGB = val * 1024;
    },

    //method to fetch the no of days
    get_count() {
      this.daysCycle = [];
      for (var i = 1; i <= 31; i++) {
        this.daysCycle.push(i);
      }
    },

    //method invokes on apply action to validate the data
    validate_data() {
      var valid = true;
      if (this.dailyEnabled == true && this.dailyLimit == null) {
        valid = false;

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields",
          timeout: 5000,
          Top: true,
        };
      }
      if (this.monthlyEnabled == true && this.monthlyLimit == null) {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields",
          timeout: 5000,
          Top: true,
        };
      }

      if (this.dataUsageCheck == 1 && (this.yearlyMonth == null || this.yearlyDay == null)) {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Yearly Month and Day Reset Date",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        this.save_details();
        this.$emit("apply");
      }
    },

    //method invokes once data is valid
    save_details() {
      var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.Usage.Value;
      // console.log(defaultJson);
      var data = {};
      if (this.dataUsageCheck == 0) {
        data = {
          DailyLimit: 0,
          DailyLimitAlert: 0,
          DailyMonitorUsageEnable: 1,
          DailyShutdownonCap: 0,
          DailyShutdownonCapAlert: 0,
          DataCycleResets: this.dataUsageCheck,
          MonthlyLimit: this.unlimitedData ? 0 : this.fetch_data(defaultJson),
          MonthlyLimitAlert: this.unlimitedData ? 0 : this.monthlyLimitAlert == true ? 1 : 0,
          MonthlyMonitorUsageEnable: 1,
          MonthlyShutdownonCap: 0,
          MonthlyShutdownonCapAlert: 0,
          UsageCycle: this.dataUsageCheck,
        };
        if(this.monthlyDays !== null) data.CycleStartDayofMonth = this.monthlyDays;
      } else {
        data = {
          DailyLimit: 0,
          DailyLimitAlert: 0,
          DailyMonitorUsageEnable: 1,
          MonthlyLimit: this.unlimitedData ? 0 : this.fetch_data(defaultJson),
          MonthlyLimitAlert: this.unlimitedData ? 0 : this.monthlyLimitAlert == true ? 1 : 0,
          MonthlyMonitorUsageEnable: 1,
          UsageCycle: this.dataUsageCheck,
        };
        if(this.yearlyMonth && this.yearlyDay) data.CycleStartDayofYear = this.yearlyMonth + this.yearlyDay
      }
      // console.log("Usage: " + data.MonthlyLimit)
      // console.log("Alert: " + data.MonthlyLimitAlert)
      this.$store.commit("SetInternet", true);
      this.$store.commit("SetDataUsage", data);

      localStorage.setItem("dataUsage", true);
    },

    //method to convert MB to GB
    fetch_data(defaultJson) {
      if (this.monthlyLimit == "") {
        return "";
      }
      var data = this.monthlyLimit * 1024;
      if (this.monthlycheck == 1) {
        data = parseInt(this.monthlyGB);
        this.monthlyLimit = data / 1024;
        this.monthlycheck = 0;
      } else if (this.monthlyLimit != 0) {
        this.monthlycheck = 0;
      } else if (defaultJson.MonthlyLimit == 0 && this.monthlycheck == 0) {
        data = 1024;
        this.monthlyLimit = 1;
      } else {
        data = parseInt(defaultJson.MonthlyLimit);
        this.monthlyLimit = data / 1024;
        this.monthlycheck = 0;
      }
      return data;
    },
  },
};
</script>

<style scoped>
.right-aligned-text >>> input {
  text-align: right;
}
.width {
  width: 100%;
  padding: 0px;
}

.configurationCard .select-yearly-month {
  padding-right: 0px;
  padding-top: 0px;
}

.configurationCard .select-yearly-day {
  padding-left: 5px;
  padding-top: 0px;
}
.usage-hint {
  display: flex;
  justify-content: flex-end;
  margin-right: -0.8em;
}
.disabled-usage-hint {
  display: flex;
  color: rgb(180, 180, 180);
  justify-content: flex-end;
  margin-right: -0.8em;
}
.custom-switch-label {
  display: inline-block;
  margin-left: -8px;
  margin-bottom: 4px;
}

.suffix-wrapper >>> .v-text-field__suffix {
  color: rgb(180, 180, 180) !important;
}

.usage-error,
.usage-error >>> fieldset,
.usage-error >>> .v-text-field__slot .v-label,
.usage-error >>> .v-text-field__slot input,
.usage-error >>> .v-text-field__slot {
  border-color: red;
  color: red;
}
</style>
