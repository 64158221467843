<template>
  <div>
    <v-dialog
      v-model="pricingPageDialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <DialogCard
        :modalTitle="'Select a subscription plan'"
        :modalIcon="'credit-card'"
      >
        <template #header>
          <v-btn icon x-small @click="close_dialog()">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
        <template #body>
          <!-- <stripe-pricing-table
            pricing-table-id="prctbl_1P1vGbBegxTVKUifg74xBNnn"
            publishable-key="pk_test_51P1EAxBegxTVKUifi52ptqMek4Pn8mJ4AmtQdiEQLhQBBbaytArgPiI4Yqg2rg7D8N7bJvu34WWIF6xW5NVDxI1u00px2PWga9"
          >
          </stripe-pricing-table> -->
          <div v-if="!isCheckoutActive">
            <h1>{{ product.name }}</h1>
            <div v-if="loading">Loading...</div>
            <div v-else>
              <v-row class="d-flex justify-center mt-6">
                  <v-col v-for="price in prices" :key="price.id" class="d-flex flex-column align-center price-card mx-4">
                    <h2 class="mb-4">{{ price.nickname }}</h2>
                    <ol>
                      <li v-for="(feature, index) in product.features" :key="index">
                        {{ feature.name }}
                      </li>
                    </ol>
                    <h3 class="mt-4 mb-4">{{ price.unit_amount / 100 }} {{ price.currency }} {{ price.type === "recurring" ? '/month' : "" }}</h3>

                    <v-btn
                      class="price-button"
                      @click.prevent="initializeCheckout(price.id, price.type)"
                    >
                      Subscribe
                    </v-btn>
                  </v-col>
              </v-row>
            </div>
          </div>
          <div v-else class="d-flex flex-column align-start">
            <v-btn
              elevation="0"
              transparent
              plain
              color="black"
              style="display: block"
              class="ml-n4 mt-n2"
              @click="seePlans"
            >
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Back to Plans
            </v-btn>
            <v-toolbar class="mt-0 mb-1" flat
              ><v-toolbar-title class="ml-n4" style="font-size: 2rem">
                Checkout
              </v-toolbar-title>
            </v-toolbar>
          </div>
          <div id="checkout"></div>
        </template>
      </DialogCard>
    </v-dialog>
  </div>
</template>
<script>
import DialogCard from "../Slots/DialogCard.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    pricingPageDialog: Boolean,
  },
  components: {
    DialogCard,
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
    isCheckoutActive() {
      return !!this.checkout;
    },
  },

  data() {
    return {
      prices: [],
      loading: true,
      product: {},
      checkout: null,
    };
  },

  mounted() {
    this.fetchProducts();
  },

  methods: {
    close_dialog() {
      if (this.checkout) {
        this.checkout.destroy();
        this.checkout = null;
      }
      this.$emit("close");
    },

    async fetchProducts() {
      try {
        const routerModel = "RG2100"; //Change to dynamic model when we know if customer obj will have model type
        const response = await fetch(
          `/api/products?router_model=${routerModel}`
        );
        const product = await response.json();
        this.product = product;
        this.prices = product.prices.filter(price => price.active).sort((a, b) => a.unit_amount - b.unit_amount);
        this.loading = false;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async initializeCheckout(priceId, mode) {
      try {
        const stripe = Stripe(
          "pk_test_51Kz1tcGfVRJmBJNYYdbpcqJQ4EyX8O6frmwVnqI0U9qTCl8bgqWB0Wu4RvLetIQ3Hh1fb4VeHE6SwNVvhIfBATMK00hUqKy5OX"
        );
        const type =
          mode === "one_time"
            ? "payment"
            : "recurring"
            ? "subscription"
            : "setup";
        const sessionData = await this.fetchClientSecret(priceId, type);
        const checkout = await stripe.initEmbeddedCheckout({
          clientSecret: sessionData.clientSecret,
        });
        this.checkout = checkout;
        // Mount Checkout
        console.log(checkout);
        checkout.mount("#checkout");
      } catch (error) {
        console.error("Error initializing checkout:", error);
        throw error; // Propagate the error to the caller
      }
    },
    async fetchClientSecret(priceId, type) {
      const email = this.getterGetUserInfo.user.user_email_id;
      const imei = "FX20M54000541FX2";
      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId, type, email, imei }),
      });
      // console.log(await response.json());
      const { client_secret, id } = await response.json();
      return { clientSecret: client_secret, id: id };
    },
    seePlans() {
      this.checkout.destroy();
      this.checkout = null;
    },
  },
};
</script>
  