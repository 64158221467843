<template>
  <v-dialog overflow-hidden v-model="MACFilteringRulesDialog" persistent max-width="600px" scrollable>
    <DialogCard :modalIcon="'table-filter'" :modalTitle="'MAC Filtering Rules'">
      <template #header>
        <v-btn icon x-small @click="close_mac_filtering_rules_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <div>
            <v-switch ref="ActiveRule" class="form-text-box d-inline-block mb-6" dense v-model="ActiveRule" hide-details>
              <template v-slot:label>
                <transition-group name="fade" tag="div" class="radio-button-enable-disable-container">
                  <div v-if="ActiveRule" key="Enabled">Enabled</div>
                  <div v-else key="Disabled">Disabled</div>
                </transition-group>
              </template>
            </v-switch>
          </div>

          <v-text-field ref="RuleName" class="form-text-box" outlined dense label="Rule Name" placeholder="Rule Name" v-model="RuleName" :rules="mixinCommonNameRule()" autocomplete="new-password"></v-text-field>

          <v-text-field ref="HostName" class="form-text-box" outlined dense label="Host Name" placeholder="Host Name" v-model="HostName" :rules="mixinCommonNameRule()" autocomplete="new-password"></v-text-field>

          <v-text-field ref="MACAddress" class="form-text-box" outlined dense label="MAC Address" placeholder="MAC Address" v-model="mixinFormattedMacAddress" :rules="mixinMACAddressRule()" autocomplete="new-password" maxlength="17" @paste="mixinPasteMACAddress($event)" @keypress="mixinFormatMACAddress($event)"></v-text-field>
        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_mac_filtering_rules_dialog()">Cancel</v-btn>
        <v-btn small @click="save_mac_filtering_rules()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    DialogCard,
  },
  props: {
    MACFilteringRulesDialog: Boolean,
    MACFilteringRulesDialogMode: Object,
    MACFilteringRules: Array,
  },

  data() {
    return {
      ActiveRule: false,
      RuleName: "",
      HostName: "",
    };
  },
  watch: {
    MACFilteringRulesDialog: {
      handler(newVal) {
        if (newVal) {
          this.initMACFilteringRulesDialog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initMACFilteringRulesDialog() {
      const mode = this.MACFilteringRulesDialogMode.mode;
      const item = this.MACFilteringRulesDialogMode.item;
      if (mode === "add") {
        this.ActiveRule = true;
        this.RuleName = "";
        this.HostName = "";
        this.mixinRawMACAddress = "";
      } else if (mode === "edit") {
        this.ActiveRule = this.mixinIsDefinedAndNotNull(item.ActiveRule) ? (item.ActiveRule ? true : false) : false;
        this.RuleName = this.mixinIsDefinedAndNotNull(item.RuleName) ? item.RuleName : "";
        this.HostName = this.mixinIsDefinedAndNotNull(item.HostName) ? item.HostName : "";
        this.mixinRawMACAddress = this.mixinIsDefinedAndNotNull(item.MACAddress) ? item.MACAddress : "";
      }
    },
    save_mac_filtering_rules() {
      if (!(this.$refs.form && this.$refs.form.validate())) {
        this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
        return;
      }
      const mode = this.MACFilteringRulesDialogMode.mode;
      const item = {
        Index: mode === "add" ? this.MACFilteringRules.length : this.MACFilteringRulesDialogMode.item.Index,
        ActiveRule: this.ActiveRule ? 1 : 0,
        RuleName: this.RuleName,
        HostName: this.HostName,
        MACAddress: this.mixinRawMACAddress,
      };

      const dialogObj = {
        status: 1,
        mode: mode,
        item: item,
      };
      if (mode === "edit") dialogObj.index = this.MACFilteringRulesDialogMode.index;

      // Set item to send back to ApnInfo.vue component for edit or add
      this.$emit("close", dialogObj);
    },
    //method invokes on close actions
    close_mac_filtering_rules_dialog() {
      this.$emit("close", { status: 0 });
    },
  },
};
</script>

<style scoped>
.radio-button-enable-disable-container {
  position: relative;
}

.radio-button-enable-disable-container > * {
  position: absolute;
  top: -0.67rem;
  left: 0.5rem;
}
</style>
