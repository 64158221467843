import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.addNTPTimerDialog),callback:function ($$v) {_vm.addNTPTimerDialog=$$v},expression:"addNTPTimerDialog"}},[_c(VCard,{staticClass:"dialogCard overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v("Add > NTP Server")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close_dialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VRow,{staticClass:"mt-3 mx-2",attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"label":"pool.ntp.org*","dense":"","outlined":""},model:{value:(_vm.poolOrg),callback:function ($$v) {_vm.poolOrg=$$v},expression:"poolOrg"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.validate_data()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }