<template>
  <div>
    <v-container>
      <!-- Sign up flow step 1 -->
      <v-row no-gutters v-if="licenseCheck == 0" class="mt-2">
        <v-col cols="12">
          <v-select label="Select Model" v-model="modelSelect" :items="['RG2100']" outlined required dense></v-select>
        </v-col>
      </v-row>
      <v-card flat class="mt-4">
        <v-row no-gutters v-if="licenseCheck == 0">
          <v-col cols="3">
            <v-text-field class="remove-border" label="Model" dense v-model="modelSelect" readonly outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select v-if="modelSelect == 'FX20'" label="TAC" v-model="fxStep2" class="ml-4" :items="['M52', 'M54', 'M32']" outlined required dense></v-select>
            <v-text-field v-else-if="modelSelect == 'RG2102'" dense class="ml-4 remove-border" v-model="rg2102Step2" label="First 8 Digits of IMEI" readonly outlined></v-text-field>
            <v-text-field v-else dense class="ml-4 remove-border" v-model="rg2100Step2" label="First 8 Digits of IMEI" readonly outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-if="modelSelect == 'FX20'" label="Last 7 Digits of IMEI" dense hint="Located On Device Display Under About > Device Info" @keyup.enter="does_router_exist()" v-model="serialFX" outlined class="ml-4" maxlength="7"></v-text-field>
            <v-text-field v-else label="Last 7 Digits of IMEI" dense hint="Located On Device Display Under About > Device Info" @keyup.enter="does_router_exist()" v-model="serialRG" outlined maxlength="7" class="ml-4"></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions v-if="licenseCheck == 0" class="justify-bottom mt-4 px-0">
          <v-btn @click="cancel_signup()" outlined class="blue--text justify-end">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="verifyLoading" @click="does_router_exist()" class="button tabledata--text ml-4 justify-end">Verify</v-btn>
        </v-card-actions>
      </v-card>
      <!-- Sign up flow step 2 -->
      <v-row no-gutters v-if="licenseCheck == 1">
        <v-col cols="6">
          <v-text-field label="Device Name" dense v-model="deviceName" :rules="[(val) => val.length <= 15 || 'Max Length(15)']" maxlength="15" outlined></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field label="Device Description" dense class="ml-2" :rules="[(val) => val.length <= 50 || 'Max Length(50)']" maxlength="50" v-model="description" outlined></v-text-field>
        </v-col>

        <v-col cols="6" v-if="licenseCheck == 1 && modelSelect.substring(0, 4) == 'RG21'">
          <v-select label="Carrier" v-model="carrierInfo" item-text="label" item-value="value" :items="carrierItems" outlined required dense></v-select>
        </v-col>
      </v-row>
      <v-card-actions v-if="licenseCheck == 1" class="justify-bottom mt-4 px-0">
        <v-btn @click="cancel_signup()" outlined class="blue--text justify-end">Cancel</v-btn>

        <v-spacer></v-spacer>
        <v-btn class="button tabledata--text ml-n2" @click="licenseCheck = 0">Back</v-btn>
        <v-btn :loading="uploadloader" @click="validate_upload_data()" class="button tabledata--text mr-n2">Next</v-btn>
      </v-card-actions>
    </v-container>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import axios from "axios";
export default {
  props: {
    userDetails: Object,
    userEmail: String,
    createNewPassword: String,
    deviceDetails: Object,
    deviceDetailsEntered: Boolean,
    device: Object,
  },
  data: () => ({
    serialFX: "",
    serialRG: "",
    fxStep2: "M52",
    rg2100Step2: "35856379",
    rg2102Step2: "35871837",
    modelSelect: "RG2100",
    modelFx20: "FX20",
    modelRG2100: "RG2100",
    modelRG2102: "RG2102",
    description: "",
    deviceName: "",
    licenseCheck: 0,
    verifyLoading: false,
    uploadloader: false,
    loginloader: false,
    confirmDialog: true,
    carrierItems: [],

    carrierInfo: "TMOBILE",

    partnerList: [],
    partnerItems: [],
    validIMEI: false,
  }),
  created() {
    this.fetch_carrier_details();
    if (this.deviceDetailsEntered == true) {
      this.licenseCheck = 1;
      this.get_device_values();
    }
  },
  computed: {
    routerId() {
      return this.modelSelect + (this.modelSelect === "RG2100" ? this.rg2100Step2 : this.rg2102Step2) + this.serialRG;
    },
  },
  watch: {
    serialRG() {
      if (this.modelSelect == "RG2100") {
        this.validIMEI = this.isValidIMEI(this.rg2100Step2 + this.serialRG);
      } else if (this.modelSelect == "RG2102") {
        this.validIMEI = this.isValidIMEI(this.rg2102Step2 + this.serialRG);
      }
    },
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },

    get_device_values() {
      this.modelSelect = this.device.modelSelect;
      if (this.modelSelect == "FX20") {
        this.fxStep2 = this.device.fxStep2;
        this.serialFX = this.device.serialFX;
      } else {
        this.serialRG = this.device.serialRG;
      }
      this.deviceName = this.deviceDetails.device_name;
      this.description = this.deviceDetails.router_description;
      this.carrierInfo = this.deviceDetails.carrier_info;
    },
    async fetch_carrier_details() {
      this.carrierItems = [];
      axios({
        method: "POST",
        // 05-24-2023: Mapped the mdm_router APIs for the production server
        url: process.env.VUE_APP_MDM_ROUTER_API + "/color_code_and_carrier_details",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          var carrierList = res.data.Items;
          carrierList.forEach((element) => {
            this.carrierItems.push({
              label: element.carrier_type,
              value: element.carrier_type,
            });
          });
        })
        .catch((err) => {});
    },

    does_router_exist() {
      this.verifyLoading = true;
      if ((this.modelSelect == "FX20" && this.serialFX == "") || (this.modelSelect.substring(0, 4) == "RG21" && this.serialRG == "")) {
        this.triggerSnackBar("error", "Enter the last 7 digits of the IMEI number");
      } else if ((this.modelSelect == "FX20" && this.serialFX.length != 7) || (this.modelSelect.substring(0, 4) == "RG21" && this.serialRG.length != 7)) {
        this.triggerSnackBar("error", "IMEI number must be 7 digits");
      } else if ((this.modelSelect == "RG2100" && !this.isValidIMEI(this.rg2100Step2 + this.serialRG)) || (this.modelSelect == "RG2102" && !this.isValidIMEI(this.rg2102Step2 + this.serialRG))) {
        this.triggerSnackBar("error", "Invalid IMEI number. Please check that IMEI number is correct");
      } else {
        let inputData = {
          command: "doesRouterExist",
          router_id: this.modelSelect + "/" + (this.modelSelect === "RG2100" ? this.rg2100Step2 : this.rg2102Step2) + this.serialRG,
        };
        axios({
          method: "POST",
          // 05-24-2023: Mapped the mdm_router APIs for the production server
          url: process.env.VUE_APP_MDM_ROUTER_API + "/does_router_exist",
          headers: {
            "Content-Type": "application/json",
          },
          data: inputData,
        }).then((res) => {
          if (res.data.errorType === "Error") {
            this.verifyLoading = false;
            this.triggerSnackBar("error", res.data.errorMessage);
          } else {
            this.verifyLoading = false;
            this.licenseCheck = 1;
          }
        });
      }
      this.verifyLoading = false;
    },
    validate_upload_data() {
      if (this.deviceName === "" || this.description === "") {
        this.triggerSnackBar("error", "Please provide all mandatory fields");
      } else {
        this.user_details();
      }
    },

    async user_details() {
      // console.log(this.routerId);
      this.uploadloader = true;
      var inputData = {};
      if (this.modelSelect == "FX20") {
        inputData = {
          command: "individalBulkUploadRouter",
          router_id: this.routerId,
          user_id: this.userDetails.user_id,
          device_name: this.deviceName,
          router_description: this.description,
          partner_id: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
          licnese_country: "USA",
        };
      } else if (this.modelSelect == "RG2100") {
        inputData = {
          command: "individalBulkUploadRouter",
          router_id: this.routerId,
          user_id: this.userDetails.user_id,
          device_name: this.deviceName,
          router_description: this.description,
          partner_id: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
          licnese_country: "USA",
          carrier_info: this.carrierInfo,
          imei_number: this.rg2100Step2 + this.serialRG, // 06/23/2023 Daniel : Just added the RG2102 case
        };
      } else {
        inputData = {
          command: "individalBulkUploadRouter",
          router_id: this.routerId,
          user_id: this.userDetails.user_id,
          device_name: this.deviceName,
          router_description: this.description,
          partner_id: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
          licnese_country: "USA",
          carrier_info: this.carrierInfo,
          imei_number: this.rg2102Step2 + this.serialRG, // 06/23/2023 Daniel : Just added the RG2102 case
        };
      }
      var formData = {
        modelSelect: this.modelSelect,
        fxStep2: this.fxStep2,
        serialFX: this.serialFX,
        serialRG: this.serialRG,
      };
      // console.log(inputData);
      this.$emit("accDetails", inputData, formData);
    },

    cancel_signup() {
      this.$emit("cancelSignup");
    },

    sumDig(n) {
      let a = 0;
      while (n > 0) {
        a = a + (n % 10);
        n = parseInt(n / 10, 10);
      }
      return a;
    },

    isValidIMEI(n) {
      // Converting the number into
      // String for finding length
      let s = n.toString();
      let len = s.length;

      if (len != 15) return false;

      let sum = 0;
      for (let i = len; i >= 1; i--) {
        let d = n % 10;

        // Doubling every alternate digit
        if (i % 2 == 0) d = 2 * d;

        // Finding sum of the digits
        sum += this.sumDig(d);
        n = parseInt(n / 10, 10);
      }

      return sum % 10 == 0;
    },
  },
};
</script>

<style scoped>
.remove-border >>> fieldset {
  border: none;
  border-width: 0px;
}
</style>
