<template>
  <v-dialog overflow-hidden v-model="discardChangesDialog" persistent max-width="400px">
    <DialogCard :modalIcon="'file-undo'" :modalTitle="'Discard all configuration changes'">
      <template #header>
        <v-btn icon x-small @click="close_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <p>Are you sure you want to discard changes?</p>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_dialog()">Cancel</v-btn>
        <v-btn small @click="clear_store_data()" class="button tabledata--text mr-2">Discard</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
export default {
  components: {
    DialogCard,
  },
  props: {
    discardChangesDialog: Boolean,
  },
  methods: {
    close_dialog() {
      this.$emit("close", 0);
    },

    clear_store_data() {
      localStorage.setItem("dhcpServer", false);
      localStorage.setItem("dhcpReservation", false);
      localStorage.setItem("ipSettings", false);
      this.$store.commit("SetDhcpReservation", {});
      this.$store.commit("SetDhcpServer", {});
      this.$store.commit("SetIPsettings", {});
      this.$store.commit("SetWiredLan", false);
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetAccessControl", {});
      localStorage.setItem("accessControl", false);
      localStorage.setItem("miscSettings", false);
      this.$store.commit("SetMiscSettings", {});
      localStorage.setItem("operationMode", false);
      this.$store.commit("SetSystem", false);
      localStorage.setItem("ntpTimer", false);
      this.$store.commit("SetNTPTimer", {});
      localStorage.setItem("remoteAdmin", false);
      this.$store.commit("SetRouterAdmin", []);
      localStorage.setItem("reportData", false);
      this.$store.commit("SetReportSets", {});
      localStorage.setItem("alertSet", false);
      this.$store.commit("SetAlertSets", {});
      localStorage.setItem("automacticReebot", false);
      this.$store.commit("SetAutoReboot", {});
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetGuestNetwork", {});
      localStorage.setItem("primaryNetwork", false);
      localStorage.setItem("inactiveTime", false);
      this.$store.commit("SetFirewall", false);
      this.$store.commit("SetFirewallGeneral", {});
      localStorage.setItem("firewallGeneral", false);
      this.$store.commit("SetUrlFilter", {});
      localStorage.setItem("urlFiltering", false);
      this.$store.commit("SetContentFilter", {});
      localStorage.setItem("contentFilter", false);
      this.$store.commit("SetServiceFilter", {});
      localStorage.setItem("serviceControl", false);
      this.$store.commit("SetInternet", false);
      this.$store.commit("SetDataUsage", {});
      localStorage.setItem("dataUsage", false);
      this.$store.commit("SetPreference", {});
      localStorage.setItem("preference", false);
      localStorage.setItem("dynamicDns", false);
      this.$store.commit("SetInteretObject", {});
      this.$store.commit("SetDynamicDns", {});
      localStorage.setItem("interntModified", false);
      localStorage.setItem("dmzInfo", false);
      this.$store.commit("SetDmzObject", {});
      localStorage.setItem("natPass", false);
      this.$store.commit("SetNATPassthrough", {});
      this.$store.commit("SetPortForward", {});
      localStorage.setItem("portForward", false);
      this.$store.commit("SetFXPortForward", {});
      localStorage.setItem("fxPortForward", false);
      this.$store.commit("SetDeletedPortForward", []);
      localStorage.setItem("deletedPortForward", false);
      this.$store.commit("SetDeletedFXPortForward", []);
      localStorage.setItem("deletedFXPortForward", false);
      this.$store.commit("SetDeletedAccessControl", []);
      localStorage.setItem("deletedAccessControl", false);
      this.$store.commit("SetDeletedTimeOfDay", []);
      localStorage.setItem("deletedTimeOfDay", false);
      localStorage.setItem("ipv4Set", false);
      this.$store.commit("SetIPV4", {});
      localStorage.setItem("ipv6Set", false);
      localStorage.setItem("controlInfo", false);
      this.$store.commit("SetIPv6Settings", {});
      this.$store.commit("SetParentControl", {});
      localStorage.setItem("parentalControl", false);
      this.$store.commit("SetModemGeneral", {});
      localStorage.setItem("General", false);
      this.$store.commit("SetSimData", {});
      localStorage.setItem("Sim", false);
      this.$store.commit("SetApnInfo", {});
      localStorage.setItem("Apn", false);
      this.$store.commit("SetSmsInfo", {});
      localStorage.setItem("Sms", false);
      this.$store.commit("SetGpsInfo", {});
      localStorage.setItem("Gps", false);
      this.$store.commit("SetMiscInfo", {});
      localStorage.setItem("Misc", false);
      this.$store.commit("SetUpnpInfo", {});
      localStorage.setItem("Upnp", false);
      localStorage.setItem("guestNetwork", false);
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetGuestNetwork", {});
      localStorage.setItem("primaryNetwork", false);
      this.$store.commit("SetTodAcces", {});
      localStorage.setItem("Tod", false);
      this.$store.commit("SetCaptivePortal", {});
      localStorage.setItem("captive", false);
      this.$store.commit("SetCongifStatusCheck", false);
      localStorage.setItem("totalConnectionCheck", false);
      this.$emit("close", 1);
    },
  },
};
</script>
