export default {
    model: "fxModel",
    modelName: "FX20",
    displayOptions: {
        showEthernetLinkStatus: true,
        showSerialNo: true,
        showSpeedTest: true,
        showSubnetMask: true,
    },
    configurations: {
        canConfigMainWifi(routerDetail) { 
            return true;
        },
        canConfigFx20Wifi(routerDetail) { 
            return true;
        },
        canConfigParentalControl(routerDetail) { 
            return routerDetail.is_individual_router;
        },
        canConfigDMZ(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigDynamicDNS(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigLANSettings(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigDHCPRange(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigVPNPassthrough(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigPortForwarding(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigSecurity(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigContentFiltering(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigURLFiltering(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigServiceFiltering(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigMACFiltering(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigSystem(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigNTPTime(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigMISCSettings(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigRemoteAdmin(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigReportSets(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigAlertsSets(routerDetail) { 
            return !routerDetail.is_individual_router;
        },
        canConfigManageFirmware(routerDetail) { 
            return true;
        },
    }
}