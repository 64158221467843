export const user_logout_details_reports = /* GraphQL */ `
  mutation User_logout_details_reports($input: UserLogoutDetailsReportsInput) {
    user_logout_details_reports(input: $input)
  }
`;
export const delete_user_permanently = /* GraphQL */ `
  mutation delete_user_permanently($input: DeleteUserPermanentlyInput) {
    delete_user_permanently(input: $input)
  }
`;

export const update_cognito_user = /* GraphQL */ `
mutation update_cognito_user($input: UpdateCognitoUserInput) {
  update_cognito_user(input: $input)
}
`;