const state = {
  progressBar: false
}

const getters = {
  // This is used for both dashboard info and router count
  getterGetProgressBarState: (state) => {
      return state.progressBar;
  }
}

const mutations = {
  mutationOpenProgressBar: (state) => {
    state.progressBar = true;
  },
  mutationCloseProgressBar: (state) => {
    state.progressBar = false;
  }
}

export default {
  state,
  getters,
  mutations
}