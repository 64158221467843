import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.tableHeaderFilterDialog),callback:function ($$v) {_vm.tableHeaderFilterDialog=$$v},expression:"tableHeaderFilterDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Customize Headers")]),_c(VSpacer),_c(VIcon,{on:{"click":_vm.cancelFilters}},[_vm._v("mdi-close")])],1),_c(VCardText,{staticClass:"header-list"},[_c('div',{staticClass:"header-checklist"},_vm._l((_vm.availableHeaders.filter((header) => header.text !== 'Actions' && header.text !== 'Device Name')),function(header,index){return _c('div',{key:header.value,staticClass:"header-list-item mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedHeaders[header.value]),expression:"selectedHeaders[header.value]"}],staticClass:"checkbox-size",attrs:{"type":"checkbox","id":header.value},domProps:{"checked":Array.isArray(_vm.selectedHeaders[header.value])?_vm._i(_vm.selectedHeaders[header.value],null)>-1:(_vm.selectedHeaders[header.value])},on:{"change":function($event){var $$a=_vm.selectedHeaders[header.value],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selectedHeaders, header.value, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selectedHeaders, header.value, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selectedHeaders, header.value, $$c)}}}}),_c('label',{staticClass:"ml-2 text-body-2",attrs:{"for":header.value}},[_vm._v(_vm._s(header.text))])])}),0)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-1 mb-2 blue--text",attrs:{"depressed":"","outlined":"","color":"white"},on:{"click":_vm.cancelFilters}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mb-2 white--text",attrs:{"color":"blue"},on:{"click":_vm.saveFilters}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }