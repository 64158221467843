import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Remote Admin")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" You can change the Admin or User?�s login password for accessing your router?�s WebUI.")])]),_c('div',{staticClass:"configurationCard"},[_c('span',[_c('b',[_vm._v("Primary Account")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"4","sm":"4","md":"4"}},[_c(VTextField,{attrs:{"label":"Username","outlined":"","dense":""},model:{value:(_vm.primaryUser),callback:function ($$v) {_vm.primaryUser=$$v},expression:"primaryUser"}})],1),_c(VCol,{attrs:{"cols":"4","sm":"4","md":"4"}},[_c(VTextField,{attrs:{"label":"Password","type":_vm.isPwd ? 'text' : 'password',"id":"password","append-icon":_vm.isPwd ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":"","maxlimit":"63"},on:{"click:append":function($event){_vm.isPwd = !_vm.isPwd}},model:{value:(_vm.primaryPwd),callback:function ($$v) {_vm.primaryPwd=$$v},expression:"primaryPwd"}})],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }