<template>
  <v-dialog overflow-hidden v-model="createGroupDialog" persistent max-width="600px">
    <DialogCard :modalIcon="'format-list-group-plus'" :modalTitle="'Create Device Group'">
      <template #header>
        <v-btn icon x-small @click="close_create_group_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <v-select v-if="superAdminCheck" v-model="organization" label="Organization*" dense outlined :items="organizationList"></v-select>
          <v-text-field v-model="groupName" label="Group Name*" dense outlined max-length="40" :rules="mixinCommonNameRule()"></v-text-field>
          <v-text-field v-model="groupDescription" label="Group Description*" dense outlined max-length="40" :rules="mixinCommonNameRule()"></v-text-field>
          <v-select v-model="groupModel" label="Model*" dense outlined :items="productList" item-text="label" item-value="value"></v-select>
        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_delete_device_dialog()">Cancel</v-btn>
        <v-btn small :loading="loadingCreateGroup" @click="create_group()" class="button tabledata--text mr-2">Create</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js';
import { mapGetters, mapMutations } from "vuex";
import { createDeviceGroup } from "@/services/groupService.js";

export default {
  mixins: [ mixin_form_validation_utils ],
  components: {
    DialogCard,
  },
  props: {
    createGroupDialog: Boolean,
    organizationList: Array,
    productList: Array,
  },
  watch: {
    createGroupDialog: {
      handler(newVal) {
        if(newVal) {
          if(this.$refs.form){
            this.$refs.form.resetValidation();
          }
          // have the first items selected upon opening
          if(this.superAdminCheck) this.organization = this.organizationList[0].value;       
          this.groupModel = this.productList[0];
          this.groupName = "";
          this.groupDescription = "";
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      organization: "",
      groupName: "",
      groupDescription: "",
      groupModel: "",
      loadingCreateGroup: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole" ]),
    superAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close actions
    close_create_group_dialog() {
      this.$emit("close", 0);
    },

    //  mutation call for adding  groups
    async create_group() {
      if(!this.$refs.form.validate()){
        this.triggerSnackBar("error", "Mandatory fields are required!");
        return;
      }
      this.loadingCreateGroup = true;
      try {
        createDeviceGroup({
          group_status: "ACTIVE",
          customer_id: this.superAdminCheck ? this.organization : this.getterGetUserInfo.customer.customer_id,
          group_name: this.groupName,
          group_description: this.groupDescription,
          group_model: this.groupModel,
        });
        this.triggerSnackBar("success", `Group "${this.groupName}" has been created.`);
        this.$emit("close", 1);
      } catch (error) {
        this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
      } finally {
        this.loadingCreateGroup = false;
      }
    },
  },
};
</script>
