import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","overflow-hidden":"","max-width":"500px"},model:{value:(_vm.unassignGroupDialog),callback:function ($$v) {_vm.unassignGroupDialog=$$v},expression:"unassignGroupDialog"}},[_c(VCard,{staticClass:"cardBg"},[_c(VToolbar,{staticClass:"medium cardTitle",attrs:{"dense":""}},[_c('span',[_vm._v("Remove Devices from Group ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.clear_data()}}},[_c(VIcon,{attrs:{"color":"iconCross"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"cardBg mt-6"},[_c('p',{staticClass:"add-device-header"},[_vm._v("Are you sure you want to remove these devices from their groups?")])]),_c(VCardActions,{staticClass:"button-corner"},[_c(VSpacer),_c(VBtn,{staticClass:"blue--text mr-2",attrs:{"outlined":"","depressed":"","color":"white"},on:{"click":function($event){return _vm.clear_data()}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"loading":_vm.isSaving},on:{"click":function($event){return _vm.remove_data()}}},[_vm._v(" Remove ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }