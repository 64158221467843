import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VMain,[_c(VToolbar,{staticClass:"elevation-0 tablePadding",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Licenses")]),_c('vue-json-to-csv',{attrs:{"labels":{
          name: { title: 'Name' },
          created: { title: 'Created On' },
          prices: { title: 'Pries' },
          id: { title: 'Product ID' },
        },"csv-title":"Log List","json-data":_vm.tableData}},[_c(VBtn,{staticClass:"button ml-6",attrs:{"dark":""}},[_vm._v("Export")])],1),_c(VSpacer),_c('div',{staticClass:"container-wrapper"},[_c(VTextField,{staticClass:"search-bar mr-1",staticStyle:{"max-width":"250px"},attrs:{"clearable":"","append-icon":"mdi-magnify blue--text","label":"Search","single-line":""},on:{"click:clearable":_vm.clear_value,"blur":_vm.search_deactive},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-1 caption",attrs:{"headers":_vm.$store.getters.GetCurrentObj.user.user_role == 'SP_ADMIN'
            ? _vm.suppHeaders
            : _vm.headers,"items":_vm.tableData,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:`header.name`,fn:function({ header }){return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(header.text))])]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.name))])]}},{key:`item.model`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.prices[0].lookup_key.split("_")[0]))])]}},{key:`item.prices`,fn:function({ item }){return _vm._l((item.prices),function(price){return _c('div',{key:price.id},[_c('b',[_vm._v(_vm._s(`${_vm.capitalizeFirstLetter(price.lookup_key.split("_")[2])}`)+" -")]),_vm._v(" "+_vm._s(`${ price.currency === "usd" ? price.unit_amount / 100 : price.unit_amount } ${price.currency} `)+" ")])})}},{key:`item.created`,fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDateWithTime(item.created))}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-height"},[(_vm.loadingIcon == true)?_c('LoadingIcon',{staticClass:"ml-2 mt-2",staticStyle:{"position":"absolute"}}):_vm._e(),(_vm.loadingIcon == true)?_c('span',{staticClass:"ml-10 mt-5",staticStyle:{"position":"absolute"}},[_vm._v(" Loading licenses... ")]):_c('span',[_c(VIcon,{staticClass:"ml-1 mt-3 btn-fix",staticStyle:{"position":"absolute","font-size":"34px"},attrs:{"dark":"","outline":"none","color":"cyan","id":"refresh"},on:{"click":function($event){return _vm.refresh_list()}}},[_vm._v(" mdi-refresh ")]),_c('span',{staticClass:"ml-11 pl-0 mt-5",staticStyle:{"position":"absolute"}},[_vm._v(" Last updated: "+_vm._s(_vm.lastUpdated)+" ")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }