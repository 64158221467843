<template>
  <div>
    <v-main>
      <v-toolbar class="elevation-0 mt-2" :class="{ 'mb-5': customerAdminCheck, 'mb-4': !customerAdminCheck }" dense flat>
        <v-toolbar-title class="pl-1 mt-1">Individual Devices</v-toolbar-title>

        <v-select v-if="!individualUserCheck" class="ml-6 mt-6 filter" color="black" style="font-size: 12px" v-model="modelFilter" clearable label="Model" :items="modelItems" @click:clearable="filterDeviceList()" @input="filterDeviceList()">
          <template #item="{ item }">
            <span class="filter-font"> {{ item }}</span>
          </template>
        </v-select>

        <v-select v-if="!individualUserCheck" class="ml-2 mt-6 filter" color="black" clearable style="font-size: 12px" v-model="onlineStatusFilter" label="Status" :items="onlineItems" @input="filterDeviceList()">
          <template #item="{ item }">
            <span class="filter-font"> {{ item.text == "ONLINE" ? "Online" : "Offline" }}</span>
          </template>
        </v-select>

        <v-select v-if="superAdminCheck" class="ml-2 mt-6 filter" color="black" clearable style="font-size: 12px" v-model="organizationFilter" label="Organization" :items="organizationItems" @input="filterDeviceList()" :no-data-text="loadingText">
          <template #label v-if="isLoadingOrganziationList">
            <i class="fas fa-spinner fa-spin ml-0" style="color: #949494"></i>
            <span> Organizations </span>
          </template>
          <template #item="{ item }" v-else>
            <span class="filter-font"> {{ item }}</span>
          </template>
        </v-select>

        <v-btn v-if="selectedRecords.length > 0 && superAdminCheck" class="button ml-6 mr-2" @click="open_delete_device_dialog" dark>Delete</v-btn>
        <v-btn v-else-if="superAdminCheck" class="ml-6 mr-2 button custom-disabled">Delete</v-btn>

        <v-btn v-if="selectedRecordsModels.length > 0 && superAdminCheck" class="button mr-2" @click="open_reset_config_dialog" dark>Reset Config</v-btn>
        <v-btn v-else-if="superAdminCheck" class="mr-2 button custom-disabled">Reset Config</v-btn>

        <vue-json-to-csv
          :labels="{
            router_model: { title: 'Model' },
            online_status: { title: 'Status' },
            last_reported_on: { title: 'Last Reported' },
            router_id: { title: 'Device SN' },
            imei_number: { title: 'IMEI' },
            license_status: { title: 'Subscription' },
            expire_date: { title: 'Renewal Date' },
            msisdn: { title: 'MSISDN' },
            carrier_info: { title: 'Carrier' },
            firmware_version: { title: 'Device Version' },
            config_updated_on: { title: 'Config Updated' },
            router_name: { title: 'Device Name' },
            router_description: { title: 'Description' },
            partner_name: { title: 'Partner' },
            organization_name: { title: 'Organization' },
            router_group: { title: 'Group' },
            data_usage: { title: 'Usage(GB)' },
            signal: { title: 'Signal' },
          }"
          csv-title=" List"
          :json-data="selectedRecords"
        >
          <v-btn v-if="selectedRecords.length > 0 && superAdminCheck" class="button" @click="export_Method()" dark>Export</v-btn>
          <v-btn v-else class="button custom-disabled">Export</v-btn>
        </vue-json-to-csv>

        <v-spacer></v-spacer>

        <div class="container-wrapper" v-if="!customerAdminCheck">
          <v-text-field dense clearable append-icon="mdi-magnify blue--text" @click:clearable="search = ''" v-model="search" class="search-bar" color="blue" label="Search" single-line style="max-width: 220px"> </v-text-field>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="transparent" elevation="0" class="columns-btn mr-0 ml-1 mt-6 mb-1 pa-0" @click="tableHeaderFilterDialog = true">
                <svg width="32" height="32" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="column-icon" fill-rule="evenodd" clip-rule="evenodd" d="M4 4H8V8H4V4ZM4 19V10H8V19H4ZM2 19V4V3C2 2.96548 2.00175 2.93137 2.00516 2.89776C2.05637 2.3935 2.48223 2 3 2H4H19H20C20.5523 2 21 2.44772 21 3V4V19V20C21 20.5523 20.5523 21 20 21H19H4H3C2.44772 21 2 20.5523 2 20V19ZM19 19V10H10V19H19ZM19 4H10V8H19V4Z" fill="#0195D2" />
                </svg>
              </v-btn>
            </template>
            <p class="tooltip-adjust">Columns</p>
          </v-tooltip>
        </div>
      </v-toolbar>

      <v-row class="tablePadding pt-0 mt-n7 pr-2" v-if="customerAdminCheck">
        <v-col cols="6" class="ml-5 mt-3 pt-0">
          <span class="header"
            >Partner:
            <span :class="{ bold: partner, 'blue-text': !partner }">
              {{ partner ? partner : isTableLoading == true ? "Loading..." : "No Partner Found" }}
            </span>
          </span>
          <span class="ml-4 header">
            Organization: <b>{{ getterGetUserInfo.user.affiliation }}</b>
          </span>
        </v-col>

        <v-spacer></v-spacer>
        <div class="container-wrapper mt-n5 pt-0">
          <v-text-field dense clearable append-icon="mdi-magnify blue--text" @click:clearable="search = ''" v-model="search" class="search-bar" color="blue" label="Search" single-line style="max-width: 220px"></v-text-field>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="transparent" elevation="0" class="columns-btn mr-0 ml-1 mt-6 mb-1 pa-0" @click="tableHeaderFilterDialog = true">
                <svg width="32" height="32" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="column-icon" fill-rule="evenodd" clip-rule="evenodd" d="M4 4H8V8H4V4ZM4 19V10H8V19H4ZM2 19V4V3C2 2.96548 2.00175 2.93137 2.00516 2.89776C2.05637 2.3935 2.48223 2 3 2H4H19H20C20.5523 2 21 2.44772 21 3V4V19V20C21 20.5523 20.5523 21 20 21H19H4H3C2.44772 21 2 20.5523 2 20V19ZM19 19V10H10V19H19ZM19 4H10V8H19V4Z" fill="#0195D2" />
                </svg>
              </v-btn>
            </template>
            <p class="tooltip-adjust">Columns</p>
          </v-tooltip>
        </div>
      </v-row>

      <!-- Start of the Table rows -->
      <v-card class="tablePadding" flat>
        <v-card-text class="pt-0">
          <v-data-table dense v-model="selectedRecords" show-select :headers="headers" :search="search" :items="filteredDeviceList" :items-per-page="10" :loading="isTableLoading" :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }" :no-data-text="isTableLoading ? 'Loading items...' : 'No devices available'" :no-results-text="`No matching results for '${search}'`" item-style="cursor: pointer" item-key="router_id" @click:row="open_device_details_dialog" class="elevation-1 dtwidth caption">
            <template v-slot:[`item.router_name`]="{ item }">
              <span>{{ item.router_name }}</span>
            </template>
            <template v-slot:[`item.license_status`]="{ item }">
              <span
                :style="{
                  color: item.license_status === 'trialing' || item.license_status.includes('rial') || item.license_status.toLowerCase() === 'active' ? 'green' : 'red',
                }"
                >{{ capitalizeFirstLetter(item.license_status) }}</span
              >
            </template>
            <template v-slot:[`item.expire_date`]="{ item }">
              <span class="mr-2" v-html="formatDateWithTime(item.expire_date)"></span>
            </template>
            <template v-slot:[`item.config_updated_on`]="{ item }">
              <v-tooltip v-if="item.config_updated_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="getColor(item)">
                    <span class="caption">{{ mixinConvertUTCToDate(item.config_updated_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="item.indicate_color === 'red'">The device has never connected to the server</span>
                <span v-else-if="item.indicate_color === 'orange'">The device has yet to receive the latest configuration from the server</span>
                <span v-else-if="item.indicate_color === 'green'">The configuration is up to date</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.last_reported_on`]="{ item }">
              <v-tooltip v-if="item.last_reported_on" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" dark small :color="mixinGetTimeDifference(item.last_reported_on) >= 1 ? 'grey' : '#4CAF50'">
                    <span class="caption">{{ mixinConvertUTCToDate(item.last_reported_on) }}</span>
                  </v-chip>
                </template>
                <span v-if="mixinGetTimeDifference(item.last_reported_on) >= 1">Last reported a day or more ago</span>
                <span v-else>Last reported within 24hrs</span>
              </v-tooltip>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.mac_address`]="{ item }">
              <span>{{ item.mac_address ? item.mac_address : "-" }}</span>
            </template>

            <template v-slot:[`item.imei_number`]="{ item }">
              <span>{{ item.imei_number && item.imei_number != "N/A" ? item.imei_number : "-" }}</span>
            </template>

            <template v-slot:[`item.msisdn`]="{ item }">
              <span>{{ item.msisdn && item.msisdn != "N/A" ? item.msisdn : "-" }}</span>
            </template>

            <template v-slot:[`item.organization_name`]="{ item }">
              <span>{{ item.organization_name ? item.organization_name : "-" }}</span>
            </template>

            <template v-slot:[`item.partner_info`]="{ item }">
              <span>{{ item.partner_info ? item.partner_info.user_full_name : "-" }}</span>
            </template>

            <template v-slot:[`item.carrier_info`]="{ item }">
              <span>{{ item.carrier_info ? item.carrier_info : "-" }}</span>
            </template>

            <template v-slot:[`item.signal`]="{ item }">
              <div v-if="shouldShowSignal(item.router_model)" :class="`signal-${item.signal}`">
                <i class="icon__signal-strength">
                  <span class="bar-1"></span>
                  <span class="bar-2"></span>
                  <span class="bar-3"></span>
                  <span class="bar-4"></span>
                  <span class="bar-5"></span>
                </i>
              </div>
              <span v-else class="text-align-center ml-1"> - </span>
            </template>

            <template v-slot:[`item.usage`]="{ item }">
              <span>{{ item.usage ? item.usage : "0.0" }}</span>
            </template>

            <template v-slot:[`item.router_description`]="{ item }">
              <span :class="{ 'text-align-center ml-1': !item.router_description }">
                {{ item.router_description ? item.router_description : "-" }}
              </span>
            </template>

            <template v-slot:[`item.data_usage`]="{ item }">
              <span :class="{ 'text-align-center ml-1': !item.data_usage }">
                {{ item.data_usage ? item.data_usage : "-" }}
              </span>
            </template>

            <template v-slot:[`item.firmware_version`]="{ item }">
              <span :class="{ 'text-align-center ml-1': !item.firmware_version }">
                {{ item.firmware_version ? item.firmware_version : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_group`]="{ item }">
              <span :class="{ 'text-align-center ml-1': !item.router_group }">
                {{ item.router_group ? item.router_group : "-" }}
              </span>
            </template>

            <template v-slot:[`item.add_ons`]="{ item }">
              <span>
                {{ item.add_ons ? item.add_ons : "-" }}
              </span>
            </template>

            <template v-slot:[`item.router_id`]="{ item }">
              <span>{{ item.router_id.split("/")[1] }}</span>
            </template>

            <template v-slot:[`item.is_individual_router`]="{ item }">
              <span class="caption">
                <span v-if="!item.is_individual_router" class="caption orange--text">Business</span>
                <span v-else class="caption green--text">Individual</span>
              </span>
            </template>

            <!-- Actions dropdown list -->
            <template v-slot:[`item.Actions`]="{ item }">
              <div ref="actions">
                <v-menu bottom left content-class="menu-offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item @click="open_device_details_dialog(item)">
                      <v-list-item-title>Device Details</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="superAdminCheck" @click="open_edit_device_dialog(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="open_configs_dialog(item)" :class="{ disabled: item.license_status.toLowerCase() !== 'active' && item.license_status !== 'trialing' }">
                      <v-list-item-title>Configuration</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="!partnerAdminCheck && !individualUserCheck" @click="open_remote_command_dialog(item)">
                      <v-list-item-title>Remote Command</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:[`item.online_status`]="{ item }">
              <span class="bold-status caption" :class="item.online_status == 'ONLINE' ? 'green--text text--darken-3' : 'red--text text--darken-2'">
                {{ item.online_status == "ONLINE" ? "Online" : "Offline" }}
              </span>
            </template>

            <!-- Footer -->
            <template v-slot:footer>
              <div>
                <div v-if="isTableLoading">
                  <LoadingIcon style="position: absolute" class="ml-2 mt-0"></LoadingIcon>
                  <span v-if="isTableLoading" style="position: absolute" class="ml-10 mt-3">
                    loading
                    {{ filteredDeviceList.length ? filteredDeviceList.length : 0 }}
                    of {{ totalRouterCount }}
                  </span>
                </div>
                <div v-else>
                  <v-icon @click="handle_refresh_list()" class="ml-1 mt-1 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                  <span style="position: absolute" class="ml-11 pl-0 mt-3">Last updated: {{ mixinGetLastUpdatedTime }}</span>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-main>

    <router-view></router-view>

    <DeviceDetailsDialog :deviceDetailsDialog="deviceDetailsDialog" :singleRouterInfo="singleRouterInfo" @openConfigs="open_configs_dialog" @close="close_device_details_dialog" />

    <RemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :singleRouterInfo="singleRouterInfo" @close="close_remote_command_dialog"> </RemoteCommandDialog>

    <div v-if="componentCheck == 2">
      <EditADeviceDialog :editDeviceDialog="editDeviceDialog" :editDeviceInfo="editDeviceInfo" @clicked="close_edit_device_dialog" />
    </div>
    <div v-if="componentCheck == 3">
      <DeleteDevicesDialog :deleteRouterDialog="deleteRouterDialog" :selectedRecords="selectedRecords" @close="close_delete_device_dialog" />
    </div>
    <div v-if="componentCheck == 4">
      <ResetDeviceConfigsDialog :resetConfigDialog="resetConfigDialog" :selectedRecords="selectedRecords" @close="close_reset_config_dialog" />
    </div>

    <DeviceConfigDialog :deviceConfigDialog="deviceConfigDialog" :isGroupConfig="false" :singleRouterInfo="singleRouterInfo" @close="close_configs_dialog" />

    <FilterHeaderDialog :tableHeaderFilterDialog="tableHeaderFilterDialog" :headers="headers" @close="filter_headers" />
  </div>
</template>

<script>
import DeviceDetailsDialog from "@/components/Devices/DeviceDialogs/DeviceDetailsDialog.vue";
import RemoteCommandDialog from "@/components/Devices/DeviceDialogs/RemoteCommandDialog.vue";
import EditADeviceDialog from "@/components/Devices/DeviceDialogs/EditADeviceDialog.vue";
import DeviceConfigDialog from "@/components/DeviceConfigurations/DeviceConfigDialog.vue";

import DeleteDevicesDialog from "@/components/Devices/DeviceDialogs/DeleteDevicesDialog.vue";
import ResetDeviceConfigsDialog from "@/components/Devices/DeviceDialogs/ResetDeviceConfigsDialog.vue";
import VueJsonToCsv from "vue-json-to-csv";
import FilterHeaderDialog from "@/components/Devices/DeviceDialogs/FilterHeaderDialog.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import { get_all_org } from "@/mixins/GetAllOrganization.js";

import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils";

import { getOrganizationList } from "@/services/organizationService.js";
import { getDeviceList } from "@/services/deviceService.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  mixins: [get_all_org, router_model_utils, mixin_time_utils],
  components: {
    EditADeviceDialog,
    VueJsonToCsv,
    DeviceDetailsDialog,
    RemoteCommandDialog,
    DeleteDevicesDialog,
    FilterHeaderDialog,
    LoadingIcon,
    ResetDeviceConfigsDialog,
    DeviceConfigDialog
  },
  data() {
    return {
      deviceConfigDialog: false,

      //DeviceDetailsDialog Component Variables
      deviceDetailsDialog: false,
      singleRouterInfo: {},

      //RemoteCommand Component Variables
      remoteCommandDialog: false,

      selectedRecordsModels: [],

      //DeviceEdit Component Variables
      editDeviceDialog: false,
      editDeviceInfo: {},
      partnerDetails: [],

      //DeleteRouters Component Variables
      routerID: [],
      deleteRouterDialog: false,

      // ResetConfig Component Variables
      resetConfigDialog: false,

      componentCheck: 0,

      search: "",

      // This is for select boxes at the top for filtering
      modelItems: [],
      modelInput: null,
      organizationItems: [],
      isLoadingOrganziationList: true,
      onlineItems: [
        { value: "ONLINE", text: "ONLINE" },
        { value: "OFFLINE", text: "OFFLINE" },
      ],

      // This is where all the filtered filteredDeviceList will go
      filteredDeviceList: [],

      modelFilter: "",
      onlineStatusFilter: "",
      organizationFilter: "",

      fetchedDevices: [],
      last_evaluated_key: {},
      selectedRecords: [],
      isTableLoading: false, // Flag for v-data-table loading

      availableDeviceModels: {}, // total router count fetched from dashboard data
      tableHeaderFilterDialog: false,
      partner: "",

      headers: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "online_status" },
        { text: "Device Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        {
          text: "Subscription",
          align: "start",
          sortable: true,
          class: "headerColor white--text ",
          value: "license_status",
        },
        {
          text: "Renewal Date",
          align: "start",
          sortable: true,
          class: "headerColor white--text",
          value: "expire_date",
        },
        { text: "Config Updated", sortable: true, class: "headerColor white--text ", value: "config_updated_on" },
        { text: "Last Reported", sortable: true, class: "headerColor white--text ", value: "last_reported_on" },
        { text: "Model", align: "start", sortable: true, class: "headerColor white--text ", value: "router_model" },
        { text: "Device SN", sortable: true, class: "headerColor white--text ", value: "router_id" },
        { text: "Device Type", sortable: true, class: "headerColor white--text ", value: "is_individual_router" },
        { text: "Email", sortable: true, class: "headerColor white--text ", value: "user_email_id" },
        { text: "Carrier", sortable: true, class: "headerColor white--text ", value: "carrier_info" },
        { text: "Partner", sortable: true, class: "headerColor white--text ", value: "partner_info" },
        { text: "Organization", sortable: true, class: "headerColor white--text ", value: "organization_name" },
        { text: "Device Version", sortable: true, class: "headerColor white--text ", value: "firmware_version" },
        { text: "Group", sortable: true, class: "headerColor white--text ", value: "router_group" },
        { text: "Usage(GB)", sortable: true, class: "headerColor white--text ", value: "data_usage" },
        { text: "Signal", sortable: true, class: "headerColor white--text ", value: "signal" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],
    };
  },

  // --------------------------Watch----------------------------------
  watch: {
    //Gene 6/27/23 Method to disable add to group when two models are selected
    selectedRecords() {
      var modelArray = this.selectedRecords.map((obj) => obj.router_model);
      this.selectedRecordsModels = [...new Set(modelArray)];
    },
  },

  // --------------------------Created----------------------------------
  async created() {
    this.modelFilter = this.$route.params.model ? this.$route.params.model : "";
    this.isTableLoading = true;

    await this.getSuperAdminDeviceCount();

    await this.getOrganizationList();
    await this.fetchAllDevices();

    this.filterDeviceList();

    this.isTableLoading = false;
  },

  // --------------------------Mounted----------------------------------
  async mounted() {
    this.$store.commit("SetCongifStatusCheck", false);
  },

  // --------------------------Computed----------------------------------
  computed: {
    ...mapGetters(["getterGetSuperAdminDashboardInfo", "getterGetCustomerAdminDashboardInfo", "getterGetUserInfo", "getterGetCurrentUserRole", "getterGetDeviceConfigurations"]),
    getDeviceConfigurations() {
      return this.getterGetDeviceConfigurations;
    },
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    superAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";
    },
    customerAdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN" || this.getterGetCurrentUserRole === "C_ADMIN_1";
    },
    supportAdminCheck() {
      return this.getterGetCurrentUserRole === "SP_ADMIN";
    },
    partnerAdminCheck() {
      return this.getterGetCurrentUserRole === "P_ADMIN";
    },
    individualUserCheck() {
      return this.getterGetCurrentUserRole === "INDIVIDUAL";
    },

    totalRouterCount() {
      if (this.modelFilter) {
        return this.availableDeviceModels[this.modelFilter] == undefined ? 0 : this.availableDeviceModels[this.modelFilter].individualCount;
      }
      let totalDeviceCount = 0;
      for (const key in this.availableDeviceModels) {
        totalDeviceCount += this.availableDeviceModels[key].individualCount;
      }

      return totalDeviceCount;
    },

    loadingText() {
      return this.isTableLoading ? "Loading..." : "No Organizations Available";
    },
  },

  // --------------------------Methods----------------------------------
  methods: {
    ...mapActions(["actionGetSuperAdminDashBoardDetails", "actionGetCustomerAdminDashBoardDetails", "actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // Config V2
    async open_configs_dialog(item) {
      this.singleRouterInfo = item;
      this.mutationOpenProgressBar();
      if (item.license_status.toLowerCase() === "active" || item.license_status === "trialing") {
        try {
          await this.actionGetDeviceConfiguration(item.router_id);
        } catch (err) {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        } finally {
          this.deviceConfigDialog = true;
          this.mutationCloseProgressBar();
        }
      } else {
        this.triggerSnackBar("error", "Renew subscription to enable configuraiton");
      }
    },
    close_configs_dialog() {
      this.deviceConfigDialog = false;
    },

    // ------------- Methods used in this form only ---------------
    //query call to fetch the router list to store
    async fetchAllDevices() {
      this.isTableLoading = true;

      // Fetch up to 100 devices per query
      let queryObj = {
        user_role: this.getCurrentUserRole,
        category: "INDIVIDUAL",
      };

      this.partnerAdminCheck ? (queryObj.partner_id = this.getCurrentUserID) : (queryObj.customer_id = this.getCurrentUserCustomerID);

      try {
        // Note that `fetchedDevices` is different function from `this.fetchDevices`
        const fetchedDevices = await getDeviceList(queryObj);
        this.fetchedDevices = fetchedDevices.items;
        this.last_evaluated_key = fetchedDevices.last_evaluated_key;
        this.filterDeviceList();
        // Continue fetching the next set of devices if there are any
        while (this.last_evaluated_key && Object.keys(this.last_evaluated_key).length != 0) {
          await this.load_more_router_data(queryObj);
        }
        this.mixinSetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isTableLoading = false;
      }
    },
    async load_more_router_data(queryObj) {
      queryObj.last_evaluated_key = this.last_evaluated_key;
      try {
        const fetchedDevices = await getDeviceList(queryObj);
        this.fetchedDevices = this.fetchedDevices.concat(fetchedDevices.items);
        this.last_evaluated_key = fetchedDevices.last_evaluated_key;
        this.filterDeviceList();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },
    //query call to fetch the data on filter option
    filterDeviceList() {
      var response = this.fetchedDevices;
      var filteredData = [];
      response.forEach((element) => {
        if (this.modelFilter && element.router_model != this.modelFilter) {
          return;
        }
        if (this.organizationFilter && element.organization_name != this.organizationFilter) {
          return;
        }
        if (this.onlineStatusFilter && element.online_status != this.onlineStatusFilter) {
          return;
        }
        filteredData.push(element);
      });
      this.filteredDeviceList = filteredData;
      // this.$forceUpdate();
    },
    async getOrganizationList() {
      this.isLoadingOrganziationList = true;

      let data = {
        organization_status: "ALL",
      };
      this.partnerAdminCheck ? (data.partner_id = this.getCurrentUserID) : "";

      try {
        // note that `getOrganizationList` and `this.getOrganizationList` are two different functions
        // `getOrganizationList` is an async query function imported from `services` folder
        let fetchedOrganizationList = await getOrganizationList(data);
        // find partner name for the currently logged in user from organization list
        this.partner = fetchedOrganizationList.find((org) => org.customer_id === this.getCurrentUserCustomerID).partner_name;
        // Extract list of organizations and sort it in alphabetical order
        this.organizationItems = fetchedOrganizationList
          .map((item) => item.organization_name)
          .sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
            return 0;
          });
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isLoadingOrganziationList = false;
      }
    },
    async handle_refresh_list() {
      if (this.isTableLoading) {
        return; // Exit the method if refresh is already in progress
      }
      this.isTableLoading = true;
      this.fetchedDevices = {};
      this.filteredDeviceList = [];
      this.getSuperAdminDeviceCount();

      document.getElementById("refresh").blur();
      await this.fetchAllDevices();
      this.isTableLoading = false;
    },

    // The following methods to fetch the total count of routers for super admin
    // This is shown at the bottom of the page
    async getSuperAdminDeviceCount() {
      try {
        await this.actionGetSuperAdminDashBoardDetails();
        this.availableDeviceModels = this.extractModelCounts(this.getterGetSuperAdminDashboardInfo, "S_ADMIN");
        this.modelItems = Object.keys(this.availableDeviceModels);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },

    // --------- FilterHeader Component related --------------
    filter_headers(filteredHeaders) {
      if (filteredHeaders == 0) {
        this.tableHeaderFilterDialog = false;
      } else {
        this.headers = filteredHeaders;
        this.tableHeaderFilterDialog = false;
      }
    },

    // ------------ Device Delete related --------------------
    open_delete_device_dialog() {
      this.routerID = [];
      this.selectedRecords.forEach((element) => {
        this.routerID.push({
          ID: element.router_id,
          name: element.router_name,
        });
      });
      this.deleteRouterDialog = true;
      this.componentCheck = 3;
    },
    //method invokes device delete action
    close_delete_device_dialog(deleteObj) {
      if (deleteObj.status === 0) {
        this.deleteRouterDialog = false;
      } else if (deleteObj.status === 1) {
        this.fetchedDevices = this.fetchedDevices.filter((device) => !deleteObj.deviceIdList.some((id) => id === device.router_id));
        this.selectedRecords = [];
        this.deleteRouterDialog = false;
        this.handle_refresh_list();
      }
    },

    // ------------------ Reset Router --------------------
    open_reset_config_dialog() {
      this.componentCheck = 4;
      this.resetConfigDialog = true;
    },

    close_reset_config_dialog() {
      this.resetConfigDialog = false;
    },

    // ---------------- CSV related -------------------------
    // Another seemingly useless code that will never reach its condition
    //method to validate if not item is slected on export action
    export_Method() {
      if (this.selectedRecords.length == 0) {
        this.triggerSnackBar("error", "Please select at least one item");
      }
    },

    // --------------- Row Dialog Related ----------------
    async open_device_details_dialog(item) {
      this.mutationOpenProgressBar();

      try {
        this.$store.commit("set_current_router_detail_object", item); // This is also stupid idea done by indian devs, all of their code sucks and has no structure
        this.singleRouterInfo = item;

        await this.actionGetSingleDeviceInfo(item.router_id);
        await this.actionGetDeviceConfiguration(item.router_id);
        // this.$store.commit("SetDefaultConfig", this.getDeviceConfigurations); // This line is required, since many other components seem to use vuex to retrieve device config
        // localStorage.setItem("Defaultconfig", JSON.stringify(this.getDeviceConfigurations)); // This line is required, since many other components seem to use local storage to retrieve device config
        // this.$store.commit("SetCongifStatusCheck", true);
        // this.$store.commit("SetRouterGroups", false); // This again seems to be shared in multiple files
        this.deviceDetailsDialog = true;
      } catch (err) {
        // Do not show any errors
      } finally {
        this.mutationCloseProgressBar();
      }
    },
    close_device_details_dialog() {
      this.deviceDetailsDialog = false;
    },
    //Method to call configuration menu of a device on action menu
    // async configuration_menu(item) {
    //   if (item.license_status.toLowerCase() === "active" || item.license_status === "trialing" || item.license_status.includes("rial")) {
    //     await this.open_device_details_dialog(item);
    //     this.$refs.routerDialog.open_device_configuration_dialog();
    //   } else {
    //     this.triggerSnackBar("error", "Renew subscription to enable configuraiton");
    //   }
    // },
    //method to route to configuration of on click
    // previewDailogMethod() {
    //   this.filterDeviceList();
    //   this.fetchedDevices = this.filteredDeviceList;
    //   this.overlay = false;
    //   this.deviceDetailsDialog = false;
    //   this.$store.commit("SetCongifStatusCheck", false);
    // },
    // This has to be deleted later
    // errorMsg(val) {
    //   this.triggerSnackBar("error", val);
    //   this.$store.commit("SetCongifStatusCheck", false);
    // },

    // -------------- Device Edit related -------------------
    // opens the device edit dialog
    // "editDeviceInfo" = selected information data from <v-data-table>.
    // The "router_id" and "customer_id" are used to fetch an appropriate list of organization and filter it down.
    async open_edit_device_dialog(selectedDeviceInfo) {
      this.componentCheck = 2;
      this.editDeviceInfo = selectedDeviceInfo;
      this.editDeviceDialog = true;
    },
    // method to validate the data that invoked from child component
    // "clickStatus" meaning:
    // 0 = The user just closed the dialog without any changes
    // 1 = The user closed the dialog after pressing save async request. Information on the local device list also has to change
    // in order to sync up with the changed data
    close_edit_device_dialog(editObj) {
      if (editObj.status === 0) {
        this.editDeviceDialog = false;
      } else if (editObj.status === 1) {
        this.editDeviceDialog = false;
        // search for edited device from router list and update information locally
        this.fetchedDevices.forEach((device) => {
          if (device.router_id === editObj.router_id) {
            device.router_activated = editObj.router_activated;
            if (!device.is_individual_router) {
              device.customer_id = editObj.customer_id;
              device.organization_name = editObj.organization_name;
            }
          }
        });
      }
    },

    // ----------- Remote Command --------------
    open_remote_command_dialog(item) {
      this.singleRouterInfo = item;
      this.remoteCommandDialog = true;
    },
    close_remote_command_dialog() {
      this.remoteCommandDialog = false;
    },

    // ------------------------- Misc ----------------------------
    // method to indicate the colorcode
    // These can be included in mixin later
    getColor(item) {
      const colorTable = {
        red: "#F44336",
        green: "#4CAF50",
        orange: "#FF9800",
      };
      return colorTable[item.indicate_color];
    },
    capitalizeFirstLetter(str) {
      //Remove once all devices convert from trialing to "Free Trial"
      if (str === "trialing") {
        return "Free Trial";
      }
      return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    formatDateWithTime(dateString) {
      // Create a new Date object from the input date string
      const currentDate = new Date();
      const endDate = new Date(dateString);
      //Calculate days remaining
      const msDifference = endDate - currentDate;
      const millisecondsInADay = 86400000;
      const daysRemaining = Math.floor(msDifference / millisecondsInADay);
      // Extract the month, day, and year from the Date object
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
      const day = String(endDate.getDate()).padStart(2, "0");
      const year = endDate.getFullYear();
      // Extract the hours, minutes, and seconds from the Date object
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      // Return the formatted date string in MM/DD/YYYY HH:MM:SS format
      // return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} <i class="grey--text">(${daysRemaining} days remaining)</i>`;
      return `${month}/${day}/${year} <i class="grey--text">(${daysRemaining} days remaining)</i>`;
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  border-radius: 4px;
}
i:hover span {
  animation-name: signal-intro;
  animation-play-state: running;
}

/* above for pen only */

$icon-width: auto;
$icon-height: 30px;
$icon-padding: 4px;
$bar-width: 6px;
$bar-spacing: 2px;
$bar-radius: 2px;
$bar-color: #87cb16;
$bar-start-height: 2px;
$bar-start-opacity: 0.2;
$bars-show-all: true; // false shows little nubs
$bars-use-grow-anim: true; // false uses fade anim

.icon__signal-strength {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: $icon-width;
  height: $icon-height;
  padding: $icon-padding;
}
.icon__signal-strength span {
  display: inline-block;
  width: $bar-width;
  margin-left: $bar-spacing;
  transform-origin: 100% 100%;
  background-color: $bar-color;
  border-radius: $bar-radius;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.3);
  animation-fill-mode: both;
  animation-play-state: paused;
}

.icon__signal-strength {
  .bar-1 {
    height: 25%;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
  }
  .bar-2 {
    height: 50%;
    animation-duration: 0.25s;
    animation-delay: 0.2s;
  }
  .bar-3 {
    height: 75%;
    animation-duration: 0.2s;
    animation-delay: 0.3s;
  }
  .bar-4 {
    height: 85%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;
  }
  .bar-5 {
    height: 100%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;
  }
}

.signal-0 {
  .bar-1,
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-1 {
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-2 {
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-3 {
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-4 {
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}

// -- component load animation
@keyframes signal-intro {
  from {
    opacity: $bar-start-opacity;

    @if $bars-use-grow-anim {
      height: $bar-start-height;
    }
  }
}

.columns-btn,
.magnify-btn {
  color: #0195d2;
}

.v-toolbar__content .search-bar {
  margin-top: 25px;
  width: 400px;
}
.columns-btn::before,
.columns-btn:hover,
.columns-btn:hover .column-icon,
.magnify-btn:hover,
.magnify-btn:hover {
  background-color: white;
  cursor: pointer;
  color: #0193d2d5;
  fill: #0193d2d5;
}

.header {
  font-size: 0.95em;
}

.banner-color {
  background-color: #dbf5f9;
}

.bold {
  font-weight: bold;
}

.text-decoration-underline {
  cursor: "pointer";
}

.menu-offset {
  transform: translateY(10px) translateX(-20px) !important;
}

.blue-text {
  color: blue;
}

.filter >>> fieldset {
  border-bottom: 1px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.filter >>> .v-label.theme--light {
  font-size: 16px;
  margin-top: -2px;
}

.filter >>> .v-select__selection.v-select__selection--comma {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0px;
}
</style>
<style scoped>
.button.custom-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  color: white;
  background-color: rgb(145, 145, 145) !important;
}

.button.v-btn {
  height: 34px;
  width: 120px;
  font-size: 14px;
  text-transform: none;
}

.tooltip-adjust {
  position: absolute;
  top: -1.2em;
  left: -2.2em;
  background-color: #202020;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 72px;
  display: flex;
  align-items: center;
  height: 30px;
}

.search-tooltip-adjust {
  position: absolute;
  top: -0.6em;
  left: -1.2em;
  background-color: #202020;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 60px;
  display: flex;
  align-items: center;
  height: 30px;
}

.v-toolbar__title {
  text-overflow: clip;
  overflow: visible;
}

.container-wrapper {
  display: inline-flex;
}

.filter,
.button {
  max-width: 130px;
  width: 130px;
}

.search-bar {
  width: 400px;
  margin-top: 80px;
}

.v-data-table.v-data-table--has-bottom >>> .v-data-footer,
.v-data-table.v-data-table--has-bottom >>> .v-data-footer .v-data-footer__select {
  height: 45px;
}

.v-application .caption.green--text.text--darken-3 {
  color: #2e7d32 !important;
  caret-color: #2e7d32 !important;
}
.blue--border {
  border-color: blue;
}

.container-wrapper > .v-text-field > .v-input__control > .v-input__slot::before {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}
.filter-font {
  font-size: 14px;
}

.bold-status {
  font-weight: 500;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}
.search-bar >>> .mdi-close {
  color: grey !important;
}
.container-wrapper .search-bar >>> .v-input__slot label,
.container-wrapper .search-bar >>> .v-input__slot input {
  margin-bottom: 4px;
}

.v-toolbar >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
