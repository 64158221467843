import { getCurrentUserDetails, logout_account } from '@/services/userService.js'

const userRole = {
    "SU_ADMIN": "Super Admin",
    "C_ADMIN": "Admin",
    "SP_ADMIN": "Support Staff",
    "P_ADMIN": "Partner",
    "G_M_ADMIN": "Group Manager",
    "INDIVIDUAL": "User",
    "C_ADMIN_1": "Org Admin"
};

const state = {
    user_info: null,
    userInfoIsLoaded: true,
}

const getters = {
    getterGetUserInfo: (state) => {
        return state.user_info;
    },
    getterGetCurrentUserRoleString: (state) => {
        return state.user_info ? userRole[state.user_info.user.user_role] : "";
    },
    getterGetCurrentUserRole: (state) => {
        return state.user_info ? state.user_info.user.user_role : "";
    },
    getterGetUserInfoIsLoaded: (state) => {
        return state.userInfoIsLoaded;
    }
}

const mutations = {
    mutationSetUserInfo: (state, payload) => {
        state.user_info = payload;
    },
    mutationSetUserInfoIsLoaded: (state, payload) => {
        state.userInfoIsLoaded = payload;
    }
}

const actions = {
    actionGetUserInfo: async (context, userEmail) => {
        try{
            context.commit('mutationSetUserInfoIsLoaded', false);
            const result = await getCurrentUserDetails(userEmail);
            context.commit('mutationSetUserInfo', result);
        } catch(error) {
            throw error
        } finally {
            context.commit('mutationSetUserInfoIsLoaded', true);
        }
    },
    actionLogOut: async (context, userEmail) => {
        const result = await logout_account(userEmail);
        context.commit('mutationSetUserInfo', null);
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}