<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="updateDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card flat width="auto">
        <v-toolbar dark class="bannercolor">
          <v-toolbar-title class="dashboard-router">User Profile</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="dialog_emit((Toggle = 1))">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="12">
          <v-container fluid class="pt-0">
            <div v-if="this.navigateLink === 'Account Information'">
              <AccountInfo :accountAccess="accountAccess" :dataResponse="dataResponse" :detailsCheck="detailsCheck" v-on:subheader="title_info" v-on:previousStep="previous_module" v-on:currentUser="current_data" :impersonatedUser="impersonatedUser" />
            </div>
            <div v-if="this.navigateLink === 'Company Details'">
              <CompanyDetails :dataResponse="dataResponse" :timeZoneArray="timeZoneArray" :previousCheck="previousCheck" v-on:subheader="title_info" v-on:finalstep="final_module" v-on:errorMsg="company_error" />
            </div>
            <div v-if="this.navigateLink === 'Account Settings'">
              <AccountInformation :accountAccess="accountAccess" :timeZoneArray="timeZoneArray" :dataResponse="dataResponse" :informationCheck="informationCheck" v-on:subheader="title_info" v-on:infoStep="info_module" v-on:currentUser="current_data" />
            </div>
            <div v-if="this.navigateLink === 'Licenses'">
              <Licenses />
            </div>
            <div v-if="this.navigateLink === 'Remote Support'">
              <RemoteSupport :dataResponse="dataResponse" v-on:currentUser="current_data" />
            </div>
          </v-container>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import AccountInfo from "@/components/UpdateProfileInfo/AccountInfo.vue";
import CompanyDetails from "@/components/UpdateProfileInfo/CompanyDetails.vue";
import AccountInformation from "@/components/UpdateProfileInfo/AccountInformation.vue";
import Licenses from "@/components/UpdateProfileInfo/Licenses.vue";
import RemoteSupport from "@/components/UpdateProfileInfo/RemoteSupport.vue";
import timeZones from "@/mixins/TimeZones.json";
export default {
  props: {
    updateDialog: Boolean,
    dataResponse: Object,
    accountAccess: String,
    impersonatedUser: Boolean,
  },
  components: {
    AccountInfo,
    CompanyDetails,
    AccountInformation,
    Licenses,
    RemoteSupport,
    SnackBar,
  },
  data() {
    return {
      drawer: null,
      selectedItem: 0,
      navigateLink: "Account Information",
      timeZones: [],
      timeZoneArray: [],
      productitems: [{ text: "Account Information" }, { text: "Account Settings" }],
      networkitems: [{ text: "Account Information" }, { text: "Company Details" }, { text: "Account Settings" }, { text: "Remote Support" }],
      SnackBarComponent: {},
      selecItems: [{ text: "Account Information" }, { text: "Account Settings" }, { text: "Remote Support" }],
      cUsers: [{ text: "Account Information" }, { text: "Account Settings" }, { text: "Remote Support" }],
      emailCheck: 0,
      detailsCheck: 0,
      previousCheck: 0,
      informationCheck: 0,
      pwdCheck: false,
    };
  },
  created() {
    this.country_timezones();
  },
  methods: {
    //method invokes on close action
    dialog_emit(Toggle) {
      this.navigateLink = "Account Information";
      this.detailsCheck = 0;
      this.selectedItem = 0;
      this.$emit("clicked", Toggle);
    },

    //method invokes on menu change
    menu_change(val) {
      this.navigateLink = val.text;
    },

    //method to fetch timezones
    country_timezones() {
      var timeZoneObj = timeZones;
      this.timeZoneArray = timeZoneObj.timezones;
    },

    //method to fetch the title on menu change
    //another braindead engineering from india
    title_info(val) {
      if (val == 1) {
        this.detailsCheck = 1;
      } else if (val == 2) {
        this.detailsCheck = 2;
      } else if (val == 3) {
      } else if (val == 4) {
        this.previousCheck = 1;
      } else if (val == 5) {
        this.detailsCheck = 3;
      } else if (val == 6) {
        this.informationCheck = 1;
      }
    },

    //method invokes on previous step actions
    previous_module() {
      this.detailsCheck = 0;
    },

    //method invokes on completion of procees to update changes to database
    current_data(val) {
      this.$emit("currentUser", val);
    },

    //method invokes on success of company details update
    final_module() {
      this.previousCheck = 0;
    },

    //method to display error if occurs
    company_error(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },

    //method invokes on success of account settings details update
    info_module() {
      this.informationCheck = 0;
    },
  },
};
</script>
