const state = {
  errorList: [],
  currentError: {},
};

const getters = {
  getterGetCurrentError: (state) => {
    return state.currentError;
  },
  getterGetErrorList: (state) => {
    return state.errorList;
  }
};

const mutations = {
  mutationAddError: (state, { path, pathString, errorMessage }) => {
    // Check if the path exists already in the list. If it doesn't add the object to the list.
    if(!state.errorList.some(error => error.path === path)) {
      state.errorList.push({ path, pathString, errorMessage });
    } else {
      // If the path already exists, change the error message
      const index = state.errorList.findIndex(error => error.path === path);
      state.errorList[index].errorMessage = errorMessage;
    }
  },
  mutationRemoveError: (state, path) => {
    const index = state.errorList.findIndex(error => error.path === path);
    if (index !== -1) {
      state.errorList.splice(index, 1);
    }
  },
  mutationSetCurrentError: (state, path) => {
    // if the path is the same without changes, change the toggle state to trigger watch functions in the config components
    // this means that the user has clicked on the same error card from the error list
    if(path === state.currentError.path) {
      state.currentError.toggle = !state.currentError.toggle;
      return;
    }
    // if the path does not match, set it as the currentError
    // note that the "toggle" property is only there to cover cases when the user clicks on the same error card
    // multiple times and have watch functions execute every time
    state.errorList.forEach(error => {
      if(error.path === path) {
        state.currentError = { ...error, toggle: true }
      }
    });
  },
  mutationResetErrors: (state, payload) => {
    state.errorList = [];
    state.currentError = {};
  },
};

export default {
  state,
  getters,
  mutations
};
