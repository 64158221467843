import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Preference")])]),_c('div',{staticClass:"subtitle-2 subheading-font"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Ethernet mode is to select top and bottom Ethernet port functionality. LAN/LAN will set the both ports to LAN mode. WAN/LAN will set the top port to WAN mode, the bottom port to LAN mode. ")])]),_c('div',{staticClass:"configurationCard"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VSelect,{staticClass:"ml-3",staticStyle:{"max-width":"250px"},attrs:{"dense":"","outlined":"","label":"Ethernet Mode","item-text":"text","item-value":"value","items":_vm.ethernetModes},model:{value:(_vm.ethernetMode),callback:function ($$v) {_vm.ethernetMode=$$v},expression:"ethernetMode"}})],1)],1),_c('div',[_c(VRow,[_c('div',{staticClass:"mt-8 ml-4 mb-4"},[_c(VImg,{attrs:{"src":require("@/assets/ethernet-mode.png")}})],1)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }