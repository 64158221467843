<template>
  <div>
    <div v-if="wifiConfigError || dhcpConfigError || connectionsConfigError || dataUsageConfigError" align="center">
      <v-btn large align="center" class="mt-3" color="#B8B8B8" dark width="200px" @click="preview_configuration()"> Submit Changes </v-btn>
      <div class="mandatory-warning mt-1 mb-1 mr-5">
        <v-icon size="20" color="red" class="mb-1">mdi-alert</v-icon>
        Enter all mandatory fields
      </div>
    </div>
    <div v-else align="center">
      <v-btn large align="center" class="mt-3" color="#94C73C" dark width="210px" @click="preview_configuration()">Submit Changes </v-btn>
    </div>

    <v-dialog v-model="viewDailog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card flat width="auto">
        <SnackBar :SnackBarComponent="SnackBarComponent" />

        <v-toolbar dark>
          <v-toolbar-title> Configuration Changes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="view_dailog_close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat align="center" class="mt-6">
          <div style="font-size: 22px">
            <b> Confirm and Update Configuration Changes</b>
          </div>
          <v-card-text class="custom-max-width"> Below is a list of all the configuration changes that were made to this device. Please review this list and make sure all the changes are correct. Once everything is correct, select "Update Parameter to Device" to update your changes. </v-card-text>
          <v-layout justify-center>
            <v-container>
              <v-row>
                <v-col v-if="this.$store.getters.GetInternet == true && this.internetArray.length > 0" cols="6">

                  <v-card>
                    <v-card-title>General Settings</v-card-title>
                    <v-list>
                      <v-list-item v-for="(item, index) in this.internetArray" :key="index">

                        <v-tooltip v-if="item.path == 'PrimaryDNSAddress' && (item.before == undefined || item.before == null)" bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on"> mdi-lock </v-icon>
                          </template>
                          <span>Cannot be deleted with Automatic DNS Disabled</span>
                        </v-tooltip>

                        <v-tooltip v-else-if="(item.path == 'CycleStartDayofYear' || item.path == 'CycleStartDayofMonth') && (item.before == undefined || item.before == null || item.before == 0)" bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on"> mdi-lock </v-icon>
                          </template>
                          <span>Cannot be deleted if "Data Cycle Resets" was recently changed</span>
                        </v-tooltip>

                        <v-icon v-else @click="revert_changes_dialog(item, 'internetArray')">mdi-close</v-icon>

                        <v-col class="left-align ml-5 bold">
                          <v-item-group>
                            <v-item v-if="item.path == 'Enable'">
                              <div>Parental Control</div>
                            </v-item>
                            <v-item v-else>
                              <div>{{ pathToReadableString(item.path, item.after) }}</div>
                            </v-item>
                          </v-item-group>
                        </v-col>
                        <v-col class="left-align">
                          <v-item-group>
                            <v-item>
                              <div>{{ configChangeValue(item.path, item.after) }}</div>
                            </v-item>
                          </v-item-group>
                        </v-col>
                      </v-list-item>
                    </v-list>
                  </v-card>

                </v-col>
                <v-col v-if="this.$store.getters.GetWiredLan == true && this.networkArray.length > 0" cols="6">
                  <v-card>
                    <v-card-title>Lan Settings</v-card-title>
                    <v-list v-for="(item, index) in this.networkArray" :key="index">
                      <v-row>
                        <v-col cols="6" class="left-align ml-3 bold">
                          <v-icon @click="revert_changes_dialog(item, 'networkArray')">mdi-close</v-icon>
                          <v-item-group class="ml-9">
                            <v-item v-if="item.path == 'Enable'">
                              <div>{{ item.path }}</div>
                            </v-item>
                            <v-item v-else>
                              <div>{{ pathToReadableString(item.path, item.after) }}</div>
                            </v-item>
                          </v-item-group>
                        </v-col>
                        <v-col cols="5">
                          <template v-if="item.path == 'FXPortMappingListChanges' || (item.path == 'MACFilterListChanges' && item.after != 0)">
                            <v-item-group v-for="(afterItem, afterIndex) in item.after" :key="afterIndex">
                              <v-row class="align-items-center">
                                <v-col v-if="hasDeletedTrue(afterItem)" class="flex-grow-1 left-align-list strikethrough truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-col v-else class="flex-grow-1 left-align-list truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-icon @click="showChangedData(afterIndex, 'portMapping')"> {{ changedPortArray[afterIndex] ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                              </v-row>
                              <v-row v-show="changedPortArray[afterIndex]" class="left-align">
                                <v-col>
                                  <v-container class="pt-0" v-for="(entry, changedIndex) in Object.values(afterItem)" dense :key="changedIndex">
                                    <v-row v-for="(value, title) in entry" :key="index" class="list-items left-align">
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <v-spacer></v-spacer>
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </v-item-group>
                          </template>
                          <template v-else-if="item.path == 'TimeOfDayAccess'">
                            <v-item-group v-for="(afterItem, afterIndex) in item.after" :key="afterIndex">
                              <v-row>
                                <v-col class="left-align-list"> {{ Object.keys(afterItem)[0] }}:00 - {{ Object.values(afterItem)[0] }}:00</v-col>
                              </v-row>
                            </v-item-group>
                          </template>
                          <v-item-group v-else class="left-align">
                            {{ configChangeValue(item.path, item.after) }}
                          </v-item-group>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-card>
                </v-col>

                <v-col v-if="this.$store.getters.GetFirewall == true && this.securityArray.length > 0" cols="6">
                  <v-card>
                    <v-card-title> Security</v-card-title>
                    <v-list v-for="(item, index) in this.securityArray" :key="index">
                      <v-row>
                        <v-col cols="6" class="left-align bold ml-3">
                          <v-item-group class="ml-9">
                            <v-item v-if="item.path == 'Enable'">
                              <v-icon @click="revert_changes_dialog(item, 'securityArray')">mdi-close</v-icon>
                              <div>
                                <v-icon @click="revert_changes_dialog(item, 'securityArray')">mdi-close</v-icon>
                                Content Filtering
                              </div>
                            </v-item>
                            <v-item v-else>
                              <div>
                                <v-icon class="ml-n8 mr-4" @click="revert_changes_dialog(item, 'securityArray')">mdi-close</v-icon>
                                {{ pathToReadableString(item.path, item.after) }}
                              </div>
                            </v-item>
                          </v-item-group>
                        </v-col>
                        <v-col cols="5">
                          <template v-if="item.path == 'PortMappingListChanges' && item.after != 0">
                            <v-item-group v-for="(afterItem, afterIndex) in item.after" :key="afterIndex">
                              <v-row class="align-items-center">
                                <v-col v-if="hasDeletedTrue(afterItem)" class="flex-grow-1 left-align-list strikethrough truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-col v-else class="flex-grow-1 left-align-list truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-icon @click="showChangedData(afterIndex, 'portMapping')"> {{ changedPortArray[afterIndex] ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                              </v-row>
                              <v-row v-show="changedPortArray[afterIndex]" class="left-align">
                                <v-col>
                                  <v-container class="pt-0" v-for="(entry, changedIndex) in Object.values(afterItem)" dense :key="changedIndex">
                                    <v-row v-for="(value, title) in entry" :key="index" class="list-items left-align">
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <v-spacer></v-spacer>
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </v-item-group>
                          </template>

                          <template v-else-if="item.path == 'MACFilteringRules' && item.after != 0">
                            <v-item-group v-for="(afterItem, afterIndex) in item.after" :key="afterIndex">
                              <v-row class="align-items-center">
                                <v-col v-if="hasDeletedTrue(afterItem)" class="flex-grow-1 left-align-list strikethrough truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-col v-else class="flex-grow-1 left-align-list truncate">
                                  {{ Object.keys(afterItem).join("") }}
                                </v-col>
                                <v-icon @click="showChangedData(afterIndex, 'MAC')"> {{ changedMACArray[afterIndex] ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                              </v-row>
                              <v-row v-show="changedMACArray[afterIndex]" class="left-align">
                                <v-col>
                                  <v-container class="pt-0" v-for="(entry, changedIndex) in Object.values(afterItem)" dense :key="changedIndex">
                                    <v-row v-for="(value, title) in entry" :key="index" class="list-items left-align">
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ pathToReadableString(title) }}
                                      </div>
                                      <v-spacer></v-spacer>
                                      <div v-if="hasDeletedTrue(afterItem) && pathToReadableString(title) != 'deleted'" class="left-align strikethrough">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                      <div v-else-if="pathToReadableString(title) != 'deleted'" class="left-align">
                                        {{ configChangeValue(title, value) }}
                                      </div>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </v-item-group>
                          </template>

                          <template v-else-if="item.path == 'TimeOfDayAccess'">
                            <v-item-group v-for="(afterItem, afterIndex) in item.after" :key="afterIndex">
                              <v-row v-if="afterItem.hasOwnProperty('deleted')" class="strikethrough">
                                <v-col class="left-align-list"> {{ Object.keys(afterItem)[0] }}:00 - {{ Object.values(afterItem)[0] }}:00</v-col>
                              </v-row>
                              <v-row v-else>
                                <v-col class="left-align-list"> {{ Object.keys(afterItem)[0] }}:00 - {{ Object.values(afterItem)[0] }}:00</v-col>
                              </v-row>
                            </v-item-group>
                          </template>
                          <v-item-group v-else class="left-align">
                            {{ configChangeValue(item.path, item.after) }}
                          </v-item-group>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col v-if="this.$store.getters.GetSystem == true && this.filteredRemoteSystem.length > 0" cols="6">
                  <v-card>
                    <v-card-title> System</v-card-title>
                    <v-list v-for="(item, index) in this.filteredRemoteSystem" :key="index">
                      <v-list-item>
                        <v-row>
                          <v-col cols="6" class="left-align bold ml-5">
                            <v-item-group>
                              <v-item>
                                <div>{{ pathToReadableString(item.path) }}</div>
                              </v-item>
                            </v-item-group>
                          </v-col>
                          <v-col class="left-align">
                            <v-item-group>
                              <v-item>
                                <div>{{ configChangeValue(item.path, item.after) }}</div>
                              </v-item>
                            </v-item-group>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-layout>
          <v-layout justify-center>
            <UpdateConfigData :totalChanges="totalChanges" :singleRouterInfo="singleRouterInfo" @clicked="close_update_part" @noChangesError="noChangesError" />
          </v-layout>
        </v-card>
        <RevertChangesDialog :revertChangesDialog="revertDialog" :revertChangesPath="revertChangesPath" @clicked="revertParams"> </RevertChangesDialog>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UpdateConfigData from "@/components/UpdateConfigData.vue";
import SnackBar from "@/components/SnackBar.vue";
import RevertChangesDialog from "@/components/RevertChangesDialog.vue";
import { get_configuration_of_router } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { mapMutations } from "vuex";

const { jsonDeepDiffList } = require("json-deep-diff-list");
export default {
  props: {
    singleRouterInfo: Object,
    applyStatus: Boolean,
    mainWifiConnection: Number,
    guestWifiConnection: Number,
    guestEnabledStatus: Number,
    dhcpErrorCheck: Boolean,
    dhcpEnabled: Boolean,
    defaultGateway: String,
    ipStartAdd: Number,
    ipEndAdd: Number,
    leaseTime: Number,
    wifiConfigError: Boolean,
    dhcpConfigError: Boolean,
    connectionsConfigError: Boolean,
    dataUsageConfigError: Boolean,
  },
  components: {
    UpdateConfigData,
    SnackBar,
    RevertChangesDialog,
  },
  computed: {
    totalChanges() {
      return this.internetArray.length + this.networkArray.length + this.securityArray.length + this.filteredRemoteSystem.length;
    },

    filteredRemoteSystem() {
      return this.remoteSystem.filter((item) => item.path != "MeshClientsReportInterval" && item.path != "WANPerformanceTestReportInterval" && item.path != "BrowsingHistoryReportInterval");
    },
  },
  data() {
    return {
      viewDailog: false,
      updateLoading: false,
      localArray: [],
      networkArray: [],
      interfaceArray: [],
      remoteSystem: [],
      securityArray: [],
      internetArray: [],
      SnackBarComponent: {},
      showData: false,
      changedData: null,
      changedPortArray: [],
      changedMACArray: [],
      changedTODArray: [],
      revertDialog: false,
      on: {},
      revertChangesPath: null,
      defaultConfig: null,
    };
  },

  async created() {
    try {
      let defaultJson = await API.graphql(
        graphqlOperation(get_configuration_of_router, {
          input: {
            // Either fetch configuration for single router or group, depending on which page(Single Routers or Groups) the user clicked on configuarion
            router_id: this.singleRouterInfo.router_id || this.singleRouterInfo.router_group_id
          },
        })
      );
      this.defaultConfig = defaultJson;
    } catch (err) {
      console.log(err);
    }
  },
  // -------------------- methods -------------------------
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    clear_data() {
      this.viewDailog = false;
    },

    noChangesError() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "No changes available to update",
        timeout: 5000,
        Top: true,
      };
    },

    preview_configuration() {
      if (this.applyStatus == false) {
        this.$emit("noChangesError");
      } else if (this.mainWifiConnection == 20 && this.guestEnabledStatus == 1) {
        this.$emit("greaterThanMaxConnections");
      } else if (this.dhcpErrorCheck == true && this.dhcpEnabled == true && (this.defaultGateway == "" || this.ipEndAdd == null || this.ipStartAdd == null || this.leaseTime == null || this.ipEndAdd > 255 || this.ipStartAdd > 255 || isNaN(this.ipEndAdd) || isNaN(this.ipStartAdd) || isNaN(this.leaseTime) || this.ipStartAdd >= this.ipEndAdd)) {
        this.$emit("dhcpRequired");
      } else {
        this.show_diff();
        this.viewDailog = true;
      }
    },

    show_diff() {
      this.networkArray = [];
      this.interfaceArray = [];
      this.remoteSystem = [];
      this.securityArray = [];
      this.internetArray = [];
      var preference = localStorage.getItem("preference");
      var mainWifi = localStorage.getItem("primaryNetwork");
      var inactiveTime = localStorage.getItem("inactiveTime");
      var dynamic = localStorage.getItem("dynamicDns");
      var dmz = localStorage.getItem("dmzInfo");
      var usage = localStorage.getItem("dataUsage");
      var guestNetwork = localStorage.getItem("guestNetwork");
      var modemGeneral = localStorage.getItem("General");
      var modemApn = localStorage.getItem("Apn");
      var modemMisc = localStorage.getItem("Misc");
      var control = localStorage.getItem("controlInfo");
      var parentalControl = localStorage.getItem("parentalControl");
      var fxPortInfo = localStorage.getItem("fxPortForward");
      var portInfo = localStorage.getItem("portForward");
      var deletedPortInfo = localStorage.getItem("deletedPortForward");
      var deletedFXPortInfo = localStorage.getItem("deletedFXPortForward");

      var dhcpServer = localStorage.getItem("dhcpServer");
      var dhcpRes = localStorage.getItem("dhcpReservation");
      var natInfo = localStorage.getItem("natPass");
      var general = localStorage.getItem("firewallGeneral");
      var accessControl = localStorage.getItem("accessControl");
      var todData = localStorage.getItem("Tod");
      var urlfiter = localStorage.getItem("urlFiltering");
      var contentSet = localStorage.getItem("contentFilter");
      var serviceSet = localStorage.getItem("serviceControl");
      var miscSet = localStorage.getItem("miscSettings");
      var ntpData = localStorage.getItem("ntpTimer");
      var remoteData = localStorage.getItem("remoteAdmin");
      var reports = localStorage.getItem("reportData");
      var alert = localStorage.getItem("alertSet");
      var automatic = localStorage.getItem("automacticReebot");

      if (JSON.parse(preference) == true) {
        this.preference();
      }
      if (JSON.parse(mainWifi) == true) {
        this.primary_net();
      }
      if (JSON.parse(inactiveTime) == true) {
        this.inactive_time();
      }
      if (JSON.parse(guestNetwork) == true) {
        this.guest_nwk_enable();
        this.guest_nwk();
      }
      if (JSON.parse(dynamic) == true) {
        this.dynamic_dns();
      }
      if (JSON.parse(dmz) == true) {
        this.dmz_internet();
      }
      if (JSON.parse(usage) == true) {
        this.usage_data();
      }

      if (JSON.parse(modemGeneral) == true) {
        this.modem_general();
      }
      if (JSON.parse(modemApn) == true) {
        this.modem_apn();
      }
      if (JSON.parse(modemMisc) == true || JSON.parse(control) == true) {
        this.modem_misc();
        this.remote_data();
      }
      if (JSON.parse(dhcpServer) == true) {
        this.wired_lan_dhcpserver();
      }

      if (JSON.parse(dhcpRes) == true) {
        this.wired_lan_dhcpres();
      }

      if (JSON.parse(fxPortInfo) == true || JSON.parse(deletedFXPortInfo) == true) {
        this.fx_port_forward();
      }

      if (JSON.parse(general) == true) {
        this.firewall_general();
      }

      if (JSON.parse(portInfo) == true || JSON.parse(deletedPortInfo) == true) {
        this.port_forward();
      }
      if (JSON.parse(natInfo) == true) {
        this.nat_pass_through();
      }

      if (JSON.parse(accessControl) == true) {
        this.access_control();
      }
      if (JSON.parse(todData) == true) {
        this.tod_diff();
      }
      if (JSON.parse(urlfiter) == true) {
        this.url_filter();
      }
      if (JSON.parse(contentSet) == true) {
        this.content_filter();
      }
      if (JSON.parse(serviceSet) == true) {
        this.service_filter();
      }
      if (JSON.parse(miscSet) == true) {
        this.misc_settings();
      }
      if (JSON.parse(ntpData) == true) {
        this.ntp_timer();
      }
      if (JSON.parse(remoteData) == true) {
        this.remote_admin();
      }
      if (JSON.parse(reports) == true) {
        this.report_sets();
      }
      if (JSON.parse(alert) == true) {
        this.alert_sets();
      }
      if (JSON.parse(automatic) == true) {
        this.automatic_reebot();
      }
      if (JSON.parse(parentalControl) == true) {
        this.parental_control();
      }
    },

    wired_lan_dhcpserver() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WiredLAN.Value.DHCPServer, this.$store.getters.GetDhcpServer.DHCPServer);
      const desiredOrder = ["DHCPRange", "DefaultGateway", "IPAddrStarting4th", "IPAddrEnding4th", "LeaseTime"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var beforeEnableStatus = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WiredLAN.Value.DHCPServerEnable;
      var defaultGateway = this.$store.getters.GetDhcpServer.IPAddress;
      var afterEnableStatus = this.$store.getters.GetDhcpServer.DHCPServerEnable;
      if (beforeEnableStatus != afterEnableStatus) {
        this.networkArray.push({
          path: "DHCPServerEnable",
          before: beforeEnableStatus,
          after: afterEnableStatus,
        });
      }
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            if (afterEnableStatus == 1) {
              if (element.path == "IPAddrStarting4th" || element.path == "IPAddrEnding4th") {
                self.networkArray.push({
                  path: element.path,
                  before: defaultGateway + "." + element.before,
                  after: defaultGateway + "." + element.after,
                });
              } else {
                self.networkArray.push({
                  path: element.path,
                  before: element.before,
                  after: element.after,
                });
              }
            }
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            if (afterEnableStatus == 1) {
              if (element.path == "IPAddrStarting4th" || element.path == "IPAddrEnding4th") {
                self.networkArray.push({
                  path: element.path,
                  before: defaultGateway + "." + element.before,
                  after: defaultGateway + "." + element.after,
                });
              } else {
                self.networkArray.push({
                  path: element.path,
                  before: element.before,
                  after: element.after,
                });
              }
            }
          }
        }
      });
    },

    wired_lan_dhcpres() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WiredLAN.Value.DHCPReservationList, this.$store.getters.GetDhcpServer.DHCPReservationList);
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.networkArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.networkArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    primary_net() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WirelessLAN.Value.MainWiFi, this.$store.getters.GetPrimaryNetwork.MainWiFi);

      const desiredOrder = ["RadioBand", "WiFiName", "WiFiPassword", "AuthenticationMethod", "MaximumConnections", "PrivacySeparator", "SSIDStealth"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },
    inactive_time() {
      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WirelessLAN.Value.InActiveTime;
      var currentData = this.$store.getters.GetPrimaryNetwork.InActiveTime;
      if (defaultData != currentData) {
        this.internetArray.push({
          path: "InActiveTime",
          before: defaultData,
          after: currentData,
        });
      }
    },

    guest_nwk() {
      const diffList = jsonDeepDiffList(this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value.Guest3WiFi, this.$store.getters.GetPrimaryNetwork.Guest3WiFi);
      var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value;
      const desiredOrder = ["RadioBand", "WiFiName", "WiFiPassword", "AuthenticationMethod", "MaximumConnections", "PrivacySeparator", "SSIDStealth"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var self = this;
      diffList.forEach((element) => {
        if (!(element.after == 0 && element.path == "MaximumConnections") && !(element.path == "MaximumConnections" && element.before == 0 && element.after == 1)) {
          if (element.before != undefined && element.after != undefined) {
            if (element.before != element.after) {
              self.internetArray.push({
                path: "Guest" + element.path,
                before: element.before,
                after: element.after,
              });
            }
          } else if (element.before == undefined && element.after != undefined) {
            if (element.before != element.after) {
              self.internetArray.push({
                path: "Guest" + element.path,
                before: element.before,
                after: element.after,
              });
            }
          }
        }
      });
    },
    guest_nwk_enable() {
      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WirelessLAN.Value.Guest3WiFiEnable;
      var currentData = this.$store.getters.GetPrimaryNetwork.Guest3WiFiEnable;
      if (defaultData != currentData) {
        this.internetArray.push({
          path: "Guest3WiFiEnable",
          before: defaultData,
          after: currentData,
        });
      }
    },
    dynamic_dns() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.DynamicDNS.Value, this.$store.getters.GetDynamicDns);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path + "ddns",
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path + "ddns",
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },
    dmz_internet() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.DMZ.Value, this.$store.getters.GetDmzObject);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path + "dmz",
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path + "dmz",
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    access_control() {
      var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);

      var diffList = jsonDeepDiffList(data.configuration_module.MACAddressFiltering.Value, this.$store.getters.GetAccessControl);

      var self = this;
      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.MACAddressFiltering.Value;
      var allRows = [];
      var ruleNameList = [];
      var tableData = null;
      if (this.$store.getters.GetAccessControl && this.$store.getters.GetAccessControl.MACFilteringRules) {
        tableData = this.$store.getters.GetAccessControl.MACFilteringRules;
      }
      defaultData.MACFilteringRules.forEach((element) => {
        tableData.forEach((tableElement) => {
          if (this.areObjectsEqual(element, tableElement)) {
            const elementDiffListPath = "MACFilteringRules." + tableElement.Index;
            const newDiffArray = diffList.filter((obj) => !obj.path.startsWith(elementDiffListPath));
            diffList = newDiffArray;
          }
        });
      });

      if (tableData != null) {
        tableData.forEach((element) => {
          for (var i = 0; i < diffList.length; i++) {
            if (!diffList[i].path.includes(element.Index.toString())) {
              continue;
            } else {
              const obj = {};
              obj[element.RuleName] = element.Index;
              ruleNameList.push(obj);
              break;
            }
          }
        });
        for (var i = 0; i < ruleNameList.length; i++) {
          var ruleName = Object.keys(ruleNameList[i])[0];
          var individualRow = {
            [ruleName]: {},
          };
          diffList.forEach((element) => {
            var columnName = element.path.split(".").pop();
            if (element.path.startsWith("MACFilteringRules.") && ruleNameList[i][ruleName] == element.path.match(/MACFilteringRules\.(\d+)\./)[1] && !element.path.endsWith("Index") && !element.path.endsWith("RuleName")) {
              individualRow[ruleName][columnName] = element.after;
            }
          });
          allRows.push(individualRow);
        }
      }
      var deletedList = this.$store.getters.GetDeletedAccessControl;
      var deletedRuleNameList = [];
      deletedList.forEach((element) => {
        const obj = {};
        obj[element.RuleName] = element.Index;
        deletedRuleNameList.push(obj);
      });
      for (var i = 0; i < deletedRuleNameList.length; i++) {
        var ruleName = Object.keys(deletedRuleNameList[i])[0];
        var individualRow = {
          [ruleName]: {},
        };
        deletedList.forEach((element) => {
          for (let key in element) {
            if (key == "Index" || key == "WANPortEnd" || key == "RuleName" || key == "deleted") {
              continue;
            } else {
              individualRow[ruleName][key] = element[key];
            }
          }
          individualRow[ruleName]["deleted"] = true;
        });

        allRows.push(individualRow);
      }

      const foundEnable = diffList.find((obj) => obj.path == "FilterSet");
      if (foundEnable) {
        self.securityArray.push({
          path: "MACFilter",
          before: foundEnable.before,
          after: foundEnable.after,
        });
      }
      const afterValue = allRows.length > 0 ? allRows : 0;
      if (afterValue != 0) {
        self.securityArray.push({
          path: "MACFilteringRules",
          before: defaultData,
          after: afterValue,
        });
      }
    },

    misc_settings() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.System.Value.MISC, this.$store.getters.GetMiscSettings.MISC);
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    ntp_timer() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.NTPTimeServer.Value, this.$store.getters.GetNTPTimer);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },
    remote_data() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.RouterAdmin.Value.RouterAccountList, this.$store.getters.GetRouterAdmin);
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },
    remote_admin() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.RouterAdmin.Value.RouterAccountList, this.$store.getters.GetRouterAdmin);
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    report_sets() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.ReportSets.Value, this.$store.getters.GetReportSets);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    alert_sets() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.AlertsSets.Value, this.$store.getters.GetAlertSets);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    parental_control() {
      var self = this;
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.ParentalControl.Value, this.$store.getters.GetParentControl);
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    automatic_reebot() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.AutomaticReboot.Value, this.$store.getters.GetAutoReboot);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.remoteSystem.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    firewall_general() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.FirewallGeneral.Value, this.$store.getters.GetFirewallGeneral);
      const desiredOrder = ["IPv4Firewall", "PortMappingList"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });

      var self = this;
      diffList.forEach((element) => {
        if (element.before != element.after) {
          self.securityArray.push({
            path: element.path,
            before: element.before,
            after: element.after,
          });
        }
      });
    },

    url_filter() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.URLFiltering.Value, this.$store.getters.GetUrlFilter);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: "Url" + element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: "Url" + element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    content_filter() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.ContentFiltering.Value, this.$store.getters.GetContentFilter);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    service_filter() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.ServiceFiltering.Value, this.$store.getters.GetServiceFilter);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: "Service" + element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.securityArray.push({
              path: "Service" + element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    usage_data() {
      const currentData = this.$store.getters.GetDataUsage;
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.Usage.Value, currentData);
      var unlimitedData = currentData.MonthlyLimit == 0 ? true : false;
      const desiredOrder = ["CycleStartDayofMonth", "CycleStartDayofYear", "MonthlyLimitAlert", "MonthlyLimit"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var self = this;
      diffList.forEach((element) => {
        if (element.path == "CycleStartDayofMonth" && element.after == 0) {
          return;
        }
        if (unlimitedData == false || element.path != "MonthlyLimitAlert") {
          if (element.before != undefined && element.after != undefined) {
            if (element.before != element.after) {
              if (element.path == "UsageCycle" || element.path == "CycleStartDayofMonth" || element.path == "MonthlyLimitAlert" || element.path == "MonthlyLimit" || element.path == "CycleStartDayofYear") {
                self.internetArray.push({
                  path: element.path,
                  before: element.before,
                  after: element.after,
                });
              }
            }
          } else if (element.before == undefined && element.after != undefined) {
            if (element.before != element.after) {
              if (element.path == "UsageCycle" || element.path == "CycleStartDayofMonth" || element.path == "MonthlyLimitAlert" || element.path == "MonthlyLimit" || element.path == "CycleStartDayofYear") {
                self.internetArray.push({
                  path: element.path,
                  before: element.before,
                  after: element.after,
                });
              }
            }
          }
        }
      });
    },

    preference() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.Ethernet.Value, this.$store.getters.GetPreference);
      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    nat_pass_through() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.VPNPassthrough.Value, this.$store.getters.GetNATPassthrough);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != undefined && element.after != undefined) {
          if (element.before != element.after) {
            if (element.path == "PPTPPassthrough") {
              return;
            }
            self.networkArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    port_forward() {
      var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);

      var diffList = jsonDeepDiffList(data.configuration_module.PortForwarding.Value, this.$store.getters.GetPortForward);
      var self = this;

      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.PortForwarding.Value;

      var allRows = [];
      var ruleNameList = [];

      var tableData = null;
      if (this.$store.getters.GetPortForward && this.$store.getters.GetPortForward.PortMappingList) {
        tableData = this.$store.getters.GetPortForward.PortMappingList;
      }
      defaultData.PortMappingList.forEach((element) => {
        if (tableData) {
          tableData.forEach((tableElement) => {
            if (this.areObjectsEqual(element, tableElement)) {
              const elementDiffListPath = "PortMappingList." + tableElement.Index;
              const newDiffArray = diffList.filter((obj) => !obj.path.startsWith(elementDiffListPath));
              diffList = newDiffArray;
            }
          });
        }
      });

      if (tableData != null) {
        tableData.forEach((element) => {
          for (var i = 0; i < diffList.length; i++) {
            if (!diffList[i].path.includes(element.Index.toString())) {
              continue;
            } else {
              const obj = {};
              obj[element.RuleName] = element.Index;
              ruleNameList.push(obj);
              break;
            }
          }
        });
        for (var i = 0; i < ruleNameList.length; i++) {
          var ruleName = Object.keys(ruleNameList[i])[0];
          var individualRow = {
            [ruleName]: {},
          };
          tableData.forEach((element) => {
            if (element.RuleName == ruleName) {
              for (let key in element) {
                if (key == "Index" || key == "WANPortEnd" || key == "RuleName" || key == "deleted") {
                  continue;
                } else {
                  individualRow[ruleName][key] = element[key];
                }
              }
            }
          });
          allRows.push(individualRow);
        }
      }
      var deletedList = this.$store.getters.GetDeletedPortForward;
      var deletedRuleNameList = [];
      deletedList.forEach((element) => {
        const obj = {};
        obj[element.RuleName] = element.Index;
        deletedRuleNameList.push(obj);
      });
      for (var i = 0; i < deletedRuleNameList.length; i++) {
        var ruleName = Object.keys(deletedRuleNameList[i])[0];
        var individualRow = {
          [ruleName]: {},
        };
        deletedList.forEach((element) => {
          for (let key in element) {
            if (key == "Index" || key == "WANPortEnd" || key == "RuleName" || key == "deleted") {
              continue;
            } else {
              individualRow[ruleName][key] = element[key];
            }
          }
          individualRow[ruleName]["deleted"] = true;
        });
        allRows.push(individualRow);
      }

      const foundEnable = diffList.find((obj) => obj.path == "Enable");
      if (foundEnable) {
        self.securityArray.push({
          path: "PortMappingListEnabled",
          before: foundEnable.before,
          after: foundEnable.after,
        });
      }
      const afterValue = allRows.length > 0 ? allRows : 0;
      if (afterValue != 0) {
        self.securityArray.push({
          path: "PortMappingListChanges",
          before: defaultData,
          after: afterValue,
        });
      }
    },

    fx_port_forward() {
      var diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.PortForwarding.Value, this.$store.getters.GetFXPortForward);
      var self = this;
      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.PortForwarding.Value;
      var allRows = [];
      var ruleNameList = [];
      var tableData = null;

      if (this.$store.getters.GetFXPortForward && this.$store.getters.GetFXPortForward.PortMappingList) {
        tableData = this.$store.getters.GetFXPortForward.PortMappingList;
      }
      defaultData.PortMappingList.forEach((element) => {
        tableData.forEach((tableElement) => {
          if (this.areObjectsEqual(element, tableElement)) {
            const elementDiffListPath = "PortMappingList." + tableElement.Index;
            const newDiffArray = diffList.filter((obj) => !obj.path.startsWith(elementDiffListPath));
            diffList = newDiffArray;
          }
        });
      });
      if (tableData != null) {
        tableData.forEach((element) => {
          for (var i = 0; i < diffList.length; i++) {
            if (!diffList[i].path.includes(element.Index.toString())) {
              continue;
            } else {
              const obj = {};
              obj[element.RuleName] = element.Index;
              ruleNameList.push(obj);
              break;
            }
          }
        });
        for (var i = 0; i < ruleNameList.length; i++) {
          var ruleName = Object.keys(ruleNameList[i])[0];
          var individualRow = {
            [ruleName]: {},
          };
          diffList.forEach((element) => {
            var columnName = element.path.split(".").pop();
            if (element.path.startsWith("PortMappingList.") && ruleNameList[i][ruleName] == element.path.match(/PortMappingList\.(\d+)\./)[1] && !element.path.includes("Index") && !element.path.includes("RuleName")) {
              if (element.path.includes("WANPortStart")) {
                individualRow[ruleName]["WAN Port Range"] = element.after;
              } else if (element.path.includes("WANPortEnd")) {
                return;
              } else if (element.path.includes("LANIPAddress")) {
                individualRow[ruleName]["Local IP"] = element.after;
              } else if (element.path.includes("LANPort")) {
                individualRow[ruleName]["Local Port"] = element.after;
              } else {
                individualRow[ruleName][columnName] = element.after;
              }
            }
          });
          allRows.push(individualRow);
        }
      }
      var deletedList = this.$store.getters.GetDeletedFXPortForward;
      var deletedRuleNameList = [];
      deletedList.forEach((element) => {
        const obj = {};
        obj[element.RuleName] = element.Index;
        deletedRuleNameList.push(obj);
      });
      for (var i = 0; i < deletedRuleNameList.length; i++) {
        var ruleName = Object.keys(deletedRuleNameList[i])[0];
        var individualRow = {
          [ruleName]: {},
        };
        deletedList.forEach((element) => {
          for (let key in element) {
            if (key == "Index" || key == "WANPortEnd" || key == "RuleName" || key == "deleted") {
              continue;
            } else {
              individualRow[ruleName][key] = element[key];
            }
          }
          individualRow[ruleName]["deleted"] = true;
        });
        allRows.push(individualRow);
      }
      const foundEnable = diffList.find((obj) => obj.path == "Enable");
      if (foundEnable) {
        self.networkArray.push({
          path: "FXPortMappingListEnabled",
          before: foundEnable.before,
          after: foundEnable.after,
        });
      }
      const afterValue = allRows.length > 0 ? allRows : 0;
      self.networkArray.push({
        path: "FXPortMappingListChanges",
        before: defaultData,
        after: afterValue,
      });
    },

    tod_diff() {
      var diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.TimeOfDayAccess.Value.ScheduleList, this.$store.getters.GetTodAcces.ScheduleList);
      var defaultData = JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.TimeOfDayAccess.Value.ScheduleList;
      var self = this;
      var allDeletedRows = [];
      var ruleNameList = [];
      var tableData = null;
      if (this.$store.getters.GetTodAcces && this.$store.getters.GetTodAcces.ScheduleList) {
        tableData = this.$store.getters.GetTodAcces.ScheduleList;
      }

      defaultData.forEach((element) => {
        tableData.forEach((tableElement) => {
          if (this.areObjectsEqual(element, tableElement)) {
            const elementDiffListPath = tableElement.Index + ".";
            const newDiffArray = diffList.filter((obj) => !obj.path.startsWith(elementDiffListPath));
            diffList = newDiffArray;
          }
        });
      });

      if (tableData != null) {
        tableData.forEach((element) => {
          for (var i = 0; i < diffList.length; i++) {
            if (!diffList[i].path.includes(element.Index.toString())) {
              continue;
            } else {
              const obj = {};
              obj[element.StartTime] = element.EndTime;
              ruleNameList.push(obj);
              break;
            }
          }
        });
      }

      var deletedList = this.$store.getters.GetDeletedTimeOfDay;

      var timeOfDayArray = [];

      ruleNameList.forEach((element) => {
        timeOfDayArray.push(element);
      });

      deletedList.forEach((element) => {
        element.deleted = "true";
        const obj = {};
        obj[element.StartTime] = element.EndTime;
        obj["deleted"] = "true";
        allDeletedRows.push(obj);
      });

      allDeletedRows.forEach((element) => {
        timeOfDayArray.push(element);
      });
      if (timeOfDayArray.length != 0) {
        self.securityArray.push({
          path: "TimeOfDayAccess",
          before: timeOfDayArray,
          after: timeOfDayArray,
        });
      }
    },

    guest_network() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.WirelessLAN.Value, this.$store.getters.GetGuestNetwork);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != element.after) {
          self.internetArray.push({
            path: element.path,
            before: element.before,
            after: element.after,
          });
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    modem_general() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.CellularGeneral.Value, this.$store.getters.GetModemGeneral);

      const desiredOrder = ["ConnectionMode", "DataRoaming", "ConnectionType", "AutomaticDNSEnable", "PrimaryDNSAddress", "SecondaryDNSAddress"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var self = this;
      diffList.forEach((element) => {
        if (element.path == "PrimaryDNSAddress" && (element.before == undefined || element.before == null)) {
          this.lockRevertChanges = true;
        }
        if (element.after != null && element.after != undefined && !(element.path == "SecondaryDNSAddress" && element.before == undefined && element.after == "")) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          } else if (element.before == undefined && element.after != undefined) {
            if (element.before != element.after) {
              self.internetArray.push({
                path: element.path,
                before: element.before,
                after: element.after,
              });
            }
          }
        }
      });
    },

    modem_apn() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.APNInfo.Value, this.$store.getters.GetApnInfo);

      var self = this;
      diffList.forEach((element) => {
        if (element.before != element.after) {
          self.internetArray.push({
            path: element.path,
            before: element.before,
            after: element.after,
          });
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    modem_misc() {
      const diffList = jsonDeepDiffList(JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.MISC.Value, this.$store.getters.GetMiscInfo);
      const desiredOrder = ["ShowUsage", "DisplayTimeout", "LEDIndicatorEnable", "DisplayWiFiNamePassword", "WebAdminPageInfoDisplay", "FactoryResetButtonEnable", "StopChargingBatteryLevel", "AccessWebInterface"];
      diffList.sort((a, b) => {
        const aIndex = desiredOrder.indexOf(a.path);
        const bIndex = desiredOrder.indexOf(b.path);
        return aIndex - bIndex;
      });
      var self = this;
      diffList.forEach((element) => {
        if (element.after != undefined && element.before != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        } else if (element.before == undefined && element.after != undefined) {
          if (element.before != element.after) {
            self.internetArray.push({
              path: element.path,
              before: element.before,
              after: element.after,
            });
          }
        }
      });
    },

    close_update_part(val) {
      if (val == 1) {
        this.viewDailog = false;
        this.$emit("previewDailogMethod", 0);
        this.triggerSnackBar("notification", "Updating to device...");
      } else if (val == 2) {
        this.viewDailog = false;
        this.$emit("previewDailogMethod", 0);
      }
    },

    ordinalIndicator(date) {
      if (date == 1 || date == 21 || date == 31) {
        return "st";
      } else if (date == 2 || date == 22) {
        return "nd";
      } else if (date == 3 || date == 23) {
        return "rd";
      } else {
        return "th";
      }
    },
    configChangeValue(path, value) {
      var result = "";
      switch (path) {
        case "AuthenticationMethod":
        case "GuestAuthenticationMethod":
          if (value == 0) {
            result = "None";
          } else if (value == 1) {
            result = "WPA2 Personal";
          } else if (value == 2) {
            result = "WPA3 Personal";
          } else if (value == 3) {
            result = "WPA/WPA2 Mixed";
          } else if (value == 4) {
            result = "WPA2/WPA3 Mixed";
          } else {
            result = "Invalid Input";
          }
          break;
        case "ConnectionMode":
          if (value == 0) {
            result = "Automatic";
          } else if (value == 1) {
            result = "5G Only";
          } else if (value == 2) {
            result = "5G/LTE";
          } else if (value == 3) {
            result = "LTE/3G";
          } else if (value == 4) {
            result = "LTE Only";
          } else if (value == 5) {
            result = "3G";
          } else {
            result = "Invalid Input";
          }
          break;
        case "InActiveTime":
          if (value == 0) {
            result = "10 minutes";
          } else if (value == 1) {
            result = "20 minutes";
          } else if (value == 2) {
            result = "30 minutes";
          } else if (value == 3) {
            result = "Always-On";
          } else {
            result = "Invalid Input";
          }
          break;
        case "ConnectionType":
          if (value == 0) {
            result = "Wi-Fi + USB";
          } else if (value == 1) {
            result = "Wi-Fi Only";
          } else if (value == 2) {
            result = "USB Only";
          } else {
            result = "Invalid Input";
          }
          break;
        case "WiFiName":
        case "WiFiPassword":
        case "GuestWiFiName":
        case "GuestWiFiPassword":
        case "0.UserName":
        case "HTTPPort":
        case "0.Password":
        case "IPAddrEnding4th":
        case "DefaultGateway":
        case "IPAddrStarting4th":
        case "LeaseTime":
        case "LANIPAddress":
        case "WANPortStart":
        case "WANPortEnd":
        case "RuleName":
        case "LANPort":
        case "WAN Port Range":
        case "Local Port":
        case "Local IP":
          result = value;
          break;
        case "RadioBand":
        case "GuestRadioBand":
          if (value == 0) {
            result = "2.4 Ghz Band";
          } else if (value == 1) {
            result = "5 Ghz Band";
          } else {
            result = "Invalid Input";
          }
          break;
        case "PrimaryDNSAddress":
        case "SecondaryDNSAddress":
          if (path == "SecondaryDNSAddress" && value == "") {
            result = "Disabled";
          } else {
            result = value;
          }
          break;
        case "StopChargingBatteryLevel":
          if (value == 0) {
            result = "5%";
          } else if (value == 1) {
            result = "25%";
          } else if (value == 2) {
            result = "50%";
          } else {
            result = "Invalid Input";
          }
          break;
        case "MaximumConnections":
        case "GuestMaximumConnections":
          if (value == 1) {
            result = value + " Connection";
          } else {
            result = value + " Connections";
          }
          break;
        case "MonthlyLimit":
          if (value == 0) {
            result = "Unlimited Usage";
          } else {
            result = value / 1024 + " GB";
          }
          break;
        case "CycleStartDayofMonth":
          result = value + this.ordinalIndicator(value);
          break;
        case "CycleStartDayofYear":
          result = value.substring(0, 2) + "/" + value.substring(2, 4);
          break;
        case "UsageCycle":
          if (value == 0) {
            result = "Monthly";
          } else if (value == 1) {
            result = "Yearly";
          } else {
            result = "Invalid Input";
          }
          break;
        case "ShowUsage":
          if (value == 0) {
            result = "WebUI Only";
          } else if (value == 1) {
            result = "Both Web and Device Display";
          } else {
            result = "Invalid Input";
          }
          break;
        case "DisplayTimeout":
          if (value == 0) {
            result = "30 seconds";
          } else if (value == 1) {
            result = "1 minute";
          } else if (value == 2) {
            result = "5 minutes";
          } else if (value == 3) {
            result = "Never";
          } else {
            result = "Invalid Input";
          }
          break;
        case "DisplayWiFiNamePassword":
          if (value == 0) {
            result = "Disabled";
          } else if (value == 1) {
            result = "Primary Wi-Fi Only";
          } else if (value == 2) {
            result = "Guest Wi-Fi Only";
          } else if (value == 3) {
            result = "Primary + Guest Wi-Fi";
          } else {
            result = "Invalid Input";
          }
          break;
        case "LeaseTime":
          result = value + "seconds";
          break;
        case "InternetDegradationAlertPercentage":
          result = value + "%";
          break;
        case "ConnectedStatusReportInterval":
        case "DHCPLeaseReportInterval":
          if (value > 0) {
            result = value;
          } else {
            result = "Disabled";
          }
          break;
        case "Protocol":
          if (value == 0) {
            result = "TCP";
          } else if (value == 1) {
            result = "UDP";
          } else if (value == 2) {
            result = "TCP & UDP";
          } else {
            result = "Invalid Value";
          }
          break;
        case "ServerProvider":
          if (value == 0) {
            result = "DynDns (dydns.org)";
          } else if (value == 1) {
            result = "Google (google.com)";
          } else if (value == 2) {
            result = "no-ip (no-ip.com)";
          } else {
            result = "Invalid Value";
          }
          break;
        case "ServerProviderddns":
          if (value == 0) {
            result = "Google (google.com)";
          } else if (value == 1) {
            result = "DynDNS (dydns.org)";
          } else if (value == 2) {
            result = "no-ip (no-ip.com)";
          } else {
            result = "Invalid Value";
          }
          break;
        case "PortMappingListChanges":
        case "FXPortMappingListChanges":
          if (value == null || value == undefined || value == 0) {
            break;
          } else {
            result = value;
          }
          break;
        case "MACFilterListChanges":
          if (value == 0) {
            break;
          } else {
            result = value;
          }
          break;
        case "EthernetMode":
          if (value == 0) {
            result = "LAN / LAN";
          } else if (value == 1) {
            result = "WAN / LAN";
          }
          break;
        case "MainWANConnection":
          if (value == 0) {
            result = "WAN (Wired) preferred";
          } else if (value == 1) {
            result = "5G/4G preferred";
          }
          break;
        default:
          if (value == 0) {
            result = "Disabled";
          } else if (value == 1) {
            result = "Enabled";
          } else {
            result = value;
          }
      }
      return result;
    },

    pathToReadableString(path, val) {
      var result = "";
      switch (path) {
        case "AuthenticationMethod":
          result = "Authentication";
          break;
        case "UsageCycle":
          result = "Data Cycle Resets";
          break;
        case "Enabledmz":
          result = "DMZ";
          break;
        case "LANIPAddress":
          result = "LAN IP Address";
          break;
        case "L2TPPassthrough":
          result = "VPN Passthrough";
          break;
        case "Enableddns":
          result = "Dynamic DNS";
          break;
        case "UserPasswordddns":
          result = "Dynamic DNS Password";
          break;
        case "ServerProviderddns":
          result = "Dynamic DNS Service Provider";
          break;
        case "DomainNameddns":
          result = "Dynamic DNS Domain Name";
          break;
        case "UserIDddns":
          result = "Dynamic DNS User ID";
          break;
        case "RadioBand":
          result = "Main Wi-Fi Band";
          break;
        case "MaximumConnections":
          result = "Main Wi-Fi Connections";
          break;
        case "GuestMaximumConnections":
          result = "Guest Wi-Fi Connections";
          break;
        case "Guest3WiFiEnable":
          result = "Guest Wi-Fi";
          break;
        case "GuestAuthenticationMethod":
          result = "Guest Authnetication";
          break;
        case "PowerBankEnable":
          result = "Power Bank";
          break;
        case "FactoryResetButtonEnable":
          result = "Factory Reset button";
          break;
        case "MonthlyLimit":
          result = "Usage Alert Threshold";
          break;
        case "MonthlyLimitAlert":
          result = "Usage Alert";
          break;
        case "0.UserName":
          result = "Remote Admin Username";
          break;
        case "0.Password":
          result = "Remote Admin Password";
          break;
        case "CycleStartDayofMonth":
        case "CycleStartDayofYear":
          result = "Usage Alert Reset Day";
          break;
        case "AutomaticDNSEnable":
          result = "Automatic DNS";
          break;
        case "IPAddrEnding4th":
          result = "IP Pool Ending Address";
          break;
        case "IPAddrStarting4th":
          result = "IP Pool Starting Address";
          break;
        case "DHCPServerEnable":
          result = "DHCP Range";
          break;
        case "IPv4Firewall":
          result = "Firewall Switch";
          break;
        case "InActiveTime":
          result = "Inactive Time";
          break;
        case "AccessFilterSet":
          result = "MAC Filtering";
          break;
        case "EnableNTP":
          result = "NTP Time Settings";
          break;
        case "InternetDegradationAlertPercentage":
          result = "Internet Degradation";
          break;
        case "ConnectedStatusReportInterval":
          result = "Connected Status Report";
          break;
        case "DHCPLeaseReportInterval":
          result = "DHCP Lease Report";
          break;
        case "PortMappingListChanges":
          if (val == 0) {
            break;
          } else {
            result = "Port Mapping List Changes";
            break;
          }
        case "FXPortMappingListChanges":
          if (val == 0) {
            break;
          } else {
            result = "FX Port Mapping List Changes";
            break;
          }
        case "MACFilterListChanges":
          if (val == 0) {
            break;
          } else {
            result = "MAC Filter List";
            break;
          }
        case "PortForwardingEnable":
          result = "Port Forwarding";
          break;
        case "FXPortMappingListEnabled":
          result = "FX Port Forwarding";
          break;
        case "PortMappingListEnabled":
          if (val == null || val == undefined) {
            break;
          }
          result = "Port Mapping List";
          break;

        default:
          for (var i = 0; i < path.length; i++) {
            const char = path[i];
            if (char === char.toUpperCase()) {
              if (char === "W" && path.slice(i, i + 4) == "WiFi") {
                if (i > 0) {
                  result += " ";
                }
                result += "Wi-Fi";
                i += 3;
              } else if (i > 0 && (path[i - 1] != path[i - 1].toUpperCase() || (i < path.length - 1 && path[i - 1] == path[i - 1].toUpperCase() && path[i + 1] != path[i + 1].toUpperCase()))) {
                result += " " + char;
              } else {
                result += char;
              }
            } else if (char === "o" && i < path.length - 2 && path[i - 1] === path[i - 1].toUpperCase() && path[i + 2] === path[i + 2].toUpperCase()) {
              if (path.slice(i, i + 2) == "of") {
                result += " of";
                i += 1;
              } else if (path.slice(i, i + 2) == "on") {
                result += " on";
                i += 1;
              } else {
                result += char;
              }
            } else {
              result += char;
            }
          }
      }
      return result;
    },

    hasDeletedTrue(obj) {
      return Object.values(obj).some((nestedObj) => nestedObj.deleted === true);
    },
    showChangedData(index, arrayType) {
      if (arrayType == "portMapping") {
        this.$set(this.changedPortArray, index, !this.changedPortArray[index]);
      } else if (arrayType == "MAC") {
        this.$set(this.changedMACArray, index, !this.changedMACArray[index]);
      }
    },
    //Gene 6/8/23
    //Checks if 2 objects are equal to eachother. return true if equal (ignores Index)
    areObjectsEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (key == "Index") {
          continue;
        }

        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },

    view_dailog_close() {
      this.viewDailog = false;
      this.$emit("renderInputValues", this.lastRevertedPath);
    },
    //Gene 6/19/23
    //Render RevertChanges component and stores item/array type
    revert_changes_dialog(item, arrayType) {
      this.revertItem = item;
      this.revertChangesPath = this.pathToReadableString(item.path, item.before);
      this.revertArray = arrayType;
      this.revertDialog = true;
    },

    //Gene 6/19/23
    //Reverts deleted parameters to default
    async revertParams(val) {
      if (val == 0) {
        this.revertItem = null;
        this.revertArray = null;
        this.revertDialog = false;
        this.revertChangesPath = null;
      } else if (val == 1) {
        var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN;
        //Reverts preview configuration UI
        switch (this.revertArray) {
          case "internetArray":
            this.internetArray = this.internetArray.filter((item) => item.path != this.revertItem.path);
            break;
          case "networkArray":
            this.networkArray = this.networkArray.filter((item) => item.path != this.revertItem.path);
            break;
          case "securityArray":
            this.securityArray = this.securityArray.filter((item) => item.path != this.revertItem.path);
            break;
        }
        //Reverts vueX store back to default
        if (this.revertItem.path == "PortMappingListChanges") {
          var path = "PortMappingList";
        } else {
          var path = this.revertItem.path;
        }
        switch (path) {
          //Gene 6/19/23 cases for Main Wi-Fi
          case "RadioBand":
          case "WiFiName":
          case "WiFiPassword":
          case "AuthenticationMethod":
          case "MaximumConnections":
          case "PrivacySeparator":
          case "SSIDStealth":
          case "EncryptionMethod":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value;
            var currentData = this.$store.getters.GetGuestNetwork;
            currentData.MainWiFi[path] = defaultJson.MainWiFi[path];
            this.$store.commit("SetGuestNetwork", currentData);
            this.$store.commit("SetPrimaryNetwork", currentData);
            break;
          //Gene 6/19/23 cases for WireLess LAN (mix of main/guest info)
          case "InActiveTime":
          case "Guest3WiFiEnable":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value;
            var currentData = this.$store.getters.GetGuestNetwork;
            if (path == "Guest3WiFiEnable" && currentData[path] == 1) {
              if (currentData.Guest3WiFi["MaximumConnections"] != 1) {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "green",
                  SnackbarText: "Guest Wi-Fi Connections is now 0",
                  timeout: 5000,
                  Top: true,
                };
              }
              currentData.Guest3WiFi["MaximumConnections"] = 0;
              this.$emit("disableGuestWifi", 0);
              this.internetArray = this.internetArray.filter((item) => item.path != "GuestMaximumConnections");
            }
            currentData[path] = defaultJson[path];
            this.$store.commit("SetGuestNetwork", currentData);
            this.$store.commit("SetPrimaryNetwork", currentData);
            break;
          //Gene 6/19/23 cases for guest WiFi
          case "GuestRadioBand":
          case "GuestWiFiName":
          case "GuestWiFiPassword":
          case "GuestAuthenticationMethod":
          case "GuestPrivacySeparator":
          case "GuestSSIDStealth":
          case "GuestEncryptionMethod":
          case "GuestMaximumConnections":
            var objPath = path.replace("Guest", "");
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WirelessLAN.Value;
            var currentData = this.$store.getters.GetGuestNetwork;
            if (objPath == "MaximumConnections" && defaultJson.Guest3WiFi[objPath] == 0) {
              currentData.Guest3WiFi[objPath] = 1;
            } else {
              currentData.Guest3WiFi[objPath] = defaultJson.Guest3WiFi[objPath];
            }
            this.$store.commit("SetGuestNetwork", currentData);
            this.$store.commit("SetPrimaryNetwork", currentData);
            break;
          //Gene 6/20/23 cases for Connections
          case "AutomaticDNSEnable":
          case "ConnectionMode":
          case "AuthenticationMethod":
          case "ConnectionType":
          case "DataRoaming":
          case "PrimaryDNSAddress":
          case "SecondaryDNSAddress":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.CellularGeneral.Value;
            var currentData = this.$store.getters.GetModemGeneral;
            if (path == "AutomaticDNSEnable" && currentData[path] == 0) {
              this.internetArray = this.internetArray.filter((item) => item.path != "PrimaryDNSAddress");
              this.internetArray = this.internetArray.filter((item) => item.path != "SecondaryDNSAddress");
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "Primary and Secondary DNS Address disabled",
                timeout: 5000,
                Top: true,
              };
            }
            currentData[path] = defaultJson[path];
            this.$store.commit("SetModemGeneral", currentData);
            break;
          //Gene 6/20/23 Cases for Display
          case "ShowUsage":
          case "DisplayTimeout":
          case "LEDIndicatorEnable":
          case "DisplayWiFiNamePassword":
          case "WebAdminPageInfoDisplay":
          //Gene 6/21/23 Cases for Control
          case "AccessWebInterface":
          case "PowerBankEnable":
          case "StopChargingBatteryLevel":
          case "FactoryResetButtonEnable":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.MISC.Value;
            var currentData = this.$store.getters.GetMiscInfo;
            currentData[path] = defaultJson[path];
            this.$store.commit("SetMiscInfo", currentData);
            break;
          //Gene 6/21/23 Cases for Admin Password inside control menu
          case "0.Password":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.Usage.Value;
            var currentData = this.$store.getters.GetRouterAdmin;
            currentData[0].Password = defaultJson[0].Password;
            this.$store.commit("SetRouterAdmin", currentData);
            break;
          //Gene 6/21/23 Cases for Data Usage
          case "UsageCycle":
          case "CycleStartDayofMonth":
          case "MonthlyLimitAlert":
          case "MonthlyLimit":
          case "CycleStartDayofYear":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.Usage.Value;
            var currentData = this.$store.getters.GetDataUsage;
            currentData[path] = defaultJson[path];
            if (path == "UsageCycle") {
              this.internetArray = this.internetArray.filter((item) => item.path != "CycleStartDayofMonth");
              this.internetArray = this.internetArray.filter((item) => item.path != "CycleStartDayofYear");
              if (currentData[path] == 0) {
                currentData["CycleStartDayofMonth"] = defaultJson["CycleStartDayofMonth"];
              } else if (currentData[path] == 1) {
                currentData["CycleStartDayofYear"] = defaultJson["CycleStartDayofYear"];
              }
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "Data Cycle start date reverted to original value",
                timeout: 5000,
                Top: true,
              };
            }
            this.$store.commit("SetDataUsage", currentData);
            break;
          case "EthernetMode":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.Ethernet.Value;
            var currentData = this.$store.getters.GetPreference;
            currentData[path] = defaultJson[path];
            this.$store.commit("SetPreference", currentData);
            break;
          //Gene 6/22/23 Cases for DHCP Range
          case "DHCPServerEnable":
          case "IPAddrStarting4th":
          case "IPAddrEnding4th":
          case "LeaseTime":
          case "DefaultGateway":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.WiredLAN.Value;
            var currentData = this.$store.getters.GetDhcpServer;
            if (path != "DHCPServerEnable") {
              currentData.DHCPServer[path] = defaultJson.DHCPServer[path];
            } else {
              currentData[path] = defaultJson[path];
            }
            var ipAddrChanged = this.networkArray.some((obj) => obj.path == "IPAddrStarting4th" || obj.path == "IPAddrEnding4th");
            if (path == "DefaultGateway" && ipAddrChanged) {
              const newNetworkArray = this.networkArray.map((obj) => {
                if (obj.path == "IPAddrStarting4th" || obj.path == "IPAddrEnding4th") {
                  const lastIndex = obj.after.lastIndexOf(".");
                  return { ...obj, after: this.revertItem.before + "." + obj.after.substring(lastIndex + 1) };
                }
                return obj;
              });
              this.networkArray = newNetworkArray;
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "Beginning of IP Pool Starting and Ending Address changed due to change in default gateway",
                timeout: 5000,
                Top: true,
              };
            }

            this.$store.commit("SetDhcpServer", currentData);
            break;
          case "PortMappingList":
            var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);
            var currentData = this.$store.getters.GetPortForward;
            currentData[path] = data.configuration_module.PortForwarding.Value.PortMappingList;
            this.$store.commit("SetDeletedPortForward", []);
            localStorage.setItem("deletedPortForward", false);
            this.$store.commit("SetPortForward", currentData);
            break;
          case "PortMappingListEnabled":
            path = "Enable";
            var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);
            var currentData = this.$store.getters.GetPortForward;
            currentData[path] = data.configuration_module.PortForwarding.Value.Enable;
            this.$store.commit("SetPortForward", currentData);
            break;
          case "IPv4Firewall":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.FirewallGeneral.Value;
            var currentData = this.$store.getters.GetFirewallGeneral;
            if (this.revertItem.after == 1 && this.securityArray.some((obj) => obj.path == "PortMappingListEnabled" || obj.path == "PortMappingList")) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "Port Mapping List will not work with Firewall Switch disabled",
                timeout: 5000,
                Top: true,
              };
            }
            currentData[path] = defaultJson[path];
            this.$store.commit("SetFirewallGeneral", currentData);
            break;
          case "MACFilteringRules":
          case "MACFilter":
            var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);
            var defaultJson = data.configuration_module.MACAddressFiltering.Value;
            var currentData = this.$store.getters.GetAccessControl;
            if (path == "MACFilter" && this.revertItem.after == 1) {
              this.securityArray = this.securityArray.filter((item) => item.path != "MACFilteringRules");
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "MAC Filtering Rules disabled",
                timeout: 5000,
                Top: true,
              };
            }
            if (path == "MACFilter") {
              path = "FilterSet";
              currentData[path] = defaultJson[path];
              currentData["MACFilteringRules"] = defaultJson["MACFilteringRules"];
              this.$store.commit("SetDeletedAccessControl", []);
              localStorage.setItem("deletedAccessControl", false);
              this.$store.commit("SetAccessControl", currentData);
              break;
            } else {
              currentData[path] = defaultJson[path];
              this.$store.commit("SetDeletedAccessControl", []);
              localStorage.setItem("deletedAccessControl", false);
              this.$store.commit("SetAccessControl", currentData);
              break;
            }
          case "TimeOfDayAccess":
            var data = JSON.parse(this.defaultConfig.data.get_configuration_of_router);
            var defaultJson = data.configuration_module.TimeOfDayAccess.Value;
            var currentData = this.$store.getters.GetTodAcces;
            currentData = defaultJson;
            this.$store.commit("SetDeletedTimeOfDay", []);
            localStorage.setItem("deletedTimeOfDay", false);
            this.$store.commit("SetTodAcces", currentData);
            break;
          case "Enable":
            var defaultJson = this.$store.getters.GetDefaultConfig.configuration_module.ParentalControl.Value;
            var currentData = this.$store.getters.GetParentControl;
            currentData[path] = defaultJson[path];
            this.$store.commit("SetParentControl", currentData);
            path = "ParentalEnable";
            break;
        }
        this.lastRevertedPath = path;
        this.revertItem = null;
        this.revertArray = null;
        this.revertDialog = false;
        this.revertChangesPath = null;
      }
    },
  },
};
</script>

<style>
.left-align {
  display: flex;
}
.strikethrough {
  text-decoration: line-through;
  color: red;
}
.left-align {
  display: flex;
}
.left-align-list {
  display: flex;
}
.right-align {
  float: right;
}
.bold {
  font-weight: 500;
}

.custom-max-width {
  max-width: 40%;
}

.list-items {
  font-size: 0.9em;
  color: rgb(121, 121, 121);
}

.disabled {
  color: gray;
}

.mandatory-warning {
  color: red;
  font-size: 0.8em;
}

.truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.align-items-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.icon-col {
  display: inline;
  text-align: right;
}
</style>
