import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.manageSubscriptionDialog),callback:function ($$v) {_vm.manageSubscriptionDialog=$$v},expression:"manageSubscriptionDialog"}},[_c('DialogCard',{attrs:{"modalTitle":'Manage Subscriptions',"modalIcon":'credit-card'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.close_dialog()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]},proxy:true},{key:"body",fn:function(){return [_c(VRow,{staticClass:"mt-0 mb-4 mx-0 d-flex align-center"},[_c('h2',[_vm._v("Subscription List")]),_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text",attrs:{"loading":_vm.loadingCustomerPortal},on:{"click":function($event){return _vm.openCustomerPortal()}}},[_vm._v("Manage")])],1),_c(VDataTable,{staticClass:"data-table-border table-caption",attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.tableData,"items-per-page":10,"loading":_vm.isTableLoading,"no-data-text":_vm.isTableLoading ? 'Loading items...' : 'No devices available',"show-select":"","item-key":"router_id"},scopedSlots:_vm._u([{key:`item.license_status`,fn:function({ item }){return [_c('span',{style:({ color: item.license_status === 'trialing' || item.license_status === 'ACTIVE' || item.license_status === 'active' ? 'green' : 'red'})},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.license_status))+" ")])]}},{key:`item.expire_date`,fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDateWithTime(item.expire_date))}})]}},{key:`item.router_id`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.router_id.split("/")[1])+" ")]}}],null,true)})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }