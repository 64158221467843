<template>
  <div>
    <v-main>
      <!-- tool bar to diaply title and add action -->
      <v-toolbar class="elevation-0 tablePadding" dense flat>
        <v-toolbar-title class="mt-1">Bulk Registers</v-toolbar-title>
        <v-spacer></v-spacer>
        <ImportExcel :onSuccess="handleSuccess" :validData="validData" :genearteData="genearteData" />
      </v-toolbar>

      <div>
        <!-- component invokes on excel upload -->
        <div v-if="excelUploadStatus == true">
          <ExcelValidation :headerExcelUpoader="headerExcelUpoader" :resultsExcelUpoader="resultsExcelUpoader" :tabledataDemo="tabledataDemo" :validData="validData" :invalidData="invalidData" :subscriptionData="subscriptionData" :modelList="modelList" :countryList="countryList" :organizationList="organizationList" />
        </div>
      </div>
    </v-main>
  </div>
</template>
<script>
import ImportExcel from "@/components/BulkExcel/ImportExcel.vue";
import ExcelValidation from "@/components/BulkExcel/ExcelValidation.vue";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
import { getAllProducts } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { does_subscription_exist } from "@/graphql/queries/stripeQueries.js";

export default {
  mixins: [mixin_form_validation_utils],
  components: {
    ImportExcel,
    ExcelValidation,
  },
  data() {
    return {
      files: [],
      excelUploadStatus: false,
      tabledataDemo: [],
      tempArray: [],
      headerExcelUpoader: [],
      resultsExcelUpoader: [],
      validData: [],
      invalidData: [],
      genearteData: false,
      licensekeys: [],
      productResponse: [],
      subscriptionData: {},
      organizationList: [],
      countryList: [],
      modelList: [],
    };
  },
  created() {},
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method to fetch the product details
    async product_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(getAllProducts, {
            input: {},
          })
        );
        var response = JSON.parse(result.data.GetAllProducts);
        this.productResponse = response;
      } catch (error) {}
    },

    // method invokes on file upload
    handleSuccess({ results, header }) {
      this.tempArray = [];
      this.excelUploadStatus = false;
      this.resultsExcelUpoader = results;
      this.headerExcelUpoader = header;
      this.validate_add_data(results, header);
    },

    //method to validate the empty record
    async validate_add_data(results, header) {
      var valid = true;
      if (this.resultsExcelUpoader.length == 0) {
        this.triggerSnackBar("error", "The data cannot be empty.");
      }
      if (valid) {
        try {
          this.validation_data(results, header);
          //Check if user/organization already contains subscription or not
          // const doesSubscriptionExist = await API.graphql(
          //   graphqlOperation(does_subscription_exist, {
          //     input: {
          //       organization_list: this.organizationList[0],
          //       model: this.modelList[0],
          //       license_country: this.countryList[0],
          //       acc_type: "business",
          //     },
          //   })
          // );
          // this.subscriptionData = JSON.parse(
          //   doesSubscriptionExist.data.does_subscription_exist
          // );
          this.excelUploadStatus = true;
        } catch (error) {
          console.log(error);
          this.triggerSnackBar("error", error.errors ? error.errors[0].message : error);
          if (error.errors[0].message.includes("does not exist in stripe")) {
            this.excelUploadStatus = true;
          }
        }
      }
    },

    //  method to validate uploaded data
    validation_data(results, header) {
      this.invalidData = [];
      this.validData = [];

      // console.log(JSON.parse(JSON.stringify(results)));
      
      this.modelList = [];
      this.countryList = [];
      this.organizationList = [];
      results.forEach((uploadedData) => {

        if (uploadedData.Model == undefined || uploadedData.FID == undefined || uploadedData.ProductName == undefined || uploadedData.LicenseType == undefined || uploadedData.LicenseName == undefined) {
          return this.invalidData.push(uploadedData);
        }

        // Main Wi-Fi validation
        // Note that neither Main Wi-Fi nor Guest Wi-Fi are mandatory. There can be either one or the other or none
        const hasMainWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasMainWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasMainWiFiSSID || hasMainWiFiPassword) {
          if (hasMainWiFiSSID != hasMainWiFiPassword) {
            this.triggerSnackBar("error", `Main Wi-Fi: You must have both SSID and Password or have neither.`);
            return this.invalidData.push(uploadedData);
          }
          const mainWifiPasswordCheck = this.mixinScriptValidation(uploadedData.MainWiFiPassword, this.mixinWifiPasswordRule());
          const mainWifiSSIDCheck = this.mixinScriptValidation(uploadedData.MainWiFiSSID, this.mixinWifiNameRule());
          if (!mainWifiPasswordCheck.valid) {
            this.triggerSnackBar("error", `Main Wi-Fi Password: ${mainWifiPasswordCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (!mainWifiSSIDCheck.valid) {
            this.triggerSnackBar("error", `Main Wi-Fi SSID: ${mainWifiSSIDCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
        }

        // Guest Wi-Fi Validation
        const hasGuestWiFiSSID = uploadedData.hasOwnProperty("GuestWiFiSSID");
        const hasGuestWiFiPassword = uploadedData.hasOwnProperty("GuestWiFiPassword");
        if (hasGuestWiFiSSID || hasGuestWiFiPassword) {
          if (hasGuestWiFiSSID != hasGuestWiFiPassword) {
            this.triggerSnackBar("error", `Guest Wi-Fi: You must have both SSID and Password or have neither.`);
            return this.invalidData.push(uploadedData);
          }
          const guestWifiPasswordCheck = this.mixinScriptValidation(uploadedData.GuestWiFiPassword, this.mixinWifiPasswordRule());
          const guestWifiSSIDCheck = this.mixinScriptValidation(uploadedData.GuestWiFiSSID, this.mixinWifiNameRule());
          if (!guestWifiPasswordCheck.valid && uploadedData.hasOwnProperty("GuestWiFiPassword")) {
            this.triggerSnackBar("error", `Guest Wi-Fi Password: ${guestWifiPasswordCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
          if (!guestWifiSSIDCheck.valid && uploadedData.hasOwnProperty("GuestWiFiSSID")) {
            this.triggerSnackBar("error", `Guest Wi-Fi SSID: ${guestWifiSSIDCheck.message}`);
            return this.invalidData.push(uploadedData);
          }
        }

        // Admin password
        const adminPasswordCheck = this.mixinScriptValidation(uploadedData.RouterAdminPassword, this.mixinRouterAdminPasswordRule());
        if (!adminPasswordCheck.valid) {
          this.triggerSnackBar("error", `Router Admin Password: ${adminPasswordCheck.message}`);
          return this.invalidData.push(uploadedData);
        }

        this.validData.push(uploadedData);
        if (uploadedData.Organization != undefined && !this.organizationList.includes(uploadedData.Organization)) {
          this.organizationList.push(uploadedData.Organization);
        }
        if (!this.modelList.includes(uploadedData.Model)) {
          this.modelList.push(uploadedData.Model);
        }
        console.log("Country: ", uploadedData.LicenseCountry);
        if (!this.countryList.includes(uploadedData.LicenseCountry)) {
          this.countryList.push(uploadedData.LicenseCountry);
        }
      });

      const throwError = (text, list) => {
        throw new Error(`There is more than 1 ${text} in your excel sheet: [${list.join(", ")}]`);
      };

      if (this.modelList.length > 1) throwError("model", this.modelList);
      if (this.countryList.length > 1) throwError("country", this.countryList);
      if (this.organizationList.length > 1) throwError("organization", this.organizationList);
    },
  },
};
</script>
