export const customer_admin_dashboard_details = /* GraphQL */ `
  query Customer_admin_dashboard_details(
    $input: CustomerAdminDashboardDetailsInput
  ) {
    customer_admin_dashboard_details(input: $input)
  }
`;

export const superadmin_dashboard_details = /* GraphQL */ `
  query Superadmin_dashboard_details {
    superadmin_dashboard_details
  }
`;