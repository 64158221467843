<template>
  <v-dialog v-model="tableHeaderFilterDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Customize Headers</span>
        <v-spacer></v-spacer>
        <v-icon @click="cancelFilters">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="header-list">
        <div class="header-checklist">
          <div class="header-list-item mt-1" v-for="(header, index) in availableHeaders.filter((header) => header.text !== 'Actions' && header.text !== 'Device Name')" :key="header.value">
            <input class="checkbox-size" v-model="selectedHeaders[header.value]" type="checkbox" :id="header.value" />
            <label :for="header.value" class="ml-2 text-body-2">{{ header.text }}</label>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed outlined class="mr-1 mb-2 blue--text" color="white" @click="cancelFilters">Cancel</v-btn>
        <v-btn class="mb-2 white--text" color="blue" @click="saveFilters">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tableHeaderFilterDialog: Boolean,
    headers: Array,
  },
  data() {
    return {
      superAdminHeaders: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text", value: "online_status" },
        { text: "Device Name", sortable: true, class: "headerColor white--text", value: "router_name" },
        { text: "Subscription", sortable: true, class: "headerColor white--text", value: "license_status" },
        { text: "Renewal Date", sortable: true, class: "headerColor white--text", value: "expire_date" },
        { text: "Config Updated", sortable: true, class: "headerColor white--text", value: "config_updated_on" },
        { text: "Last Reported", sortable: true, class: "headerColor white--text", value: "last_reported_on" },
        { text: "Model", align: "start", sortable: true, class: "headerColor white--text", value: "router_model" },
        { text: "Device SN", sortable: true, class: "headerColor white--text", value: "router_id" },
        { text: "Device Type", sortable: true, class: "headerColor white--text", value: "is_individual_router" },
        { text: "User Fullname", sortable: true, class: "headerColor white--text", value: "user_full_name" },
        { text: "Email", sortable: true, class: "headerColor white--text", value: "user_email_id" },
        { text: "Device Version", sortable: true, class: "headerColor white--text", value: "firmware_version" },
        { text: "Carrier", sortable: true, class: "headerColor white--text", value: "carrier_info" },
        { text: "Description", sortable: true, class: "headerColor white--text", value: "router_description" },
        { text: "Partner", sortable: true, class: "headerColor white--text", value: "partner_info" },
        { text: "Organization", sortable: true, class: "headerColor white--text", value: "organization_name" },
        { text: "Group", sortable: true, class: "headerColor white--text", value: "router_group" },
        { text: "Usage(GB)", sortable: true, class: "headerColor white--text", value: "data_usage" },
        { text: "Signal", sortable: true, class: "headerColor white--text", value: "signal" },
        { text: "IMEI", sortable: true, class: "headerColor white--text", value: "imei_number" },
        { text: "MSISDN", sortable: true, class: "headerColor white--text", value: "msisdn" },
        { text: "Actions", class: "headerColor white--text", readOnly: true, value: "Actions" },
      ],
      custAdminHeaders: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "online_status" },
        { text: "Device Name", sortable: true, class: "headerColor white--text ", value: "router_name" },
        // { text: "Subscription", sortable: true, class: "headerColor white--text ", value: "license_status" },
        // { text: "Renewal Date", sortable: true, class: "headerColor white--text ", value: "expire_date" },
        { text: "Config Updated", sortable: true, class: "headerColor white--text ", value: "config_updated_on" },
        { text: "Last Reported", sortable: true, class: "headerColor white--text ", value: "last_reported_on" },
        { text: "Model", align: "start", sortable: true, class: "headerColor white--text ", value: "router_model" },
        { text: "Device SN", sortable: true, class: "headerColor white--text ", value: "router_id" },
        { text: "Device Version", sortable: true, class: "headerColor white--text ", value: "firmware_version" },
				{ text: "Carrier", sortable: true, class: "headerColor white--text ", value: "carrier_info" },
				{ text: "Description", sortable: true, class: "headerColor white--text ", value: "router_description" },
        { text: "Group", sortable: true, class: "headerColor white--text ", value: "router_group" },
        { text: "Usage(GB)", sortable: true, class: "headerColor white--text ", value: "data_usage" },
        { text: "Signal", sortable: true, class: "headerColor white--text ", value: "signal" },
        { text: "IMEI", sortable: true, class: "headerColor white--text ", value: "imei_number" },
        { text: "MSISDN", sortable: true, class: "headerColor white--text ", value: "msisdn" },
				{ text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],
      availableHeaders: [],
      selectedHeaders: {},
    };
  },
  watch: {
    tableHeaderFilterDialog: {
      handler(newVal){
        if(newVal){
          this.customerAdminCheck ? this.availableHeaders = this.custAdminHeaders.map((header) => ({...header})) : this.availableHeaders = this.superAdminHeaders.map((header) => ({...header}));
          this.initializeSelectedHeaders();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getterGetCurrentUserRole"]),
    customerAdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN" || this.getterGetCurrentUserRole === "C_ADMIN_1";
    },
  },
  methods: {
    initializeSelectedHeaders() {
      this.selectedHeaders = this.availableHeaders.reduce((acc, header) => {
        acc[header.value] = this.headers.some((h) => h.value === header.value);
        return acc;
      }, {});
    },
    cancelFilters() {
      this.$emit("close", 0);
    },
    saveFilters() {
      const filteredHeaders = this.availableHeaders.filter((header) => this.selectedHeaders[header.value]);
      this.$emit("close", filteredHeaders);
    },
  },
};
</script>

<style scoped>
.header-checklist {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.checkbox-size {
  height: 16px;
  width: 16px;
}
.v-btn {
  text-transform: none;
}
</style>
