<template>
  <div>
    <!-- tabs to diaplay the valid and invalid data -->
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="validationTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Valid</v-tab>
          <v-tab-item>
            <v-data-table dense :headers="validHeaders" :items="validData" :items-per-page="20" class="elevation-1 dtwidth"></v-data-table>

            <div class="toggle-button-font">
              <div class="mt-4 mb-2">Select subscription option:</div>
              <v-btn-toggle mandatory v-model="subscriptionType" class="toggle-button">
                <v-btn :value="0">Create new subscription</v-btn>
                <v-btn :value="1">Update current subscription</v-btn>
              </v-btn-toggle>
            </div>
            <v-row v-if="subscriptionType === 1" no-gutters dense class="mt-6 align-center">
              <v-col cols="auto" class="mr-4">
                <span>Current Subscription:</span>
              </v-col>
              <v-col cols="3"> <v-select v-model="subscriptionId" :items="subscriptionData.subscription_data" dense outlined hide-details item-text="id" item-value="id"></v-select></v-col>
            </v-row>
            <div>
              <v-btn class="mt-4 ma-4" rounded color="primary" dark @click="add_data()" :loading="loadingBtn" :disabled="subscriptionType === 1 && subscriptionId === null"> Add </v-btn>
            </div>
          </v-tab-item>
          <v-tab> InValid </v-tab>
          <v-tab-item>
            <v-data-table dense :headers="validHeaders" :items="invalidData" hide-default-footer class="elevation-0 dtwidth">
              <template v-slot:[`item.RouterAdminPassword`]="{ item }">
                <v-tooltip v-if="item.RouterAdminPassword.length > 16" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="invalid-field">{{ item.RouterAdminPassword }}</span>
                  </template>
                  <span>Route Admin Password must be less than 16 characters</span>
                </v-tooltip>
                <span v-else class="invalid-field">{{ item.RouterAdminPassword }}</span>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { upload_routers_configuration_excel, replace_bulk_upload_tracker_data } from "@/graphql/mutations.js";
import { update_stripe_subscription } from "@/graphql/mutations/stripeMutations.js";
import { get_bulk_upload_tracker } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

var AWS = require("aws-sdk");
export default {
  props: ["headerExcelUpoader", "resultsExcelUpoader", "tabledataDemo", "invalidData", "validData", "subscriptionData", "organizationList", "modelList", "countryList"],
  data() {
    return {
      validationTab: 0,
      tableData: [],
      inValidHeaders: [],
      loadingBtn: false,
      validInput: [],
      routerIdList: [],
      subscriptionId: null,
      subscriptionType: 0,
      validHeaders: [
        { text: "Model", value: "Model", class: "headerColor white--text", sortable: true },
        { text: "FID", value: "FID", class: "headerColor white--text", sortable: true },
        { text: "PrimaryMAC", value: "PrimaryMAC", class: "headerColor white--text", sortable: true },
        { text: "IMEI", value: "IMEI", class: "headerColor white--text", sortable: true },
        { text: "PhoneNumber", value: "PhoneNumber", class: "headerColor white--text", sortable: true },
        { text: "Carrier", value: "Carrier", class: "headerColor white--text", sortable: true },
        { text: "ProductName", value: "ProductName", class: "headerColor white--text", sortable: true },
        { text: "ProductDescription", value: "ProductDescription", class: "headerColor white--text", sortable: true },
        { text: "Organization", value: "Organization", class: "headerColor white--text", sortable: true },
        { text: "OrganizationEmail", value: "OrganizationEmail", class: "headerColor white--text", sortable: true },
        { text: "Group", value: "Group", class: "headerColor white--text", sortable: true },
        { text: "Partner", value: "Partner", class: "headerColor white--text", sortable: true },
        { text: "LicenseType", value: "LicenseType", class: "headerColor white--text", sortable: true },
        { text: "LicenseName", value: "LicenseName", class: "headerColor white--text", sortable: true },
        { text: "LicenseCountry", value: "LicenseCountry", class: "headerColor white--text", sortable: true },
        { text: "APNProfileName", value: "APNProfileName", class: "headerColor white--text", sortable: true },
        { text: "APNName", value: "APNName", class: "headerColor white--text", sortable: true },
        { text: "APNPDPType", value: "APNPDPType", class: "headerColor white--text", sortable: true },
        { text: "MainWiFiSSID", value: "MainWiFiSSID", class: "headerColor white--text", sortable: true },
        { text: "MainWiFiPassword", value: "MainWiFiPassword", class: "headerColor white--text", sortable: true },
        { text: "GuestWiFiSSID", value: "GuestWiFiSSID", class: "headerColor white--text", sortable: true },
        { text: "GuestWiFiPassword", value: "GuestWiFiPassword", class: "headerColor white--text", sortable: true },
        { text: "TotalConnections", value: "TotalConnections", class: "headerColor white--text", sortable: true },
        { text: "MainWiFiMaximumConnections", value: "MainWiFiMaximumConnections", class: "headerColor white--text", sortable: true },
        { text: "Guest3WiFiMaximumConnections", value: "Guest3WiFiMaximumConnections", class: "headerColor white--text", sortable: true },
        { text: "UsageCycle", value: "UsageCycle", class: "headerColor white--text", sortable: true },
        { text: "CycleStartDayofMonth", value: "CycleStartDayofMonth", class: "headerColor white--text", sortable: true },
        { text: "CycleStartDayofYear", value: "CycleStartDayofYear", class: "headerColor white--text", sortable: true },
        { text: "MonthlyLimit", value: "MonthlyLimit", class: "headerColor white--text", sortable: true },
        { text: "ConnectionType", value: "ConnectionType", class: "headerColor white--text", sortable: true },
        { text: "AutomaticDNSEnable", value: "AutomaticDNSEnable", class: "headerColor white--text", sortable: true },
        { text: "PrimaryDNSAddress", value: "PrimaryDNSAddress", class: "headerColor white--text", sortable: true },
        { text: "SecondaryDNSAddress", value: "SecondaryDNSAddress", class: "headerColor white--text", sortable: true },
        { text: "DisplayWiFiNamePassword", value: "DisplayWiFiNamePassword", class: "headerColor white--text", sortable: true },
        { text: "DisplayTimeout", value: "DisplayTimeout", class: "headerColor white--text", sortable: true },
        { text: "FactoryResetButtonEnable", value: "FactoryResetButtonEnable", class: "headerColor white--text", sortable: true },
        { text: "PowerBankEnable", value: "PowerBankEnable", class: "headerColor white--text", sortable: true },
        { text: "StopChargingBatteryLevel", value: "StopChargingBatteryLevel", class: "headerColor white--text", sortable: true },
        { text: "WebAdminPageInfoDisplay", value: "WebAdminPageInfoDisplay", class: "headerColor white--text", sortable: true },
        { text: "AccessWebInterface", value: "AccessWebInterface", class: "headerColor white--text", sortable: true },
        { text: "ShowUsage", value: "ShowUsage", class: "headerColor white--text", sortable: true },
        { text: "RouterAdminPassword", value: "RouterAdminPassword", class: "headerColor white--text", sortable: true },
        { text: "WebUIAccess", value: "WebUIAccess", class: "headerColor white--text", sortable: true },
        { text: "BrowsingHistoryReportInterval", value: "BrowsingHistoryReportInterval", class: "headerColor white--text", sortable: true },
        { text: "ConnectedStatusReportInterval", value: "ConnectedStatusReportInterval", class: "headerColor white--text", sortable: true },
        { text: "WiFiMeshOperation", value: "WiFiMeshOperation", class: "headerColor white--text", sortable: true },
        { text: "AuthenticationMethod", value: "AuthenticationMethod", class: "headerColor white--text", sortable: true },
        { text: "EncryptionMethod", value: "EncryptionMethod", class: "headerColor white--text", sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on add event
    async add_data() {
      this.loadingBtn = true;
      this.routerIdList = [];
      this.fetch_data();
      if (this.invalidData.length > 0) {
        this.loadingBtn = false;
        this.triggerSnackBar("error", "There are some invalid items, clear up the errors. Columns that have errors will have red text.");
        return;
      }
      var s3Bucket = new AWS.S3({
        region: "us-east-1",
        accessKeyId: this.getterGetUserInfo.s3details.accessKey,
        secretAccessKey: this.getterGetUserInfo.s3details.secretAccessKey,
      });
      var subKey = this.getterGetUserInfo.user.customer_id + Date.now() + ".Json";
      var params = {
        Bucket: this.getterGetUserInfo.s3details.bucket,
        Key: subKey,
        ACL: "public-read",
        Body: JSON.stringify(this.validInput),
      };
      //Create array of routers in excel sheet
      this.validInput.forEach((input) => {
        this.routerIdList.push(`${input.Model}/${input.IMEI}`);
      });
      await s3Bucket.putObject(params, async (err, data) => {
        if (err) {
          this.loadingBtn = false;
        } else if (data) {
          //Upload all router ids to dynamodb table mdm_bulk_upload_tracker
          try {
            //Replace data in dynamoDB to hold most recently updated router IDs
            // await API.graphql(
            //   graphqlOperation(replace_bulk_upload_tracker_data, {
            //     input: {
            //       router_id_list: this.routerIdList,
            //     },
            //   })
            // );

            //If there is no subscription, create one for the user
            // if (this.subscriptionType === 0) {
            //   const subscription = await API.graphql(
            //     graphqlOperation(create_stripe_subscription, {
            //       input: {
            //         customer_id: this.subscriptionData.customer_id,
            //         license_country: this.countryList[0],
            //         acc_type: "business",
            //         model: this.modelList[0],
            //         organization: this.organizationList[0],
            //         quantity: 0,
            //       },
            //     })
            //   );
            //   console.log(subscription);
            //   this.subscriptionId = JSON.parse(subscription.data.create_stripe_subscription).id;
            //   console.log(this.subscriptionId);
            // }
            this.upload_excel(subKey);
          } catch (error) {
            console.log(error);
            this.loadingBtn = false;
          }
        }
      });
    },

    //mutation call to upload  file to s3
    async upload_excel(key) {
      try {
        let result = await API.graphql(
          graphqlOperation(upload_routers_configuration_excel, {
            input: {
              s3_key: key,
            },
          })
        );
        if (this.getterGetUserInfo.user.user_role == "SU_ADMIN") {
          this.$router.push("/Home/SuperBusinessDeviceList");
        }
        // this.$nextTick(() => {
        //   this.isUploadDone();
        // });
      } catch (error) {
        console.log(error);
      }
    },

    //Method to check if bulkupload tracker is complete
    async isUploadDone() {
      let isUploadPending;
      do {
        const tableData = await API.graphql(graphqlOperation(get_bulk_upload_tracker));
        const uploadStatus = JSON.parse(tableData.data.get_bulk_upload_tracker);
        isUploadPending = uploadStatus.some((router) => router.upload_status === "Pending");
        if (isUploadPending) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      } while (isUploadPending);
      console.log(this.routerIdList);
      console.log(this.subscriptionId);
      const result = await API.graphql(
        graphqlOperation(update_stripe_subscription, {
          input: {
            router_id_list: this.routerIdList,
            subscription_id: this.subscriptionId,
          },
        })
      );
      console.log(result);
    },
    //method invokes on bulkupload data to format the json
    fetch_data() {
      var tempArray = [];
      this.validInput = [];
      this.validData.forEach((element) => {
        tempArray.push({
          Model: element.Model,
          FID: element.FID != undefined ? element.FID : null,
          PrimaryMAC: element.PrimaryMAC != undefined ? element.PrimaryMAC : null,
          IMEI: element.IMEI != undefined ? element.IMEI.toString() : null,
          Carrier: element.Carrier != undefined ? element.Carrier : null,
          Group: element.Group != undefined ? element.Group : null,
          ProductName: element.ProductName != undefined ? element.ProductName : null,
          ProductDescription: element.ProductDescription != undefined ? element.ProductDescription : null,
          PhoneNumber: element.PhoneNumber != undefined ? element.PhoneNumber.toString() : null,
          Organization: element.Organization != undefined ? element.Organization : null,
          OrganizationEmail: element.OrganizationEmail != undefined ? element.OrganizationEmail : null,
          LicenseName: element.LicenseName != undefined ? element.LicenseName : null,
          LicenseType: element.LicenseType != undefined ? element.LicenseType : null,
          Partner: element.Partner != undefined ? element.Partner : null,
          MainWiFiSSID: element.MainWiFiSSID != undefined ? element.MainWiFiSSID : null,
          MainWiFiPassword: element.MainWiFiPassword != undefined ? element.MainWiFiPassword : null,
          GuestWiFiSSID: element.GuestWiFiSSID != undefined ? element.GuestWiFiSSID : null,
          GuestWiFiPassword: element.GuestWiFiPassword != undefined ? element.GuestWiFiPassword : null,
          LicenseCountry: element.LicenseCountry != undefined ? element.LicenseCountry : "USA",
          APNProfileName: element.APNProfileName != undefined ? element.APNProfileName : null,
          APNName: element.APNName != undefined ? element.APNName : null,
          APNPDPType: element.APNPDPType != undefined ? parseInt(element.APNPDPType) : null,
          TotalConnections: element.TotalConnections != undefined ? parseInt(element.TotalConnections) : null,
          MainWiFiMaximumConnections: element.MainWiFiMaximumConnections != undefined ? parseInt(element.MainWiFiMaximumConnections) : null,
          GuestWiFiMaximumConnections: element.Guest3WiFiMaximumConnections != undefined ? parseInt(element.Guest3WiFiMaximumConnections) : null,
          UsageCycle: element.UsageCycle != undefined ? parseInt(element.UsageCycle) : 0,
          CycleStartDayofMonth: element.CycleStartDayofMonth != undefined ? parseInt(element.CycleStartDayofMonth) : null,
          CycleStartDayofYear: element.CycleStartDayofYear != undefined ? parseInt(element.CycleStartDayofYear) : null,
          MonthlyLimit: element.MonthlyLimit != undefined ? parseInt(element.MonthlyLimit) : 0,
          ConnectionType: element.ConnectionType != undefined ? parseInt(element.ConnectionType) : 0,
          AutomaticDNSEnable: element.AutomaticDNSEnable != undefined ? parseInt(element.AutomaticDNSEnable) : 1,
          PrimaryDNSAddress: element.PrimaryDNSAddress != undefined ? element.PrimaryDNSAddress : null,
          SecondaryDNSAddress: element.SecondaryDNSAddress != undefined ? element.SecondaryDNSAddress : null,
          DisplayWiFiNamePassword: element.DisplayWiFiNamePassword != undefined ? parseInt(element.DisplayWiFiNamePassword) : 1,
          DisplayTimeout: element.DisplayTimeout != undefined ? parseInt(element.DisplayTimeout) : 1,
          FactoryResetButtonEnable: element.FactoryResetButtonEnable != undefined ? parseInt(element.FactoryResetButtonEnable) : 1,
          RemoteManagementEnable: element.RemoteManagementEnable != undefined ? parseInt(element.RemoteManagementEnable) : 1,
          PowerBankEnable: element.PowerBankEnable != undefined ? parseInt(element.PowerBankEnable) : 1,
          StopChargingBatteryLevel: element.StopChargingBatteryLevel != undefined ? parseInt(element.StopChargingBatteryLevel) : 1,
          WebAdminPageInfoDisplay: element.WebAdminPageInfoDisplay != undefined ? parseInt(element.WebAdminPageInfoDisplay) : 1,
          AccessWebInterface: element.AccessWebInterface != undefined ? parseInt(element.AccessWebInterface) : 1,
          ShowUsage: element.ShowUsage != undefined ? parseInt(element.ShowUsage) : 0,
          RouterAdminPassword: element.RouterAdminPassword != undefined ? element.RouterAdminPassword : null,
          WiFiMeshOperation: element.WiFiMeshOperation != undefined ? element.WiFiMeshOperation : "Controller",
          WebUIAccess: element.WebUIAccess != undefined ? parseInt(element.WebUIAccess) : 1,
          BrowsingHistoryReportInterval: element.BrowsingHistoryReportInterval != undefined ? parseInt(element.BrowsingHistoryReportInterval) : 1,
          ConnectedStatusReportInterval: element.ConnectedStatusReportInterval != undefined ? parseInt(element.ConnectedStatusReportInterval) : 1,
          AuthenticationMethod: element.AuthenticationMethod != undefined ? element.AuthenticationMethod : null,
          EncryptionMethod: element.EncryptionMethod != undefined ? element.EncryptionMethod : null,
        });
      });
      this.validInput = tempArray;
    },
  },
};
</script>

<style scoped>
.invalid-field {
  color: #e53935;
}
</style>

<style scoped>
.invalid-field {
  color: #e53935;
}
</style>
