<template>
  <v-dialog overflow-hidden v-model="deleteDialog" persistent max-width="400px">
    <DialogCard :modalIcon="'file-undo'" :modalTitle="'Discard all configuration changes'">
      <template #header>
        <v-btn icon x-small @click="close_delete_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <p>Are you sure you want to delete this item?</p>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_delete_dialog()">Cancel</v-btn>
        <v-btn small @click="confirm_delete_dialog()" class="button tabledata--text mr-2">Delete</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
export default {
  components: {
    DialogCard,
  },
  props: {
    deleteDialog: Boolean,
    index: Number
  },
  methods: {
    close_delete_dialog() {
      this.$emit("close", { mode: 0 });
    },
    confirm_delete_dialog(){
      this.$emit("close", { mode: 1, index: this.index })
    }
  },
};
</script>
