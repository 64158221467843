<template>
  <div>
    <!-- popup for adding devices for individual users  -->
    <v-dialog overflow-hidden persistent v-model="addRouterDialog" max-width="500px">
      <v-card class="cardBg">
        <v-card-title dense class="medium cardTitle">
          <span>Add Device</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close_add_device_dialog()">
            <v-icon color="iconCross">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="cardBg mt-4">
          <v-row no-gutters v-if="formPage == 0" class="mt-2">
            <v-col cols="12">
              <!-- Gene 4/4/23 Added model RG2102 to dropdown -->
              <v-select label="Select Model" v-model="modelSelect" :items="['RG2100', 'RG2102']" outlined required dense></v-select>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="formPage == 0">
            <v-col cols="3">
              <v-text-field v-if="modelSelect == 'FX20'" label="Model" class="remove-border" dense v-model="modelSelect" outlined readonly></v-text-field>

              <v-text-field v-else-if="modelSelect == 'RG2102'" label="Model" class="remove-border" dense v-model="modelRG2102" outlined readonly></v-text-field>

              <v-text-field v-else label="Model" class="remove-border" dense v-model="modelRG2100" outlined readonly></v-text-field>
            </v-col>
            <v-col cols="3">
              <!-- Gene 4/4/23 FX20 second step dropdown -->
              <v-select v-if="modelSelect == 'FX20'" class="ml-4" v-model="fxStep2" :items="['M52', 'M54']" outlined required dense></v-select>
              <v-text-field v-else-if="modelSelect == 'RG2102'" dense v-model="rg2102Step2" class="ml-4 remove-border" outlined readonly></v-text-field>

              <v-text-field v-else dense class="ml-4 remove-border" v-model="rg2100Step2" outlined readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-if="modelSelect == 'FX20'" label="Device Serial Number*" dense v-model="serialFX" @keyup.enter="validation_data()" outlined class="ml-4" maxlength="7"></v-text-field>
              <v-text-field v-else label="Device Serial Number*" dense @keyup.enter="validation_data()" v-model="serialRG" outlined maxlength="7" class="ml-4"></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions v-if="formPage == 0" class="justify-bottom mt-4">
            <v-spacer></v-spacer>
            <v-btn :loading="verifyLoading" @click="validation_data()" class="button tabledata--text ml-4 justify-end">Verify</v-btn>
          </v-card-actions>

          <v-row no-gutters v-if="formPage == 1 && modelSelect == 'FX20'">
            <v-col cols="6">
              <v-text-field label="Device Name" dense v-model="deviceName" :rules="[(val) => val.length <= 15 || 'Max Length(15)']" maxlength="15" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Device Description" dense class="ml-2" :rules="[(val) => val.length <= 50 || 'Max Length(50)']" maxlength="50" v-model="description" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select label="License Region" v-model="licenseRegion" :items="['Korea', 'USA']" outlined required dense></v-select>
            </v-col>
            <v-col cols="6">
              <v-select class="ml-2" label="Partner Name" v-model="partnerName" item-text="label" item-value="value" :items="partnerItems" outlined required dense></v-select>
            </v-col>
          </v-row>
          <v-card-actions v-if="formPage == 1 && modelSelect == 'FX20'" class="justify-bottom mt-4">
            <v-btn class="button tabledata--text ml-n2" @click="formPage = 0">Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="validate_upload_data()" class="button tabledata--text mr-n2">Add</v-btn>
          </v-card-actions>
          <v-row no-gutters v-if="formPage == 1 && modelSelect.substring(0, 4) == 'RG21'">
            <v-col cols="6">
              <v-text-field label="Device Name" dense v-model="deviceName" :rules="[(val) => val.length <= 15 || 'Max Length(15)']" maxlength="15" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Device Description" dense class="ml-2" :rules="[(val) => val.length <= 50 || 'Max Length(50)']" maxlength="50" v-model="description" outlined></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select label="License Region" v-model="licenseRegion" :items="['Korea', 'USA']" outlined required dense></v-select>
            </v-col>
            <v-col cols="6">
              <v-select class="ml-2" label="Partner Name" v-model="partnerName" item-text="label" item-value="value" :items="partnerItems" outlined required dense></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Carrier" v-model="carrierInfo" item-text="label" item-value="value" :items="carrierItems" outlined required dense></v-select>
            </v-col>
          </v-row>
          <v-card-actions class="justify-bottom" v-if="formPage == 1 && modelSelect.substring(0, 4) == 'RG21'">
            <v-btn class="button tabledata--text ml-n2" @click="formPage = 0">Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="validate_upload_data()" class="button tabledata--text mr-n2">Add</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent overflow-hidden max-width="420px">
      <v-card class="dialogCard cardBg overflow-hidden">
        <v-card-title class="ml-4 mt-4 mb-n4">Restart {{ modelSelect }}</v-card-title>
        <v-card-text class="cardBg pt-6 justify-center">
          <div v-if="modelSelect.substring(0, 4) == 'RG21'">
            <b> 1.Power down device.</b>
            <br /><b> 2.Power on device.</b>
            <v-img height="300px" width="300px" src="@/assets/RG2Powercycle.png"> </v-img>
          </div>
          <div v-if="modelSelect == 'FX20'">
            <v-row>
              <v-col cols="6">
                <v-img class="ml-n4" height="300px" width="300px" src="@/assets/FX20InternetConnection.png"> </v-img>
                Make sure your device has an internet connection.
                <br />Recommend restarting your modem.
              </v-col>
              <v-col cols="6">
                <v-img class="ml-n4" height="300px" width="300px" src="@/assets/FX20-Powercycle.png"> </v-img>
                1.Power down device.
                <br />
                2.Power on device.
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>

          <v-btn class="button tabledata--text mr-2" :loading="verifyLoading" @click="confirm_add_device()">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { get_router_check_exists_or_not, get_all_partners } from "@/graphql/queries.js";
import { individal_bulk_upload_router } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { mapMutations } from "vuex";

export default {
  props: {
    addRouterDialog: Boolean,
    carrierItems: Array,
  },
  data() {
    return {
      formPage: 0,

      serialFX: "",
      serialRG: "",
      fxStep2: "M52",
      rg2100Step2: "35856379",
      rg2102Step2: "35871837",
      modelSelect: "RG2100",
      modelFx20: "FX20",
      modelRG2100: "RG2100",
      modelRG2102: "RG2102",
      partnerName: "c67eae2d-c536-4d57-9709-8bb3b77cafbd",
      licenseRegion: "USA",
      description: "",
      deviceName: "",
      verifyLoading: false,

      checkRouter: {},
      
      carrierInfo: "TMOBILE",
      confirmDialog: false,
      partnerList: [],
      partnerItems: [],
      validIMEI: false,
    };
  },

  watch: {
    addRouterDialog: {
      handler(newVal){
        // reinitialize the form when it is closed
        if(!newVal){
          this.formPage = 0;
          this.modelSelect = "RG2100";
          this.serialFX = "";
          this.serialRG = "";
          this.deviceName = "";
          this.description = "";
          this.licenseRegion = "USA";
          this.partnerName = "c67eae2d-c536-4d57-9709-8bb3b77cafbd";
          this.carrierInfo = "TMOBILE";
        }
      },
      immediate: true
    },
    serialRG() {
      if (this.modelSelect == "RG2100") {
        this.validIMEI = this.isValidIMEI(this.rg2100Step2 + this.serialRG);
      } else if (this.modelSelect == "RG2102") {
        this.validIMEI = this.isValidIMEI(this.rg2102Step2 + this.serialRG);
      }
    },
  },

  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close action
    close_add_device_dialog() {
      this.$emit("close");
    },

    //method invokes on confirm action
    device_img_close() {
      this.confirmDialog = false;
    },

    //method to validate the data
    validation_data() {
      var valid = true;
      if ((this.modelSelect == "FX20" && this.serialFX == "") || (this.modelSelect.substring(0, 4) == "RG21" && this.serialRG == "")) {
        valid = false;
        this.triggerSnackBar("error", "Please enter a serial number");
      } else if ((this.modelSelect == "FX20" && this.serialFX.length != 7) || (this.modelSelect.substring(0, 4) == "RG21" && this.serialRG.length != 7)) {
        valid = false;
        this.triggerSnackBar("error", "Serial number must be 7 digits long");
      } else if ((this.modelSelect == "RG2100" && !this.isValidIMEI(this.rg2100Step2 + this.serialRG)) || (this.modelSelect == "RG2102" && !this.isValidIMEI(this.rg2102Step2 + this.serialRG))) {
        valid = false;
        this.triggerSnackBar("error", "Serial number number is invalid");
      } else {
        if (valid) {
          this.verify_device();
        };
      }
    },

    //mutation call for checking the device exits or not
    async verify_device() {
      this.verifyLoading = true;
      if (this.modelSelect == "FX20") {
        var data = {
          router_id: "FX20" + this.fxStep2 + this.serialFX,
        };
      } else if (this.modelSelect == "RG2100") {
        var data = {
          router_id: `${this.modelSelect}/${this.rg2100Step2 + this.serialRG}`,
        };
      } else if (this.modelSelect == "RG2102") {
        var data = {
          router_id: `${this.modelSelect}/${this.rg2102Step2 + this.serialRG}`,
        };
      }
      try {
        let result = await API.graphql(
          graphqlOperation(get_router_check_exists_or_not, {
            input: data,
          })
        );
        console.log(result);

        this.checkRouter = JSON.parse(result.data.get_router_check_exists_or_not).router;
        this.triggerSnackBar("success", "Verified Successfully!");
        this.verifyLoading = false;
        this.formPage = 1;
        this.get_partners();
      } catch (err) {
        this.verifyLoading = false;

        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },

    //method to validate the empty fields
    validate_upload_data() {
      if ( this.deviceName == "" || this.description == "" || this.licenseRegion == "") {
        return this.triggerSnackBar("error", "Please Provide Mandatory Details");
      }
      this.confirmDialog = true;
      // this.upload_data();
    },

    //mutation call for adding the new devices
    async confirm_add_device() {
      this.verifyLoading = true;
      let data;
      if (this.modelSelect == "FX20") {
        data = {
          router_id: this.checkRouter.router_id.split('/').join(''),
          user_id: this.$store.getters.GetCurrentObj.user.user_id,
          device_name: this.deviceName,
          router_description: this.description,
          partner_id: this.partnerName,
          licnese_country: this.licenseRegion,
        };
      } else {
        data = {
          router_id: this.checkRouter.router_id.split('/').join(''),
          user_id: this.$store.getters.GetCurrentObj.user.user_id,
          device_name: this.deviceName,
          router_description: this.description,
          partner_id: this.partnerName,
          licnese_country: this.licenseRegion,
          carrier_info: this.carrierInfo,
          imei_number: 35856379 + this.serialRG,
        };
      }
      console.log(data);
      try {
        let result = await API.graphql(
          graphqlOperation(individal_bulk_upload_router, {
            input: data,
          })
        );
        this.triggerSnackBar("success", "Added Succcesfully");
        this.close_add_device_dialog();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.verifyLoading = false;
        this.confirmDialog = false;
      }
    },

    //query to fetch partners list
    async get_partners() {
      try {
        let result = await API.graphql(
          graphqlOperation(get_all_partners, {
            input: {},
          })
        );
        this.partnerList = result.data.get_all_partners;
        var self = this;
        this.partnerList.forEach((element) => {
          self.partnerItems.push({
            label: element.user_full_name,
            value: element.user_id,
          });
        });
      } catch (error) {}
    },

    sumDig(n) {
      let a = 0;
      while (n > 0) {
        a = a + (n % 10);
        n = parseInt(n / 10, 10);
      }
      return a;
    },

    isValidIMEI(n) {
      // Converting the number into
      // String for finding length
      let s = n.toString();
      let len = s.length;

      if (len != 15) return false;

      let sum = 0;
      for (let i = len; i >= 1; i--) {
        let d = n % 10;

        // Doubling every alternate digit
        if (i % 2 == 0) d = 2 * d;

        // Finding sum of the digits
        sum += this.sumDig(d);
        n = parseInt(n / 10, 10);
      }

      return sum % 10 == 0;
    },
  },
};
</script>

<style scoped>
.v-input.remove-border >>> fieldset {
  border: none;
  border-width: 0px;
  background-color: #ededed !important;
}
</style>
