import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"overflow-hidden":"","persistent":"","max-width":"600px"},model:{value:(_vm.serviceDialog),callback:function ($$v) {_vm.serviceDialog=$$v},expression:"serviceDialog"}},[_c(VCard,{staticClass:"cardBg"},[_c(VToolbar,{staticClass:"dialogToolbar",attrs:{"dense":""}},[_c('span',[_vm._v("Add > Service Control ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close_dialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VToolbar,{staticClass:"cardBg mt-6",attrs:{"flat":"","dense":""}},[_c(VTextField,{staticClass:"ml-1",attrs:{"dense":"","label":"Url*","outlined":""},model:{value:(_vm.urlName),callback:function ($$v) {_vm.urlName=$$v},expression:"urlName"}}),_c(VCheckbox,{staticClass:"mt-n6 ml-1",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Enable")])]},proxy:true}]),model:{value:(_vm.deviceEnable),callback:function ($$v) {_vm.deviceEnable=$$v},expression:"deviceEnable"}}),_c(VSpacer),_c(VBtn,{staticClass:"mt-n3 button tabledata--text",attrs:{"small":""},on:{"click":function($event){return _vm.validate_add()}}},[_vm._v("Add")])],1),_c(VCard,{staticClass:"configurationCard mx-2",attrs:{"flat":""}},[_c(VDataTable,{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.tableData,"items-per-page":5,"hide-default-footer":""}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text",on:{"click":function($event){return _vm.save_changes()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }