<template>
  <div>
    <v-dialog
      v-model="customerPortalDialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <div id="portal"></div>

      <DialogCard :modalTitle="'Customer Portal'" :modalIcon="'credit-card'">
        <template #header>
          <v-btn icon x-small @click="close_dialog()">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
        <template #body>
          <iframe
            v-if="sessionCallDone"
            id="portal"
            width="100%"
            height="100%"
            :src="sessionUrl"
          ></iframe>
        </template>
      </DialogCard>
    </v-dialog>
  </div>
</template>
  <script>
import DialogCard from "../Slots/DialogCard.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    customerPortalDialog: Boolean,
  },
  components: {
    DialogCard,
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
    isCheckoutActive() {
      return !!this.checkout;
    },
  },

  data() {
    return {
      prices: [],
      loading: true,
      product: {},
      checkout: null,
      sessionUrl: null,
      sessionCallDone: false,
    };
  },
  watch: {
    async customerPortalDialog(newVal) {
      if (newVal) {
        const email = this.getterGetUserInfo.user.user_email_id;
        const response = await fetch("/api/create-customer-portal-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        const session = await response.json();
        this.sessionUrl = session.session.url;
        this.sessionCallDone = true;
      }
    },
  },
  methods: {
    close_dialog() {
      // if (this.checkout) {
      //   this.checkout.destroy();
      //   this.checkout = null;
      // }
      this.$emit("close");
    },

    async initializeCheckout(priceId, mode) {
      try {
        const stripe = Stripe(
          "pk_test_51Kz1tcGfVRJmBJNYYdbpcqJQ4EyX8O6frmwVnqI0U9qTCl8bgqWB0Wu4RvLetIQ3Hh1fb4VeHE6SwNVvhIfBATMK00hUqKy5OX"
        );
        const type =
          mode === "one_time"
            ? "payment"
            : "recurring"
            ? "subscription"
            : "setup";
        const sessionData = await this.fetchClientSecret(priceId, type);
        const checkout = await stripe.initEmbeddedCheckout({
          clientSecret: sessionData.clientSecret,
        });
        this.checkout = checkout;
        // Mount Checkout
        checkout.mount("#checkout");
      } catch (error) {
        console.error("Error initializing checkout:", error);
        throw error; // Propagate the error to the caller
      }
    },
  },
};
</script>
    