<template>
  <v-form class="mb-4">
    <div class="mb-2">{{ label }}</div>
    <div class="time-picker-container">
      <v-select outlined dense hide-details label="Hour" :items="hoursList" v-model="hours" @input="setTime()"></v-select>
      <v-select outlined dense hide-details label="Minutes" :items="minutesList" v-model="minutes" @input="setTime()"></v-select>
      <v-select outlined dense hide-details label="period" :items="periodList" v-model="period" @input="setTime()"></v-select>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    // The v-model value is now expected to be a string
    value: {
      type: String,
      default: "",
    },
    isEndTime: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  watch: {
    value: {
      handler(newVal){
        this.convertTo12HourFormat(newVal.split(':').join(''));
      },
      immediate: true
    }
  },
  data() {
    return {
      hoursList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      minutesList: [
        "00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
        "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
        "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
        "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
        "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
        "50", "51", "52", "53", "54", "55", "56", "57", "58", "59",
      ],
      periodList: ["AM", "PM"],
      hours: "",
      minutes: "",
      period: ""
    };
  },
  methods: {
    convertTo12HourFormat(val) {
      let date = new Date();
      date.setHours(parseInt(val.substring(0, 2)), parseInt(val.substring(2, 4)));

      this.hours = ('0' + (date.getHours() % 12 || 12).toString()).slice(-2); // Converts 0 to 12 for 12 AM and keeps 1-11 as is for PM
      this.minutes = ('0' + date.getMinutes().toString()).slice(-2);
      this.period = date.getHours() >= 12 ? 'PM' : 'AM';
    },
    convertTo24HourFormat() {
      let hours = parseInt(this.hours);
      let isPM = this.period === 'PM';

      // Convert 12 AM and 12 PM correctly
      if (hours === 12) {
        hours = isPM ? 12 : (this.isEndTime && this.minutes === "00" ? 24 : 0); // 12 PM -> 12, 12 AM -> 0 or 24
      } else if (isPM) {
        hours += 12; // Convert PM hours to 24-hour format
      }

      return hours.toString().padStart(2, '0') + ":" + this.minutes;
    },
    setTime() {
      let convertedHour = this.convertTo24HourFormat();
      // Emit the updated value as a string
      this.$emit("input", convertedHour);
    },
  },
};
</script>

<style scoped>
  .time-picker-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
  }

  .time-picker-container > * {
    max-width: 6rem;
  }
</style>