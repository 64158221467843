<template>
 
  <v-tabs v-model="navTabs" @change="reset_data()" dense right dark>
    <v-tabs-slider class="button"></v-tabs-slider>

    <!-- Dashboard Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'Dashboard' })">Dashboard</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in dashboardMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Devices Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'CustomerDeviceList' })">Devices</v-tab>
      </template>
      <v-list @change="reset_data()" style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in manageMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Event Logs Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'AlertLogs' })">Event logs</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in eventLogsMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Accounts Menu -->
    <v-menu open-on-hover bottom offset-y nudge-top="2">
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-bind="attrs" v-on="on" class="text-capitalize" @click="pushRoute({ name: 'DeviceGroupsList' })">Accounts</v-tab>
      </template>
      <v-list style="background-color: #d9d9d9" dense>
        <v-list-item v-for="menu in accountsMenu" :key="menu.title" @click="pushRoute(menu.name)">
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    
  </v-tabs>
</template>


<script>
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
export default {
  mixins: [ mixin_routing_utils ],
  data() {
    return {
      navTabs: 0,
      dashboardMenu: [
        {title: "Dashboard", name: { name: "Dashboard" }}
      ],
      manageMenu: [
        { title: "Devices", name: { name: "CustomerDeviceList" }},
      ],
      eventLogsMenu: [
        { title: "Alert Logs", name: { name: "AlertLogs" } },
        { title: "Admin Logs", name: { name: "AdminLogs" } },
      ],
      accountsMenu: [
        { title: "Groups", name: { name: "DeviceGroupsList" } },
        { title: "Users", name: { name: "BusinessUsers" } },
      ]
    };
  },
  // The <v-tabs-slider> will move according to the "navTabs" value index
  // Watch for changes in routing. If the route changes, return the matching "navTabs" index value
  watch: {
    '$route'(to, from) {
      this.navTabs = this.positionMenuSlider(to.name);
    }
  },
  // Make sure that the slider is on the correct position in case the user refreshes the page or enters by link directly
  created () {
    this.navTabs = this.positionMenuSlider(this.$route.name);
  },
  methods: {
    pushRoute(menuRoute) {
      this.mixinChangeRoute(menuRoute);
    },
    positionMenuSlider(name){
      if(this.dashboardMenu.some(item => name === item.name.name)) return 0;
      if(this.manageMenu.some(item => name === item.name.name)) return 1;
      if(this.eventLogsMenu.some(item => name === item.name.name)) return 2;
      if(this.accountsMenu.some(item => name === item.name.name)) return 3;
    },
    //method invokes on tab change
    reset_data() {
      localStorage.setItem("online", false);
      localStorage.setItem("offline", false);
      localStorage.setItem("ExcelSucces", false);
      localStorage.setItem("model", false);
      localStorage.setItem("regDevices", false);
    }
  }
};
</script>