<template> 
  <v-dialog 
    v-model="deleteGroupDialog" 
    persistent 
    overflow-hidden
    max-width="500px"
    >
      <v-card class="cardBg">

        <v-toolbar dense class="medium cardTitle">
          <span>Delete Group </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="clear_data()">
            <v-icon color="iconCross">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="cardBg mt-6">
          <p class="add-device-header">Are you sure you want to delete these groups?</p>
          <v-row class="ml-0 mb-6">
            <b style="color: red;">*This action is permanent and cannot be undone</b>
          </v-row>
          <v-container class="border group-container">
            <v-row class="mb-n1 mt-n4">
              <v-col class="selected-item-column" cols="4">
                <b>Group Name</b>
              </v-col>
              <v-col class="selected-item-column text-center" cols="4">
                <b class="ml-n2">Model</b>
              </v-col>
              <v-col class="selected-item-column text-right" cols="4">
                <b># of Routers</b>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-0" v-for="group in selectedRecords" :key="group.router_group_id">
              <v-col class="selected-item-column" cols="4">
                {{ group.group_name }}
              </v-col>
              <v-col class="selected-item-column text-center" cols="4">
                {{ group.group_model }}
              </v-col>
              <v-col class="selected-item-column text-right" cols="4">
                {{ group.registered_routers }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            depressed
            class="blue--text mr-2"
            color="white"
            @click="clear_data()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="remove tabledata--text mr-2"
            color="red"
            @click="remove_data()"
            :loading="isSaving"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { deactivate_router_group } from "@/graphql/mutations.js";
import { un_map_router_from_router_group } from "@/graphql/mutations.js";

export default {
  props: {
    deleteGroupDialog: Boolean,
    selectedRecords: Array,
    tableDeviceData: Array,
  },

  data() {
    return {
      isSaving: false,
      currentPage: 1,
      itemsPerPage: 5,
    }
  },

  methods: {
    clear_data() {
      this.$emit('clicked', 0)
    },

    async remove_data() {
      this.isSaving = true;
      try {
        for (const group of this.selectedRecords) {
          if (group.registered_routers != 0) {
            await Promise.all(this.tableDeviceData.map(async (element) => {
              if (element.group_name == group.group_name) {
                await API.graphql(
                  graphqlOperation(un_map_router_from_router_group, {
                    input: {
                      router_id: element.router_id,
                    },
                  })
                );
              }
            }));
          }
          await API.graphql(
            graphqlOperation(deactivate_router_group, {
              input: {
                router_group_id: group.router_group_id,
              },
            })
          );
        }
        this.isSaving = false;
        this.$emit("clicked", 3);
      } catch (err) {
        console.log(err);
        this.$emit("errorMsg", err.errors[0].message);
      }
    },
  },
}
</script>

<style scoped>
.add-device-header {
  font-size: 16px;
}
.selected-item-column {
  padding-top: 8px;
  padding-bottom: 8px;
}

.border {
  border: 2px solid red;
}

.group-container {
  max-height: 184px;
  overflow-y: auto;
}
</style>