<template>
  <div>
    <v-dialog
      v-model="routersDialog"
      persistent
      overflow-hidden
      max-width="400px"
    >
      <v-card class="dialogCard cardBg overflow-hidden">
        <v-toolbar class="dialogToolbar cardBg" dense>
          <v-toolbar-title>Delete Router</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon @click="close_dialog()" class="ml-2" color="iconCross"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text class="cardBg pt-6 justify-center">
          Are you sure you want to delete all routers assigned to the user?
          <br />
          <div v-for="router in routerData" :key="router.id">
            - {{ router }}
          </div>
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn
            class="blue--text"
            outlined
            color="white"
            @click="close_dialog()"
            >Cancel</v-btn
          >
          <v-btn
            class="button tabledata--text mr-2"
            :loading="loadingDelete"
            @click="delete_router()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  delete_user_permanently,
  delete_router_details,
} from "@/graphql/mutations.js";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { mapActions } from 'vuex';

export default {
  props: {
    routerData: Array,
    userID: String,
    routersDialog: Boolean,
    userEmail: String,
  },
  data() {
    return {
      loadingDelete: false,
    };
  },
  methods: {
    ...mapActions(["actionDeleteCustomer"]),
    async delete_router() {
      this.loadingDelete = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_router_details, {
            input: {
              user_id: this.$store.getters.GetCurrentObj.user.user_id,
              router_id: this.routerData,
            },
          })
        );
        await this.delete_permanently();
      } catch (error) {
        console.log(error);
        this.loadingDelete = false;
      }
    },
    async delete_permanently() {
      try {
        let result = await API.graphql(
          graphqlOperation(delete_user_permanently, {
            input: {
              user_id: this.userID,
            },
          })
        );
        console.log(result);
        //Delete customer from stripe
        const email = this.userEmail;
        await this.actionDeleteCustomer(email);
        this.$emit("clicked", 7);
        this.loadingDelete = false;
      } catch (error) {
        this.loadingDelete = false;
        console.log(error);
        var text = error.errors[0].message;
        this.$emit("errorMsg", text);
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>