import { VForm } from 'vuetify/lib/components/VForm';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{staticClass:"pa-5"},[_c(VTabs,{attrs:{"align-with-title":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c(VTabsSlider),_vm._l((_vm.tabItems),function(item){return _c(VTab,{key:item.id},[_vm._v(_vm._s(item.text))])})],2),_c(VTabsItems,{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.tabItems),function(component){return _c(VTabItem,{key:component.id,attrs:{"eager":""}},[_c(component.name,{tag:"component",attrs:{"id":component.id},on:{"changeValidState":_vm.changeValidState}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }