import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.addConfigJson),callback:function ($$v) {_vm.addConfigJson=$$v},expression:"addConfigJson"}},[_c(VCard,{staticClass:"dialogCard cardBg overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar cardBg",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v("Upload Default Configuration")]),_c(VSpacer),_c(VIcon,{staticClass:"ml-2",attrs:{"color":"iconCross"},on:{"click":function($event){return _vm.close_dailog()}}},[_vm._v("mdi-close")])],1),_c(VCardText,[_c(VForm,{ref:"addUserForm",attrs:{"lazy-validation":""},model:{value:(_vm.addUserForm),callback:function ($$v) {_vm.addUserForm=$$v},expression:"addUserForm"}},[_c(VRow,{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c(VCol,[_c(VSelect,{attrs:{"dense":"","label":"Product SKU Model","outlined":"","item-text":"label","item-value":"value","items":_vm.productList},model:{value:(_vm.productSku),callback:function ($$v) {_vm.productSku=$$v},expression:"productSku"}})],1)],1),_c('div',[_vm._v("Please upload the default configuration of this product file.")]),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VFileInput,{attrs:{"accept":".json","label":"Choose Files"},on:{"change":function($event){return _vm.file_detect(_vm.files)}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1)],1)],1),_c(VCardActions,{staticClass:"button-corner"},[_c(VSpacer),_c(VBtn,{staticClass:"blue--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.close_dailog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.validate_data()}}},[_vm._v("Upload")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }