<template> 
  <v-dialog 
    v-model="unassignGroupDialog" 
    persistent 
    overflow-hidden
    max-width="500px"
    >
      <v-card class="cardBg">

        <v-toolbar dense class="medium cardTitle">
          <span>Remove Devices from Group </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="clear_data()">
            <v-icon color="iconCross">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="cardBg mt-6">
          <p class="add-device-header">Are you sure you want to remove these devices from their groups?</p>
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            depressed
            class="blue--text mr-2"
            color="white"
            @click="clear_data()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="button tabledata--text mr-2"
            @click="remove_data()"
            :loading="isSaving"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { un_map_router_from_router_group } from "@/graphql/mutations.js";

export default {
  props: {
    unassignGroupDialog: Boolean,
    selectedRecords: Array,
  },
  data() {
    return {
      isSaving: false,
    }
  },

  methods: {
    clear_data() {
      this.$emit('closeDialog', 0)
    },
    async remove_data() {
      this.isSaving = true;
      try {
        for (const device of this.selectedRecords) {
          if (device.group_name != "") {
            await API.graphql(
              graphqlOperation(un_map_router_from_router_group, {
                input: {
                  router_id: device.router_id,
                },
              })
            );
          }
        }
        this.isSaving = false;
        this.$emit("closeDialog", 1)
      } catch (err) {
        console.log(err);
        this.$emit("errorMsg", err.errors[0].message);
      }
    },
  },
}
</script>

<style>
.add-device-header {
  font-size: 16px;
}
</style>