<template>
  <CardGroupSlot v-if="filteredDiffs.length" :CardGroupTitle="'Main and Guest Wi-Fi'" :isPreview="isPreview">
    <template #body>
      <transition-group name="fade">
        <div class="history-list my-2" v-for="diff in filteredDiffs" :key="diff.path">
          <SingleCard type="single" :diff="diff" :propertyNameTable="mixinFieldNames" />
        </div>
      </transition-group>
    </template>
  </CardGroupSlot>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import CardGroupSlot from "../CardComponents/CardGroupSlot.vue";
import SingleCard from "../CardComponents/SingleCard.vue";

export default {
  mixins: [mixin_config_history_utils],
  props: {
    groupDiffs: Array,
    isPreview: Boolean
  },
  components: {
    SingleCard,
    CardGroupSlot,
  },
  data(){
    return {
      filteredDiffs: []
    }
  },
  // filter out "TotalConnections" since it is never used for RG---- models
  watch: {
    groupDiffs: {
      handler() {
        this.filteredDiffs = this.groupDiffs.filter((item) => item.path.split(".")[3] !== "TotalConnections");
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
