<template>
  <Teleport to="#app">
    <v-slide-x-transition>
      <div v-show="configChangeHistoryPanel" ref="historypanel" class="history-panel">
        <v-card color="#E7E9ED">
          <v-card-title class="history-title">
            <span class="font-weight-dark text-h6">Config History</span>
            <v-spacer></v-spacer>
            <v-btn icon small @hover.stop @click="close_config_change_history_panel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
    
          <v-card-text>
            <div class="history-content-wrapper">
              <v-fade-transition>
                <div v-if="groupNames.length === 0" key="no-data" class="no-record-box">
                  <v-icon x-large>mdi-note-off-outline</v-icon>
                  <span>No Records</span>
                </div>
              </v-fade-transition>
              <div class="history-content">
                <transition-group name="fade">
                  <div class="history-logs" name="fade" v-for="(groupName, index) in groupNames" :key="groupName">
                    <div class="history-list mb-2">
                      <component class="main-content-height" :is="`${getterGetCurrentDeviceModel}_${groupName}ChangeCard`" :groupDiffs="groupDiffs[groupName]" />
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-slide-x-transition>
  </Teleport>
</template>

<script>
import { mapGetters } from "vuex";
import Teleport from 'vue2-teleport';

// RG2100
import RG2100_WirelessLANChangeCard from "../ChangeHistoryCards/RG2100/WirelessLANChangeCard.vue";
import RG2100_APNInfoChangeCard from "../ChangeHistoryCards/RG2100/APNInfoChangeCard.vue";
import RG2100_CellularGeneralChangeCard from "../ChangeHistoryCards/RG2100/CellularGeneralChangeCard.vue";
import RG2100_MISCChangeCard from "../ChangeHistoryCards/RG2100/MISCChangeCard.vue";
import RG2100_UsageChangeCard from "../ChangeHistoryCards/RG2100/UsageChangeCard.vue";
import RG2100_WiredLANChangeCard from "../ChangeHistoryCards/RG2100/WiredLANChangeCard.vue";
import RG2100_FirewallGeneralChangeCard from "../ChangeHistoryCards/RG2100/FirewallGeneralChangeCard.vue";
import RG2100_PortForwardingChangeCard from "../ChangeHistoryCards/RG2100/PortForwardingChangeCard.vue";
import RG2100_MACAddressFilteringChangeCard from "../ChangeHistoryCards/RG2100/MACAddressFilteringChangeCard.vue";
import RG2100_TimeOfDayAccessChangeCard from "../ChangeHistoryCards/RG2100/TimeOfDayAccessChangeCard.vue";
import RG2100_RouterAdminChangeCard from "../ChangeHistoryCards/RG2100/RouterAdminChangeCard.vue";

//FX20
import FX20_WirelessLANChangeCard from "../ChangeHistoryCards/FX20/WirelessLANChangeCard.vue";
import FX20_VPNPassthroughChangeCard from "../ChangeHistoryCards/FX20/VPNPassthroughChangeCard.vue"
import FX20_ParentalControlChangeCard from "../ChangeHistoryCards/FX20/ParentalControlChangeCard.vue"
// import FX20_DMZChangeCard from "../ChangeHistoryCards/FX20/DMZChangeCard.vue";
// import FX20_DynamicDNSChangeCard from "../ChangeHistoryCards/FX20/DynamicDNSChangeCard.vue";
// import FX20_WiredLANChangeCard from "../ChangeHistoryCards/FX20/WiredLANChangeCard.vue";
// import FX20_PortForwardingChangeCard from "../ChangeHistoryCards/FX20/PortForwardingChangeCard.vue"
// import FX20_ContentFilteringChangeCard from "../ChangeHistoryCards/FX20/ContentFilteringChangeCard.vue";
// import FX20_URLFilteringChangeCard from "../ChangeHistoryCards/FX20/URLFilteringChangeCard.vue";
// import FX20_ServiceFilteringChangeCard from "../ChangeHistoryCards/FX20/ServiceFilteringChangeCard.vue";
// import FX20_MACAddressFilteringChangeCard from "../ChangeHistoryCards/FX20/MACAddressFilteringChangeCard.vue";
// import FX20_NTPTimeServerChangeCard from "../ChangeHistoryCards/FX20/NTPTimeServerChangeCard.vue";
// import FX20_SystemChangeCard from "../ChangeHistoryCards/FX20/SystemChangeCard.vue";
// import FX20_RouterAdminChangeCard from "../ChangeHistoryCards/FX20/RouterAdminChangeCard.vue";
// import FX20_ReportSetsChangeCard from "../ChangeHistoryCards/FX20/ReportSetsChangeCard.vue";
// import FX20_AlertsSetsChangeCard from "../ChangeHistoryCards/FX20/AlertsSetsChangeCard.vue";


export default {
  components: {
    Teleport,
    // RG2100
    RG2100_WirelessLANChangeCard,
    RG2100_APNInfoChangeCard,
    RG2100_CellularGeneralChangeCard,
    RG2100_MISCChangeCard,
    RG2100_UsageChangeCard,
    RG2100_WiredLANChangeCard,
    RG2100_FirewallGeneralChangeCard,
    RG2100_PortForwardingChangeCard,
    RG2100_MACAddressFilteringChangeCard,
    RG2100_TimeOfDayAccessChangeCard,
    RG2100_RouterAdminChangeCard,

    // FX20
    FX20_WirelessLANChangeCard,
    FX20_ParentalControlChangeCard,
    FX20_VPNPassthroughChangeCard,
    // FX20_DMZChangeCard,
    // FX20_DynamicDNSChangeCard,
    // FX20_WiredLANChangeCard,
    // FX20_PortForwardingChangeCard,
    // FX20_ContentFilteringChangeCard,
    // FX20_URLFilteringChangeCard,
    // FX20_ServiceFilteringChangeCard,
    // FX20_MACAddressFilteringChangeCard,
    // FX20_NTPTimeServerChangeCard,
    // FX20_SystemChangeCard,
    // FX20_RouterAdminChangeCard,
    // FX20_ReportSetsChangeCard,
    // FX20_AlertsSetsChangeCard,
  },
  props: {
    configChangeHistoryPanel: Boolean,
  },
  data() {
    return {
      groupNames: [],
      groupDiffs: {}
    };
  },
  mounted() {
    this.makeDialogMovable();
  },
  beforeDestroy() {
    this.removeDialogMovableEventListeners();
  },
  // Watch for diff array or in this case, the getter of the diff array state change
  // If the user makes a configuration change via UI and the configuration diff array changes, this form will execute "divideDiffsToGroups()"
  // In order to update the configuration History Log UI
  watch: {
    getterGetConfigurationsDiff: {
      handler() {
        this.divideDiffsToGroups();
      },
      immediate: true,
      deep: true,
    },
    configChangeHistoryPanel: {
      handler(newVal) {
        if(newVal) {
          this.$nextTick(() => {
            this.screenResizeHandler();
          })
        } 
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getterGetConfigurationsDiff", "getterGetNewDeviceConfigurations", "getterGetDeviceConfigurations", "getterGetCurrentDeviceModel"]),
  },
  methods: {
    // Configuration 차이점들 중 한 그룹으로 모듈 Object들 묶음
    // 예시: "configuration_module.WirelessLAN.Value.MainWiFi.MaximumConnections" 가 "path"데이터 값이 면
    // "WirelessLAN"에 있는 모든 것들을 한곳으로 모음
    // 아래와 같은 데이터를
    // 기존 diff array
    //     [
    //       { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.MaximumConnections", "before": 10, "after": 3 },
    //       { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.WiFiName", "before": "RG2100 2317", "after": "Wifi 1234" },
    //       { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.RadioBand", "before": 0, "after": 1 }
    //     ]
    // 다음과 같이 변경
    // 새로운 Group array
    //     {
    //       WirlessLAN: [
    //         { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.MaximumConnections", "before": 10, "after": 3 },
    //         { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.WiFiName", "before": "RG2100 2317", "after": "Wifi 1234" },
    //         { "type": "replace", "path": "configuration_module.WirelessLAN.Value.MainWiFi.RadioBand", "before": 0, "after": 1 }
    //       ],
    //       ConnectedStatus: [...]
    //     }
    divideDiffsToGroups() {
      this.groupDiffs = this.getterGetConfigurationsDiff.reduce((acc, change) => {
        const pathSegments = change.path.split(".");
        const secondLevel = pathSegments[1]; // "WirelessLAN"과 같은 값들을 split된 path array에서 가져옴

        if (!acc[secondLevel]) {
          acc[secondLevel] = []; // acc["WirelessLAN"]과 같은 group 속성이 없다면 새로 초기화 및 array 생성
        }
        // group별로 각 json비교 차이 item을 group array로 넣음
        acc[secondLevel].push(change);
        return acc;
      }, {});
      // console.log(this.$doublejson(this.groupDiffs));
      // json 차이점 array안에 존재하는 그룹 이름을 하나의 이름으로 생성함
      this.groupNames = Object.keys(this.groupDiffs);
    },
    close_config_change_history_panel() {
      this.$emit("close");
    },
    splitPath(path) {
      return path.split(".");
    },

    // Script below makes the dialog movable
    makeDialogMovable() {
      const d = {};

      const mouseDownHandler = (event) => {
        if(!this.$refs.historypanel) return;
        const closestDialog = this.$refs.historypanel;
        if (event.button === 0 && closestDialog != null && event.target.classList.contains("history-title")) {
          d.el = closestDialog;
          d.mouseStartX = event.clientX;
          d.mouseStartY = event.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      };

      const mouseMoveHandler = (event) => {
        if (!d.el) return;
        d.el.style.left = Math.min(
          Math.max(d.elStartX + event.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
        d.el.style.top = Math.min(
          Math.max(d.elStartY + event.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
      };

      const mouseUpHandler = () => {
        if (!d.el) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      };

      document.addEventListener("mousedown", mouseDownHandler);
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      window.addEventListener("resize", this.screenResizeHandler);

      this.removeDialogMovableEventListeners = () => {
        document.removeEventListener("mousedown", mouseDownHandler);
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
        window.removeEventListener("resize", this.screenResizeHandler);
      };
    },
    screenResizeHandler() {
      const dialog = this.$refs.historypanel;
      if(dialog) {
        dialog.style.left = `${window.innerWidth - 520}px`;
        dialog.style.top = "10px";
      }
    }
  },
};
</script>

<style scoped>
.history-panel {
  display: block;
  position: fixed;
  z-index: 9999;
}

.history-title {
  cursor: pointer;
  transition: background 300ms ease-in-out;
}
.history-title:hover {
  background-color: rgb(220, 222, 224);
  transition: background 300ms ease-in-out;
}
.history-content-wrapper {
  height: 400px;
  width: 450px;
}
.history-content {
  box-sizing: border-box;
  position: absolute;
  padding: 1rem;
  height: 400px;
  width: 450px;
  overflow-y: auto;
}
.no-record-box {
  box-sizing: border-box;
  position: absolute;
  display: grid;
  place-content: center;
  padding: 1rem;
  height: 400px;
  width: 450px;
}
.history-list {
  word-break: break-word;
}
</style>
