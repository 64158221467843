import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("DHCP Range")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" DHCP (Dynamic Host Configuration Protocol) manages to automatically assign IP addresses and other network configuration parameters to network devices.")])]),(this.dhcpEnabled == true)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[(_vm.defaultGateway == null || _vm.defaultGateway == '')?_c(VTextField,{staticClass:"error-warning mt-4",attrs:{"label":"Default Gateway*","maxlength":"15","outlined":"","dense":""},model:{value:(_vm.defaultGateway),callback:function ($$v) {_vm.defaultGateway=$$v},expression:"defaultGateway"}}):_c(VTextField,{staticClass:"mt-4",attrs:{"label":"Default Gateway*","maxlength":"15","outlined":"","dense":""},model:{value:(_vm.defaultGateway),callback:function ($$v) {_vm.defaultGateway=$$v},expression:"defaultGateway"}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{staticClass:"fixed-input",attrs:{"readonly":"","outlined":"","dense":""},model:{value:(_vm.ipFixed),callback:function ($$v) {_vm.ipFixed=$$v},expression:"ipFixed"}})],1),_c(VCol,[(this.ipStartAdd == null ||
                      this.ipStartAdd.length <= 0 || 
                      this.ipStartAdd > 255)?_c(VTextField,{staticClass:"ml-2 error-warning",attrs:{"label":"IP Pool Starting Address*","maxlength":"3","hint":"Address must be between (0~255)","persistent-hint":"","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipStartAdd),callback:function ($$v) {_vm.ipStartAdd=$$v},expression:"ipStartAdd"}}):(parseInt(this.ipStartAdd) >= parseInt(this.ipEndAdd))?_c(VTextField,{staticClass:"ml-2 error-warning",attrs:{"label":"IP Pool Starting Address*","maxlength":"3","hint":"Start IP must be less than End IP","persistent-hint":"","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipStartAdd),callback:function ($$v) {_vm.ipStartAdd=$$v},expression:"ipStartAdd"}}):_c(VTextField,{staticClass:"ml-2",attrs:{"label":"IP Pool Starting Address*","maxlength":"3","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipStartAdd),callback:function ($$v) {_vm.ipStartAdd=$$v},expression:"ipStartAdd"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{staticClass:"fixed-input",attrs:{"readonly":"","outlined":"","dense":""},model:{value:(_vm.ipFixed),callback:function ($$v) {_vm.ipFixed=$$v},expression:"ipFixed"}})],1),_c(VCol,[(this.ipEndAdd == null ||
                    this.ipEndAdd.length <= 0 || 
                    this.ipEndAdd > 255)?_c(VTextField,{staticClass:"ml-2 error-warning",attrs:{"label":"IP Pool Ending Address* ","hint":"Address must be between (0~255)","persistent-hint":"","maxlength":"3","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipEndAdd),callback:function ($$v) {_vm.ipEndAdd=$$v},expression:"ipEndAdd"}}):(parseInt(this.ipStartAdd) >= parseInt(this.ipEndAdd))?_c(VTextField,{staticClass:"ml-2 error-warning",attrs:{"label":"IP Pool Ending Address*","maxlength":"3","hint":"Start IP must be less than End IP","persistent-hint":"","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipEndAdd),callback:function ($$v) {_vm.ipEndAdd=$$v},expression:"ipEndAdd"}}):_c(VTextField,{staticClass:"ml-2",attrs:{"label":"IP Pool Ending Address* ","maxlength":"3","outlined":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.ipEndAdd),callback:function ($$v) {_vm.ipEndAdd=$$v},expression:"ipEndAdd"}})],1)],1),(_vm.leaseTime == null || _vm.leaseTime.length <= 0)?_c(VTextField,{staticClass:"error-warning",attrs:{"label":"Lease Time (Sec)*","outlined":"","maxlength":"5","hint":"Lease Time (sec) required","persistent-hint":"","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.leaseTime),callback:function ($$v) {_vm.leaseTime=$$v},expression:"leaseTime"}}):_c(VTextField,{attrs:{"label":"Lease Time (Sec)*","outlined":"","maxlength":"5","hint":"(eg:300sec)","dense":""},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)}},model:{value:(_vm.leaseTime),callback:function ($$v) {_vm.leaseTime=$$v},expression:"leaseTime"}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }