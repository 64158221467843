<template>
  <v-dialog v-model="progressBarDialog" persistent width="600">
    <v-card dark class="gradient-bg overflow-hidden">
      <v-card-text class="mb-n1 mt-2" align="center" justify="center">
        Loading... Please wait
        <v-progress-linear indeterminate rounded color="white" height="10" class="mb-2 mt-2"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    progressBarDialog: Boolean,
  },
};
</script>
