<template>
  <v-form class="pa-5">
    <v-tabs v-model="tabIndex" align-with-title>
      <v-tabs-slider></v-tabs-slider>
  
      <v-tab v-for="item in tabItems" :key="item.id">{{ item.text }}</v-tab>
    </v-tabs>
  
    <v-tabs-items v-model="tabIndex">
      <v-tab-item v-for="component in tabItems" :key="component.id" eager>
        <component :is="component.name" :id="component.id" @changeValidState="changeValidState" />
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
import CellularGeneral from './SubComponents/CellularGeneral.vue';
import ApnInfo from './SubComponents/ApnInfo.vue';
import { mapGetters } from 'vuex';
  // Make sure to create a script to make the form run validation checks initially
  export default {
    components: {
      CellularGeneral,
      ApnInfo
    },
    watch: {
      getterGetCurrentError: {
        handler(newVal){
          if(newVal && newVal.path){
            let errorMenu = newVal.path.split('.')[1];
            console.log(newVal);
            this.$nextTick(() => {
              if(this.tabErrorMap[errorMenu]) this.tabIndex = this.tabErrorMap[errorMenu];
            })
          }
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {
      if(this.getterGetCurrentUserRole === "SU_ADMIN"){
        this.tabItems.push({ name: "ApnInfo", id: "apn", text: "APN" });
      }
    },
    data () {
      return {
        tabIndex: 0,
        tabItems: [
          { name: "CellularGeneral", id: "cellularGeneral", text: "General" }
        ],
        tabErrorMap: {
          cellularGeneral: 0,
          apnInfo: 1
        }
      }
    },
    computed: {
      ...mapGetters(["getterGetCurrentError", "getterGetCurrentUserRole"]),
    },
    methods: {
      changeValidState(validityObj) {
        this.$emit("changeValidState", validityObj);
      }
    }
  }
</script>