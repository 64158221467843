<template>
  <div>
    <div class="preview-space">
      <div class="preview-content">
        <transition-group name="fade">
          <div name="fade" v-for="(groupName, index) in groupNames" :key="groupName">
            <div class="history-list mb-2">
              <component class="main-content-height" :is="`${getterGetCurrentDeviceModel}_${groupName}ChangeCard`" :groupDiffs="groupDiffs[groupName]" :isPreview="true" />
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="footer-buttons">
      <v-btn dark @click="return_to_config" :loading="getterGetIsSendingDeviceConfig">Go Back</v-btn>
      <v-btn dark @click="submit_config" :loading="getterGetIsSendingDeviceConfig">Submit</v-btn>
    </div>
  </div>
</template>

<script>

// RG2100
import RG2100_WirelessLANChangeCard from "../ChangeHistoryCards/RG2100/WirelessLANChangeCard.vue";
import RG2100_APNInfoChangeCard from "../ChangeHistoryCards/RG2100/APNInfoChangeCard.vue";
import RG2100_CellularGeneralChangeCard from "../ChangeHistoryCards/RG2100/CellularGeneralChangeCard.vue";
import RG2100_MISCChangeCard from "../ChangeHistoryCards/RG2100/MISCChangeCard.vue";
import RG2100_UsageChangeCard from "../ChangeHistoryCards/RG2100/UsageChangeCard.vue";
import RG2100_WiredLANChangeCard from "../ChangeHistoryCards/RG2100/WiredLANChangeCard.vue";
import RG2100_FirewallGeneralChangeCard from "../ChangeHistoryCards/RG2100/FirewallGeneralChangeCard.vue";
import RG2100_PortForwardingChangeCard from "../ChangeHistoryCards/RG2100/PortForwardingChangeCard.vue";
import RG2100_MACAddressFilteringChangeCard from "../ChangeHistoryCards/RG2100/MACAddressFilteringChangeCard.vue";
import RG2100_TimeOfDayAccessChangeCard from "../ChangeHistoryCards/RG2100/TimeOfDayAccessChangeCard.vue";
import RG2100_RouterAdminChangeCard from "../ChangeHistoryCards/RG2100/RouterAdminChangeCard.vue";

//FX20
import FX20_WirelessLANChangeCard from "../ChangeHistoryCards/FX20/WirelessLANChangeCard.vue";
import FX20_ParentalControlChangeCard from "../ChangeHistoryCards/FX20/ParentalControlChangeCard.vue"
import FX20_VPNPassthroughChangeCard from "../ChangeHistoryCards/FX20/VPNPassthroughChangeCard.vue"
// import FX20_DMZChangeCard from "../ChangeHistoryCards/FX20/DMZChangeCard.vue";
// import FX20_DynamicDNSChangeCard from "../ChangeHistoryCards/FX20/DynamicDNSChangeCard.vue";
// import FX20_WiredLANChangeCard from "../ChangeHistoryCards/FX20/WiredLANChangeCard.vue";
// import FX20_PortForwardingChangeCard from "../ChangeHistoryCards/FX20/PortForwardingChangeCard.vue"
// import FX20_ContentFilteringChangeCard from "../ChangeHistoryCards/FX20/ContentFilteringChangeCard.vue";
// import FX20_URLFilteringChangeCard from "../ChangeHistoryCards/FX20/URLFilteringChangeCard.vue";
// import FX20_ServiceFilteringChangeCard from "../ChangeHistoryCards/FX20/ServiceFilteringChangeCard.vue";
// import FX20_MACAddressFilteringChangeCard from "../ChangeHistoryCards/FX20/MACAddressFilteringChangeCard.vue";
// import FX20_NTPTimeServerChangeCard from "../ChangeHistoryCards/FX20/NTPTimeServerChangeCard.vue";
// import FX20_SystemChangeCard from "../ChangeHistoryCards/FX20/SystemChangeCard.vue";
// import FX20_RouterAdminChangeCard from "../ChangeHistoryCards/FX20/RouterAdminChangeCard.vue";
// import FX20_ReportSetsChangeCard from "../ChangeHistoryCards/FX20/ReportSetsChangeCard.vue";
// import FX20_AlertsSetsChangeCard from "../ChangeHistoryCards/FX20/AlertsSetsChangeCard.vue";


import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {

    // RG2100
    RG2100_WirelessLANChangeCard,
    RG2100_APNInfoChangeCard,
    RG2100_CellularGeneralChangeCard,
    RG2100_MISCChangeCard,
    RG2100_UsageChangeCard,
    RG2100_WiredLANChangeCard,
    RG2100_FirewallGeneralChangeCard,
    RG2100_PortForwardingChangeCard,
    RG2100_MACAddressFilteringChangeCard,
    RG2100_TimeOfDayAccessChangeCard,
    RG2100_RouterAdminChangeCard,

    // FX20
    FX20_WirelessLANChangeCard,
    FX20_ParentalControlChangeCard,
    FX20_VPNPassthroughChangeCard,
    // FX20_DMZChangeCard,
    // FX20_DynamicDNSChangeCard,
    // FX20_WiredLANChangeCard,
    // FX20_PortForwardingChangeCard,
    // FX20_ContentFilteringChangeCard,
    // FX20_ServiceFilteringChangeCard,
    // FX20_MACAddressFilteringChangeCard,
    // FX20_URLFilteringChangeCard,
    // FX20_NTPTimeServerChangeCard,
    // FX20_SystemChangeCard,
    // FX20_RouterAdminChangeCard,
    // FX20_ReportSetsChangeCard,
    // FX20_AlertsSetsChangeCard,
  },
  props: {
    singleRouterInfo: Object,
    isGroupConfig: Boolean
  },
  data() {
    return {
      groupDiffs: {},
      configurationData: [],
      isSubmittingConfigs: false
    };
  },
  watch: {
    getterGetConfigurationsDiff: {
      handler() {
        this.divideDiffsToGroups();
        console.log(this.$doublejson(this.groupNames));
      },
      immediate: true,
      deep: true,
    }
  },
  computed: {
    ...mapGetters(["getterGetConfigurationsDiff", "getterGetCurrentDeviceModel", "getterGetIsSendingDeviceConfig", "getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapActions([ "actionSetDeviceConfiguration", "actionSetGroupConfiguration" ]),
    ...mapMutations(["mutationSetIsSendingDeviceConfig", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    divideDiffsToGroups() {
      this.groupDiffs = this.getterGetConfigurationsDiff.reduce((acc, change) => {
        const pathSegments = change.path.split(".");
        const secondLevel = pathSegments[1]; // "WirelessLAN"과 같은 값들을 split된 path array에서 가져옴

        if (!acc[secondLevel]) {
          acc[secondLevel] = []; // acc["WirelessLAN"]과 같은 group 속성이 없다면 새로 초기화 및 array 생성
        }
        // group별로 각 json비교 차이 item을 group array로 넣음
        acc[secondLevel].push(change);
        return acc;
      }, {});
      // json 차이점 array안에 존재하는 그룹 이름을 하나의 이름으로 생성함
      this.groupNames = Object.keys(this.groupDiffs);
      const configs = this.getterGetNewDeviceConfigurations.configuration_module;

      this.configurationData = this.groupNames.map(name => {
        return {
          record_type: name,
          configuration_value: {
            payload_type: "Structure",
            payload_value: JSON.stringify(configs[name].Value)
          }
        }
      })
    },
    return_to_config() {
      this.$emit("return");
    },
    splitPath(path) {
      return path.split(".");
    },
    async submit_config() {
      try{
        this.triggerSnackBar("notification", "Updating...");
        this.mutationSetIsSendingDeviceConfig(true);
        if(this.isGroupConfig) {
          await this.actionSetGroupConfiguration({ router_group_id: this.singleRouterInfo.router_group_id, configuration_module: this.configurationData });
        } else {
          await this.actionSetDeviceConfiguration({ router_id: this.singleRouterInfo.router_id, configuration_module:this.configurationData });
        }
        this.triggerSnackBar("success", "Configuration has been updated");
        this.$emit("close", 1);
      } catch(err){
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.mutationSetIsSendingDeviceConfig(false);
      }
    }
  },
};
</script>

<style scoped>
  .preview-space {
    background-color: rgba(0, 0, 0, 0.1);
    height: calc(80vh - 64px);
  }
  .preview-content {
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
    overflow-y: auto;
    background-color: white;
  }
  .preview-content > * {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1.5rem;
  }
  .footer-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 800px) {
    .preview-content > * {
      grid-template-columns: 1fr;
    }
  }
</style>
