<template>
  <v-card v-if="listData.length" color="#F3F4F6" key="apn-list" flat rounded="lg">
    <v-card-title class="text-subtitle-1 pa-3 pb-1">
      <span>{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <transition-group name="fade">
        <div class="history-list my-2" v-for="listIndexGroup in listData" :key="listIndexGroup.key">
          <v-card flat rounded="lg">
            <v-card-title class="text-subtitle-2">
              <v-img class="mr-2" style="flex: 0 0 auto;" src="@/assets/config_list.svg" height="24" width="24" contain></v-img>
              <span class="font-weight-medium">Changes for {{ title }} Item <span class="font-weight-bold">[{{ listIndexGroup.index }}]</span></span>
            </v-card-title>
            <v-card-text>
              <transition-group name="fade">
                <div v-for="(diff, index) in listIndexGroup.diffs" :key="diff.path">
                  <v-divider v-show="index !== 0"></v-divider>
                  <SingleCard type="array" :diff="diff" :propertyNameTable="mixinConfigArrayNames" />
                </div>
              </transition-group>
            </v-card-text>
          </v-card>
        </div>
      </transition-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mixin_config_history_utils } from "@/mixins/configHistoryUtils.js";
import SingleCard from "./SingleCard.vue";
export default {
  mixins: [mixin_config_history_utils],
  components: {
    SingleCard,
  },
  props: {
    title: String,
    listData: Array,
  },
};
</script>

<!--
The following comopnent parses data below and renders it on the UI

listData = [
  {
    "key": "ProfileList-0",
    "index": "0",
    "diffs": [
      {
        "type": "replace",
        "path": "configuration_module.APNInfo.Value.ProfileList.0.Active",
        "before": 1,
        "after": 0
      },
      {
        "type": "delete",
        "path": "configuration_module.APNInfo.Value.ProfileList.0.UserName",
        "before": ""
      },
      {
        "type": "delete",
        "path": "configuration_module.APNInfo.Value.ProfileList.0.Password",
        "before": ""
      }
    ]
  },
  {
    "key": "ProfileList-1",
    "index": "1",
    "diffs": [
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.Index",
        "after": 2
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.Active",
        "after": 0
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.ProfileName",
        "after": "mobile"
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.APNName",
        "after": "t-mobile.com"
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.PDPType",
        "after": 1
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.AuthType",
        "after": 0
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.InactiveTime",
        "after": 0
      },
      {
        "type": "add",
        "path": "configuration_module.APNInfo.Value.ProfileList.1.IPAllocation",
        "after": 0
      }
    ]
  }
] -->
