<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-card flat>
      <v-card-text>
        <span class="text-h6 primary--text"
          ><b class="heading-font">MAC Filtering</b></span
        >

        <v-row class="configurationCard mt-4" align="center">
          <v-tooltip v-if="tableData.length == 0" right>
          <template v-slot:activator= "{ on }">
          <div class="switch-container" v-on="on">
            <v-switch
              disabled
              class="ml-4"
              dense
              v-model="accessPolicy"
              color="gray"
              inset
              label="Allow only users on this list"
              true-value= 1
              false-value= 0          
              >
            </v-switch>
            <v-icon class="alert-icon">mdi-help-circle</v-icon>

          </div>
          </template> 
          <p class="custom-tooltip-mac">Add MAC Filter address to enable</p>
        </v-tooltip>
        <v-tooltip v-else-if="macDeactivated" right>
        <template v-slot:activator= "{ on }">
          <div v-on="on" class="switch-container">
              <v-switch
              disabled
              class="ml-4"
              dense
              v-model="accessPolicy"
              color="gray"
              inset
              label="Allow only users on this list"
              true-value= 1
              false-value= 0          
              ></v-switch>
            <v-icon class="alert-icon">mdi-alert</v-icon>
          </div>
          </template> 
          <p class="custom-tooltip-mac width-adjustment">Enable at least 1 rule in order to use MAC filtering</p>
        </v-tooltip>
            <v-switch
            v-else
            class="ml-4"
            dense
            v-model="accessPolicy"
            color="blue"
            inset
            label="Allow only users on this list"       
            ></v-switch>
          <v-spacer></v-spacer>
          <v-btn color="button mr-3" dark @click="add_action()">
            ADD
          </v-btn>
        </v-row>
        
        <v-card flat>
            <v-data-table
              dense
              :class="{'disabled-table' : !accessPolicy}"
              :headers="headers"
              :items="tableData"
              :items-per-page="10"
              class="elevation-0 mt-4"
            >
            <template v-slot:[`header.RuleName`]="{ header }">
                <div class="ml-2">{{  header.text }}</div>
              </template>
              <template v-slot:[`item.RuleName`]="{ item }">
                <div class="ml-2">{{  item.RuleName }}</div>
              </template>
              <template v-slot:[`header.action`]="{ header }">
                <div class="action-column">{{  header.text }}</div>
              </template>

              <template slot="item.action" slot-scope="props">
                <v-menu  bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="action-column" icon v-bind="attrs" v-on="on">
                      <v-icon >mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="edit_item(props.item, props.index)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="delete_item(props.item, props.index)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="deleteDailog" max-width="400px">
      <v-card class="cardBg">
        <v-toolbar dense class="medium cardTitle">
          <v-toolbar-title> Delete Record</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon class="ml-2" @click="deleteDailog = false" color="iconCross"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="cardBg pt-6 justify-center">
          Are you sure you want to delete {{ ruleName }} ?
        </v-card-text>
        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn @click="deleteDailog = false" class="button tabledata--text"
            >Cancel</v-btn
          >
          <v-btn @click="delete_data()" class="button tabledata--text mr-2"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="componentCheck == 1">
      <AddAccessControlDialog
        :tableData="tableData"
        :addAccessContDialog="addAccessContDialog"
        @clicked="close_dialog"
        v-on:addInfo="get_add_info"
      />
    </div>
    <div v-if="componentCheck == 2">
      <EditAccces
        :editDialog="editDialog"
        :editInfo="editInfo"
        @clicked="close_dialog"
        v-on:editInfo="get_edit_info"
      />
    </div>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import EditAccces from "./EditAccces.vue";
import AddAccessControlDialog from "./AddAccessControlDialog.vue";
export default {
  components: {
    AddAccessControlDialog,
    EditAccces,
    SnackBar,
  },
  data() {
    return {
      addAccessContDialog: false,
      accessPolicy: null,
      SnackBarComponent: {},
      accessPolicyItems: [
        { value: 0, text: "OFF (Allow all device to access)" },

        { value: 1, text: "ON (Allow only users on permitted list)" },
        
      ],
      headers: [
        {
          text: "Rule Name",
          align: "start",
          sortable: false,
          value: "RuleName",
        },
       
        { text: "MAC Address", value: "MACAddress" },
        { text: "Actions", value: "action" },
      ],
      tableData: [],
      componentCheck: 0,
      editInfo: {},
      editDialog: false,
      currentIndex: null,
      ruleName: "",
      deleteDailog: false,
      filterSet: null,
      isMounted: false,
      macDeactivated: false,
      storedValue: null,
      deletedItem: false,
      addToEmptyTable: false,
    };
  },

  watch: {
    accessPolicy() {
      if (this.accessPolicy == false) {
        this.accessPolicy = 0;
      } else if (this.accessPolicy == true) {
        this.accessPolicy = 1;
      }
      this.save_final();
    },

    tableData: {
      handler() {
        var deactiveArray = [];
        this.tableData.forEach((element) => {
          if (element.ActiveRule == 0) {
            element.ActiveRule = 1;
          }
        })
        if (this.tableData.length == 0) {
          this.macDeactivated = false;
          this.accessPolicy = 0;
          this.storedValue = null;
        }
        if (this.addToEmptyTable) {
          this.accessPolicy = this.tableData[0].ActiveRule;
          this.addToEmptyTable = false;
          console.log("access policy: " + this.accessPolicy)
          return;
        }
        console.log("access policy: " + this.accessPolicy)
      },
      deep: true
    }
  },

  computed: {
    switchLabel() {
      if (this.accessPolicy == 0) {
        return "Disabled"
      } else {
        return "Enabled"
      }
    }
  },
  created() {
    this.bind_default_json();
  },
  mounted() {
    var data = localStorage.getItem("accessControl");
    if (JSON.parse(data) == true) {
      this.bind_modified_data();
    }
  },
  methods: {
    bind_default_json() {
      var defaultJson =
      JSON.parse(localStorage.getItem("Defaultconfig")).configuration_module.MACAddressFiltering
      this.accessPolicy = defaultJson.Value.FilterSet;
      this.tableData = defaultJson.Value.MACFilteringRules;
    },

    bind_modified_data() {
      var data = this.$store.getters.GetAccessControl;
      this.tableData = data.MACFilteringRules;
        this.accessPolicy = data.FilterSet;
    },

    set_data(item, status) {
      this.tableData[item.Index].ActiveRule = status == true ? 1 : 0;
      this.save_final();
    },
    add_action() {
      this.componentCheck = 1;
      this.addAccessContDialog = true;
    },

    close_dialog(val) {
      if (val == 0) {
        this.addAccessContDialog = false;
        this.componentCheck = 0;
      } else if (val == 1) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields",
          timeout: 5000,
          Top: true,
        };
      } else if (val == 2) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Added Successfully",
          timeout: 5000,
          Top: true,
        };
        this.addAccessContDialog = false;
        this.componentCheck = 0;
      } else if (val == 3) {
        this.editDialog = false;
        this.componentCheck = 0;
      } else if (val == 4) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Saved Successfully",
          timeout: 5000,
          Top: true,
        };
        this.editDialog = false;
        this.componentCheck = 0;
      } else if (val == 5) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Invalid Mac Address",
          timeout: 5000,
          Top: true,
        };
      } else if (val == 6) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Max Limit is 20 Records",
          timeout: 5000,
          Top: true,
        };
      }
    },

    get_add_info(val) {
      if (this.tableData.length == 0) {
        this.tableData.push(val);
        this.addToEmptyTable = true;
      } else {
        this.tableData.push(val);
        this.addToEmptyTable = false;
      }
      this.save_final();
    },

    edit_item(item) {
      this.componentCheck = 2;
      this.editDialog = true;
      this.editInfo = item;
      this.currentIndex = item.Index;
    },

    get_edit_info(val) {
      this.tableData[this.currentIndex].RuleName = val.RuleName;
      this.tableData[this.currentIndex].MACAddress = val.MACAddress;
      this.tableData[this.currentIndex].ActiveRule = val.ActiveRule;
      this.save_final();
    },

    delete_item(item) {
      this.ruleName = item.RuleName;
      this.currentIndex = item.Index;
      this.deleteDailog = true;
      this.deletedItem = true;
    },

    //method to delete the selected record
    delete_data() {
      var indexInfo = 0;
      this.$store.commit("SetDeletedAccessControl", this.tableData[this.currentIndex]);
      this.$store.commit("SetFirewall", true);
      localStorage.setItem("deletedAccessControl", true);
      this.tableData.splice(this.currentIndex, 1);
      this.tableData.forEach((element) => {
        element.Index = indexInfo++;
      });

      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: "Deleted Successfully",
        timeout: 5000,
        Top: true,
      };
      this.save_final();
      this.deleteDailog = false;
      this.deletedItem = false;
    },

    
    save_final() {
      var data = {};
      if (this.accessPolicy == 1) {
        data = {
          FilterSet: 1,
          MACFilteringRules: this.tableData,
        };
      } else {
        data = {
          FilterSet: 0,
          MACFilteringRules: this.tableData,
        };
      }
      this.$store.commit("SetFirewall", true);
      this.$store.commit("SetAccessControl", data);
      localStorage.setItem("accessControl", true);
      this.$emit("apply");
    },

  },
};
</script>

<style>
.tooltip-container-mac {
  position: relative;
}

.custom-tooltip-mac {
  position: absolute;
  top: -2.1em;
  left: 0.4em; 
  background-color: #202020;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 185px;
}
.custom-tooltip-mac::before {
  content: "";
  position: absolute;
  bottom: 25%; 
  left: 0%;
  transform: translateX(-100%) rotate(270deg);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #202020 transparent;
}

.custom-tooltip-mac.width-adjustment {
  width: 280px; 
}

.switch-container {
  display: flex;
  align-items: center;
}

.alert-icon {
  margin-left: 8px;
}
.alert-icon.red-outline {
  color: red
}
.v-data-table.elevation-0.mt-4.v-data-table--dense.v-data-table--has-bottom.theme--light.disabled-table .v-data-table__wrapper {
  opacity: 0.5
}
.v-data-table.elevation-0.mt-4.v-data-table--dense.v-data-table--has-bottom.theme--light.header-color .v-data-table__wrapper {
  background-color: rgba(253, 136, 136, 0.205) !important;
}
.v-data-table.elevation-0.mt-4.v-data-table--dense.v-data-table--has-bottom.theme--light.header-color .v-data-table-header {
  background-color: red !important;
}

.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default.action-column,
.text-start.sortable .action-column{
  float: right;
  margin-right: 1.5em;
}
</style>