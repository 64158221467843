<template>
  <!-- popup for adding devices to groups -->
  <v-dialog overflow-hidden persistent v-model="devicePopup" max-width="500px">
    <v-card class="cardBg">
      <v-toolbar dense class="medium cardTitle">
        <span>Assign Devices to Group</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="clear_data()">
          <v-icon color="iconCross">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="cardBg mt-6">
        <div v-if="orgDetails[0].selectedOrg" class="inline-paragraph">
          <p class="add-device-header">
            Organization: <b>{{ orgDetails[0].label }}</b>
          </p>
          <v-spacer></v-spacer>
          <p class="add-device-header">
            Device Model: <b>{{ selectedRecordsModels[0] }}</b>
          </p>
        </div>
        <p v-else class="add-device-header mb-6">
          Device Model: <b>{{ selectedRecordsModels[0] }}</b>
        </p>
        <v-form ref="addUserForm" class="mb-n4">
          <v-select v-if="!orgDetails[0].selectedOrg" outlined dense v-model="orgInfo" label="Select Organization*" item-text="label" :class="{ 'preselected-org': orgDetails[0].selectedOrg }" item-value="value" :items="orgDetails" @input="get_group_info(orgInfo)"> </v-select>
          <template v-if="groupDetails.length > 0">
            <v-select outlined dense v-model="groups" label="Select Group*" item-text="label" item-value="value" :items="groupDetails"> </v-select>
          </template>
          <v-tooltip v-else-if="this.orgInfo.length == 0" right>
            <template v-slot:activator="{ on }">
              <div class="tooltip-container" v-on="on">
                <v-select outlined disabled dense v-model="groups" label="Select Group*" item-text="label" item-value="value" :items="groupDetails"> </v-select>
              </div>
            </template>
            <p class="custom-tooltip">Select Organization to view groups</p>
          </v-tooltip>
          <div v-else-if="groupDetails.length == 0 && dropdownLoading == false" class="tooltip-container">
            <v-select outlined disabled dense v-model="groups" :label="noGroupsLabel" item-text="label" item-value="value" :items="groupDetails"> </v-select>
          </div>
          <v-select v-else outlined disabled dense :label="noGroupsLabel" v-model="groups" item-text="label" item-value="value" :items="groupDetails">
            <template #label v-if="dropdownLoading == true">
              <i class="fas fa-spinner fa-spin mr-1" style="color: #949494"></i>
              <span> Loading {{ selectedRecordsModels[0] }} groups </span>
            </template>
          </v-select>
        </v-form>
        <span>*Devices can only be assigned to one group at a time</span>
      </v-card-text>
      <v-card-actions class="button-corner">
        <v-spacer></v-spacer>
        <v-btn outlined depressed class="blue--text mr-2" color="white" @click="clear_data()">Cancel</v-btn>
        <v-btn v-if="groups" class="button tabledata--text mr-2" @click="validate_data()" :loading="loading1">Assign</v-btn>
        <v-btn v-else disabled class="button tabledata--text mr-2" :loading="loading1">Assign</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { add_multiple_router_to_router_group } from "@/graphql/mutations.js";
import { get_org_groups } from "@/mixins/GetOrgGroups.js";

export default {
  mixins: [get_org_groups],
  props: {
    devicePopup: Boolean,
    orgDetails: Array,
    selectedDevices: Array,
    selectedRecordsModels: Array,
  },

  computed: {
    noGroupsLabel() {
      if (this.dropdownLoading == false) {
        return "No " + this.selectedRecordsModels[0] + " groups available";
      }
    },
  },

  created() {
    if (this.devicePopup == true && this.orgDetails[0].selectedOrg) {
      this.orgInfo = this.orgDetails[0].value;
      this.get_group_info(this.orgDetails[0].value);
    }
  },

  data() {
    return {
      orgInfo: "",
      groups: "",
      loading1: false,
      groupDetails: [],
      custId: "",
      routerIds: [],
      fetchGroupsDone: false,
      modelMatches: false,
      dropdownLoading: false,
    };
  },
  methods: {
    //methods invokes on close action
    clear_data() {
      this.$emit("clicked", 0);
      this.groups = "";
      this.orgInfo = "";
    },

    //methods invokes on org selection
    get_group_info(val) {
      this.fetch_groups(val);
      this.custId = val;
    },

    //guery call to fetch selected organization groups
    async fetch_groups(val) {
      this.dropdownLoading = true;
      var self = this;
      this.groupDetails = [];
      this.$store.commit("SetGetGroupID", val);
      await this.get_org_groups();
      if (this.getOrgGroups.data != undefined) {
        this.getOrgGroups.data.forEach((element) => {
          if (element.group_model == this.selectedRecordsModels[0]) {
            self.groupDetails.push({
              label: element.group_name + "  (" + element.group_model + " only)",
              value: element.router_group_id,
              disabled: element.group_model != this.selectedRecordsModels[0],
            });
          }
        });
      }
      this.dropdownLoading = false;
      this.groups = "";
    },

    //method to validate the empty fields
    validate_data() {
      var valid = true;
      if (this.orgInfo == "" || this.groups == "") {
        valid = false;
        this.$emit("clicked", 1);
      }
      if (valid) {
        this.fetch_id();
        this.add_devices();
      }
    },

    //method to fetch the selected devices id
    fetch_id() {
      var self = this;
      this.routerIds = [];
      this.selectedDevices.forEach((element) => {
        self.routerIds.push(element.router_id);
      });
    },

    //mutation call to add mutiple devices to group
    async add_devices() {
      this.loading1 = true;
      try {
        let result = await API.graphql(
          graphqlOperation(add_multiple_router_to_router_group, {
            input: {
              router_ids: this.routerIds,
              customer_id: this.$store.getters.GetGetGroupID,
              router_group_id: this.groups,
            },
          })
        );
        this.loading1 = false;
        this.$emit("clicked", 2);
        this.groups = "";
        this.orgInfo = "";
      } catch (error) {
        this.$emit("clicked", 3);
        this.loading1 = false;
        this.$emit("errorMsg", error.errors[0].message);
      }
    },
  },
};
</script>

<style>
.tooltip-container {
  position: relative;
}

.custom-tooltip {
  position: absolute;
  top: -2em;
  left: 0%;
  background-color: #202020;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  width: 240px;
}

.custom-tooltip::before {
  content: "";
  position: absolute;
  bottom: 25%;
  left: 0%;
  transform: translateX(-100%) rotate(270deg);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #202020 transparent;
}

.add-device-header {
  font-size: 16px;
}
.v-btn {
  text-transform: none;
}
</style>

<style scoped>
.preselected-org >>> fieldset {
  background-color: rgb(223, 223, 223) !important;
}
.inline-paragraph {
  display: flex;
  align-items: center;
}
</style>
