import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px","persistent":"","overflow-hidden":""},model:{value:(_vm.remoteCommandDialog),callback:function ($$v) {_vm.remoteCommandDialog=$$v},expression:"remoteCommandDialog"}},[_c(VCard,{staticClass:"dialogCard cardBg overflow-hidden"},[_c(VToolbar,{staticClass:"dialogToolbar cardBg",attrs:{"dense":""}},[_c(VToolbarTitle,[_vm._v("Remote Command - "+_vm._s(_vm.routersArray.group_name))]),_c(VSpacer),_c(VIcon,{staticClass:"ml-2",attrs:{"color":"iconCross"},on:{"click":function($event){return _vm.close_dialog_remote()}}},[_vm._v("mdi-close")])],1),_c(VCardText,[_c(VRow,{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c(VSelect,{attrs:{"label":"Type","items":this.$store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' ? _vm.superItems : _vm.normItems,"outlined":"","dense":"","required":""},model:{value:(_vm.commandType),callback:function ($$v) {_vm.commandType=$$v},expression:"commandType"}})],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.validate_reebot()}}},[_vm._v("Send")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }