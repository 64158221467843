import { render, staticRenderFns } from "./BasicSecurity.vue?vue&type=template&id=207a5882&scoped=true&"
import script from "./BasicSecurity.vue?vue&type=script&lang=js&"
export * from "./BasicSecurity.vue?vue&type=script&lang=js&"
import style0 from "./BasicSecurity.vue?vue&type=style&index=0&id=207a5882&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207a5882",
  null
  
)

export default component.exports