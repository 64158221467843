import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","overflow-hidden":"","max-width":"600px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c(VCard,{staticClass:"cardBg"},[_c(VToolbar,{staticClass:"medium cardTitle",attrs:{"dense":""}},[_c('span',[_vm._v("Add > IP Address for accessing Web UI")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.close_dialog()}}},[_c(VIcon,{attrs:{"color":"iconCross"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"cardBg"},[_c(VRow,{staticClass:"mt-4"},[_c(VCol,[_c(VTextField,{attrs:{"dense":"","outlined":"","label":"Ip Address"},model:{value:(_vm.ipAddress),callback:function ($$v) {_vm.ipAddress=$$v},expression:"ipAddress"}})],1),_c(VSpacer),_c(VBtn,{staticClass:"button mt-1 mr-7 ml-4",attrs:{"fab":"","dark":"","x-small":""},on:{"click":function($event){return _vm.validate_port()}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.ipHeader,"items":_vm.tableData,"items-per-page":5}})],1),_c(VCardActions,{staticClass:"button-corner"},[_c(VSpacer),_c(VBtn,{staticClass:"button tabledata--text mr-2",on:{"click":function($event){return _vm.save_data()}}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }