<template>
  <v-form ref="form" class="pa-5">

    <h3 class="blue--text text--darken-1 mb-4">VPN Passthrough</h3>

    <div>
      <v-switch ref="PPTPPassthrough" class="form-text-box d-inline-block" dense v-model="PPTPPassthrough" hide-details @click="mixinUpdateFormField('VPNPassthrough.PPTPPassthrough', `${configPath}.PPTPPassthrough`, PPTPPassthrough ? 1 : 0)">
        <template v-slot:label>
          <div>PPTP Passthrough</div>
        </template>
      </v-switch>
    </div>

    <div>
      <v-switch ref="L2TPPassthrough" class="form-text-box d-inline-block" dense v-model="L2TPPassthrough" hide-details @click="mixinUpdateFormField('VPNPassthrough.L2TPPassthrough', `${configPath}.L2TPPassthrough`, L2TPPassthrough ? 1 : 0)">
        <template v-slot:label>
          <div>L2TP Passthrough</div>
        </template>
      </v-switch>
    </div>

  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js'
  import { mixin_list_table_utils } from '@/mixins/listTableUtils.js';

  export default {
    mixins: [ mixin_form_validation_utils, mixin_list_table_utils ],
    data(){
      return {
        PPTPPassthrough: 0,
        L2TPPassthrough: 0,
        
        configPath: "configuration_module.VPNPassthrough.Value"
      }
    },
    created() {
      this.initMisc();
    },
    watch: {
      // Watch vuex state. This will ensure that the values on this form get updated if the values used in this component change from another component.
      '$store.state.newDeviceConfigurations': {
        handler(){
          this.initMisc();
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters(["getterGetNewDeviceConfigurations"]),
    },
    methods: {
      initMisc(){
        const defaultVPNPassthrough = this.getterGetNewDeviceConfigurations.configuration_module.VPNPassthrough.Value;

        this.PPTPPassthrough = defaultVPNPassthrough.hasOwnProperty("PPTPPassthrough") ? (defaultVPNPassthrough.PPTPPassthrough === 1 ? true : false) : false;
        this.L2TPPassthrough = defaultVPNPassthrough.hasOwnProperty("L2TPPassthrough") ? (defaultVPNPassthrough.L2TPPassthrough === 1 ? true : false) : false;

        this.$nextTick(() => {
          this.mixinValidateForm('VPNPassthrough', 'VPNPassthrough');
        })
      }
    }
  }
</script>