<template>
  <div>
    <div>
      <v-main>
        <v-toolbar class="elevation-0 tablePadding" dense flat>
          <v-toolbar-title class="black--text mt-1">Device Groups</v-toolbar-title>

        <v-select v-if="superAdminCheck" class="ml-6 mr-2 mt-6 filter" color="black" clearable style="font-size: 12px; max-width: 200px;" v-model="organizationFilter" label="Organization" :items="organizationItems" @input="filterGroupList()" no-data-text="loading...">
          <template #label v-if="isLoadingOrganziationList">
            <i class="fas fa-spinner fa-spin ml-0" style="color: #949494"></i>
            <span> Organizations </span>
          </template>
          <template #item="{ item }" v-else>
            <span class="filter-font"> {{ item }}</span>
          </template>
        </v-select>

          <v-btn class="button ml-5" v-if="$store.getters.GetCurrentObj.user.user_role != 'SP_ADMIN'" @click="open_create_group_dialog()" dark>Create Group</v-btn>
          <v-spacer></v-spacer>
          <div class="container-wrapper">
            <v-text-field clearable append-icon="mdi-magnify blue--text" @click:clearable="clear_value" v-model="search" class="search-bar mr-1" label="Search" single-line style="max-width: 250px"></v-text-field>
          </div>
        </v-toolbar>

        <v-card-text>
          <v-data-table dense :headers="$store.getters.GetCurrentObj.user.user_role == 'SP_ADMIN' ? suppHeaders : headers" :items="filteredGroupList" :search="search" class="elevation-1" :loading="isTableLoading" :items-per-page="10" :no-results-text="noResultsText">
            <template v-slot:[`item.group_status`]="{ item }">
              <span v-if="item.group_status == 'ACTIVE'" class="caption blue--text text--darken-2 ml-2">Active</span>
              <span v-else class="caption grey--text text--darken-1">Inactive</span>
            </template>

            <template v-slot:[`item.group_description`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="caption text_ellipsis">{{ item.group_description }}</span>
                </template>
                <span>{{ item.group_description }}</span>
              </v-tooltip>
            </template>

            <template v-slot:header.group_status="{ header }">
              <span class="ml-2">
                {{ header.text }}
              </span>
            </template>

            <template slot="item.Actions" slot-scope="props">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-if="$store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1'" @click="edit_info(props.item)">
                    <v-list-item-title>Edit Info</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="$store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1'" @click="open_manage_group_devices_dialog(props.item)">
                    <v-list-item-title>Manage Devices</v-list-item-title>
                  </v-list-item>
                  
                  <v-list-item @click="open_group_configs_dialog(props.item)">
                    <v-list-item-title>Configuration</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="open_group_configuration_dialog(props.item)">
                    <v-list-item-title>Configuration</v-list-item-title>
                  </v-list-item> -->
                  <!-- Gene 7/24/23 hidden until further notice -->
                  <!-- <v-list-item
                    v-if="
                      $store.getters.GetCurrentObj.user.user_role ==
                        'SU_ADMIN' ||
                      $store.getters.GetCurrentObj.user.user_role ==
                        'C_ADMIN' ||
                      $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1'
                    "
                    @click="manage_firmware(props.item, props.index)"
                  >
                    <v-list-item-title>Manager Firmware</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item v-if="props.item.registered_routers != 0 && ($store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1')" @click="remote_command(props.item, props.index)">
                    <v-list-item-title>Remote Command</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="$store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1'" @click="delete_item(props.item, props.index)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:footer>
              <div class="footer-height">
                <LoadingIcon v-if="isTableLoading" style="position: absolute" class="ml-2 mt-2"></LoadingIcon>

                <span v-if="isTableLoading" style="position: absolute" class="ml-10 mt-5"> Loading device groups... </span>

                <span v-else>
                  <v-icon @click="refresh_list()" class="ml-1 mt-3 btn-fix" dark outline="none" color="cyan" style="position: absolute; font-size: 34px" id="refresh"> mdi-refresh </v-icon>
                  <!-- getLastUpdateTime is from time_utils mixin -->
                  <span style="position: absolute" class="ml-11 pl-0 mt-5"> Last updated: {{ mixinGetLastUpdatedTime }} </span>
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-main>
    </div>

    <DeviceConfigurationDialog :deviceConfigurationDialog="deviceConfigurationDialog" :singleRouterInfo="singleRouterInfo" @previewDailogMethod="close_group_configuration_dialog" @close="close_group_configuration_dialog" />

    <CreateGroupDialog :createGroupDialog="createGroupDialog" :productList="productList" :organizationList="organizationList" @close="close_create_group_dialog" />

    <div v-if="componentCheck == 2">
      <ManageGroupDevicesDialog :manageGroupDevicesDialog="manageGroupDevicesDialog" :groupInfo="groupInfo" @close="close_manage_group_devices_dialog" />
    </div>
    <div v-if="componentCheck == 3">
      <EditGroupInfoDialog :editDialog="editDialog" :editItem="editItem" v-on:errorMsg="error_info" @clicked="close_dialog" />
    </div>
    <div v-if="componentCheck == 4">
      <ManageFirmwareForGroups :manageFirmwareDialog="manageFirmwareDialog" :firmwareRow="firmwareRow" :firmwareResponse="firmwareResponse" @clicked="close_firm"></ManageFirmwareForGroups>
    </div>
    <div v-if="componentCheck == 5">
      <DeleteGroupDialog :deleteItem="deleteItem" :deletePopup="deletePopup" v-on:errorMsg="error_info" @clicked="close_dialog"></DeleteGroupDialog>
    </div>
    <div v-if="componentCheck == 6">
      <GroupRemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :routersArray="routersArray" v-on:errorMsg="error_info" @clicked="close_dialog_remote"></GroupRemoteCommandDialog>
    </div>

    <!-- Gene 4/3/23 Added for discard dialog when closing configuration menu -->
    <div v-if="componentCheck == 7">
      <DiscardDialog :discardChangesDialog="discardChangesDialog" @close="close_discard_dialog"></DiscardDialog>
    </div>
    <DeviceConfigDialog :deviceConfigDialog="deviceConfigDialog" :isGroupConfig="true" :singleRouterInfo="singleRouterInfo" @close="close_group_configs_dialog"/>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import CreateGroupDialog from "@/components/Groups/GroupDialogs/CreateGroupDialog.vue";
import EditGroupInfoDialog from "@/components/Groups/GroupDialogs/EditGroupInfoDialog.vue";
import DeleteGroupDialog from "@/components/Groups/GroupDialogs/DeleteGroupDialog.vue";
import GroupRemoteCommandDialog from "@/components/Groups/GroupDialogs/GroupRemoteCommandDialog.vue";
import ManageGroupDevicesDialog from "@/components/Groups/GroupDialogs/ManageGroupDevicesDialog.vue";
import ManageFirmwareForGroups from "@/components/Groups/GroupDialogs/ManageFirmwareForGroups.vue";
import DeviceConfigurationDialog from "@/components/DeviceConfigurations/DeviceConfigurationDialog.vue"
import Overlay from "@/components/Overlay.vue";
import DeviceConfigDialog from "@/components/DeviceConfigurations/DeviceConfigDialog.vue"

import DiscardDialog from "@/components/DiscardDialog.vue";
import { mixin_time_utils } from "@/mixins/timeUtils";

import { getOrganizationList, getProductList } from "@/services/organizationService.js";
import { getGroupList, getUpdatedGroupList } from "@/services/groupService";

import { mapMutations, mapGetters, mapActions } from "vuex";

import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  components: {
    DiscardDialog,
    CreateGroupDialog,
    EditGroupInfoDialog,
    ManageFirmwareForGroups,
    DeleteGroupDialog,
    ManageGroupDevicesDialog,
    Overlay,
    GroupRemoteCommandDialog,
    LoadingIcon,
    DeviceConfigurationDialog,
    DeviceConfigDialog
  },
  mixins: [ mixin_time_utils ],
  data() {
    return {
      deviceConfigDialog: false,

      // variables used for filtering
      organizationFilter: "",
      organizationItems: [],
      isLoadingOrganziationList: true,
      filteredGroupList: [],

      // variables used for create group dialog
      organizationList: [],
      productList: [],
      createGroupDialog: false,

      overlay: false,
      deviceConfigurationDialog: false,
      groupList: [],
      isTableLoading: true,
      groupInfo: {},
      search: "",
      ungroupedRouters: [],
      singleRouterInfo: {},
      componentCheck: 0,
      manageGroupDevicesDialog: false,
      deviceGroupTableData: [],
      firmwareResponse: {},
      editItem: {},
      editDialog: false,
      routersArray: {},
      firmwareRow: {},
      manageFirmwareDialog: false,
      remoteCommandDialog: false,
      deleteItem: {},
      deletePopup: false,
      routetDevices: [],
      searchActive: false,
      suppHeaders: [
        { text: "Group Name", value: "group_name", class: "headerColor white--text ", sortable: true },
        { text: " Description", value: "group_description", class: "headerColor white--text ", sortable: true },
        { text: "Organization", value: "customer_name", class: "headerColor white--text ", sortable: true },
        { text: "Model ", sortable: true, value: "group_model", class: "headerColor white--text " },
        { text: "# of Devices", sortable: true, value: "registered_routers", class: "headerColor white--text " },
        { text: "# of Online", sortable: true, value: "online_routers", class: "headerColor white--text " },
        { text: "# of Offline", sortable: true, value: "offline_routers", class: "headerColor white--text " },
        { text: "Status", sortable: true, value: "group_status", class: "headerColor white--text " },
      ],
      headers: [
        { text: "Status", sortable: true, value: "group_status", class: "headerColor white--text " },
        { text: "Group Name", value: "group_name", class: "headerColor white--text ", sortable: true },
        { text: "Model ", sortable: true, value: "group_model", class: "headerColor white--text " },
        { text: "Online", sortable: true, value: "online_routers", class: "headerColor white--text " },
        { text: "Offline", sortable: true, value: "offline_routers", class: "headerColor white--text " },
        { text: "Total Devices", sortable: true, value: "registered_routers", class: "headerColor white--text " },
        { text: "Organization", value: "customer_name", class: "headerColor white--text ", sortable: true },
        { text: " Description", value: "group_description", class: "headerColor white--text ", sortable: true },
        { text: "Actions", sortable: true, value: "Actions", class: "headerColor white--text " },
      ],
    };
  },

  async created() {
    await this.getOrganizationList();
    await this.get_group_list();
    this.filterGroupList();
  },

  computed: {
    ...mapGetters(["getterGetUserInfo", "getterGetCurrentUserRole", "getterGetNewDeviceConfigurations"]),
    getDeviceConfigurations() {
      return this.getterGetDeviceConfigurations;
    },
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
    getCurrentGroupID() {
      return this.getterGetUserInfo.user.router_group_id
    },
    getCurrentUserRole() {
      return this.getterGetCurrentUserRole;
    },
    noResultsText() {
      return `No matching results for "${this.search}"`;
    },
    getGroupConfigurations() {
      return this.getterGetNewDeviceConfigurations;
    },
    superAdminCheck() {
      return this.getterGetCurrentUserRole === "SU_ADMIN";
    },
  },

  methods: {
    ...mapActions(["actionGetDeviceConfiguration", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //query call to fetch the list of groups
    async get_group_list() {
      this.isTableLoading = true;
      try {
        const fetchedGroupList = await getGroupList({
          customer_id: this.getCurrentUserCustomerID,
          user_role: this.getCurrentUserRole,
          limit: 100,
          user_id: this.getCurrentUserID,
        });
        this.groupList = fetchedGroupList.filter((group) => group.group_status === "ACTIVE");
        this.filterGroupList();
        this.mixinSetStartTime();
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isTableLoading = false;
      }
    },
    filterGroupList() {
      this.filteredGroupList = this.organizationFilter ? this.groupList.filter(group => group.customer_name === this.organizationFilter) : this.filteredGroupList = this.groupList;
    },

    async getOrganizationList() {
      this.isLoadingOrganziationList = true;
      try {
        // note that `getOrganizationList` and `this.getOrganizationList` are two different functions
        // `getOrganizationList` is an async query function imported from `services` folder
        let fetchedOrganizationList = await getOrganizationList({ organization_status: "ACTIVE" });
        // Extract list of organizations and sort it in alphabetical order
        this.organizationItems = fetchedOrganizationList.map((item) => item.organization_name).sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        });
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isLoadingOrganziationList = false;
      }
    },

    // -------------- Configuration Related --------------------
    async open_group_configuration_dialog(item) {
      this.overlay = true;
      try{
        await this.actionGetDeviceConfiguration(item.router_group_id);
        this.$store.commit("SetDefaultConfig", this.getGroupConfigurations);
        this.$store.commit("SetCongifStatusCheck", true);
        localStorage.setItem("Defaultconfig", JSON.stringify(this.getGroupConfigurations));
        this.$store.commit("SetRouterGroups", true);
        this.singleRouterInfo = item;
        this.deviceConfigurationDialog = true;
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.overlay = false;
      }
    },
    close_group_configuration_dialog() {
      this.deviceConfigurationDialog = false;
    },

    async open_group_configs_dialog(item){
      this.overlay = true;
      this.singleRouterInfo = item;
      try{
        await this.actionGetDeviceConfiguration(item.router_group_id);
        this.deviceConfigDialog = true;
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.overlay = false;
      }
    },
    close_group_configs_dialog() {
      this.deviceConfigDialog = false;
    },


    //method invokes on add action
    async open_create_group_dialog() {
      this.overlay = true;
      try{
        if(this.superAdminCheck) {
          let fetchedOrganizationList = await getOrganizationList({ organization_status: "ACTIVE" });
          fetchedOrganizationList.forEach(organization => {
            this.organizationList.push({ text: organization.organization_name, value: organization.customer_id })            
          })
        }
        this.organizationList.sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
          if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
          return 0;
        });
        const productList = await getProductList();
        productList.forEach((product) => {
          this.productList.push(product.product_sku);
        });
        this.createGroupDialog = true;
      } catch (err){
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.overlay = false;
      }
    },
    async close_create_group_dialog(val) {
      this.createGroupDialog = false;
      // refresh the group list
      if(val === 1){
        await this.get_group_list();
      }
    },

    //method invokes on emit action for display error and success messages
    async close_dialog(val) {
      if (val == 1) {
        this.editDialog = false;
        this.deletePopup = false;
        this.manageGroupDevicesDialog = false;
        this.ungroupedRouters = [];
      } else if (val == 2) {
        this.triggerSnackBar("error", "Please Provide Mandatory Fields");
      } else if (val == 4) {
        this.triggerSnackBar("success", "Updated Successfully");
        this.editDialog = false;
        this.get_group_list();
      } else if (val == 5) {
        this.triggerSnackBar("success", "Deleted Successfully");
        this.deletePopup = false;
        this.get_group_list();
      } else if (val == 6) {
        this.manageGroupDevicesDialog = false;
        this.triggerSnackBar("success", "Update Complete");
        await this.get_group_list();
      } else if (val == 7) {
        this.triggerSnackBar("error", "No changes have been made");
      }
    },

    //method invokes on error action
    error_info(val) {
      this.triggerSnackBar("error", val);
    },

    //method invokes on edit action
    edit_info(item) {
      this.componentCheck = 3;
      this.editItem = item;
      this.editDialog = true;
    },

    // ----------------- related to manage group devices ----------------------
    open_manage_group_devices_dialog(groupInfo) {
      this.groupInfo = groupInfo;
      this.componentCheck = 2;
      this.manageGroupDevicesDialog = true;
    },

    async close_manage_group_devices_dialog(groupObj) {
      if (groupObj.status === 0) {
        this.manageGroupDevicesDialog = false;
      } else if (groupObj.status === 1) {
        this.manageGroupDevicesDialog = false;
        await this.updateGroupList([groupObj.router_group_id]);
      }
    },

    async updateGroupList(changedGroupIdList) {
      try{
        let fetchedUpdatedGroupList = await getUpdatedGroupList({
          user: this.getCurrentUserID,
          groups: changedGroupIdList
        });

        // fetchedUpdatedGroupList.forEach((group) => (group.registered_routers = group.routers_array.length));

        this.groupList = this.groupList.map(group => {
          const matchingItem = fetchedUpdatedGroupList.find(fetchedGroup => fetchedGroup.router_group_id === group.router_group_id);
          return matchingItem || group;
        })
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },
    //method invokes on manage firmware action
    // async manage_firmware(item) {
    //   await this.get_module_info(item);
    //   this.manageFirmwareDialog = true;
    // },

        //query call to get specific  model configuration
    // async get_module_info(item) {
    //   this.overlay = true;
    //   try {
    //     let result = await API.graphql(
    //       graphqlOperation(get_particular_configuration, {
    //         input: {
    //           model_no: item.group_model,
    //         },
    //       })
    //     );
    //     var response = JSON.parse(result.data.get_particular_configuration);
    //     var res = response.Items[0];
    //     this.firmwareResponse = res;
    //     this.firmwareRow = item;
    //     this.manageCheck = true;
    //     this.componentCheck = 4;
    //     this.overlay = false;
    //   } catch (error) {
    //     console.log(error);
    //     this.overlay = false;
    //     this.componentCheck = 4;
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       SnackbarText: error.errors[0].message,
    //       timeout: 5000,
    //       Top: true,
    //     };
    //   }
    // },

    // method invokes on remote action
    remote_command(item) {
      this.routersArray = item;
      this.componentCheck = 6;
      this.remoteCommandDialog = true;
    },

    //method invokes on emit actions for error  and success desplays
    close_dialog_remote(val) {
      if (val == 0) {
        this.remoteCommandDialog = false;
        this.componentCheck = 6;
      } else if (val == 1) {
        this.triggerSnackBar("error", "Please Select Command Type");
      } else if (val == 2) {
        this.triggerSnackBar("success", "Updated Successfully");
        this.remoteCommandDialog = false;
        this.componentCheck = 6;
      }
    },

    //method invokes on emit actions for error  and success desplays
    close_firm(val) {
      if (val == 0) {
        this.manageFirmwareDialog = false;
      } else if (val == 1) {
        this.manageFirmwareDialog = false;
        this.triggerSnackBar("success", "Router Group Firmware Scheduled");
      }
    },

    //method invokes on delete action
    delete_item(item) {
      this.deletePopup = true;
      this.componentCheck = 5;
      this.deleteItem = item;
    },

    clear_value() {
      this.search = "";
    },
    //Method to refresh router list
    async refresh_list() {
      this.isTableLoading = true;
      this.mixinSetStartTime();
      //Gene 3/2/6/23
      //Find refresh button and remove focus from it after it is clicked
      document.getElementById("refresh").blur();
      await this.get_group_list();
    },
  },
};
</script>

<style>
.button.v-btn {
  text-transform: none;
}
.v-input.search-bar {
  width: 400px;
  margin-top: 25px;
}

.v-icon.magnify-btn {
  color: #0195d2;
}
</style>

<style scoped>
.search-bar >>> .v-input__slot::before,
.container-wrapper .search-bar >>> .v-input__slot {
  border-color: #0195d2 !important;
  color: #0195d2 !important;
}

.v-application .search-bar >>> .mdi-close {
  color: grey !important;
}
</style>
