import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"overflow-hidden":"","persistent":"","max-width":"600px","scrollable":""},model:{value:(_vm.ScheduleListDialog),callback:function ($$v) {_vm.ScheduleListDialog=$$v},expression:"ScheduleListDialog"}},[_c('DialogCard',{attrs:{"modalIcon":'calendar-clock',"modalTitle":'Schedule List'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.close_tod_schedule_list_dialog()}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]},proxy:true},{key:"body",fn:function(){return [_c(VForm,{ref:"form"},[_c('div',[_c(VSwitch,{ref:"Enable",staticClass:"form-text-box d-inline-block mb-6",attrs:{"dense":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('transition-group',{staticClass:"radio-button-enable-disable-container",attrs:{"name":"fade","tag":"div"}},[(_vm.Enable)?_c('div',{key:"Enabled"},[_vm._v("Enabled")]):_c('div',{key:"Disabled"},[_vm._v("Disabled")])])]},proxy:true}]),model:{value:(_vm.Enable),callback:function ($$v) {_vm.Enable=$$v},expression:"Enable"}})],1),_c(VSelect,{staticClass:"mb-2",attrs:{"outlined":"","dense":"","hide-details":"","label":'Start Hour',"items":_vm.hoursList},model:{value:(_vm.StartTime),callback:function ($$v) {_vm.StartTime=$$v},expression:"StartTime"}}),_c(VSelect,{attrs:{"outlined":"","dense":"","hide-details":"","label":'End Hour',"items":_vm.hoursList},model:{value:(_vm.EndTime),callback:function ($$v) {_vm.EndTime=$$v},expression:"EndTime"}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c(VBtn,{staticClass:"blue--text",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.close_tod_schedule_list_dialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.save_tod_schedule_list()}}},[_vm._v("Save")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }