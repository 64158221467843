import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.subscriptionDialog),callback:function ($$v) {_vm.subscriptionDialog=$$v},expression:"subscriptionDialog"}},[_c(VCard,[_c(VCardText,{staticClass:"justify-center pt-3"},[_c('h3',{staticClass:"font-weight-black mb-3 black--text"},[_vm._v("Unsubscribe")]),_c('p',{staticClass:"content-font font-weight-medium"},[_vm._v("Are you sure you’d like to stop using the Jextream MDM service? Unsubscribing will permanently remove your account and license information. To use JEXtream MDM again, you will need a new license.")])]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"button tabledata--text",attrs:{"small":""},on:{"click":function($event){return _vm.close_subscription_dialog()}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"button tabledata--text",attrs:{"small":"","loading":_vm.isDeletingUser || _vm.isFetchingUserDevices},on:{"click":function($event){return _vm.submit_unsubscribe()}}},[_vm._v("Agree and Continue")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }