import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("DDNS")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Dynamic DNS(DDNS) allows you to direct your domain or a subdomain to a resource that is behind a gateway that has a dynamically assigned IP address. You can be embedded a popular DDNS service for your purpose. ")])]),_c('div',{staticClass:"configurationCard"},[_c(VCheckbox,{attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Enable")])]},proxy:true}]),model:{value:(_vm.dynamicEnabled),callback:function ($$v) {_vm.dynamicEnabled=$$v},expression:"dynamicEnabled"}})],1),(this.dynamicEnabled == true)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c(VSelect,{attrs:{"dense":"","outlined":"","label":"Service Provider","item-text":"text","item-value":"value","items":_vm.serviceProviderItems},model:{value:(_vm.serviceProvider),callback:function ($$v) {_vm.serviceProvider=$$v},expression:"serviceProvider"}}),_c(VTextField,{attrs:{"label":"Domain Name","outlined":"","dense":""},model:{value:(_vm.domainName),callback:function ($$v) {_vm.domainName=$$v},expression:"domainName"}}),_c(VTextField,{attrs:{"label":"UserName","outlined":"","dense":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c(VTextField,{attrs:{"label":"Password","type":_vm.isPwd ? 'text' : 'password',"append-icon":_vm.isPwd ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":""},on:{"click:append":function($event){_vm.isPwd = !_vm.isPwd}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }