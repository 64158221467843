<template>
  <div>
    <v-layout class="justify-center">
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <div v-if="pwdCheck == 0">
        <v-card class="justify-start mt-4">
          <v-card-title>Change Your Password</v-card-title>
          <v-card-text>
            <v-row v-if="!passwordValid" justify="start" no-gutters>
              <v-col>
                Enter your current password
                <v-text-field dense class="mt-6" style="width: 400px" v-model="oldPwd" label="Current Password" outlined @keyup.enter="check_password_validity()"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else justify="start" no-gutters>
              <v-col>
                Enter your new password
                <v-text-field dense class="mt-6 mb-0" v-model="newPwd" style="width: 400px" label="New Password" outlined maxlength="20" @keyup.enter="valid_password()"></v-text-field>
                <v-text-field dense class="mt-n2" v-model="confirmPwd" style="width: 400px" label="Confirm Password" outlined maxlength="20" @keyup.enter="valid_password()"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="passwordValid" class="justify-end mr-2">
            <v-btn small depressed class="blue--text mt-n4 ml-2 mb-2 normal-text" color="white" outlined @click="previous_step()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="valid_password()"> Submit </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="justify-end mr-2">
            <v-btn small depressed class="blue--text mt-n4 ml-2 mb-2 normal-text" color="white" outlined @click="previous_step()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="check_password_validity()"> Next </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import { Auth } from "aws-amplify";
export default {
  props: {
    emailId: String,
  },
  components: {
    SnackBar,
  },
  data() {
    return {
      confirmnewPwd: "",
      pwdCheck: 0,
      newPwd: "",
      oldPwd: "",
      confirmPwd: "",
      SnackBarComponent: {},
      loading: false,
      passwordValid: false,
    };
  },
  methods: {
    //method invokes on cancel action to move to previous step
    previous_step() {
      var toggle = 0;
      this.$emit("previousStep", toggle);
    },

    //method to check password fields are not empty
    valid_password() {
      var valid = true;
      if (this.newPwd.trim() == "" || this.oldPwd.trim() == "") {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please Provide Data",
          timeout: 5000,
          Top: true,
        };
      } else if (this.newPwd.length < 8) {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Password Must Be Atleast 8 Characters",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        this.update_password();
      }
    },

    //Gene 7/18/23 Check if initial password is valid
    async check_password_validity() {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.emailId, this.oldPwd);
        this.passwordValid = true;
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Password does not match our records.",
          timeout: 5000,
          Top: true,
        };
        this.passwordValid = false;
        this.loading = false;
      }
    },

    //Gene 7/18/23 Update password with new value
    async update_password() {
      var passwordMismatch = false;

      if (this.newPwd != this.confirmPwd) {
        passwordMismatch = true;
      }

      try {
        this.loading = true;
        if (passwordMismatch) {
          throw new Error("Passwords do not match");
        }
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.oldPwd, this.newPwd);

        this.loading = false;
        this.pwdCheck = 2;
        var text = 3;
        this.$emit("successMsg", text);
        this.success();
      } catch (err) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: err.message,
          timeout: 5000,
          Top: true,
        };
        this.loading = false;
      }
    },
    //method invokes on success
    success() {
      var toggle = 0;
      this.$emit("previousStep", toggle);
    },
  },
};
</script>
