<template>
  <v-app class="split-background">
    <v-main class="d-flex align-center split-background">
      <v-row align="center">
        <v-col cols="6" align="center">
          <v-card-title class="py-0">
            <v-img max-width="240px" src="@/assets/jextream-log-mdm.png" class="ml-auto mr-auto"></v-img>
          </v-card-title>
          <v-card class="white mt-4 ml-2 login-card">
            <v-card-text>
              <b>Login</b>
              <v-window v-model="loginWindowIndex">
                <!-- Log in form -->
                <v-window-item :value="1">
                  <v-form ref="form" lazy-validation class="pa-0">
                    <v-card-text>
                      <v-text-field outlined class="ma-2 login-text-field" filled dense label="Email*" @keydown.space="prevent_leading_space" @keyup.enter="validate()" v-model="userName" :rules="mixinLoginEmailRule()" required prepend-inner-icon="mdi-email" type="text" color="blue" clearable />
                      <v-text-field outlined class="ma-2 login-text-field" filled dense @keyup.enter="validate()" @keydown.space="prevent_leading_space" :rules="[(v) => !!v || 'Password is required']" :type="isPwd ? 'text' : 'password'" @click:append="isPwd = !isPwd" id="password" label="Password*" v-model="password" required prepend-inner-icon="mdi-key" :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'" color="blue" clearable/>
                      <v-row class="forgot-password-row">
                        <v-btn text small class="forgot-password-btn" :disabled="loginWindowIndex === 3" depressed @click="loginWindowIndex++">Forgot Password?</v-btn>
                      </v-row>
                      <v-row class="mt-2 ml-1 container pb-0">
                        <v-checkbox class="mt-n2 mr-2" v-model="checkbox"> </v-checkbox>
                        <p class="inline-no-mt width-size">
                          I've read and will comply with the terms of
                          <a href="https://www.jextream.net/terms-of-use" target="_blank" class="text-link">Jextream MDM agreement </a>
                        </p>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="button-corner mb-0 pb-0">
                      <v-row dense align="center" class="login-row">
                        <v-col dense>
                          <v-btn shaped v-if="this.checkbox != true" class="ml-2 mr-2 statustext--text alerts login-btn" :loading="loading" :loader-color="loading ? '#ffffff' : null" width="292" color="button" disabled @click="validate()">Login</v-btn>
                          <v-btn shaped v-else class="ml-2 mr-2 statustext--text alerts login-btn" :loading="loading" width="292" color="button" @click="validate()">Login</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>

                    <v-card-actions class="py-0 mt-0">
                      <v-row dense class="mt-0">
                        <v-col dense class="py-0 container">
                          <p class="mb-0">Don't have an account?</p>
                          <v-btn text class="text-link ml-n2 mt-0" depressed to="/SignUp">Sign Up</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-window-item>

                <!-- Forgot password enter email form -->
                <v-window-item :value="2">
                  <v-form ref="form2" lazy-validation>
                    <div style="color: black" class="title font-weight-light mb-2">Forgot Password?</div>
                    <div class="pt-6 pl-4 black--text" align="left" style="font-size: 12px">Enter the Registered Email</div>
                    <v-card-text>
                      <v-text-field outlined filled dense label="Email" @keydown.space="prevent_leading_space" @keydown.enter="preventRefresh" @keyup.enter="onClickNext()" v-model="resetEmail" :rules="mixinLoginEmailRule()" required class="black--text" color="black" style="font-size: 13px; color: black"></v-text-field>
                      <div align="left" class="black--text text--darken-3" style="font-size: 10px; color: black">*Please enter the e-mail used for login/registration</div>
                    </v-card-text>
                  </v-form>
                  <v-card-actions class="button-corner">
                    <v-btn :disabled="loginWindowIndex === 1" text class="text-capitalize" color="#64B5F6" @click="backLogin()">BACK</v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="#64B5F6" text class="text-capitalize" :disabled="loginWindowIndex === 3" depressed @click="onClickNext()">NEXT</v-btn>
                  </v-card-actions>
                </v-window-item>

                <!-- Forgot password code activation form -->
                <v-window-item :value="3">
                  <v-form ref="form3" lazy-validation>
                    <div class="text-center">
                      <h3 class="title font-weight-light black--text mb-2">Forgot Password?</h3>
                      <v-card-text>
                        <div align="left" class="black--text text--darken-3 mb-3" style="font-size: 10px">*Enter the Activation Code sent to your registered email</div>
                        <v-text-field outlined dense filled label="Code" color="black" @keydown.enter="preventRefresh" @keyup.enter="submit_reset_password()" @keydown.space="prevent_leading_space" v-model="activationCode" style="font-size: 13px; color: black" maxlength="6" :rules="mixinMfaCodeRule()"></v-text-field>

                        <div align="left" style="font-size: 10px" class="caption black--text text--darken-1 mb-3">*Please enter a new password for your account</div>
                        <v-text-field outlined filled dense label="Password" :append-icon="isPwd1 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd1 ? 'text' : 'password'" @click:append="isPwd1 = !isPwd1" v-model="newPwd" @keydown.space="prevent_leading_space" @keyup.enter="submit_reset_password()" color="black" style="font-size: 13px" :rules="mixinLoginPasswordRule()"></v-text-field>
                        <v-text-field outlined filled dense label="Confirm Password" :append-icon="isPwd4 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd4 ? 'text' : 'password'" v-model="confirmNewPwd" @click:append="isPwd4 = !isPwd4" @keydown.space="prevent_leading_space" @keyup.enter="submit_reset_password()" color="black" style="font-size: 13px" :rules="mixinLoginPasswordRule()"></v-text-field>
 
                      </v-card-text>
                    </div>
                    <v-card-actions class="button-corner">
                      <v-btn :disabled="loginWindowIndex === 1" text class="text-capitalize" color="#64B5F6" @click="loginWindowIndex--">BACK</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="#64B5F6" text class="text-capitalize" depressed @click="submit_reset_password()">SUBMIT</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>

                <!-- Enter new password form -->
                <!-- <v-window-item :value="4">
                  <v-form ref="form9" lazy-validation>
                    <div class="text-center">
                      <h3 class="title font-weight-light mb-2 black--text">Create a new password</h3>
                      <v-card-text>
                        <v-text-field outlined filled dense label="Password" :append-icon="isPwd1 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd1 ? 'text' : 'password'" @click:append="isPwd1 = !isPwd1" v-model="newPwd" @keydown.space="prevent_leading_space" @keyup.enter="reset_password()" color="black" style="font-size: 13px; color: black"></v-text-field>
                        <v-text-field outlined filled dense label="Confirm Password" :append-icon="isPwd4 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd4 ? 'text' : 'password'" v-model="confirmNewPwd" @click:append="isPwd4 = !isPwd4" @keydown.space="prevent_leading_space" @keyup.enter="reset_password()" color="black" style="font-size: 13px; color: black"></v-text-field>
                        <div style="font-size: 10px" align="left" class="caption black--text text--darken-1">*Please enter a new password for your account</div>
                      </v-card-text>
                    </div>
                  </v-form>
                  <v-card-actions class="button-corner">
                    <v-btn :disabled="loginWindowIndex === 1" text class="text-capitalize" color="#64B5F6" @click="loginWindowIndex--">BACK</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="#64B5F6" text class="text-capitalize" depressed @click="reset_password()">RESET PASSWORD</v-btn>
                  </v-card-actions>
                </v-window-item> -->

                <v-window-item :value="5">
                  <v-form ref="form5" lazy-validation>
                    <div class="text-center">
                      <h3 class="title font-weight-light mb-2 black--text">Change Temporary Password</h3>
                      <v-card-text>

                        <v-text-field outlined filled dense :append-icon="isPwd2 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd2 ? 'text' : 'password'" name="input-10-1" hint="At least 8 characters(Special Characters,Numbers and Capital letters)" @keydown.space="prevent_leading_space" @click:append="isPwd2 = !isPwd2" v-model="createNewPassword" label="Enter New Password" minlength="8" maxlength="15" required :lazy-validation="false" color="black" style="font-size: 13px; color: black"></v-text-field>

                        <v-text-field outlined filled dense v-model="confirmNewPassword" @keydown.space="prevent_leading_space" :append-icon="isPwd3 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd3 ? 'text' : 'password'" name="input-10-1" hint="At least 8 characters(Special Characters,Numbers and Capital letters)" @click:append="isPwd3 = !isPwd3" label="Confirm New Password" required color="black" minlength="8" maxlength="15" style="font-size: 13px; color: black" :lazy-validation="false"></v-text-field>

                        <div align="left" class="caption black--text text--darken-1">*Please enter a new password for your account</div>
                      </v-card-text>
                    </div>
                    <v-card-actions class="button-corner">
                      <v-spacer></v-spacer>
                      <v-btn color="#64B5F6" text @click="valid_password()" :loading="loading1" depressed>Confirm</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>

                <!-- MFA login form -->
                <v-window-item :value="6">
                  <v-form ref="form3" @submit.prevent>
                    <div class="text-center">
                      <h3 class="title font-weight-light mb-2 black--text">Multi-Factor Authentication</h3>
                      <v-card-text>
                        <v-text-field ref="mfaCode" @keyup.enter="multi_factor_auth_login()" outlined filled dense v-model="mfaPassword" label="Enter your 6 digit MFA token" maxlength="6" :rules="mixinMfaCodeRule()" style="font-size: 13p"></v-text-field>
                      </v-card-text>
                    </div>
                    <v-card-actions class="button-corner">
                      <v-btn class="blue--text" outlined @click="back_from_multi_factor_login()" depressed>Go Back</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn class="button tabledata--text" @click="multi_factor_auth_login()" :loading="loading1" depressed>Login</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>

              </v-window>
            </v-card-text>
          </v-card>

          <!-- Bottom Device support form -->
          <v-row dense class="supported-devices">
            <v-col>
              <v-img max-width="200px" src="@/assets/jextreamDevices.png"></v-img>
            </v-col>
            <v-col>
              <ul class="supported-device-list">
                <b class="grey--text text--darken-2">Jextream* Supported Devices:</b>
                <li>
                  <p class="li-column mb-0 grey--text text--darken-3">RG2100</p>
                  <em class="em-column grey--text text--darken-1">(T-Mobile)</em>
                </li>
                <li>
                  <p class="li-column mb-0 grey--text text--darken-3">RG2102</p>
                  <em class="em-column grey--text text--darken-1">(AT&T / Enterprise only)</em>
                </li>
                <li>
                  <p class="li-column mb-0 grey--text text--darken-3">RG2100N</p>
                  <em class="em-column grey--text text--darken-1">(C-spire coming soon)</em>
                </li>
                <li>
                  <p class="li-column mb-0 grey--text text--darken-3">CG890</p>
                  <em class="em-column grey--text text--darken-1">(Coming soon)</em>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>

        <!-- Images to the right -->
        <v-col cols="6" align="center" class="pintrac-height">
          <v-card-title class="py-0 mb-3">
            <v-img max-width="260px" src="@/assets/pintrac-logo.png" class="ml-auto mr-auto"></v-img>
          </v-card-title>
          <v-card style="background-color: transparent" class="elevation-0">
            <v-card-text>
              <v-img max-width="450px" src="@/assets/pintrac-devices.png" class="ml-auto mr-auto"></v-img>
              <div class="text-align">
                <p class="pintrac-login-text mt-12 mb-3">Want to access one of the <em>LTE devices</em> shown above?</p>
              </div>
              <div class="text-align pintrac-login-text">
                Access the <b><a class="pintrac-login-link" href="https://mdm.pintracview.com" target="_blank"> Pintrac MDM (LTE Devices Portal)</a> </b> to get started
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils'
import { mixin_routing_utils } from '@/mixins/routingUtils'
import { Auth } from "aws-amplify";
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  mixins: [ mixin_form_validation_utils, mixin_routing_utils ],
  data: () => ({
    mfaPassword: "",
    loading: false,
    checkbox: true,
    loginWindowIndex: null,
    loading1: false,
    isPwd: false,
    isPwd4: false,
    isPwd1: false,
    isPwd2: false,
    isPwd3: false,
    userName: "",
    password: "",
    createNewPassword: "",
    confirmNewPassword: "",
    resetEmail: "",
    activationCode: "",
    newPwd: "",
    confirmNewPwd: ""
  }),
  created() {
    this.$store.commit("SetPopupAppeared", false);
  },
  computed: {
    ...mapGetters(["getterGetMfaLoginStatus"])
  },
  methods: {
    ...mapActions(["actionSetMfaFailedLoginAttempt"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationSetMfaLoginStatus"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    prevent_leading_space(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },
    backLogin() {
      this.resetEmail = "";
      this.loginWindowIndex--;
    },
    // Valid funtion to validate if inputs are empty
    validate() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        if (this.userName.trim() == "" && this.password.trim() == "") {
          this.triggerSnackBar("error", "Please enter your username and password.");
        } else if (this.userName.trim() == "") {
          this.triggerSnackBar("error", "Enter your username.");
        } else if (this.password.trim() == "") {
          this.triggerSnackBar("error", "Enter your password.");
        }
      }
    },

    // Unused code. This may be required later down the line when the code is refactored.
    //Gene 7/11/23 Check is user exists
    userExist(email) {
      return Auth.signIn(email.toLowerCase(), "123")
        .then((res) => {
          return false;
        })
        .catch((error) => {
          switch (error.code) {
            case "UserNotFoundException":
            case "UserLambdaValidationException":
              this.triggerSnackBar("error", "Email address could not be found.");
              break;
            case "NotAuthorizedException":
              return true;
            case "PasswordResetRequiredException":
              this.triggerSnackBar("error", "Please reset your password to continue.");
              break;
            case "UserNotConfirmedException":
              this.triggerSnackBar("error", "Your account is not yet confirmed. Please check your email for a verification code to activate your account.");
              break;
            default:
              this.loading = false;
              return false;
          }
          this.loading = false;
          return false;
        });
    },

    // Signin Process
    async SignIn() {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.userName, this.password);
        this.userDetails = user;
        this.user = user;
        if (user.challengeName === "SMS_MFA" || user.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.loginWindowIndex = 6;
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.loginWindowIndex = 5;
          this.triggerSnackBar("success", "The password has been reset successfully.");
          this.createNewPassword = "";
          this.confirmNewPassword = "";
        } else {
          this.$store.commit("SetAuth", true);
          this.$store.commit("SetImpersonateAccount", "");
          this.$store.commit("SetImpersonateAccType", "");
          this.$store.commit("SetImpersonate", false);
          this.$store.commit("SetUserEmail", this.userName.toLowerCase());
          this.triggerSnackBar("success", "Welcome");
          this.$router.push("/Home");
        }
      } catch (error) {
        switch (error.code) {
          case "UserNotFoundException":
          case "UserLambdaValidationException":
            this.triggerSnackBar("error", "Either the account you've entered does not exist or the password is incorrect.");
            break;
          case "NotAuthorizedException":
            if (error.message.startsWith("Password")) {
              this.triggerSnackBar("error", "Maximum number of login attempts exceeded. Please try again later.");
            } else {
              this.triggerSnackBar("error", "Either the account you've entered does not exist or the password is incorrect.");
            }
            break;
          case "PasswordResetRequiredException":
            this.triggerSnackBar("error", "Please reset your password to continue.");
            break;
          case "UserNotConfirmedException":
            this.triggerSnackBar("error", "Your account has not been activated yet. Please check your email for a verification code to activate your account.");
            break;
          default:
            this.triggerSnackBar("error", error);
            break;
        }
      } finally {
        this.loading = false;
      }
    },

    // Function gets invoked on Forgot pw  next click
    onClickNext() {
      this.activationCode = "";
      if (this.$refs.form2.validate()) {
        Auth.forgotPassword(this.resetEmail)
          .then((data) => {
            this.loginWindowIndex++;
          })
          .catch((err) => {
            this.triggerSnackBar("error", "Maximumm number of attempts exceeded. Please try again later.");
          });
      } else {
        this.triggerSnackBar("error", "Please provide a valid email address.");
      }
    },
    async submit_reset_password(){
      if(!this.$refs.form3.validate()){
        this.triggerSnackBar("error", "Enter all the mandatory fields.");
        return;
      }
      if (this.newPwd != this.confirmNewPwd) {
        this.triggerSnackBar("error", "The passwords do not match.");
        return;
      }
      try{
        const response = await Auth.forgotPasswordSubmit(this.resetEmail, this.activationCode, this.newPwd);
        this.triggerSnackBar("success", "The password has been reset successfully.");
        this.loginWindowIndex = 1;
        // reset variable values to empty string
        this.resetEmail = "";
        this.activationCode = "";
        this.newPwd = "";
      } catch(err){
        this.triggerSnackBar("error", err.message);
      }
    },

    //Confirm pw  validation
    valid_password() {
      var valid = true;
      if (this.createNewPassword.trim() == "" || this.confirmNewPassword.trim() == "") {
        valid = false;
        this.triggerSnackBar("error", "Please enter a valid password.");
      }
      if (valid) {
        this.ConfirmPassword();
      }
    },
    // Check MFA validity for login. If valid, send post login request to the appsync server
    async multi_factor_auth_login() {
      const isMfaValid = this.$refs.mfaCode && this.$refs.mfaCode.validate();
      this.mutationSetMfaLoginStatus(Date.now());
      const loginStatus = this.getterGetMfaLoginStatus;
      if(!isMfaValid){
        this.triggerSnackBar("error", "Wrong code format! Please enter a valid 6 digit code.");
        return;
      }
      if(!loginStatus.isLoginAvailable){
        this.triggerSnackBar("error", `Login failed due to too many failed login attempts. You have ${loginStatus.timeLeft} min(s) left until you can try again.`);
        return;
      }
      this.loading1 = true;
      try {
        const loggedUser = await Auth.confirmSignIn(this.userDetails, this.mfaPassword, "SOFTWARE_TOKEN_MFA");
        this.triggerSnackBar("success", "Welcome");
        this.$store.commit("SetAuth", true);
        this.$store.commit("SetUserEmail", this.userName);
        this.mixinChangeRoute("/Home");
      } catch (err) {
        if(err.code === "CodeMismatchException") {
          this.mutationSetMfaLoginStatus(Date.now());
          this.actionSetMfaFailedLoginAttempt();
          this.triggerSnackBar("error", `Code Mismatch! You have ${this.getterGetMfaLoginStatus.loginAttemptsLeft} attempts left.`);
        } else {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
        }
      } finally {
        this.loading1 = false;
      }
    },
    back_from_multi_factor_login(){
      this.loginWindowIndex = 1;
      this.loading = false;
    },
    //Function gets invoked when user sets new pw
    async ConfirmPassword() {
      if (this.createNewPassword === this.confirmNewPassword) {
        try {
          this.loading1 = true;
          const result = await Auth.completeNewPassword(this.user, this.confirmNewPassword);
          this.triggerSnackBar("success", "Welcome");
          this.$store.commit("SetAuth", true);
          this.$store.commit("SetLoginStatus", true);
          this.$store.commit("SetUserEmail", this.userName);
          this.$router.push("/Home");
          this.createNewPassword = "";
          this.confirmNewPassword = "";
        } catch (error) {
          this.triggerSnackBar("error", error.message)
        } finally {
          this.loading1 = false;
        }
      } else if (this.createNewPassword.trim() != this.confirmNewPassword.trim()) {
        this.triggerSnackBar("error", "The passwords do not match.")
      }
    },
    preventRefresh(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.login-text-field {
  font-size: 14px;
  color: black;
  width: 90%;
  max-width: 550px;
  border-top: 0px;
}

.login-card {
  max-width: 596px;
  width: auto;
  height: auto;
}

.login-text-field .v-input__control .v-input__slot fieldset {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  border-width: 2px;
}

.login-text-field .v-input__control .v-input__slot .v-text-field__slot .v-label--active {
  left: 0px !important;
}
.login-text-field .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-right: 6px;
}
.login-text-field .v-input__control .v-input__slot .v-input__prepend-inner .v-input__icon .v-icon {
  color: #0195d2;
}
.inline-no-mt {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 100%;
}

.width-size {
  max-width: calc(100% - 34px);
}

.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.container .v-input,
.container .v-input__control {
  height: 24px;
  width: 24px;
}
.forgot-password-row {
  justify-content: end;
  width: 90%;
  max-width: 550px;
}

.forgot-password-row .forgot-password-btn.v-btn,
.forgot-password-row .forgot-password-btn.v-btn:before,
.container .text-link,
.container .text-link:before {
  text-decoration: underline;
  text-transform: none;
  font-weight: 500;
  padding-right: 0px;
  margin-top: -16px;
  background-color: transparent;
  color: #0195d2;
}

.forgot-password-row .forgot-password-btn.v-btn:hover,
.container .text-link:hover {
  color: #003f5a;
}

.supported-devices {
  max-width: 75%;
  margin-top: 2%;
  white-space: nowrap;
}

.supported-device-list {
  text-align: left;
  font-size: 16px;
}
.supported-device-list li {
  margin-left: 12px;
  font-size: 14px;
}

.li-column {
  display: inline-block;
  margin-bottom: 0px;
  width: 35%;
}

.em-column {
  right: 100px;
}
.info-column {
  right: 0px;
}

.full-height {
  height: inherit;
}

.login-row {
  width: 100%;
}

.pintrac-login-text {
  color: white !important;
  font-size: 20px;
  font-weight: 400;
}

.pintrac-login-link {
  color: white !important;
  font-size: 20px;
  font-weight: 600;
}

.pintrac-login-link:hover {
  color: #004c6d !important;
}
.text-align {
  text-align: center;
}

.split-background {
  height: 100vh;
  background: linear-gradient(to right, rgb(248, 248, 248) 50%, #0195d2 50%) !important;
}
.login-row >>> .login-btn {
  width: 50% !important;
}

.login-btn >>> .v-btn__content {
  color: white;
}

.login-btn {
  color: white;
}
</style>
