<template>
  <v-dialog
    overflow-hidden
    v-model="deleteRouterDialog"
    scrollable
    persistent
    max-width="600px"
  >
    <DialogCard :modalIcon="'delete-forever'" :modalTitle="'Delete Devices'">
      <template #header>
        <v-btn icon x-small @click="close_delete_device_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <p class="py-2 red--text font-weight-bold">
          ※Warning: This action cannot be undone.<br /><br />Are you sure you
          want to delete the device(s) below? Deleting the device(s) will
          permanently erase all data and reset the device(s) to factory
          settings.
        </p>
        <div class="reset-table">
          <v-row class="grey darken-2 white--text py-2 gx-3" no-gutters>
            <v-col class="pl-4" cols="6">Device Name</v-col>
            <v-col cols="6">Device Serial Number</v-col>
          </v-row>
          <div class="scrollable">
            <div v-for="device in deviceDeleteList" :key="device.router_id">
              <v-row class="py-2 gx-3" no-gutters>
                <v-col class="pl-4" cols="6">{{ device.router_name }}</v-col>
                <v-col class="font-weight-light" cols="6">{{
                  device.router_id
                }}</v-col>
              </v-row>
              <v-divider class="divider"></v-divider>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <v-btn
          small
          class="blue--text"
          outlined
          @click="close_delete_device_dialog()"
          >Cancel</v-btn
        >
        <v-btn
          small
          :loading="loadingDelete"
          @click="confirm_delete_device()"
          class="button tabledata--text mr-2"
          >Confirm Delete</v-btn
        >
      </template>
    </DialogCard>
  </v-dialog>
</template>
<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { deleteDevices } from "@/services/deviceService";

import { API, graphqlOperation } from "aws-amplify";
import { mapGetters, mapMutations } from "vuex";
import { remove_device_from_subscription } from "@/graphql/mutations/stripeMutations.js";
export default {
  components: {
    DialogCard,
  },
  props: {
    selectedRecords: Array,
    deleteRouterDialog: Boolean,
  },

  data() {
    return {
      loadingDelete: false,
      deviceDeleteList: [],
    };
  },
  watch: {
    deleteRouterDialog: {
      handler() {
        // fill in with new list
        // the array should be mapped with spread operator per each item to copy the item and avoid direct refrencing
        if (this.deleteRouterDialog) {
          this.deviceDeleteList = [];
          this.deviceDeleteList = this.selectedRecords.map((record) => ({
            router_name: record.router_name,
            router_id: record.router_id,
          }));
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    allSelectedOrganizations() {
      return this.selectedRecords[0].organization_name
        ? [
            ...new Set(
              this.selectedRecords.map((records) => records.organization_name)
            ),
          ]
        : null;
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on close actions
    close_delete_device_dialog() {
      this.$emit("close", { status: 0 });
    },

    //mutation call for delete router details
    async confirm_delete_device() {
      let deviceIdList = this.deviceDeleteList.map(
        (device) => device.router_id
      );
      this.loadingDelete = true;
      try {
        await deleteDevices({
          user_id: this.getCurrentUserID,
          router_id: deviceIdList,
        });
        // await API.graphql(
        //   graphqlOperation(remove_device_from_subscription, {
        //     input: {
        //       router_id_array: deviceIdList,
        //       organization_list: this.allSelectedOrganizations,
        //     },
        //   })
        // );
        this.triggerSnackBar("success", "Device deleted successfully!");
        this.$emit("close", {
          status: 1,
          deviceIdList,
        });
      } catch (error) {
        this.triggerSnackBar(
          "error",
          error.errors ? error.errors[0].message : error
        );
      } finally {
        this.loadingDelete = false;
      }
    },
  },
};
</script>
<style>
.reset-table {
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  overflow: hidden;
}
.scrollable {
  height: 360px;
  overflow-y: scroll;
}

.divider {
  border-top: 1px solid #bdbdbd;
}
</style>
