function calculateTimeElapsed(startTime) {
  const seconds = Math.floor((Date.now() - startTime) / 1000);
  // Years
  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  // Months
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  // Days
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  // Hours and Minutes
  interval = seconds / 3600;
  if (interval >= 1) {
    const hours = Math.floor(interval);
    const remainingMinutes = Math.ceil((seconds - hours * 3600) / 60);
    const hourStr = hours === 1 ? "hour" : "hours";
    const minuteStr = remainingMinutes === 1 ? "minute" : "minutes";
    return remainingMinutes > 0 ? `${hours} ${hourStr} ${remainingMinutes} ${minuteStr} ago` : `${hours} ${hourStr} ago`;
  }
  // Minutes
  interval = seconds / 60;
  if (interval >= 1) {
    return `${Math.floor(interval)} ${Math.floor(interval) === 1 ? "minute" : "minutes"} ago`;
  }
  // Recently Updated
  return "Recently Updated";
}

// Format time from minutes back to HHMM format
function formatTime(minutes) {
  const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hours}:${mins}`;
}

// Convert times to minutes for easy comparison and also add names for identifying which schdule item it is from
function formatSchedule(schedule){
  const startTime = parseInt(schedule.StartTime.substring(0, 2)) * 60 + parseInt(schedule.StartTime.substring(2, 4));
  const endTime = parseInt(schedule.EndTime.substring(0, 2)) * 60 + parseInt(schedule.EndTime.substring(2, 4));
  const name = schedule.ScheduleName;
  return { name, startTime, endTime };
}

// Function to check for overlap between two ScheduleList
function isOverlap (schedule1, schedule2){
  return schedule1.endTime > schedule2.startTime && schedule1.startTime < schedule2.endTime;
}

function checkForOverlaps(ScheduleList, newSchedule) {
  // Initialize an object to hold arrays of ScheduleList for each day of the week
  const daySchedules = {
    Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: []
  };

  const newDaySchedule = {
    Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: []
  };

  const overlaps = [];

  // Populate the daySchedules object with the ScheduleList
  ScheduleList.forEach(schedule => {
    schedule.DayOfWeek.split(', ').forEach(day => {
      daySchedules[day].push(formatSchedule(schedule));
    });
  });

  // Populate the newDaySchedule
  newSchedule.DayOfWeek.split(', ').forEach(day => {
    newDaySchedule[day].push(formatSchedule(newSchedule));
  });

  // Check each day for overlaps
  for (const day in daySchedules) {
    const ScheduleListObj = daySchedules[day];
    const newScheduleObj = newDaySchedule[day];
    for (let i = 0; i < ScheduleListObj.length; i++) {
      for(let j = 0; j < newScheduleObj.length; j++) {
        if (isOverlap(ScheduleListObj[i], newScheduleObj[j])) {
          overlaps.push(`Time overlap found on schedule "${ScheduleListObj[i].name}": ${day}, ${formatTime(ScheduleListObj[i].startTime)}-${formatTime(ScheduleListObj[i].endTime)}`);
        }
      }
    }
  }
  return overlaps;
}

export const mixin_time_utils = {
  data() {
    return {
      startTime: 0,
      lastUpdated: "Recently Updated",
      timeInterval: null,
    };
  },
  // Set start time to whenever the component that calls this mixin gets mounted
  mounted() {
    this.startTime = Date.now();
    this.timeInterval = setInterval(() => {
      this.lastUpdated = calculateTimeElapsed(this.startTime);
    }, 60001);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  computed: {
    mixinGetLastUpdatedTime() {
      return this.lastUpdated;
    },
  },
  methods: {
    // This is no longer used for RG2100
    mixinGetCurrentTimePicker(laterFlag = false) {
      let now = new Date();
      let hours;
      if(laterFlag){
        hours = (now.getHours() + 1).toString().padStart(2, '0');
      } else {
        hours = now.getHours().toString().padStart(2, '0');
      }
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    mixinCheckTimeOverlap(ScheduleList, newSchedule){
      return checkForOverlaps(ScheduleList, newSchedule);
    },
    mixinConvertIntDateToString(intDate){
      let dateStr = intDate.toString();
      if(dateStr.length === 3) {
        dateStr = '0' + dateStr;
      }
      return dateStr;
    },
    mixinSetStartTime() {
      this.startTime = Date.now();
    },
    mixinConvertUTCToDate(dateGMTSplit) {
      function pad(x) {
        return ("00" + x).slice(-2);
      }
      if (dateGMTSplit) {
        let b = dateGMTSplit.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()} : ${pad(d.getHours())}:${pad(d.getMinutes())}`;
      } else {
        return "NA";
      }
    },
    mixinGetTimeDifference(userInput) {
      const userTime = new Date(userInput);
      const currentTime = new Date();
      // Check if user input is valid
      if (isNaN(userTime.getTime())) {
        return "Invalid input time";
      }
      // Calculate time difference in milliseconds
      const differenceInMilliseconds = currentTime - userTime;
      // Convert milliseconds to days
      const differenceInDays = Math.round(differenceInMilliseconds / (1000 * 60 * 60 *24));
      return differenceInDays;
    },
  },
};
