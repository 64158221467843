<template>
  <div>
    <v-dialog
      v-model="toggleOrgDialog"
      persistent
      overflow-hidden
      max-width="400px"
    >
      <v-card class="dialogCard cardBg overflow-hidden">
        <v-toolbar class="dialogToolbar cardBg" dense>
          <v-toolbar-title>{{itemCheck == 1 ? 'Deactivate Organization' : 'Activate Organization'}}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-icon @click="close_toggle()" class="ml-2" color="iconCross"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text v-if="itemCheck == 1" class="cardBg pt-6 justify-center">
          Are you sure you want to deactivate
          <b> {{ toggleItem.organization_name }}</b> ?
        </v-card-text>
        <v-card-text v-if="itemCheck == 2"  class="cardBg pt-6 justify-center">
          Are you sure you want to activate
          <b> {{ toggleItem.organization_name }}</b> ?
        </v-card-text>

        <v-card-actions class="button-corner">
          <v-spacer></v-spacer>
          <v-btn class="blue--text" color="button" outlined @click="close_toggle()"
            >Cancel</v-btn
          >
          <v-btn
            v-if="itemCheck == 1"
            class="button tabledata--text"
            :loading="loadingDelete"
            @click="delete_func()"
            >Deactivate</v-btn
          >
           <v-btn
            v-if="itemCheck == 2"
            class="button tabledata--text"
            :loading="loadingDelete"
            @click="delete_func()"
            >Activate</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { edit_organization } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    toggleOrgDialog: Boolean,
    toggleItem: Object,
    itemCheck:Number
  },
  data() {
    return {
      loadingDelete: false,
    };
  },
  methods: {
    close_toggle() {
      this.$emit("clicked", 0);
    },
    async delete_func() {
      this.loadingDelete = true;
      try {
        let result = await API.graphql(
          graphqlOperation(edit_organization, {
            input: {
              customer_id: this.toggleItem.customer_id,
              organization_name: this.toggleItem.organization_name,
              organization_status: this.itemCheck == 1 ?"INACTIVE" : 'ACTIVE',
              color_id: this.toggleItem.color_id,
              partner_id: this.toggleItem.partner_id,
              carrier_id:this.toggleItem.carrier_id,
            },
          })
        );
        if( this.itemCheck == 1){
         this.$emit("clicked", 4);
        }if( this.itemCheck == 2){
         this.$emit("clicked", 5);
        }
       
        
        this.loadingDelete = false;
      } catch (error) {
        this.loadingDelete = false;
        var text = error.errors[0].message;
        this.$emit("errorMsg", text);
      }
    },
  },
};
</script>
