import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/LoginPage.vue";
import SignUp from "@/views/SignUp.vue";
import Home from "@/views/TopBar.vue";
import Dashboard from "@/components/Dashboard/Dashboard.vue";
import Alerts from "@/components/Dashboard/Alerts.vue";
import DataUsage from "@/components/Dashboard/DataUsage.vue";
import DeviceGroupsList from "@/components/Groups/DeviceGroupsList.vue";
import FotaPackage from "@/components/SuperAdminAcces/FotaPackage.vue";
import BulkUpload from "@/components/SuperAdminAcces/BulkUpload.vue";
import AdminLogs from "@/components/EventLogs/AdminLogs.vue";
import AlertLogs from "@/components/EventLogs/AlertLogs.vue";
import PartnersUsers from "@/components/SuperAdminAcces/Accounts/PartnersUsers.vue";
import DashboardSAdmin from "@/components/Dashboard/DashboardSAdmin.vue";
import Config from "@/components/SuperAdminAcces/Config.vue";
import ProductModel from "@/components/SuperAdminAcces/ProductModel.vue";

import BusinessUsers from "@/components/SuperAdminAcces/Accounts/BusinessUsers.vue";
import IndividualUsers from "@/components/SuperAdminAcces/Accounts/IndividualUsers.vue";
import Licenses from "@/components/SuperAdminAcces/Licenses/Licenses.vue";
import DataUsageReport from "@/components/Reports/DataUsageReport.vue";
import AlertLogsReport from "@/components/Reports/AlertLogsReport.vue";
import AdminLogsReport from "@/components/Reports/AdminLogsReport.vue";
import LicenseReport from "@/components/Reports/LicenseReport.vue";
import CarrierReport from "@/components/Reports/CarrierReport.vue";
import PaymentStatus from "@/components/Reports/PaymentStatus.vue";
import Organization from "@/components/SuperAdminAcces/Organizations/SuperOrganization.vue";
import FotaLogs from "@/components/EventLogs/FotaLogs.vue";
import IndividualDeviceList from "@/components/Devices/IndividualDeviceList.vue";

import CustomerDeviceList from "@/components/Devices/CustomerDeviceList.vue";
import SuperIndividualDeviceList from "@/components/Devices/SuperIndividualDeviceList.vue"
import SuperBusinessDeviceList from "@/components/Devices/SuperBusinessDeviceList.vue"

import PricingPage from "@/components/Stripe/PricingPage.vue";

import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    props: true,
    children: [
      {
        name: "Dashboard",
        path: "Dashboard",
        component: Dashboard,
      },
      {
        name: "SDashboard",
        path: "DashboardSAdmin",
        component: DashboardSAdmin,
      },
      {
        name: "Organization",
        path: "Organization",
        component: Organization,
      },
      {
        name: "Licenses",
        path: "Licenses",
        component: Licenses,
      },
      {
        name: "BusinessUsers",
        path: "BusinessUsers",
        component: BusinessUsers,
      },
      {
        name: "IndividualDeviceList",
        path: "IndividualDeviceList",
        component: IndividualDeviceList,
      },
      {
        name: "IndividualUsers",
        path: "IndividualUsers",
        component: IndividualUsers,
      },
      {
        name: "Config",
        path: "Config",
        component: Config,
      },
      {
        name: "ProductModel",
        path: "ProductModel",
        component: ProductModel,
      },
      {
        name: "LicenseReport",
        path: "LicenseReport",
        component: LicenseReport,
      },
      {
        name: "CarrierReport",
        path: "CarrierReport",
        component: CarrierReport,
      },
      {
        name: "PaymentStatus",
        path: "PaymentStatus",
        component: PaymentStatus,
      },
      {
        name: "Alerts",
        path: "Alerts",
        component: Alerts,
      },

      {
        name: "DataUsage",
        path: "DataUsage",
        component: DataUsage,
      },

      {
        name: "CustomerDeviceList",
        path: "CustomerDeviceList",
        component: CustomerDeviceList,
      },
      {
        name: "DeviceGroupsList",
        path: "DeviceGroupsList",
        component: DeviceGroupsList,
      },

      {
        name: "FotaPackage",
        path: "FotaPackage",
        component: FotaPackage,
      },
      {
        name: "BulkUpload",
        path: "BulkUpload",
        component: BulkUpload,
      },

      {
        name: "AdminLogs",
        path: "AdminLogs",
        component: AdminLogs,
      },

      {
        name: "AlertLogs",
        path: "AlertLogs",
        component: AlertLogs,
      },
      {
        name: "FOTALogs",
        path: "FotaLogs",
        component: FotaLogs,
      },

      {
        name: "Users",
        path: "Users",
        component: PartnersUsers,
      },

      {
        name: "DataUsageReport",
        path: "DataUsageReport",
        component: DataUsageReport,
      },

      {
        name: "AdminLogsReport",
        path: "AdminLogsReport",
        component: AdminLogsReport,
      },

      {
        name: "AlertLogsReport",
        path: "AlertLogsReport",
        component: AlertLogsReport,
      },

      {
        name: "SuperBusinessDeviceList",
        path: "SuperBusinessDeviceList",
        component: SuperBusinessDeviceList,
      },

      {
        name: "SuperIndividualDeviceList",
        path: "SuperIndividualDeviceList",
        component: SuperIndividualDeviceList,
      },
      {
        name: "PricingPage",
        path: "PricingPage",
        component: PricingPage,
      }

    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
