<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <div v-if="$store.getters.GetCurrentObj.user.user_role !== 'SU_ADMIN'">
      <v-card flat>
        <v-card-text>
          <span class="text-h6 primary--text"><b class="heading-font">Connections</b></span>
          <General ref="General" @apply="apply" @configError="configErrorCheck"></General>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-card flat>
        <v-card-text>
          <span class="text-h6 primary--text"><b class="heading-font">Connections</b></span>
          <v-tabs v-model="modemTabs">
            <v-tab>General</v-tab>
            <v-tab>APN</v-tab>
            <v-tab-item class="mt-4">
              <General ref="General" @apply="apply" @configError="configErrorCheck"></General>
            </v-tab-item>
            <v-tab-item class="mt-4">
              <Apn @apply="apply"></Apn>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import SnackBar from "@/components/SnackBar.vue";
import General from "@/components/GeneralSettings/ModemTabs/General.vue";
import Apn from "@/components/GeneralSettings/ModemTabs/Apn.vue";
import Misc from "@/components/GeneralSettings/ModemTabs/Misc.vue";
import ControlInfo from "@/components/GeneralSettings/ModemTabs/ControlInfo.vue";

export default {
  components: {
    SnackBar,
    General,
    Apn,
   
   
    Misc,
   
    ControlInfo,
  },
  data() {
    return {
      modemTabs: "General",
      fx20Tabs: "APN",
      SnackBarComponent: {},
      dataObjects: {},
    };
  },
  created() {},

  mounted() {},

  methods: {
    apply() {
      this.$emit("apply");
    },
    general_modified_data() {
      this.$refs.General.modified_data();
    },  
    configErrorCheck(val, source) {
      this.$emit('configError', val, source);
    }
  },
};
</script>
