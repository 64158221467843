<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">TOD(Time of Day) Access</h3>

    <v-row class="mb-4" no-gutters>
      <v-col cols="10">
        <v-text-field ref="ScheduleList" class="list-proxy-text-field" v-model="ScheduleList" :rules="ScheduleListValidationRule()" hidden></v-text-field>
      </v-col>
      <v-col class="d-flex justify-center" cols="2">
        <v-btn small @click="open_schedule_list_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
      </v-col>
    </v-row>

    <v-data-table class="mb-6" show-select single-select dense :items="ScheduleList" :headers="ScheduleListHeaders">
      <template v-slot:item.data-table-select="{ item, index }">
        <v-switch :input-value="item.Enable" @click.stop="change_schedule_list_enable_state(item, index)" hide-details style="margin: auto"></v-switch>
      </template>

      <template v-slot:item.StartTime="{ item }">
        <span>{{ mixinIntegerTimeReturn(item.StartTime) }}~{{ mixinIntegerTimeReturn(item.EndTime) }}</span>
      </template>

      <template v-slot:[`item.Actions`]="{ item, index }">
        <v-menu bottom left content-class="menu-offset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="open_schedule_list_dialog('edit', item, index)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteFromList(item, index)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <!-- <v-sheet height="400">
      <v-calendar ref="calendar" :events="weeklyEvents" :type="'week'" :now="'2022-01-03'" :value="'2022-01-03'" :weekdays="[1, 2, 3, 4, 5, 6, 0]"></v-calendar>
    </v-sheet> -->
    <!-- <ScheduleChart :ScheduleList="ScheduleList"/> -->

    <!-- other form fields -->
    <TODScheduleListDialog :ScheduleListDialog="ScheduleListDialog" :ScheduleListDialogMode="ScheduleListDialogMode" :ScheduleList="ScheduleList" @close="close_schedule_list_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import ScheduleChart from "./SubComponents/ScheduleChart.vue"
import TODScheduleListDialog from "./SubDialogs/TODScheduleListDialog.vue";
import DeleteDialog from "../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    ScheduleChart,
    TODScheduleListDialog,
    DeleteDialog
  },
  data() {
    return {
      ScheduleListHeaders: [
        { text: "Time Range", align: "start", sortable: true, class: "headerColor white--text ", value: "StartTime" },
        { text: "Day of Week", sortable: true, class: "headerColor white--text ", value: "DayOfWeek" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      ScheduleList: [], // your table data

      configPath: "configuration_module.TimeOfDayAccess.Value",

      ScheduleListDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      ScheduleListDialogMode: {},

      weeklyEvents: [],

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initTODAccess();
  },
  watch: {
    // The ScheduleList is artificially bound to v-text-field for a proper form validation
    // Since
    ScheduleList: {
      handler(newVal) {
        this.$nextTick(() => {
          this.mixinUpdateFormField("todAccess.ScheduleList", `${this.configPath}.ScheduleList`, this.ScheduleList);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initTODAccess() {
      const defaultTimeOfDayAccess = this.getterGetNewDeviceConfigurations.configuration_module.TimeOfDayAccess.Value;

      this.ScheduleList = defaultTimeOfDayAccess.hasOwnProperty("ScheduleList") ? [...defaultTimeOfDayAccess.ScheduleList] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("todAccess", "todAccess");
      });
    },
    ScheduleListValidationRule() {
      return [() => this.ScheduleList.length <= 3 || "There cannot be more than 3 schedules"];
    },

    change_schedule_list_enable_state(item, itemIndex) {
      this.ScheduleList.forEach((listItem, listIndex) => {
        if (itemIndex === listIndex) {
          listItem.Enable = item.Enable === 0 ? 1 : 0;
        }
      });
    },
    deleteFromList(item, index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.ScheduleList.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.ScheduleList.forEach((listItem, index) => {
          listItem.Index = index;
        })
      }
    },
    // a method for both edit and add
    open_schedule_list_dialog(mode, item, index) {
      if (this.ScheduleList.length >= 3) {
        this.triggerSnackBar("error", "You cannot have more than 3 schedules");
        return;
      }
      this.ScheduleListDialog = true;
      this.ScheduleListDialogMode = {
        mode,
      };
      if (mode === "edit") {
        this.ScheduleListDialogMode.item = item;
        this.ScheduleListDialogMode.index = index;
      }
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_schedule_list_dialog(dialogObj) {
      if (dialogObj.status === 1) {
        if (dialogObj.mode === "edit") {
          this.$set(this.ScheduleList, dialogObj.index, dialogObj.item);
        } else if (dialogObj.mode === "add") {
          this.ScheduleList.push(dialogObj.item);
        }
      }
      this.ScheduleListDialog = false;
    },
    // -----------------------NOT USED--------------------------
    // This converts hh:mm into a usable format for Vuetify calendar
    // It converts StartTime and EndTime by setting date ISO string with an offset date
    // It first adds the day mapped in "dayMap" to the offset date
    // hours and minutes are then added with setHours function to get the time in ISO format
    convertSchedules(schedules) {
      const dayMap = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6, Sun: 7 };
      const baseDate = new Date(Date.UTC(2022, 0, 2)); // Using the first Monday of 2022 as the base date
      const events = [];
      schedules.forEach((schedule) => {
        const daysOfWeek = schedule.DayOfWeek.split(", ");
        const indexColor = this.getColorByIndex(schedule.Index);
        daysOfWeek.forEach((dayOfWeek) => {
          const dayOffset = dayMap[dayOfWeek];
          const eventDate = new Date(baseDate);
          eventDate.setDate(baseDate.getDate() + dayOffset);

          const startTime = schedule.StartTime;
          const endTime = schedule.EndTime;

          const startHour = parseInt(startTime.substring(0, 2), 10);
          const startMinute = parseInt(startTime.substring(2, 4), 10);
          const endHour = parseInt(endTime.substring(0, 2), 10);
          const endMinute = parseInt(endTime.substring(2, 4), 10);

          const startDate = new Date(eventDate.setHours(startHour, startMinute));
          const endDate = new Date(eventDate.setHours(endHour, endMinute));

          events.push({
            name: schedule.ScheduleName,
            start: this.convertDate(startDate),
            end: this.convertDate(endDate),
            color: indexColor
          });
        });
      });
      return events;
    },
    // convert ISO string to yyyy-mm-dd hh:mm format
    convertDate(date){
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
    },
    getColorByIndex(number) {
      const colors = [ 'red', 'green', 'blue', 'yellow', 'purple', 'lime', 'orange', 'teal', 'gray', 'pink' ];
      const index = Math.abs(number) % 10;
      return colors[index];
    }
  },
};
</script>

<style lang="scss" scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}

::v-deep .v-calendar-daily_head-day-label .v-btn {
  display: none;
}

</style>
