import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Periodic Report Settings ")])]),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c('div',[_vm._v(" Connected Status Report "),_c(VSwitch,{staticClass:"mt-n7",staticStyle:{"margin-left":"250px"},attrs:{"inset":"","label":_vm.connectedStatus == false ? 'Disabled' : 'Enabled'},model:{value:(_vm.connectedStatus),callback:function ($$v) {_vm.connectedStatus=$$v},expression:"connectedStatus"}})],1)]),_c(VCol,{attrs:{"cols":"2"}},[(_vm.connectedStatus == true)?_c(VSelect,{attrs:{"label":"Interval Hours","items":_vm.intervalArray,"outlined":"","dense":"","required":""},model:{value:(_vm.connectedInterval),callback:function ($$v) {_vm.connectedInterval=$$v},expression:"connectedInterval"}}):_vm._e()],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c('div',[_vm._v(" DHCP Lease Report "),_c(VSwitch,{staticClass:"mt-n7",staticStyle:{"margin-left":"250px"},attrs:{"inset":"","label":_vm.dhcpReport == false ? 'Disabled' : 'Enabled'},model:{value:(_vm.dhcpReport),callback:function ($$v) {_vm.dhcpReport=$$v},expression:"dhcpReport"}})],1)]),_c(VCol,{attrs:{"cols":"2"}},[(_vm.dhcpReport == true)?_c(VSelect,{attrs:{"label":"Interval Hours","items":_vm.intervalArray,"outlined":"","dense":"","required":""},model:{value:(_vm.dhcpInterval),callback:function ($$v) {_vm.dhcpInterval=$$v},expression:"dhcpInterval"}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }