import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.listData.length)?_c(VCard,{key:"apn-list",attrs:{"color":"#F3F4F6","flat":"","rounded":"lg"}},[_c(VCardTitle,{staticClass:"text-subtitle-1 pa-3 pb-1"},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.listData),function(listIndexGroup){return _c('div',{key:listIndexGroup.key,staticClass:"history-list my-2"},[_c(VCard,{attrs:{"flat":"","rounded":"lg"}},[_c(VCardTitle,{staticClass:"text-subtitle-2"},[_c(VImg,{staticClass:"mr-2",staticStyle:{"flex":"0 0 auto"},attrs:{"src":require("@/assets/config_list.svg"),"height":"24","width":"24","contain":""}}),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Changes for "+_vm._s(_vm.title)+" Item "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("["+_vm._s(listIndexGroup.index)+"]")])])],1),_c(VCardText,[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((listIndexGroup.diffs),function(diff,index){return _c('div',{key:diff.path},[_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}]}),_c('SingleCard',{attrs:{"type":"array","diff":diff,"propertyNameTable":_vm.mixinConfigArrayNames}})],1)}),0)],1)],1)],1)}),0)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }