<template>
  <div>
    <!-- popup for device detail  -->
    <div v-if="deviceDetailsDialog">
      <v-dialog v-model="deviceDetailsDialog" overflow-hidden persistent fullscreen>
        <v-app-bar flat style="background-color: black" fixed height="70%" width="100%">
          <v-toolbar-title class="white--text">
            Device Detail - {{ getDeviceName }}
            <!-- Refer to /mixin/RouterModelUtil.js for the "shouldShowIMEI" function -->
            <em>({{ shouldShowIMEI(getDeviceModel) ? getDeviceIMEI : getDeviceID }})</em>
          </v-toolbar-title>
          <v-spacer />

          <v-btn @click="close_dialog" icon class="white--text mr-16 pr-6">
            <v-icon class="white--text mr-1">mdi-close</v-icon>
            Back to Device List
          </v-btn>
        </v-app-bar>

        <v-card class="dialogCard overflow-hidden">
          <v-row class="mt-6"></v-row>

          <!-- Device Name, Description part -->
          <v-card class="mt-16 ml-4" flat>
            <v-row no-gutters>
              <v-col cols="9" md="7" class="mt-3 mb-3">
                <v-row no-gutters class="d-flex align-center">
                  <b class="mr-2">Device Name</b>
                  <v-col cols="3" sm="3" class="pa-1">
                    <v-tooltip bottom :nudge-bottom="-30">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense v-model="routerName" outlined v-bind="attrs" v-on="on" class="mb-n6"></v-text-field>
                      </template>
                      <span>{{ routerName }}</span>
                    </v-tooltip>
                  </v-col>
                  <b class="ml-6 mr-2">Description</b>
                  <v-col cols="3" sm="3" class="pa-1">
                    <v-tooltip bottom :nudge-bottom="-30">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind="attrs" v-on="on" v-model="routerDescription" dense outlined class="mb-n6"></v-text-field>
                      </template>
                      <span>{{ routerDescription }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="1" offset="5" offset-sm="0">
                    <v-btn @click="saveDeviceDetails()" class="ml-6" dark small color="cyan"> Save </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" offset="2" offset-md="4" class="d-flex align-center">
                <v-icon @click="refreshDeviceDetails()" class="ml-6" dark large color="cyan"> mdi-refresh </v-icon>
              </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>
          </v-card>

          <v-card class="mt-3 pt-4" flat>
            <v-row class="d-flex">
              <v-col class="mt-n1" col="3" lg="2" align="center">
                <div style="font-size: 15px">
                  <strong>Status</strong>
                </div>

                <v-img class="mt-2" width="150" :src="getDeviceImage"></v-img>

                <div style="font-size: 15px">
                  <b :class="getDeviceOnlineStatus == 'ONLINE' ? 'statusOnline--text' : 'statusOffline--text'">{{ getDeviceOnlineStatus }}</b>
                </div>
              </v-col>

              <!-- Ethernet Status -->
              <v-col v-if="shouldShowEthernetLinkStatus(getDeviceModel)" class="mt-n1 ml-n4" col="3" lg="1" align="center">
                <div style="font-size: 15px; white-space: nowrap">
                  <strong>Ethernet Status</strong>
                </div>
                <v-row class="mt-1" no-gutters>
                  <v-col class="d-flex justify-end align-center mr-2" col="6">
                    <v-icon medium :color="getDeviceLANStatus ? (getDeviceLANStatus.LAN1PortStatus == '1' ? '#4CAF50' : '#F44336') : '#F44336'">mdi-ethernet</v-icon>
                  </v-col>
                  <v-col class="d-flex justify-start align-center ml-2" col="6">
                    <strong style="font-size: 10px">LAN1</strong>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex justify-end align-center mr-2" col="6">
                    <v-icon medium :color="getDeviceLANStatus ? (getDeviceLANStatus.LAN2PortStatus == '1' ? '#4CAF50' : '#F44336') : '#F44336'">mdi-ethernet</v-icon>
                  </v-col>
                  <v-col class="d-flex justify-start align-center ml-2" col="6">
                    <strong style="font-size: 10px">LAN2</strong>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex justify-end align-center mr-2" col="6">
                    <v-icon medium :color="getDeviceLANStatus ? (getDeviceLANStatus.LAN3PortStatus == '1' ? '#4CAF50' : '#F44336') : '#F44336'">mdi-ethernet</v-icon>
                  </v-col>
                  <v-col class="d-flex justify-start align-center ml-2" col="6">
                    <strong style="font-size: 10px">LAN3</strong>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex justify-end align-center mr-2" col="6">
                    <v-icon medium :color="getDeviceLANStatus ? (getDeviceLANStatus.LAN4PortStatus == '1' ? '#4CAF50' : '#F44336') : '#F44336'">mdi-ethernet</v-icon>
                  </v-col>
                  <v-col class="d-flex justify-start align-center ml-2" col="6">
                    <strong style="font-size: 10px">LAN4</strong>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex justify-end align-center mr-2" col="6">
                    <v-icon medium :color="getDeviceLANStatus ? (getDeviceLANStatus.WANPortStatus == 'up' ? '#4CAF50' : '#F44336') : '#F44336'">mdi-ethernet</v-icon>
                  </v-col>
                  <v-col class="d-flex justify-start align-center ml-2" col="6">
                    <strong style="font-size: 10px">WAN</strong>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Signal -->
              <v-col v-if="shouldShowSignal(getDeviceModel)" class="mt-n1 ml-n8" col="3" lg="1" align="center">
                <div style="font-size: 15px"><strong>Signal</strong></div>
                <div :class="`signal-${getDeviceSignal}`">
                  <i class="icon__signal-strength mt-13 ml-n2">
                    <span class="bar-1"></span>
                    <span class="bar-2"></span>
                    <span class="bar-3"></span>
                    <span class="bar-4"></span>
                    <span class="bar-5"></span>
                  </i>
                </div>
                <div class="mt-2">
                  <strong :class="`signalType-${colorCode}`">{{ getDeviceConnectedStatus ? convert_connectedStatus(getDeviceConnectedStatus.Value.ConnectionType) : "" }}</strong>
                </div>
              </v-col>

              <!-- Battery Level -->
              <v-col v-if="shouldShowBatteryLevel(getDeviceModel)" col="3" lg="1" align="center">
                <div class="ml-n1 mt-n1" style="font-size: 15px">
                  <strong>Battery</strong>
                </div>
                <div v-if="getDeviceBatteryLevel >= 50" class="battery mt-8">
                  <div class="battery-level" :style="{ height: getDeviceBatteryLevel + '%' }"></div>
                </div>

                <div v-if="getDeviceBatteryLevel >= 15 && getDeviceBatteryLevel < 50" class="battery mt-6">
                  <div class="battery-level warn" :style="{ height: getDeviceBatteryLevel + '%' }"></div>
                </div>

                <div v-if="getDeviceBatteryLevel < 15" class="battery mt-8">
                  <div class="battery-level alert" :style="{ height: getDeviceBatteryLevel + '%' }"></div>
                </div>
                <strong>{{ getDeviceBatteryLevel == 0 ? "Offline" : getDeviceBatteryLevel + "%" }}</strong>
              </v-col>

              <!-- Usage -->
              <v-col class="mt-n1" col="3" lg="1" align="center">
                <div style="font-size: 15px; white-space: nowrap" class="ml-3">
                  <strong>Usage</strong>
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular class="mt-5" :size="70" :width="10" v-bind="attrs" v-on="on" :value="getDeviceDataUsage" color="#12f112">
                      {{ getDeviceDataUsage.toString().substr(0, 5) }}
                    </v-progress-circular>
                  </template>
                  <span>{{ `${getDeviceDataUsage}(GB)` }}</span>
                </v-tooltip>
                <div>
                  <v-btn plain class="text-decoration-underline ml-n2 mt-n3 no-uppercase" @click="dataUsageChartDialog = !dataUsageChartDialog" color="deep-purple darken-4">
                    <span v-if="getDeviceIsMonthlyUsageChecked === 1">{{ getDeviceMonthlyDataUsageLimit }} GB Total</span>
                    <span v-else> View Details</span>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12" lg="7" class="mt-0">
                <v-card class="ml-4 mt-n1" flat>
                  <v-row no-gutters>
                    <v-col cols="2"><strong>License Status</strong></v-col>
                    <v-col cols="3">
                      <span v-if="!getCustomerAdminCheck" :style="{ color: getLicenseStatus === 'trialing' || getLicenseStatus.toLowerCase() === 'active' ? 'green' : 'red' }">
                        {{ getLicenseStatus }}
                      </span>
                      <v-chip v-else small dark color="red">
                        <span class="caption">NA</span>
                      </v-chip>
                    </v-col>
                    <v-col cols="2">
                      <strong>License Expiration</strong>
                    </v-col>
                    <v-col cols="3">
                      <v-chip v-if="!getCustomerAdminCheck" small dark :color="getLicenseStatus === 'trialing' || getLicenseStatus.includes('rial') || getLicenseStatus.toLowerCase() === 'active' ? getColor('green') : getColor('red')">
                        <span class="caption">{{ mixinConvertUTCToDate(getLicenseExpiration) }}</span>
                      </v-chip>
                      <v-chip v-else small dark color="red">
                          <span class="caption">NA</span>
                      </v-chip>
                    </v-col>
                    <v-col cols="1">
                      <v-btn v-if="getDeviceModel" @click="open_device_configuration_dialog()"
                        :class="{
                          disabled: getLicenseStatus !== 'trialing' && !getLicenseStatus.includes('rial') && getLicenseStatus.toLowerCase() !== 'active',
                        }"
                        dark
                        color="cyan"
                        small>
                        Configuration
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- 1st Row -->
                  <v-row no-gutters class="mt-2">
                    <v-col cols="2">
                      <strong>Serial Number</strong>
                    </v-col>
                    <v-col cols="3">
                      {{ getDeviceID ? getDeviceID.split("/")[1] : "" }}
                    </v-col>
                    <v-col cols="2">
                      <strong>Partner Name</strong>
                    </v-col>
                    <v-col cols="3" class="text_ellipsis">
                      <!-- partner is never provided from the backend -->
                      {{ singleRouterInfo.partner_info ? singleRouterInfo.partner_info.user_full_name : "" }}
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                  </v-row>

                  <!-- 2nd Row -->
                  <v-row no-gutters class="mt-3" v-if="shouldShowIMEI(getDeviceModel)">
                    <v-col cols="2">
                      <strong>IMEI Number</strong>
                    </v-col>
                    <v-col cols="3">{{ getDeviceIMEI }}</v-col>
                    <v-col cols="2">
                      <strong>Group</strong>
                    </v-col>
                    <v-col cols="3" class="text_ellipsis">
                      <!-- Group data does not exist in the fetched source -->
                      {{ getDeviceGroupName }}
                    </v-col>
                  </v-row>

                  <!-- 3rd Row -->
                  <v-row no-gutters class="mt-3">
                    <v-col cols="2">
                      <strong> Last Reported </strong>
                    </v-col>
                    <v-col cols="3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-on="on" v-bind="attrs" small dark :color="mixinGetTimeDifference(getDeviceLastReportDate) >= 1 ? 'grey' : '#4CAF50'">
                            <span class="caption">{{ mixinConvertUTCToDate(getDeviceLastReportDate) }}</span>
                          </v-chip>
                        </template>
                        <span v-if="mixinGetTimeDifference(getDeviceLastReportDate) >= 1">Last reported a day or more ago</span>
                        <span v-else>Last reported within 24hrs</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="2">
                      <b>Organization</b>
                    </v-col>
                    <v-col cols="3" class="text_ellipsis">
                      {{ getDeviceOrganizationName }}
                    </v-col>
                    <v-col cols="2">
                      <v-btn v-if="supportSpeedTest(getDeviceModel)" @click="toggleSpeedtest()" class="blue--text" dark small outlined>Speed Test</v-btn>
                    </v-col>
                  </v-row>

                  <!-- 4rd Row -->
                  <v-row no-gutters class="mt-3">
                    <v-col cols="2">
                      <strong> Config Updated </strong>
                    </v-col>
                    <v-col cols="3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-on="on" v-bind="attrs" small dark :color="getColor(getDeviceReportDateColor)">
                            <span class="caption">{{ mixinConvertUTCToDate(getDeviceConfigUpdatedOn) }}</span>
                          </v-chip>
                        </template>
                        <span v-if="getDeviceReportDateColor === 'red'">The device has never connected to the server</span>
                        <span v-else-if="getDeviceReportDateColor === 'orange'">The device has yet to receive the latest configuration from the server</span>
                        <span v-else-if="getDeviceReportDateColor === 'green'">The configuration is up to date</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
          </v-card>

          <v-row class="mt-6" no-gutters>
            <v-col cols="6">
              <v-card elevation="0">
                <v-toolbar class="elevation-0 mt-n4" dense flat>
                  <v-toolbar-title align="center" class="black--text"> Wi-Fi Details </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <WifiInfo />
                </v-card-text>
              </v-card>
              <v-card elevation="0">
                <v-toolbar class="elevation-0 mt-n0" dense flat>
                  <v-toolbar-title align="center" class="black--text"> WAN Details </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-n2">
                  <DeviceWANStatus />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card elevation="0">
                <v-toolbar class="elevation-0 mt-n4" dense flat>
                  <v-toolbar-title align="center" class="black--text"> Connected Device History </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <ConnectedDeviceHistory />
                </v-card-text>
              </v-card>
            </v-col>

            <v-card elevation="0">
              <DeviceDataUsage :dataUsageChartDialog="dataUsageChartDialog" @clicked="dataUsageChartDialog = !dataUsageChartDialog" />
            </v-card>
          </v-row>
        </v-card>

        <!-- Change Description Modal -->
        <v-dialog overflow-hidden persistent v-model="detailChangeConfirmDialog" max-width="400px">
          <v-card class="cardBg">
            <v-toolbar dense class="medium cardTitle">
              <span>Change Device Details</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="detailChangeConfirmDialog = false">
                <v-icon color="iconCross">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="cardBg pt-6 justify-center">
              Are you sure you want to change Device Name and Description?
              <br />
            </v-card-text>

            <v-card-actions class="button-corner">
              <v-spacer></v-spacer>

              <v-btn @click="confirmChangeDetails()" :loading="detailChangeBtnLoading" dark class="button mr-2">YES</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-dialog>
    </div>

    <!-- components routing based on the action -->
    <!-- <DeviceConfigurationDialog :deviceConfigurationDialog="deviceConfigurationDialog" :singleRouterInfo="singleRouterInfo" v-on:previewDailogMethod="previewDailogMethod" @close="close_device_configuration_dialog" /> -->

    <div v-if="speedTestCheck">
      <SpeedTestDialog :speedTestDialog="speedTestDialog" @clicked="speedTestDialog = false"></SpeedTestDialog>
    </div>
    <div v-if="componentCheck">
      <DiscardDialog :discardChangesDialog="discardChangesDialog" @close="close_discard_dialog"></DiscardDialog>
    </div>
  </div>
</template>

<script>
import DeviceWANStatus from "./DeviceDetailsComponents/DeviceWANStatus.vue";
import SpeedTestDialog from "./DeviceDetailsComponents/SpeedTestDialog.vue";
import DeviceDataUsage from "./DeviceDetailsComponents/DeviceDataUsage.vue";
import ConnectedDeviceHistory from "./DeviceDetailsComponents/ConnectedDeviceHistory.vue";
import WifiInfo from "./DeviceDetailsComponents/WifiInfo.vue";
// import DeviceConfigurationDialog from "@/components/DeviceConfigurations/DeviceConfigurationDialog.vue";

import DiscardDialog from "@/components/DiscardDialog.vue";

import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mixin_time_utils } from "@/mixins/timeUtils";
import { setDeviceDetails } from "@/services/deviceService.js";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  mixins: [router_model_utils, mixin_time_utils],
  props: {
    deviceDetailsDialog: Boolean,
    singleRouterInfo: Object,
  },
  components: {
    DeviceWANStatus,
    DeviceDataUsage,
    ConnectedDeviceHistory,
    // DeviceConfigurationDialog,
    WifiInfo,
    SpeedTestDialog,
    DiscardDialog,
  },
  data: () => ({
    routerName: "",
    routerDescription: "",
    batteryLevel: 0,
    componentCheck: false,
    detailChangeConfirmDialog: false,
    detailChangeBtnLoading: false,
    dataUsageChartDialog: false,
    colorCode: null,
    speedTestCheck: false,
    speedTestDialog: false,
    discardChangesDialog: false,
    deviceConfigurationDialog: false,
  }),
  computed: {
    ...mapGetters(["getterGetDeviceWANStatus", "getterGetDeviceDHCPClients", "getterGetDeviceConfigurations", "getterGetDeviceInfoIsLoaded", "getterGetCurrentUserRole"]),
    getDeviceName() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_name : "";
    },
    getDeviceDescription() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_description : "";
    },
    getRouterActiveStatus() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_activated : "";
    },
    getDeviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    getDeviceIMEI() {
      return this.getterGetDeviceConfigurations.router_details.imei_number;
    },
    getDeviceID() {
      return this.getterGetDeviceConfigurations.router_id;
    },
    getDeviceImage() {
      return this.getterGetDeviceConfigurations.router_details.router_image ? this.getterGetDeviceConfigurations.router_details.router_image : "";
    },
    getDeviceOnlineStatus() {
      return this.getterGetDeviceConfigurations.router_details.online_status;
    },
    getDeviceSignal() {
      return this.getterGetDeviceConfigurations.router_details.signal;
    },
    getDeviceLANStatus() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.LANStatus[0] : "";
    },
    getDeviceConnectedStatus() {
      return this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus;
    },
    getDeviceBatteryLevel() {
      return this.getterGetDeviceConfigurations.router_details.battery_level ? this.getterGetDeviceConfigurations.router_details.battery_level : 0;
    },
    getDeviceDataUsage() {
      return this.getterGetDeviceConfigurations.router_details.data_usage ? this.getterGetDeviceConfigurations.router_details.data_usage : 0;
    },
    getDeviceMonthlyDataUsageLimit() {
      return this.getterGetDeviceConfigurations.configuration_module.Usage ? this.getterGetDeviceConfigurations.configuration_module.Usage.Value.MonthlyLimit / 1024 : 0;
    },
    getDeviceIsMonthlyUsageChecked() {
      return this.getterGetDeviceConfigurations.configuration_module.Usage ? this.getterGetDeviceConfigurations.configuration_module.Usage.Value.MonthlyLimitAlert : 0;
    },
    getDeviceLastReportDate() {
      return this.getterGetDeviceConfigurations.router_details.last_reported_on ? this.getterGetDeviceConfigurations.router_details.last_reported_on : "";
    },
    getDeviceConfigUpdatedOn() {
      return this.getterGetDeviceConfigurations.router_details.config_updated_on ? this.getterGetDeviceConfigurations.router_details.config_updated_on : "";
    },
    getDeviceReportDateColor() {
      return this.getterGetDeviceConfigurations.router_details.indicate_color;
    },

    getDeviceOrganizationName() {
      return this.getterGetDeviceConfigurations.router_details.organization_name ? this.getterGetDeviceConfigurations.router_details.organization_name : "N/A";
    },
    getDeviceGroupName() {
      return this.singleRouterInfo.router_group ? this.singleRouterInfo.router_group : "N/A";
    },
    getLicenseStatus() {
      return this.singleRouterInfo.license_status === "trialing" ? "Free Trial" : this.singleRouterInfo.license_status;
    },
    getLicenseExpiration() {
      return this.singleRouterInfo.expire_date;
    },
    getDeviceGroupName() {
      return this.singleRouterInfo.router_group ? this.singleRouterInfo.router_group : "N/A";
    },
    // This is a temporary script
    // It should be deleted later on when Daniel decides to show license details to regular users again
    // The license info should only be shown to super admin or partner admin for now
    getCustomerAdminCheck() {
      return this.getterGetCurrentUserRole === "C_ADMIN";
    },
  },
  watch: {
    // Keep local variable bindings for editable device information fields updated
    // Notice that this form is mounted only once and will not change routerName or routerDescription everytime the dialog pops up
    getDeviceName: {
      handler(routerName) {
        this.routerName = routerName;
      },
      immediate: true,
    },
    getDeviceDescription: {
      handler(routerDescription) {
        this.routerDescription = routerDescription;
      },
      immediate: true,
    },
    // deviceDetailsDialog: {
    //   handler(){
    //     console.log(this.$doublejson(this.getterGetDeviceConfigurations));
    //     console.log(this.getterGetDeviceWANStatus);
    //     console.log(this.getterGetDeviceDHCPClients);
    //   }
    // }
  },

  methods: {
    ...mapActions(["actionGetSingleDeviceInfo", "actionGetDeviceConfiguration"]),
    ...mapMutations(["mutationSetSnackBarItem", "mutationOpenProgressBar", "mutationCloseProgressBar"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method to fetch the connected status of device
    convert_connectedStatus(statusCode) {
      const WANConnectionType = ["Automatic", "Static Ip", "PPoE", "L2Tp", "PPTP", "CDMA", "GSM", "UMTS", "LTE", "5G"];
      return WANConnectionType[statusCode];
    },

    //This is used in multiple instances in multiple forms and need to be moved to Mixin
    getColor(color) {
      const reportedDateColor = {
        red: "#F44336",
        green: "#4CAF50",
        orange: "#FF9800",
      };
      return reportedDateColor[color];
    },

    // Bring up save dialog for name and description change
    saveDeviceDetails() {
      this.detailChangeConfirmDialog = true;
    },

    // change device name and description
    async confirmChangeDetails() {
      if (this.routerName == "" || this.routerDescription == "") {
        this.triggerSnackBar("error", "Provide Device Details");
        return;
      }
      const descriptionObj = {
        router_id: this.getDeviceID,
        router_name: this.routerName,
        router_description: this.routerDescription,
        router_activated: this.getRouterActiveStatus,
      };
      this.detailChangeBtnLoading = true;
      try {
        await setDeviceDetails(descriptionObj);
        await this.actionGetSingleDeviceInfo(this.getDeviceID);
        await this.actionGetDeviceConfiguration(this.getDeviceID);
        this.triggerSnackBar("success", "Updated device information successfully");
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.detailChangeConfirmDialog = false;
        this.detailChangeBtnLoading = false;
      }
    },

    //method invokes on refresh action
    async refreshDeviceDetails() {
      this.mutationOpenProgressBar();
      try{
        await this.actionGetSingleDeviceInfo(this.getDeviceID);
        await this.actionGetDeviceConfiguration(this.getDeviceID);
        this.routerName = this.getDeviceName;
        this.routerDescription = this.getDeviceDescription;
      } catch(err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.mutationCloseProgressBar();
      }
    },

    // ----------------- configuration -------------------
    open_device_configuration_dialog() {
      this.$emit("openConfigs", this.singleRouterInfo);
      // if (this.getLicenseStatus === "trialing" || this.getLicenseStatus.toLowerCase() === "active" || this.getLicenseStatus.includes("rial")) {
      //   this.clear_store_items();
      //   this.deviceConfigurationDialog = true;
      // } else {
      //   this.triggerSnackBar("error", "Renew subscription to enable configuraiton");
      // }
    },
    // close_device_configuration_dialog() {
    //   this.deviceConfigurationDialog = false;
    // },
    //method invokes on close icon
    // previewDailogMethod(val) {
    //   this.deviceConfigurationDialog = false;
    //   this.$emit("previewDailogMethod", val);
    // },

    //method invokes on close action
    // closes the device details dialog
    close_dialog() {
      this.$emit("close");
    },

    // close_discard_dialog(val) {
    //   if (val == 0) {
    //     this.discardChangesDialog = false;
    //   } else if (val == 1) {
    //     this.componentCheck = false;
    //     this.$emit("clicked", 1);
    //   }
    // },

    //method invokes on speed test action
    toggleSpeedtest() {
      this.speedTestCheck = true;
      this.speedTestDialog = true;
    },

    //method to clear the local store values
    // clear_store_items() {
    //   localStorage.setItem("dhcpServer", false);
    //   localStorage.setItem("dhcpReservation", false);
    //   localStorage.setItem("ipSettings", false);
    //   this.$store.commit("SetFXPortForward", {});
    //   localStorage.setItem("fxPortForward", false);
    //   this.$store.commit("SetDeletedPortForward", []);
    //   localStorage.setItem("deletedPortForward", false);
    //   this.$store.commit("SetDeletedFXPortForward", []);
    //   localStorage.setItem("deletedFXPortForward", false);
    //   this.$store.commit("SetDeletedAccessControl", []);
    //   localStorage.setItem("deletedAccessControl", false);
    //   this.$store.commit("SetDeletedTimeOfDay", []);
    //   localStorage.setItem("deletedTimeOfDay", false);
    //   this.$store.commit("SetDhcpReservation", {});
    //   this.$store.commit("SetDhcpServer", {});
    //   this.$store.commit("SetIPsettings", {});
    //   this.$store.commit("SetWiredLan", false);
    //   this.$store.commit("SetWirelessLan", false);
    //   this.$store.commit("SetAccessControl", {});
    //   localStorage.setItem("accessControl", false);
    //   localStorage.setItem("miscSettings", false);
    //   this.$store.commit("SetMiscSettings", {});
    //   this.$store.commit("SetSystem", false);
    //   localStorage.setItem("operationMode", false);
    //   localStorage.setItem("ntpTimer", false);
    //   this.$store.commit("SetNTPTimer", {});
    //   localStorage.setItem("remoteAdmin", false);
    //   this.$store.commit("SetRouterAdmin", []);
    //   localStorage.setItem("reportData", false);
    //   this.$store.commit("SetReportSets", {});
    //   localStorage.setItem("alertSet", false);
    //   this.$store.commit("SetAlertSets", {});
    //   localStorage.setItem("automacticReebot", false);
    //   localStorage.setItem("totalConnectionCheck", false);
    //   this.$store.commit("SetAutoReboot", {});
    //   this.$store.commit("SetWirelessLan", false);
    //   this.$store.commit("SetGuestNetwork", {});
    //   localStorage.setItem("primaryNetwork", false);
    //   localStorage.setItem("inactiveTime", false);
    //   this.$store.commit("SetFirewall", false);
    //   this.$store.commit("SetFirewallGeneral", {});
    //   localStorage.setItem("firewallGeneral", false);
    //   this.$store.commit("SetUrlFilter", {});
    //   localStorage.setItem("urlFiltering", false);
    //   this.$store.commit("SetContentFilter", {});
    //   localStorage.setItem("contentFilter", false);
    //   this.$store.commit("SetServiceFilter", {});
    //   localStorage.setItem("serviceControl", false);
    //   this.$store.commit("SetInternet", false);
    //   this.$store.commit("SetDataUsage", {});
    //   localStorage.setItem("dataUsage", false);
    //   this.$store.commit("SetPreference", {});
    //   localStorage.setItem("preference", false);
    //   localStorage.setItem("dynamicDns", false);
    //   this.$store.commit("SetInteretObject", {});
    //   this.$store.commit("SetDynamicDns", {});
    //   localStorage.setItem("interntModified", false);
    //   localStorage.setItem("dmzInfo", false);
    //   this.$store.commit("SetDmzObject", {});
    //   localStorage.setItem("natPass", false);
    //   this.$store.commit("SetNATPassthrough", {});
    //   this.$store.commit("SetPortForward", {});
    //   localStorage.setItem("portForward", false);
    //   this.$store.commit("SetFXPortForward", {});
    //   localStorage.setItem("fxPortForward", false);
    //   localStorage.setItem("ipv4Set", false);
    //   this.$store.commit("SetIPV4", {});
    //   localStorage.setItem("ipv6Set", false);
    //   this.$store.commit("SetIPv6Settings", {});
    //   this.$store.commit("SetParentControl", {});
    //   localStorage.setItem("parentalControl", false);
    //   localStorage.setItem("controlInfo", false);
    //   this.$store.commit("SetModemGeneral", {});
    //   localStorage.setItem("General", false);
    //   this.$store.commit("SetSimData", {});
    //   localStorage.setItem("Sim", false);
    //   this.$store.commit("SetApnInfo", {});
    //   localStorage.setItem("Apn", false);
    //   this.$store.commit("SetSmsInfo", {});
    //   localStorage.setItem("Sms", false);
    //   this.$store.commit("SetGpsInfo", {});
    //   localStorage.setItem("Gps", false);
    //   this.$store.commit("SetMiscInfo", {});
    //   localStorage.setItem("Misc", false);
    //   this.$store.commit("SetUpnpInfo", {});
    //   localStorage.setItem("Upnp", false);
    //   localStorage.setItem("guestNetwork", false);
    //   this.$store.commit("SetWirelessLan", false);
    //   this.$store.commit("SetGuestNetwork", {});
    //   localStorage.setItem("primaryNetwork", false);
    //   this.$store.commit("SetTodAcces", {});
    //   localStorage.setItem("Tod", false);
    //   this.$store.commit("SetCaptivePortal", {});
    //   localStorage.setItem("captive", false);
    //   this.$store.commit("SetCongifStatusCheck", false);
    // },
  },
};
</script>

<style scoped>
.v-chip::before {
  display: none;
}
.no-uppercase {
  text-transform: unset !important;
}
.v-progress-circular {
  margin: 1rem;
}
.v-dialog {
  overflow-y: visible !important;
}
</style>
<style lang="scss" scoped>
i {
  border-radius: 4px;
}
i:hover span {
  animation-name: signal-intro;
  animation-play-state: running;
}

/* above for pen only */

$icon-width: auto;
$icon-height: 35px;
$icon-padding: 4px;
$bar-width: 6px;
$bar-spacing: 2px;
$bar-radius: 2px;
$bar-color: #87cb16;
$bar-start-height: 2px;
$bar-start-opacity: 0.2;
$bars-show-all: true; // false shows little nubs
$bars-use-grow-anim: true; // false uses fade anim

.icon__signal-strength {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: $icon-width;
  height: $icon-height;
  padding: $icon-padding;
}
.icon__signal-strength span {
  display: inline-block;
  width: $bar-width;
  margin-left: $bar-spacing;
  transform-origin: 100% 100%;
  background-color: $bar-color;
  border-radius: $bar-radius;

  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.3);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.buttonLabel {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  background: gray;
  margin: 0 4px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon__signal-strength {
  .bar-1 {
    height: 45%;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    width: 8px;
  }
  .bar-2 {
    height: 65%;
    animation-duration: 0.25s;
    animation-delay: 0.2s;
    width: 8px;
  }
  .bar-3 {
    height: 85%;
    animation-duration: 0.2s;
    animation-delay: 0.3s;
    width: 8px;
  }
  .bar-4 {
    height: 105%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;
    width: 8px;
  }
  .bar-5 {
    height: 125%;
    animation-duration: 0.15s;
    animation-delay: 0.4s;
    width: 8px;
  }
}
.signalType-5 {
  color: rgb(255, 131, 16);
}
.signalType-6 {
  color: rgb(255, 131, 16);
}
.signalType-7 {
  color: rgb(255, 131, 16);
}
.signalType-8 {
  color: rgb(135, 203, 22);
}
.signalType-9 {
  color: rgb(65, 203, 236);
}
.signal-0 {
  .bar-1,
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-1 {
  .bar-2,
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-2 {
  .bar-3,
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-3 {
  .bar-4,
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}
.signal-4 {
  .bar-5 {
    opacity: $bar-start-opacity;

    @if $bars-show-all == false {
      height: $bar-start-height;
    }
  }
}

// -- component load animation
@keyframes signal-intro {
  from {
    opacity: $bar-start-opacity;

    @if $bars-use-grow-anim {
      height: $bar-start-height;
    }
  }
}
body {
  padding: 25px 50px;
}

$lightning-size: 18px;

.battery {
  border: 3px solid #333;
  width: 40px;
  height: 50px;
  padding: 8px;
  border-radius: 5px;
  position: relative;
  margin: 15px 0;

  &:before {
    content: "";
    height: 3px;
    width: 18px;
    background: #333;
    display: block;
    position: absolute;
    top: -6px;
    border-radius: 4px 4px 0 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #fff;
    border-radius: 2px;
  }
}

.battery-level {
  background: #87cb16;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;

  &.warn {
    background-color: #efaf13;
  }

  &.alert {
    background-color: #e81309;
  }
}
</style>
