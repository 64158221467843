export const add_multiple_router_to_router_group = /* GraphQL */ `
  mutation Add_multiple_router_to_router_group(
    $input: AddMultipleRouterToRouterGroupInput
  ) {
    add_multiple_router_to_router_group(input: $input)
  }
`;

export const unmap_router_list_from_router_group = /* GraphQL */ `
  mutation Un_map_router_from_router_group(
    $input: UnmapRouterListFromRouterGroupInput
  ) {
    unmap_router_list_from_router_group(input: $input)
  }
`;

export const add_router_group = /* GraphQL */ `
  mutation Add_router_group($input: AddRouterGroupInput) {
    add_router_group(input: $input)
  }
`;