import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Teleport',{attrs:{"to":"#app"}},[_c(VSlideXTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configChangeHistoryPanel),expression:"configChangeHistoryPanel"}],ref:"historypanel",staticClass:"history-panel"},[_c(VCard,{attrs:{"color":"#E7E9ED"}},[_c(VCardTitle,{staticClass:"history-title"},[_c('span',{staticClass:"font-weight-dark text-h6"},[_vm._v("Config History")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"hover":function($event){$event.stopPropagation();},"click":_vm.close_config_change_history_panel}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',{staticClass:"history-content-wrapper"},[_c(VFadeTransition,[(_vm.groupNames.length === 0)?_c('div',{key:"no-data",staticClass:"no-record-box"},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-note-off-outline")]),_c('span',[_vm._v("No Records")])],1):_vm._e()]),_c('div',{staticClass:"history-content"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.groupNames),function(groupName,index){return _c('div',{key:groupName,staticClass:"history-logs",attrs:{"name":"fade"}},[_c('div',{staticClass:"history-list mb-2"},[_c(`${_vm.getterGetCurrentDeviceModel}_${groupName}ChangeCard`,{tag:"component",staticClass:"main-content-height",attrs:{"groupDiffs":_vm.groupDiffs[groupName]}})],1)])}),0)],1)],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }