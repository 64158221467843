import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{style:({ bottom: _vm.snackBarProperties.bottomPosition + 'px' }),attrs:{"color":_vm.snackBarProperties.color,"timeout":_vm.snackBarProperties.timeout,"right":"","height":_vm.snackBarProperties.height,"width":"400"},on:{"input":_vm.removeSnackBarItem},model:{value:(_vm.snackBarProperties.visibility),callback:function ($$v) {_vm.$set(_vm.snackBarProperties, "visibility", $$v)},expression:"snackBarProperties.visibility"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"3"}},[_c(VIcon,{staticStyle:{"display":"block"},attrs:{"size":"40"}},[_vm._v(_vm._s(_vm.snackBarProperties.icon))])],1),_c(VCol,{staticStyle:{"width":"270px"},attrs:{"cols":"9"}},[_c(VRow,{staticClass:"content-top",attrs:{"no-gutters":""}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":_vm.removeSnackBarItem}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VRow,{staticClass:"content-middle pa-2 d-flex align-center",attrs:{"no-gutters":""}},[_vm._v(_vm._s(_vm.snackBarProperties.text))]),_c(VRow,{staticClass:"content-bottom",attrs:{"no-gutters":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }