<template>
  <div>
    <div v-if="getCurrentUserRole === 'SU_ADMIN'">
      <SuperAdminMenu></SuperAdminMenu>
    </div>
    <div v-if="getCurrentUserRole === 'C_ADMIN'">
      <CustomerAdminMenu></CustomerAdminMenu>
    </div>
    <div v-if="getCurrentUserRole === 'SP_ADMIN'">
      <SupportAdmin></SupportAdmin>
    </div>
    <div v-if="getCurrentUserRole === 'P_ADMIN'">
      <Partner></Partner>
    </div>
    <div v-if="getCurrentUserRole === 'G_M_ADMIN'">
      <GroupManager></GroupManager>
    </div>
    <div v-if="getCurrentUserRole === 'INDIVIDUAL'">
      <Individual></Individual>
    </div>
    <div v-if="getCurrentUserRole === 'C_ADMIN_1'">
      <OrgAdmin></OrgAdmin>
    </div>
  </div>
</template>

<script>
import SuperAdminMenu from "@/components/MenuAccess/TopNav/SuperAdminMenu.vue";
import SupportAdmin from "@/components/MenuAccess/TopNav/SupportAdmin.vue";
import Individual from "@/components/MenuAccess/TopNav/Individual.vue";
import OrgAdmin from "@/components/MenuAccess/TopNav/OrgAdmin.vue";
import CustomerAdminMenu from "@/components/MenuAccess/TopNav/CustomerAdminMenu.vue";
import GroupManager from "@/components/MenuAccess/TopNav/GroupManager.vue";
import Partner from "@/components/MenuAccess/TopNav/Partner.vue";

export default {
  components: {
    CustomerAdminMenu,
    SuperAdminMenu,
    OrgAdmin,
    Individual,
    SupportAdmin,
    Partner,
    GroupManager,
  },
  computed: {
    getCurrentUserRole() {
      return this.$store.getters.getterGetCurrentUserRole;
    }
  }
};
</script>
