import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("VPN Passthrough")])]),_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" This item allows a VPN connection to pass through your router to the network clients.")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('div',{staticClass:"configurationCard"},[_c(VSwitch,{attrs:{"dense":"","inset":"","label":_vm.enableOption == true ? 'Enabled' : 'Disabled',"hide-details":""},model:{value:(_vm.enableOption),callback:function ($$v) {_vm.enableOption=$$v},expression:"enableOption"}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }