<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="yellow darken-3">mdi-delta</v-icon>
    </template>
    <span>Note that this configuration setting has yet to be applied to the devices in this group. If you submit this group configuration once, any device added to this group afterwards have group configuration applied automatically.</span>
  </v-tooltip>
</template>

<script>
  import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js';

  export default {
    mixins: [ mixin_form_validation_utils ],
  }
</script>