import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Teleport',{attrs:{"to":"#app"}},[_c(VSlideYReverseTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorListPanel),expression:"errorListPanel"}],ref:"errorListPanel",staticClass:"error-list-panel"},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('div',{staticClass:"error-content-wrapper"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Error List")]),_c(VFadeTransition,[(_vm.getterGetErrorList.length === 0)?_c('div',{key:"no-data",staticClass:"no-record-box"},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-note-off-outline")]),_c('span',[_vm._v("No Errors")])],1):_vm._e()]),_c('div',{staticClass:"error-content"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.getterGetErrorList),function(error,index){return _c('div',{key:error.path,attrs:{"name":"fade"}},[_c('div',{staticClass:"error-card my-2"},[_c('div',{staticClass:"d-flex justify-center align-center red darken-1"},[_c(VIcon,{staticStyle:{"display":"block"},attrs:{"color":"white","size":"30"}},[_vm._v("mdi-close-circle-outline")])],1),_c('div',{staticClass:"white pa-2"},[_c('div',{staticClass:"path-string"},[_vm._v(_vm._s(error.pathString))]),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(error.errorMessage))])]),_c('div',{staticClass:"d-flex justify-center align-center white"},[_c(VIcon,{staticStyle:{"display":"block"},attrs:{"size":"40"},on:{"click":function($event){return _vm.move_to_error_page(error.path)}}},[_vm._v("mdi-chevron-right")])],1)])])}),0)],1)],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }