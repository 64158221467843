import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"blue lighten-2",staticStyle:{"padding-block":"0.2rem","padding-inline":"0.8rem","font-size":"1rem"}},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"white","size":"16"}},[_vm._v("mdi-"+_vm._s(_vm.modalIcon))]),_c('span',{staticClass:"white--text mr-2"},[_vm._v(_vm._s(_vm.modalTitle))]),(_vm.subTitle)?_c('span',{staticClass:"font-italic grey--text text--lighten-3"},[_vm._v("- "+_vm._s(_vm.subTitle))]):_vm._e(),_c(VSpacer),_vm._t("header")],2),_c(VCardText,{staticClass:"pt-5"},[_c(VForm,[_vm._t("body")],2)],1),_c(VCardActions,{staticClass:"pa-3",staticStyle:{"border-top":"1px solid rgb(199, 199, 199)"}},[_c(VSpacer),_vm._t("footer")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }