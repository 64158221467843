<template>
  <v-dialog overflow-hidden v-model="ScheduleListDialog" persistent max-width="600px" scrollable>
    <DialogCard :modalIcon="'calendar-clock'" :modalTitle="'Schedule List'">
      <template #header>
        <v-btn icon x-small @click="close_tod_schedule_list_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <v-form ref="form">
          <div>
            <v-switch ref="Enable" class="form-text-box d-inline-block mb-6" dense v-model="Enable" hide-details>
              <template v-slot:label>
                <transition-group name="fade" tag="div" class="radio-button-enable-disable-container">
                  <div v-if="Enable" key="Enabled">Enabled</div>
                  <div v-else key="Disabled">Disabled</div>
                </transition-group>
              </template>
            </v-switch>
          </div>

          <v-select class="mb-2" outlined dense hide-details v-model="StartTime" :label="'Start Hour'" :items="hoursList"></v-select>
          <v-select outlined dense hide-details v-model="EndTime" :label="'End Hour'" :items="hoursList"></v-select>

        </v-form>
      </template>

      <template #footer>
        <v-btn small class="blue--text" outlined @click="close_tod_schedule_list_dialog()">Cancel</v-btn>
        <v-btn small @click="save_tod_schedule_list()" class="button tabledata--text mr-2">Save</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import DayPicker from "../SubComponents/DayPicker.vue";
import TimePicker from "../SubComponents/TimePicker.vue";

import { mixin_time_utils } from "@/mixins/timeUtils.js";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mapMutations } from "vuex";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils, mixin_time_utils],
  components: {
    DialogCard,
    DayPicker,
    TimePicker
  },
  props: {
    ScheduleListDialog: Boolean,
    ScheduleListDialogMode: Object,
    ScheduleList: Array,
  },

  data() {
    return {
      startTimeMenu: false,
      endTimeMenu: false,

      StartTime: null,
      EndTime: null,
      DayOfWeek: "",
      Enable: false,

      hoursList: [ { text: "00", value: 0 }, { text: "01", value: 1 }, { text: "02", value: 2 }, { text: "03", value: 3 }, { text: "04", value: 4 }, { text: "05", value: 5 }, { text: "06", value: 6 }, { text: "07", value: 7 }, { text: "08", value: 8 }, { text: "09", value: 9 }, { text: "10", value: 10 }, { text: "11", value: 11 }, { text: "12", value: 12 }, { text: "13", value: 13 }, { text: "14", value: 14 }, { text: "15", value: 15 }, { text: "16", value: 16 }, { text: "17", value: 17 }, { text: "18", value: 18 }, { text: "19", value: 19 }, { text: "20", value: 20 }, { text: "21", value: 21 }, { text: "22", value: 22 }, { text: "23", value: 23 }, { text: "24", value: 24 } ]
    }
  },
  watch: {
    ScheduleListDialog: {
      handler(newVal) {
        if (newVal) {
          this.initScheduleListDialog();
        }
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    joinTime(time){
      return time.split(':').join('');
    },
    initScheduleListDialog() {
      const mode = this.ScheduleListDialogMode.mode;
      const item = this.ScheduleListDialogMode.item;
      if (mode === "add") {
        this.Enable = false;
        this.StartTime = 1;
        this.EndTime = 2;
        this.DayOfWeek = "All";
      } else if (mode === "edit") {
        this.Enable = this.mixinIsDefinedAndNotNull(item.Enable) ? (item.Enable === 1 ? true : false) : false;
        this.StartTime =this.mixinIsDefinedAndNotNull(item.StartTime) ? item.StartTime : 1;
        this.EndTime =this.mixinIsDefinedAndNotNull(item.EndTime) ? item.EndTime : 2;
        this.DayOfWeek = this.mixinIsDefinedAndNotNull(item.DayOfWeek) ? item.DayOfWeek : "All";
      }
    },
    save_tod_schedule_list() {
      if (!(this.$refs.form && this.$refs.form.validate())) {
        this.triggerSnackBar("error", "Mandatory fields are missing or have incorrect values.");
        return;
      }
      if(this.EndTime <= this.StartTime) {
        this.triggerSnackBar("error", "The end time must be later than the start time, at least 1hr apart.");
        return;
      }
      const mode = this.ScheduleListDialogMode.mode;
      const item = {
        Index: mode === "add" ? this.ScheduleList.length : this.ScheduleListDialogMode.item.Index,
        Enable: this.Enable ? 1 : 0,
        StartTime: this.StartTime,
        EndTime: this.EndTime,
        DayOfWeek: this.DayOfWeek
      };

      let currentScheduleList = [ ...this.ScheduleList ];
      // Make sure the current item is excluded from the list for overlap checking so that it does not conflict with the item that is being edited
      if(mode === "edit") {
        currentScheduleList.splice(this.ScheduleListDialogMode.item.Index, 1);
      }

      const overlaps = currentScheduleList.some(schedule => {
        return schedule.EndTime > item.StartTime && schedule.StartTime < item.EndTime;
      })
      if(overlaps) {
        this.triggerSnackBar("error", "Time overlap found.");
        return;
      }

      const dialogObj = {
        status: 1,
        mode: mode,
        item: item,
      };
      if (mode === "edit") dialogObj.index = this.ScheduleListDialogMode.index;

      // Set item to send back to ApnInfo.vue component for edit or add
      this.$emit("close", dialogObj);
    },
    //method invokes on close actions
    close_tod_schedule_list_dialog() {
      this.$emit("close", { status: 0 });
    },
  },
};
</script>

<style scoped>
.radio-button-enable-disable-container {
  position: relative;
}

.radio-button-enable-disable-container > * {
  position: absolute;
  top: -0.67rem;
  left: 0.5rem;
}
</style>
