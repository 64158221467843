import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{attrs:{"flat":"","multiple":"","value":_vm.openPanels}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"font-weight-light grey--text text--darken-3 text-h6"},[_c('div',[_vm._v(_vm._s(_vm.CardGroupTitle))])]),_c(VExpansionPanelContent,[_c(VDivider,{staticClass:"mb-5"}),_vm._t("body")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }