<template>
  <div>
    <v-dialog v-model="deviceConfigurationDialog" overflow-hidden persistent fullscreen>
      <v-app-bar flat style="background-color: black" fixed height="70%">
        <v-toolbar-title class="white--text">
          {{ getConfigurationTitle }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn @click="close_configuration_dialog()" icon class="white--text mr-16 pr-6">
          <v-icon class="white--text mr-1">mdi-close</v-icon>
          Back to Device List
        </v-btn>
      </v-app-bar>

      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <!-- `v-if="deviceConfigurationDialog"` here is a hack that is used due to bug in vuetify. The navigation drawer seems to close once the dialog closes for some reason. -->
      <v-navigation-drawer v-if="deviceConfigurationDialog" permanent app clipped class="navbar" hide-overlay fixed width="240">
        <div>
          <v-list shaped dense>
            <v-list-item class="listItem" active-class="border" @click="close_configuration_dialog()">
              <v-list-item-icon>
                <v-icon color="white" class="ml-n4">mdi-chevron-left</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text ml-n8">
                {{ $store.getters.GetRouterGroups == true ? singleRouterInfo.group_name : singleRouterInfo.router_name }}
              </v-list-item-title>
            </v-list-item>
  
            <v-list-group sub-group :value="true">
  
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>General settings</v-list-item-title>
                </v-list-item-content>
              </template>
  
              <v-list-item v-show="canConfigMainWifi(singleRouterInfo)" :class="{ active: openMenu == 'Main Wi-Fi' }" @click="menu_change('Main Wi-Fi')">
                <v-list-item-title>Main Wi-Fi</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigMainWifi(singleRouterInfo)" :class="{ active: openMenu == 'Guest Wi-Fi' }" @click="menu_change('Guest Wi-Fi')">
                <v-list-item-title>Guest Wi-Fi</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigConnections(singleRouterInfo)" :class="{ active: openMenu == 'Connections' }" @click="menu_change('Connections')">
                <v-list-item-title>Connections</v-list-item-title>
                <v-icon v-if="this.connectionsConfigError == true" color="red">mdi-alert</v-icon>
              </v-list-item>
  
              <v-list-item v-if="canConfigDisplay(singleRouterInfo)" :class="{ active: openMenu == 'Display' }" @click="menu_change('Display')">
                <v-list-item-title>Display</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigControl(singleRouterInfo)" :class="{ active: openMenu == 'Control' }" @click="menu_change('Control')">
                <v-list-item-title>Control</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigParentalControl(singleRouterInfo)" :class="{ active: openMenu == 'Parental Control' }" @click="menu_change('Parental Control')">
                <v-list-item-title>Parental Control</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigDataUsage(singleRouterInfo)" :class="{ active: openMenu == 'Data Usage' }" @click="menu_change('Data Usage')">
                <v-list-item-title>Data Usage</v-list-item-title>
                <v-icon v-if="this.dataUsageConfigError == true" color="red">mdi-alert</v-icon>
              </v-list-item>
  
              <v-list-item v-if="canConfigPreference(singleRouterInfo)" :class="{ active: openMenu == 'Preference' }" @click="menu_change('Preference')">
                <v-list-item-title>Preference</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigDMZ(singleRouterInfo)" :class="{ active: openMenu == 'DMZ' }" @click="menu_change('DMZ')">
                <v-list-item-title>DMZ</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigDynamicDNS(singleRouterInfo)" :class="{ active: openMenu == 'Dynamic DNS' }" @click="menu_change('Dynamic DNS')">
                <v-list-item-title>Dynamic DNS</v-list-item-title>
              </v-list-item>
  
            </v-list-group>
  
            <v-list-group sub-group v-show="canConfigLANSettings(singleRouterInfo)">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>LAN Settings</v-list-item-title>
                </v-list-item-content>
              </template>
  
              <v-list-item v-show="canConfigDHCPRange(singleRouterInfo)" :class="{ active: openMenu == 'DHCP Range' }" @click="menu_change('DHCP Range')">
                <v-list-item-title>DHCP Range</v-list-item-title>
                <v-icon v-if="dhcpConfigError == true" color="red"> mdi-alert</v-icon>
              </v-list-item>
  
              <v-list-item v-show="canConfigVPNPassthrough(singleRouterInfo)" :class="{ active: openMenu == 'VPN Passthrough' }" @click="menu_change('VPN Passthrough')">
                <v-list-item-title>VPN Passthrough</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigPortForwarding(singleRouterInfo)" :class="{ active: openMenu == 'Port Forwarding' }" @click="menu_change('Port Forwarding')">
                <v-list-item-title>Port Forwarding</v-list-item-title>
              </v-list-item>
            </v-list-group>
  
            <v-list-group sub-group v-if="canConfigSecurity(singleRouterInfo)">
  
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Security</v-list-item-title>
                </v-list-item-content>
              </template>
  
              <v-list-item v-if="canConfigBasicSecurity(singleRouterInfo)" :class="{ active: openMenu == 'Basic Security' }" @click="menu_change('Basic Security')">
                <v-list-item-title>Basic Security</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigContentFiltering(singleRouterInfo)" :class="{ active: openMenu == 'Content Filtering' }" @click="menu_change('Content Filtering')">
                <v-list-item-title>Content Filtering</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigURLFiltering(singleRouterInfo)" :class="{ active: openMenu == 'URL Filtering' }" @click="menu_change('URL Filtering')">
                <v-list-item-title>URL Filtering</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigServiceFiltering(singleRouterInfo)" :class="{ active: openMenu == 'Service Filtering' }" @click="menu_change('Service Filtering')">
                <v-list-item-title>Service Filtering</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-show="canConfigMACFiltering(singleRouterInfo)" :class="{ active: openMenu == 'MAC Filtering' }" @click="menu_change('MAC Filtering')">
                <v-list-item-title>MAC Filtering</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigTODAccess(singleRouterInfo)" :class="{ active: openMenu == 'TOD Access' }" @click="menu_change('TOD Access')">
                <v-list-item-title>TOD Access</v-list-item-title>
              </v-list-item>
  
            </v-list-group>
  
            <v-list-group v-show="canConfigSystem(singleRouterInfo)" sub-group>
  
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>System</v-list-item-title>
                </v-list-item-content>
              </template>
  
              <v-list-item v-if="canConfigNTPTime(singleRouterInfo)" :class="{ active: openMenu == 'NTP Time' }" @click="menu_change('NTP Time')">
                <v-list-item-title>NTP Time</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigMISCSettings(singleRouterInfo)" :class="{ active: openMenu == 'MISC Settings' }" @click="menu_change('MISC Settings')">
                <v-list-item-title>MISC Settings</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigRemoteAdmin(singleRouterInfo)" :class="{ active: openMenu == 'Remote Admin' }" @click="menu_change('Remote Admin')">
                <v-list-item-title>Remote Admin</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigReportSets(singleRouterInfo)" :class="{ active: openMenu == 'Report Sets' }" @click="menu_change('Report Sets')">
                <v-list-item-title>Report Sets</v-list-item-title>
              </v-list-item>
  
              <v-list-item v-if="canConfigAlertsSets(singleRouterInfo)" :class="{ active: openMenu == 'Alerts Sets' }" @click="menu_change('Alerts Sets')">
                <v-list-item-title>Alerts Sets</v-list-item-title>
              </v-list-item>
  
            </v-list-group>
          </v-list>
        </div>
  
        <div align="center">
          <v-btn
            v-show="$store.getters.GetRouterGroups == false && ($store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1')"
            class="button mt-1"
            elevation="6"
            height="45px"
            width="95px"
            dark
            @click="open_remote_command_dialog">
            Remote
            <br/>
            Command
          </v-btn>
          <v-btn
            v-show="canConfigDebugCelluar(singleRouterInfo) && $store.getters.GetRouterGroups == false && ($store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1')"
            class="button ml-2 mt-1"
            elevation="6"
            height="45px"
            width="95px"
            dark
            :loading="buttonLoader1"
            @click="debug_cellular()">
            Debug
            </v-btn>
          <v-btn
            v-show="canConfigManageFirmware(singleRouterInfo) && $store.getters.GetRouterGroups == false && ($store.getters.GetCurrentObj.user.user_role == 'SU_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN' || $store.getters.GetCurrentObj.user.user_role == 'C_ADMIN_1')"
            elevation="6"
            class="button ml-2 mt-1"
            height="45px"
            dark
            width="95px"
            :loading="buttonLoader"
            @click="manage_firmware()">
            Manage <br />
            Firmware
          </v-btn>
          <PreviewRouterConfig
            v-if="wifiConfigError == true || dhcpConfigError == true || connectionsConfigError == true || dataUsageConfigError == true"
            :wifiConfigError="wifiConfigError"
            :dhcpConfigError="dhcpConfigError"
            :connectionsConfigError="connectionsConfigError"
            :dataUsageConfigError="dataUsageConfigError">
          </PreviewRouterConfig>
  
          <PreviewRouterConfig
            v-else
            :singleRouterInfo="singleRouterInfo"
            v-on:previewDailogMethod="previewDailogMethod"
            :applyStatus="applyStatus"
            :mainWifiConnection="mainWifiConnection"
            :guestWifiConnection="guestWifiConnection"
            :guestEnabledStatus="guestEnabledStatus"
            :dhcpErrorCheck="dhcpErrorCheck"
            :dhcpEnabled="dhcpEnabled"
            :defaultGateway="defaultGateway"
            :ipStartAdd="ipStartAdd"
            :ipEndAdd="ipEndAdd"
            :leaseTime="leaseTime"
            @dhcpRequired="dhcpRequired"
            @noChangesError="noChangesError"
            @greaterThanMaxConnections="greaterThanMaxConnections"
            @renderInputValues="renderInputValues"
            @disableGuestWifi="disableGuestWifi"
          >
          </PreviewRouterConfig>
        </div>
      </v-navigation-drawer>

      <!-- The configuration will not update for the second device if it is not completely taken out of the DOM for some reason -->
      <v-main v-if="deviceConfigurationDialog" class="dialog-main-style">
        <div height="100%" v-show="this.divlink === 'Main Wi-Fi' && !canConfigFx20Wifi(singleRouterInfo)">
          <PrimaryWiFi ref="PrimaryWiFi" :guestEnabledStatus="guestEnabledStatus" @update="updateGuestComponent" @configError="configErrorCheck" @apply="applyTrue" />
        </div>
  
        <div v-show="this.divlink === 'Guest Wi-Fi' && !canConfigFx20Wifi(singleRouterInfo)">
          <GuestWiFi ref="GuestWiFi" :mainWifiConnection="mainWifiConnection" :guestWifiConnection="guestWifiConnection" @guestEnable="guestEnable" @apply="applyTrue" />
        </div>
  
        <div v-if="this.divlink === 'Main Wi-Fi' && canConfigFx20Wifi(singleRouterInfo)">
          <Fx20MainWifi ref="Fx20MainWifi" @configError="configErrorCheck" @apply="applyTrue" />
        </div>
  
        <div v-if="this.divlink === 'Guest Wi-Fi' && canConfigFx20Wifi(singleRouterInfo)">
          <Fx20GuestWifi ref="Fx20GuestWifi" @apply="applyTrue" />
        </div>
  
        <div v-if="this.divlink === 'Parental Control'">
          <ParentalControl ref="ParentalControl" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Connections'">
          <ModemsWan ref="ModemsWan" @configError="configErrorCheck" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Data Usage'">
          <DataUsage ref="DataUsage" @apply="applyTrue" @configError="configErrorCheck" />
        </div>
        <div v-if="this.divlink === 'Preference'">
          <Preference ref="Preference" @apply="applyTrue" @configError="configErrorCheck" />
        </div>
        <div v-if="this.divlink === 'DMZ'">
          <GeneralDmz ref="GeneralDmz" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Dynamic DNS'">
          <DynamicDns ref="DynamicDns" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Basic Security'">
          <GeneralSettings ref="GeneralSettings" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'MAC Filtering'">
          <AccessControl ref="AccessControl" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'TOD Access'">
          <TodAccess ref="TodAccess" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'DHCP Range'">
          <DhcpServer ref="DhcpServer" @configError="configErrorCheck" @apply="applyTrue" @dhcpErrorCheck="errorCheck" />
        </div>
        <div v-if="this.divlink === 'DHCP Reservation'">
          <DhcpReservation ref="DhcpReservation" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'VPN Passthrough'">
          <NatPassthrough ref="NatPassthrough" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Port Forwarding'">
          <PortForwarding ref="PortForwarding" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Content Filtering'">
          <ContentFiltering ref="ContentFiltering" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'URL Filtering'">
          <URLFiltering ref="URLFiltering" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Service Filtering'">
          <ServiceFiltering ref="ServiceFiltering" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'NTP Time'">
          <NTPTime ref="NTPTime" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'MISC Settings'">
          <MISCSettings ref="MISCSettings" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Remote Admin'">
          <RemoteAdmin ref="RemoteAdmin" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Report Sets'">
          <ReportSets ref="ReportSets" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Alerts Sets'">
          <AlertsSets ref="AlertsSets" @apply="applyTrue" />
        </div>
        <div v-show="this.divlink === 'Display'">
          <Misc ref="Misc" @apply="applyTrue" />
        </div>
        <div v-if="this.divlink === 'Control'">
          <ControlInfo ref="ControlInfo" @apply="applyTrue" />
        </div>
      </v-main>
    </v-dialog>
    
    <DiscardDialog :discardChangesDialog="discardChangesDialog" @close="close_discard_dialog"></DiscardDialog>
    <RemoteCommandDialog :remoteCommandDialog="remoteCommandDialog" :singleRouterInfo="singleRouterInfo" @close="close_remote_command_dialog"></RemoteCommandDialog>
    <div v-if="debugCheck == 1">
      <RouterCellular :cellularDialog="cellularDialog" :cellularDetails="cellularDetails" :deviceDetails="deviceDetails" :CaElements="CaElements" @clicked="close_cellular" />
    </div>
    <div v-if="manageCheck == true">
      <ManageFirmware :manageFirmwareDialog="manageFirmwareDialog" :firmwareRow="firmwareRow" :firmwareResponse="firmwareResponse" @clicked="close_firm"></ManageFirmware>
    </div>

  </div>
</template>
<script>
import PrimaryWiFi from "@/components/DeviceConfigurations/DeviceConfigurationComponents/PrimaryWiFi.vue";
import ModemsWan from "@/components/GeneralSettings/ModemsWan.vue";
import DataUsage from "@/components/GeneralSettings/DataUsage.vue";
import DhcpServer from "@/components/LanSettings/DhcpServer.vue";
import DhcpReservation from "@/components/LanSettings/DhcpReservation.vue";
import GeneralDmz from "@/components/GeneralSettings/GeneralDmz.vue";
import DynamicDns from "@/components/GeneralSettings/DynamicDns.vue";
import NatPassthrough from "@/components/LanSettings/NatPassthrough.vue";
import PortForwarding from "@/components/LanSettings/FXPortForwarding.vue";
import GuestWiFi from "@/components/GeneralSettings/GuestWiFi.vue";
import Fx20GuestWifi from "@/components/GeneralSettings/Fx20GuestWifi.vue";
import Fx20MainWifi from "@/components/GeneralSettings/Fx20MainWifi.vue";
import Fx20Usage from "@/components/GeneralSettings/Fx20Usage.vue";
import Preference from "@/components/GeneralSettings/Preference.vue";
import ParentalControl from "@/components/Security/ParentalControl.vue";
import Misc from "@/components/GeneralSettings/ModemTabs/Misc.vue";
import ControlInfo from "@/components/GeneralSettings/ModemTabs/ControlInfo.vue";
import GeneralSettings from "@/components/Security/GeneralSettings.vue";
import AccessControl from "@/components/Security/AccessControl.vue";
import CaptivePortal from "@/components/Security/CaptivePortal.vue";
import TodAccess from "@/components/Security/TodAccess.vue";
import URLFiltering from "@/components/Security/URLFiltering.vue";
import ContentFiltering from "@/components/Security/ContentFiltering.vue";
import ServiceFiltering from "@/components/Security/ServiceFiltering.vue";
import OperationMode from "@/components/System/OperationMode.vue";
import NTPTime from "@/components/System/NTPTime.vue";
import MISCSettings from "@/components/System/MISCSettings.vue";
import RemoteAdmin from "@/components/System/RemoteAdmin.vue";
import ReportSets from "@/components/System/ReportSets.vue";
import AlertsSets from "@/components/System/AlertsSets.vue";
import PreviewRouterConfig from "@/views/PreviewRouterConfig.vue";
import DiscardDialog from "@/components/DiscardDialog.vue";
import { get_module_info } from "@/mixins/GetModelConfiguration.js";
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { get_cellular_info } from "@/mixins/GetCellularInfo.js";
import ManageFirmware from "@/components/Devices/DeviceDialogs/ManageFirmware.vue";
import SnackBar from "@/components/SnackBar.vue";
import { mapGetters } from "vuex";

import RemoteCommandDialog from "@/components/Devices/DeviceDialogs/RemoteCommandDialog.vue";
import RouterCellular from "@/components/Configuration/Routers/RouterCellular.vue";
import { check_for_firmware_update } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export default {
  props: {
    deviceConfigurationDialog: Boolean,
    singleRouterInfo: Object,
  },
  mixins: [get_module_info, get_cellular_info, router_model_utils],
  components: {
    DiscardDialog,
    ManageFirmware,
    ModemsWan,
    DataUsage,
    Preference,
    Misc,
    ControlInfo,
    DhcpServer,
    DhcpReservation,
    PrimaryWiFi,
    GuestWiFi,
    Fx20GuestWifi,
    Fx20MainWifi,
    Fx20Usage,
    GeneralSettings,
    AccessControl,
    CaptivePortal,
    TodAccess,
    URLFiltering,
    ContentFiltering,
    ServiceFiltering,
    ParentalControl,
    OperationMode,
    NTPTime,
    MISCSettings,
    RemoteAdmin,
    ReportSets,
    AlertsSets,
    PreviewRouterConfig,
    GeneralDmz,
    DynamicDns,
    NatPassthrough,
    PortForwarding,
    SnackBar,
    RouterCellular,
    RemoteCommandDialog,
  },

  data() {
    return {
      buttonLoader: false,
      buttonLoader1: false,
      SnackBarComponent: {},
      remoteCommandDialog: false,
      discardChangesDialog: false,
      divlink: "Main Wi-Fi",
      openMenu: "Main Wi-Fi",
      firmwareResponse: {},
      manageCheck: false,
      manageFirmwareDialog: false,
      firmwareRow: {},
      deviceDetails: {},
      cellularDetails: {},
      debugCheck: 0,
      CaElements: [],
      applyStatus: false,
      guestEnabledStatus: null,
      mainWifiConnection: null,
      guestWifiConnection: null,
      dhcpErrorCheck: false,
      dhcpEnabled: null,
      defaultGateway: null,
      ipStartAdd: null,
      ipEndAdd: null,
      leaseTime: null,
      menuChangeStorage: null,
      wifiConfigError: false,
      dhcpConfigError: false,
      connectionsConfigError: false,
      dataUsageConfigError: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetDeviceWANStatus", "getterGetDeviceDHCPClients", "getterGetDeviceConfigurations", "getterGetDeviceInfoIsLoaded"]),
    getDeviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    getDeviceID() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_id : '';
    },
    getConfigurationTitle() {
      return this.$store.getters.GetRouterGroups ? this.singleRouterInfo.group_name : `Device Detail - ${this.singleRouterInfo.router_name}(${this.getDeviceID})`;
    }
  },
  methods: {
    // both on click parent and device details seem to do the same thing
    close_configuration_dialog() {
      if(this.applyStatus) {
        this.discardChangesDialog = true;
      } else {
        this.$emit("close");
      }
    },
    close_discard_dialog(val) {
      if (val == 0) {
        this.discardChangesDialog = false;
      } else if (val == 1) {
        // reset error states
        this.wifiConfigError = false;
        this.dhcpConfigError = false;
        this.connectionsConfigError = false;
        this.dataUsageConfigError = false;

        this.discardChangesDialog = false;
        this.applyStatus = false;
        this.$emit("close");
      }
    },
    previewDailogMethod(val) {
      this.$emit("previewDailogMethod", val);
    },
    async debug_cellular() {
      this.buttonLoader1 = true;
      this.$store.commit("set_current_router_detail_object", this.singleRouterInfo);
      await this.get_cellular_info();
      this.buttonLoader1 = false;
      this.cellularDialog = true;
      this.debug_cellular_details();
    },

    debug_cellular_details() {
      this.cellularDetails = this.cellularInfoTopic;
      var caList = this.cellularDetails.CAList;
      this.CaElements = [];
      var self = this;
      if (Array.isArray(caList) == true) {
        caList.forEach((element) => {
          self.CaElements.push(element.Band + "/" + element.BandWidth);
        });
      }
      this.deviceDetails = this.singleRouterInfo;
      this.debugCheck = 1;
    },
    async manage_firmware() {
      this.buttonLoader = true;

      await this.check_fimrware_update();
      this.buttonLoader = false;
    },
    async check_fimrware_update() {
      try {
        let result = await API.graphql(
          graphqlOperation(check_for_firmware_update, {
            input: {
              router_id: this.singleRouterInfo.router_id,
            },
          })
        );
        if (result.data.check_for_firmware_update != null) {
          this.firmwareResponse = result.data.check_for_firmware_update;
        }

        this.firmwareRow = this.singleRouterInfo;
        this.manageCheck = true;
        this.manageFirmwareDialog = true;
      } catch (error) {}
    },

    //methods invokes on firmware close action
    close_firm(val) {
      if (val == 0) {
        this.manageFirmwareDialog = false;
      } else if (val == 1) {
        this.manageFirmwareDialog = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "Router firmware scheduled!!",
          timeout: 5000,
          Top: true,
        };
      }
    },
    // ----------- Remote Command --------------
    open_remote_command_dialog() {
      this.remoteCommandDialog = true;
    },
    close_remote_command_dialog() {
      this.remoteCommandDialog = false;
    },

    errorMsg(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.$store.commit("SetCongifStatusCheck", false);
    },
    close_cellular(val) {
      if (val == 0) {
        this.cellularDialog = false;
      } else if (val == 1) {
        this.cellularDialog = false;
      }
    },
    menu_change(value) {
      this.divlink = value;
      this.openMenu = value;
    },
    applyTrue() {
      this.applyStatus = true;
    },

    noChangesError() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "No changes have been made",
        timeout: 5000,
        Top: true,
      };
    },
    noGuestWifiError() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "Guest Wi-Fi must have at least 1 connection",
        timeout: 5000,
        Top: true,
      };
    },

    dhcpRequired() {
      if (this.ipStartAdd >= this.ipEndAdd) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "IP Pool Starting Address must be less than Ending Address",
          timeout: 5000,
          Top: true,
        };
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Provide Mandatory Fields",
          timeout: 5000,
          Top: true,
        };
      }
    },
    //Gene 5/23/23
    //Checks if mandatory configuration menus are filled. Add icon if empty
    configErrorCheck(val, source) {
      if (source == "wifi") {
        this.wifiConfigError = val;
      } else if (source == "dhcp") {
        this.dhcpConfigError = val;
      } else if (source == "connections") {
        this.connectionsConfigError = val;
      } else if (source == "data") {
        this.dataUsageConfigError = val;
      }
    },

    //Gene 4/6/23 Method to track guest wifi enabled/disabled
    guestEnable(val) {
      this.guestEnabledStatus = val;
    },
    updateGuestComponent(condition, val) {
      if (condition == "main") {
        this.mainWifiConnection = val;
      } else if (condition == "guest") {
        this.guestWifiConnection = val;
      }
    },

    greaterThanMaxConnections() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: "Guest Wi-Fi must have at least 1 connection",
        timeout: 5000,
        Top: true,
      };
    },

    errorCheck(data) {
      this.dhcpErrorCheck = true;
      this.ipStartAdd = data.ipStartAdd;
      this.ipEndAdd = data.ipEndAdd;
      this.defaultGateway = data.defaultGateway;
      this.leaseTime = data.leaseTime;
      this.dhcpEnabled = data.dhcpEnabled;
    },

    revert_DHCP() {
      this.$refs.DhcpServer.bind_default_data();
      this.$refs.DhcpServer.validate_data();
      this.dhcpErrorCheck = false;
      this.divlink = this.menuChangeStorage;
      this.openMenu = this.menuChangeStorage;
      this.menuChangeStorage = null;
    },

    //Gene 6/18/23 Update UI when item is deleted
    renderInputValues(path) {
      switch (path) {
        //Gene 6/19/23 cases for Main WiFi
        case "RadioBand":
        case "WiFiName":
        case "WiFiPassword":
        case "AuthenticationMethod":
        case "MaximumConnections":
        case "GuestMaximumConnections":
        case "PrivacySeparator":
        case "SSIDStealth":
        case "EncryptionMethod":
        case "InActiveTime":
          if (this.canConfigFx20Wifi(this.singleRouterInfo)) {
            this.$refs.Fx20MainWifi.modified_data();
          } else {
            this.$refs.PrimaryWiFi.modified_data();
            this.$refs.PrimaryWiFi.get_mainwifi_count();
          }
          break;
        //Gene 6/19/23 cases for guest WiFi
        case "GuestRadioBand":
        case "GuestWiFiName":
        case "GuestWiFiPassword":
        case "GuestAuthenticationMethod":
        case "GuestPrivacySeparator":
        case "GuestSSIDStealth":
        case "GuestEncryptionMethod":
        case "GuestMaximumConnections":
        case "Guest3WiFiEnable":
          if (this.canConfigFx20Wifi(this.singleRouterInfo)) {
            this.$refs.Fx20GuestWifi.format_guest_nwk(this.$store.getters.GetGuestNetwork);
          } else {
            this.$refs.GuestWiFi.update_deleted_item(path);
          }
          break;
        case "AutomaticDNSEnable":
        case "ConnectionMode":
        case "AuthenticationMethod":
        case "ConnectionType":
        case "DataRoaming":
        case "PrimaryDNSAddress":
        case "SecondaryDNSAddress":
          this.$refs.ModemsWan.general_modified_data();
          break;
        //Gene 6/20/23 Cases for Display
        case "ShowUsage":
        case "DisplayTimeout":
        case "LEDIndicatorEnable":
        case "DisplayWiFiNamePassword":
        case "WebAdminPageInfoDisplay":
          this.$refs.Misc.fetch_data();
          break;
        //Gene 6/21/23 Cases for Control
        case "AccessWebInterface":
        case "PowerBankEnable":
        case "StopChargingBatteryLevel":
        case "FactoryResetButtonEnable":
        case "0.Password":
          this.$refs.ControlInfo.bind_default_structure();
          var data = localStorage.getItem("controlInfo");
          if (JSON.parse(data) == true) {
            this.$refs.ControlInfo.fetch_data();
          }
          var admin = localStorage.getItem("remoteAdmin");
          if (JSON.parse(admin) == true) {
            this.$refs.ControlInfo.modified_data();
          }
          break;
        //Gene 6/21/23 Cases for Data Usage
        case "UsageCycle":
        case "CycleStartDayofMonth":
        case "MonthlyLimitAlert":
        case "MonthlyLimit":
        case "CycleStartDayofYear":
          this.$refs.DataUsage.modified_data();
          break;
        //Gene 6/22/23 Cases for DHCP Range
        case "DHCPServerEnable":
        case "IPAddrStarting4th":
        case "IPAddrEnding4th":
        case "LeaseTime":
        case "DefaultGateway":
          this.$refs.DhcpServer.bind_modified_data();
          break;
        case "PortMappingList":
        case "Enable":
          this.$refs.GeneralSettings.modify_port_forwarding();
          break;
        case "IPv4Firewall":
          this.$refs.GeneralSettings.modified_data();
          break;
        case "FilterSet":
        case "MACFilteringRules":
          this.$refs.AccessControl.bind_modified_data();
          break;
        case "TimeOfDayAccess":
          this.$refs.TodAccess.modified_data();
          break;
        case "ParentalEnable":
          this.$refs.ParentalControl.modified_data();
          break;
      }
    },
    //Gene 6/20/23
    //Disable guest wifi when it is reverted in the preview
    disableGuestWifi(val) {
      this.guestEnabledStatus = val;
    },
    clear_store_data() {
      localStorage.setItem("dhcpServer", false);
      localStorage.setItem("dhcpReservation", false);
      localStorage.setItem("ipSettings", false);
      this.$store.commit("SetDhcpReservation", {});
      this.$store.commit("SetDhcpServer", {});
      this.$store.commit("SetIPsettings", {});
      this.$store.commit("SetWiredLan", false);
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetAccessControl", {});
      localStorage.setItem("accessControl", false);
      localStorage.setItem("miscSettings", false);
      this.$store.commit("SetMiscSettings", {});
      localStorage.setItem("operationMode", false);
      this.$store.commit("SetSystem", false);
      localStorage.setItem("ntpTimer", false);
      this.$store.commit("SetNTPTimer", {});
      localStorage.setItem("remoteAdmin", false);
      this.$store.commit("SetRouterAdmin", []);
      localStorage.setItem("reportData", false);
      this.$store.commit("SetReportSets", {});
      localStorage.setItem("alertSet", false);
      this.$store.commit("SetAlertSets", {});
      localStorage.setItem("automacticReebot", false);
      this.$store.commit("SetAutoReboot", {});
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetGuestNetwork", {});
      localStorage.setItem("primaryNetwork", false);
      localStorage.setItem("inactiveTime", false);
      this.$store.commit("SetFirewall", false);
      this.$store.commit("SetFirewallGeneral", {});
      localStorage.setItem("firewallGeneral", false);
      this.$store.commit("SetUrlFilter", {});
      localStorage.setItem("urlFiltering", false);
      this.$store.commit("SetContentFilter", {});
      localStorage.setItem("contentFilter", false);
      this.$store.commit("SetServiceFilter", {});
      localStorage.setItem("serviceControl", false);
      this.$store.commit("SetInternet", false);
      this.$store.commit("SetDataUsage", {});
      localStorage.setItem("dataUsage", false);
      this.$store.commit("SetPreference", {});
      localStorage.setItem("preference", false);
      localStorage.setItem("dynamicDns", false);
      this.$store.commit("SetInteretObject", {});
      this.$store.commit("SetDynamicDns", {});
      localStorage.setItem("interntModified", false);
      localStorage.setItem("dmzInfo", false);
      this.$store.commit("SetDmzObject", {});
      localStorage.setItem("natPass", false);
      this.$store.commit("SetNATPassthrough", {});
      this.$store.commit("SetPortForward", {});
      localStorage.setItem("portForward", false);
      this.$store.commit("SetFXPortForward", {});
      localStorage.setItem("fxPortForward", false);
      this.$store.commit("SetDeletedPortForward", []);
      localStorage.setItem("deletedPortForward", false);
      this.$store.commit("SetDeletedFXPortForward", []);
      localStorage.setItem("deletedFXPortForward", false);
      this.$store.commit("SetDeletedAccessControl", []);
      localStorage.setItem("deletedAccessControl", false);
      this.$store.commit("SetDeletedTimeOfDay", []);
      localStorage.setItem("deletedTimeOfDay", false);
      localStorage.setItem("ipv4Set", false);
      this.$store.commit("SetIPV4", {});
      localStorage.setItem("ipv6Set", false);
      localStorage.setItem("controlInfo", false);
      this.$store.commit("SetIPv6Settings", {});
      this.$store.commit("SetParentControl", {});
      localStorage.setItem("parentalControl", false);
      this.$store.commit("SetModemGeneral", {});
      localStorage.setItem("General", false);
      this.$store.commit("SetSimData", {});
      localStorage.setItem("Sim", false);
      this.$store.commit("SetApnInfo", {});
      localStorage.setItem("Apn", false);
      this.$store.commit("SetSmsInfo", {});
      localStorage.setItem("Sms", false);
      this.$store.commit("SetGpsInfo", {});
      localStorage.setItem("Gps", false);
      this.$store.commit("SetMiscInfo", {});
      localStorage.setItem("Misc", false);
      this.$store.commit("SetUpnpInfo", {});
      localStorage.setItem("Upnp", false);
      localStorage.setItem("guestNetwork", false);
      this.$store.commit("SetWirelessLan", false);
      this.$store.commit("SetGuestNetwork", {});
      localStorage.setItem("primaryNetwork", false);
      this.$store.commit("SetTodAcces", {});
      localStorage.setItem("Tod", false);
      this.$store.commit("SetCaptivePortal", {});
      localStorage.setItem("captive", false);
      this.$store.commit("SetCongifStatusCheck", false);
      localStorage.setItem("totalConnectionCheck", false);
    },

  },
};
</script>
<style>
.listItem {
  background-color: #71c9f0;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 10px;
  margin-right: 10px;
}
::v-deep.v-btn {
  padding-left: 12px;
  padding-right: 12px;
}

.active {
  background-color: rgb(99, 99, 99);
  color: white !important;
}

.dialog-main-style {
  background: #ffffff;
  min-height: 100vh;
}
</style>
