import { render, staticRenderFns } from "./DataUsage.vue?vue&type=template&id=453361f1&scoped=true&"
import script from "./DataUsage.vue?vue&type=script&lang=js&"
export * from "./DataUsage.vue?vue&type=script&lang=js&"
import style0 from "./DataUsage.vue?vue&type=style&index=0&id=453361f1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453361f1",
  null
  
)

export default component.exports