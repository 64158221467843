<template>
  <div>
    <v-card flat>
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12" lg="6" xl="6">
          <div class="subtitle-1">
            <strong>
              Main Wi-Fi -
              <span class="statusOnline--text">Enabled</span>
            </strong>
          </div>
          <v-row class="mt-1" no-gutters>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">SSID</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings ? getWirelessLANSettings.MainWiFi.WiFiName : "-" }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">Password</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings ? getWirelessLANSettings.MainWiFi.WiFiPassword : "-" }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">Wi-Fi Band</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings && getWirelessLANSettings.MainWiFi.RadioBand ? '5GHz' : '2.4GHz' }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" sm="12" xs="12" lg="6" xl="6">
          <div class="subtitle-1">
            <strong>
              Guest Wi-Fi -
              <span v-if="getWirelessLANSettings && getWirelessLANSettings.Guest3WiFiEnable" class="statusOnline--text">Enabled</span>
              <span v-else class="statusOffline--text">Disabled</span>
            </strong>
          </div>
          <v-row v-if="getWirelessLANSettings && getWirelessLANSettings.Guest3WiFiEnable" class="mt-1" no-gutters>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">SSID</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings ? getWirelessLANSettings.Guest3WiFi.WiFiName : '' }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">Password</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings ? getWirelessLANSettings.Guest3WiFi.WiFiPassword : '' }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="12" xl="5" lg="5">
              <v-card height="30px" class="sub_heading_card" outlined elevation="2" tile>
                <div class="ml-1">Wi-Fi Band</div>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" sm="12" xl="7" lg="7">
              <v-card height="30px" outlined elevation="2" tile>
                <div class="ml-1">
                  {{ getWirelessLANSettings && getWirelessLANSettings.Guest3WiFi.RadioBand ? '5GHz' : '2.4GHz' }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([ 'getterGetDeviceConfigurations' ]),
    getWirelessLANSettings() {
      return this.getterGetDeviceConfigurations.configuration_module.WirelessLAN ? this.getterGetDeviceConfigurations.configuration_module.WirelessLAN.Value : '';
    }
  },

};
</script>