import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),(_vm.$store.getters.GetCurrentObj.user.user_role !== 'SU_ADMIN')?_c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Connections")])]),_c('General',{ref:"General",on:{"apply":_vm.apply,"configError":_vm.configErrorCheck}})],1)],1)],1):_c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"text-h6 primary--text"},[_c('b',{staticClass:"heading-font"},[_vm._v("Connections")])]),_c(VTabs,{model:{value:(_vm.modemTabs),callback:function ($$v) {_vm.modemTabs=$$v},expression:"modemTabs"}},[_c(VTab,[_vm._v("General")]),_c(VTab,[_vm._v("APN")]),_c(VTabItem,{staticClass:"mt-4"},[_c('General',{ref:"General",on:{"apply":_vm.apply,"configError":_vm.configErrorCheck}})],1),_c(VTabItem,{staticClass:"mt-4"},[_c('Apn',{on:{"apply":_vm.apply}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }