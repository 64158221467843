import { render, staticRenderFns } from "./VPNPassthroughChangeCard.vue?vue&type=template&id=1289f854&"
import script from "./VPNPassthroughChangeCard.vue?vue&type=script&lang=js&"
export * from "./VPNPassthroughChangeCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports