import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('div',{staticClass:"subtitle-2"},[_c('b',{staticClass:"subheading-font"},[_vm._v(" Internet usage will collect the amount of data (in bytes, kilobytes, megabytes, or gigabytes) in a given period of Daily or Monthly. The device will report the summarized data to Server synced with the schedule period.")])]),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"pt-2"},[_vm._v("Daily")]),_c('div',{staticClass:"configurationCard"},[_c(VRow,{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,{attrs:{"label":"Limit*","outlined":"","dense":"","hint":"(eg:5)","maxlength":"3"},on:{"keypress":function($event){_vm.is_Number($event), _vm.specal_charaters($event)},"paste":function($event){return _vm.specal_charaters($event)},"input":function($event){return _vm.convert_megabytes(_vm.dailyLimit)}},model:{value:(_vm.dailyLimit),callback:function ($$v) {_vm.dailyLimit=$$v},expression:"dailyLimit"}})],1),_c(VCol,[_c(VTextField,{staticClass:"ml-1",attrs:{"label":"Usage Unit","outlined":"","dense":"","readonly":""},on:{"keypress":function($event){return _vm.is_Number($event)}},model:{value:(_vm.usageUnit),callback:function ($$v) {_vm.usageUnit=$$v},expression:"usageUnit"}})],1)],1),_c(VSwitch,{staticClass:"mt-n2",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Limit Alert")])]},proxy:true}]),model:{value:(_vm.dailylimitAlert),callback:function ($$v) {_vm.dailylimitAlert=$$v},expression:"dailylimitAlert"}})],1)]),_c(VCol,{attrs:{"cols":"6"}})],1)],1)],1),_c(VLayout,{attrs:{"justify-center":""}},[_c(VCardActions,[_c(VBtn,{staticClass:"button",attrs:{"dark":""},on:{"click":function($event){return _vm.validate_data()}}},[_vm._v("Apply")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }