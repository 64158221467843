import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c(VDialog,{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[_c(VCard,{attrs:{"flat":"","width":"auto"}},[_c(VToolbar,{staticClass:"bannercolor",attrs:{"dark":""}},[_c(VToolbarTitle,{staticClass:"dashboard-router"},[_vm._v("User Profile")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog_emit((_vm.Toggle = 1))}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VContainer,{staticClass:"pt-0",attrs:{"fluid":""}},[(this.navigateLink === 'Account Information')?_c('div',[_c('AccountInfo',{attrs:{"accountAccess":_vm.accountAccess,"dataResponse":_vm.dataResponse,"detailsCheck":_vm.detailsCheck,"impersonatedUser":_vm.impersonatedUser},on:{"subheader":_vm.title_info,"previousStep":_vm.previous_module,"currentUser":_vm.current_data}})],1):_vm._e(),(this.navigateLink === 'Company Details')?_c('div',[_c('CompanyDetails',{attrs:{"dataResponse":_vm.dataResponse,"timeZoneArray":_vm.timeZoneArray,"previousCheck":_vm.previousCheck},on:{"subheader":_vm.title_info,"finalstep":_vm.final_module,"errorMsg":_vm.company_error}})],1):_vm._e(),(this.navigateLink === 'Account Settings')?_c('div',[_c('AccountInformation',{attrs:{"accountAccess":_vm.accountAccess,"timeZoneArray":_vm.timeZoneArray,"dataResponse":_vm.dataResponse,"informationCheck":_vm.informationCheck},on:{"subheader":_vm.title_info,"infoStep":_vm.info_module,"currentUser":_vm.current_data}})],1):_vm._e(),(this.navigateLink === 'Licenses')?_c('div',[_c('Licenses')],1):_vm._e(),(this.navigateLink === 'Remote Support')?_c('div',[_c('RemoteSupport',{attrs:{"dataResponse":_vm.dataResponse},on:{"currentUser":_vm.current_data}})],1):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }